// ['en', 'de']

export default {
  [`Finances`]: [
    `Finances`,
    `Finanzen`,
  ],
  [`See all previous settlements with Flatastic Premium`]: [
    `See all previous settlements with Flatastic Premium`,
    `Siehe alle vorherigen Kassenstürze mit Flatastic Premium`,
  ],
  [`Your Archive is empty. Every time you “settle up” we’ll display the entries here.`]: [
    `Your Archive is empty. Every time you “settle up” we’ll display the entries here.`,
    `Das Archiv is leer. Nach einem Kassensturz findest du die dazugehörigen Einträge hier wieder.`,
  ],
  [`Get Premium`]: [
    `Get Premium`,
    `Premium holen`,
  ],
}
