// Wrapper for https://github.com/EddyVerbruggen/Custom-URL-scheme#ios-usage

export default angular.module('flatastic.customUrl', [])

.run(['$rootScope', '$window', function($rootScope, $window) {
  $window.handleOpenURL = function(url) {
    console.debug('$window.handleOpenURL', url);
    $rootScope.$broadcast('customURL', url);
  };
}])

.name;

