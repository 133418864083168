// ['en', 'de']

export default {
  [`The Flatastic you love, only better!`]: [
    `The Flatastic you love, only better!`,
    `Das Flatastic, das du liebst. Nur besser!`,
  ],
  [`Our home`]: [
    `Our home`,
    `Unser Zuhause`,
  ],
  [`Invite members`]: [
    `Invite members`,
    `Mitbewohner einladen`,
  ],
  [`Name`]: [
    `Name`,
    `Name`,
  ],
  [`Type here…`]: [
    `Type here…`,
    `Hier tippen…`,
  ],
  [`Type`]: [
    `Type`,
    `Art`,
  ],
  [`Members`]: [
    `Members`,
    `Mitbewohner`,
  ],
  [`Home Photo`]: [
    `Home Photo`,
    `Unser Foto`,
  ],
  [`Home Photo`]: [
    `Home Photo`,
    `Unser Foto`,
  ],
  [`None selected`]: [
    `None selected`,
    `Keins ausgewählt`,
  ],
  [`City`]: [
    `City`,
    `Stadt`,
  ],
  [`e.g. Flatastic City`]: [
    `e.g. Flatastic City`,
    `z.B. Flatastic City`,
  ],
  [`e.g. 12345`]: [
    `e.g. 12345`,
    `z.B. 12345`,
  ],
  [`Postal Code`]: [
    `Postal Code`,
    `PLZ`,
  ],
  [`Country`]: [
    `Country`,
    `Land`,
  ],
  [`Save`]: [
    `Save`,
    `Speichern`,
  ],
  [`My Profile`]: [
    `My Profile`,
    `Mein Profil`,
  ],
  [`Name`]: [
    `Name`,
    `Name`,
  ],
  [`Profile Photo`]: [
    `Profile Photo`,
    `Profilfoto`,
  ],
  [`Email`]: [
    `Email`,
    `Email`,
  ],
  [`Change Password`]: [
    `Change Password`,
    `Passwort ändern`,
  ],
  [`Logout`]: [
    `Logout`,
    `Abmelden`,
  ],
  [`General Settings`]: [
    `General Settings`,
    `Allgemeine Einstellungen`,
  ],
  [`Notifications`]: [
    `Notifications`,
    `Benachrichtigungen`,
  ],
  [`Test Notifications`]: [
    `Test Notifications`,
    `Benachrichtigungen testen`,
  ],
  [`Currency`]: [
    `Currency`,
    `Währung`,
  ],
  [`Language`]: [
    `Language`,
    `Sprache`,
  ],
  [`Premium`]: [
    `Premium`,
    `Premium`,
  ],
  [`Get Premium`]: [
    `Get Premium`,
    `Premium holen`,
  ],
  [`Subscription Info`]: [
    `Subscription Info`,
    `Abo-Informationen`,
  ],
  [`Restore Purchases`]: [
    `Restore Purchases`,
    `Käufe wiederherstellen`,
  ],
  [`Support & Legal`]: [
    `Support & Legal`,
    `Support & Rechtliches`,
  ],
  [`Vote on Features`]: [
    `Vote on Features`,
    `Für Features abstimmen`,
  ],
  [`FAQs`]: [
    `FAQs`,
    `Fragen & Antworten`,
  ],
  [`Give Feedback`]: [
    `Give Feedback`,
    `Feedback geben`,
  ],
  [`Legal Stuff`]: [
    `Legal Stuff`,
    `Rechtliches`,
  ],
  [`Connect`]: [
    `Connect`,
    `Connect`,
  ],
  [`Rate Flatastic`]: [
    `Rate Flatastic`,
    `Flatastic bewerten`,
  ],
  [`Share`]: [
    `Share`,
    `Flatastic teilen`,
  ],
  [`Follow us on Twitter`]: [
    `Follow us on Twitter`,
    `Folge uns auf Twitter`,
  ],
  [`Follow us on Facebook`]: [
    `Follow us on Facebook`,
    `Folge uns auf Facebook`,
  ],
  [`Version Number`]: [
    `Version {{ version }}`,
    `Version {{ version }}`,
  ],

  [`Made with ❤️ in Switzerland and Germany`]: [
    `Made with ❤️ in Switzerland and Germany`,
    `Mit ❤️ in Schweiz und Deutschland gemacht`,
  ],


  [`SET__SHARE-TITLE`]: [
    `Have you heard of Flatastic?`,
    `Kennst du Flatastic?`,
  ],
  [`SET__SHARE-MSG`]: [
    `Hey,\n\nhave you already heard of Flatastic, the App for you and your shared flat? The App has a shared shopping list, provides an overview over the shared finances and politely reminds you of your tasks.`,
    `Hey,\n\nkennst du schon Flatastic, die App für dich und deine WG? Die App hat eine geteilte Einkaufsliste, behält die Übersicht über die WG-Kasse und erinnert an die Aufgaben.`,
  ],
  [`SET__LAW_STUFF-TITLE`]: [
    `Flatastic`,
    `Flatastic`,
  ],
  [`SET__LAW_STUFF-BTN_AGB`]: [
    `Terms & Conditions`,
    `AGB`,
  ],
  [`SET__LAW_STUFF-BTN_PRIVACY`]: [
    `Privacy`,
    `Privatsphäre und Datenschutz`,
  ],
  [`BTN_CANCEL`]: [
    `Cancel`,
    `Abbrechen`,
  ],

  // User Photo
  [`USR__IMAGE-BTN`]: [
    `Click on the profile picture to change it.`,
    `Klicke auf das Profilbild um es zu ändern`,
  ],
  [`USR__IMAGE_DIALOGUE-TITLE`]: [
    `Please choose how you want to update your profile image`,
    `Bitte wähle aus, wie du dein Profilfoto ändern möchtest`,
  ],
  [`USR__IMAGE_DIALOGUE-FROM_GALLERY`]: [
    `Choose existing photo`,
    `Bild aus Galerie`,
  ],
  [`USR__IMAGE_DIALOGUE-TAKE_PHOTO`]: [
    `Take photo`,
    `Foto machen`,
  ],
  [`USR__IMAGE_ERROR_DIALOGUE-TITLE`]: [
    `Upload failed`,
    `Upload fehlgeschlagen`,
  ],
  [`USR__IMAGE_ERROR_DIALOGUE-MSG`]: [
    `Upload was unsuccessful: \n\n {{ error }}`,
    `Folgender Fehler ist aufgetreten:\n\n {{ error }}`,
  ],

  // Share Access Code
  [`WG__SHARE_WG_CODE-SUBJ`]: [
    `An app for our home`,
    `Eine App für unser Zuhause`,
  ],
  [`WG__SHARE_WG_CODE-MSG`]: [
    `Hey {{ name }},\n\nI made us a new Flatastic Home – It’s the perfect app to make living together a breeze. You can download it below, create an account, then use the key "{{ wgCode }}" to join our Home.\n\nDownload the Flatastic App (https://flatastic-app.com/app) and move in!`,
    `Hey {{ name }},\n\nIch habe für uns ein neues Zuhause bei Flatastic angelegt. Es ist die perfekte App um den Haushalt zu organisieren und zu führen.\nDu kannst sie unten downloaden und mit dem Zugangscode "{{ wgCode }}" einziehen.\n\nHier der Link zur App: https://flatastic-app.com/app`,
  ],
  [`WG__SHARE_WG_CODE_SUCCESS-TITLE`]: [
    `Well done!`,
    `Gut gemacht!`,
  ],
  [`WG__SHARE_WG_CODE_SUCCESS-MSG`]: [
    `Nice! By entering the access code {{ name }} can now move into your home.`,
    `Sehr gut! {{ name }} kann sich jetzt mit dem Zugangscode anmelden`,
  ],

  //
  [`WG__SELECT_TYPE`]: [
    `Household`,
    `Haushaltstyp`,
  ],
  [`WG__PLEASE_CHOOSE`]: [
    `Please choose`,
    `Bitte auswählen`,
  ],
  [`WG__TYPES-FAMILY`]: [
    `Family`,
    `Familie`,
  ],
  [`WG__TYPES-COUPLE`]: [
    `Couple`,
    `Paar`,
  ],
  [`WG__TYPES-SHAREDFLAT`]: [
    `Shared flat`,
    `WG`,
  ],

  // Save changes
  [`WG__SAVE_SUCCESS-TITLE`]: [
    `Your Home`,
    `Dein Haushalt`,
  ],
  [`WG__SAVE_SUCCESS-MSG`]: [
    `Your changes have been saved.`,
    `Die Änderungen wurden gespeichert.`,
  ],

  //
  [`USR__CHANGE_EMAIL`]: [
    `Change email address`,
    `E-Mail-Adresse ändern`,
  ],
  [`USR__CHANGE_EMAIL-EXTRAINFO`]: [
    `Please enter a valid email address.`,
    `Bitte gib eine gültige E-Mail-Adresse ein.`,
  ],
  [`USR__CHANGE_EMAIL-SUCCESS`]: [
    `Your email address was updated to {{ email }}`,
    `Deine E-Mail-Adresse ist jetzt {{ email }}`,
  ],

  [`USR__LOGOUT-TITLE`]: [
    `Logout`,
    `Abmelden`,
  ],
  [`USR__LOGOUT-MSG`]: [
    `Do you really want to log out?`,
    `Möchtest du dich wirklich abmelden?`,
  ],
  [`USR__LOGOUT-BTN`]: [
    `Logout`,
    `Abmelden`,
  ],
}

