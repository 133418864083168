export default {
  [`Shopping List`]: [
    `Shopping List`,
    `Einkaufen`,
  ],
  [`To buy`]: [
    `To buy`,
    `Zu kaufen`,
  ],
  [`Recently used`]: [
    `Recently used`,
    `Kürzlich gebraucht`,
  ],
  [`Clear Recent`]: [
    `Clear Recent`,
    `Liste leeren`,
  ],
  [`What do you have to buy?`]: [
    `What do you have to buy?`,
    `Was muss eingekauft werden?`,
  ],
  [`General`]: [
    `General`,
    `Allgemein`,
  ],
  [`+ New List`]: [
    `+ New List`,
    `+ Neue Liste`,
  ],
  [`Done`]: [
    `Done`,
    `Fertig`,
  ],
  [`Add details to item`]: [
    `Add details to "{{ itemName }}"`,
    `Details zu "{{ itemName }}" hinzufügen`,
  ],
  [`Add Details`]: [
    `Add Details`,
    `Verfeinern`,
  ],
  [`+ New Tag`]: [
    `+ New Tag`,
    `+ Tag`,
  ],
  [`For member`]: [
    `For {{ name }}`,
    `Für {{ name }}`,
  ],
  [`Premium Feature`]: [
    `Premium Feature`,
    `Premium-Funktion`,
  ],
  [`Not interested`]: [
    `Not interested`,
    `Nicht interessiert`,
  ],
  [`Add details to your shopping list items.`]: [
    `Add details to your shopping list items`,
    `Füge Details zu Einträgen auf der Einkaufsliste hinzu`,
  ],

  // Share Shopping List
  [`shoppinglist.share.title`]: [
    `Flatastic Shopping List`,
    `Flatastic Einkaufsliste`,
  ],
  [`shoppinglist.share.message`]: [
    `Here's our shopping list:


{{ items }}

––––––––––––––––

We organize our household with an app called "Flatastic" (https://www.flatastic-app.com)`,
    `Hier ist unsere flatastische Einkaufsliste:

{{ items }}

––––––––––––––––

Wir organisieren unseren Haushalt mit der App "Flatastic" (https://www.flatastic-app.com)`,
  ],

  // Detail Modal
  [`Add details`]: [
    `Add Details`,
    `Details hinzufügen`,
  ],
  [`Added by`]: [
    `Added by`,
    `Hinzugefügt von`,
  ],
  [`Checked by`]: [
    `Checked by`,
    `Abgehakt von`,
  ],
  [`Category`]: [
    `Category`,
    `Kategorie`,
  ],
  [`Check`]: [
    `Check`,
    `Abhaken`,
  ],
  [`Add to list`]: [
    `Add to list`,
    `Auf die Liste setzen`,
  ],
  [`Edit`]: [
    `Edit`,
    `Bearbeiten`,
  ],

  // Edit Modal
  [`Item Name`]: [
    `Item Name`,
    `Produktname`,
  ],
  [`Item Details`]: [
    `Details`,
    `Details`,
  ],
  [`Type or append the suggestions by clicking`]: [
    `Type or append the suggestions by clicking`,
    `Vorschläge durch Klicken anhängen`,
  ],
  [`e.g. organic, 3l`]: [
    `e.g. organic, 3l`,
    `z.B. Bio, 3l`,
  ],
  [`e.g. Milk`]: [
    `e.g. Milk`,
    `z.B. Milch`,
  ],
  [`Delete Item`]: [
    `Delete Item`,
    `Eintrag löschen`,
  ],
  [`Close`]: [
    `Close`,
    `Schließen`,
  ],
  [`Save`]: [
    `Save`,
    `Speichern`,
  ],
  [`Add with return`]: [
    `Add with return`,
    `Mit Enter hinzufügen`,
  ],
  [`Want to edit names after creating items? Get full control over every item with Flatastic premium!`]: [
    `Want to edit names after creating items? Get full control over every item with Flatastic premium!`,
    `Möchtest du Einträge editieren nachdem sie hinzugefügt wurden? Mit Flatastic Premium hast du die volle Kontrolle.`,
  ],
  [`shoppinglist.editItem.promoBanner.text`]: [
    `Assign tags, add details and edit the name with Flatastic Premium`,
    `Mit Premium kannst du Einträge bearbeiten, Tags zuweisen und Details hinzufügen`,
  ],
  [`Filter`]: [
    `Filter`,
    `Filter`,
  ],
  [`+ Create Tag`]: [
    `+ Create Tag`,
    `+ Tag erstellen`,
  ],
  [`+ Tag`]: [
    `New Tag`,
    `+ Tag`,
  ],
  [`Create Tag`]: [
    `Create Tag`,
    `Tag erstellen`,
  ],
  [`Create`]: [
    `Create`,
    `Erstellen`,
  ],
  [`Create new list`]: [
    `Create new list`,
    `Neue Liste erstellen`,
  ],
  [`Use tags to filter items by them.`]: [
    `Assign tags to items and filter by them. Popular tags are #Supermarket, #Drug Store, #Hardware Store`,
    `Erstelle Tags und filtere nach diesen Einträge auf der Einkaufsliste. Führe so mehrere Einkaufslisten (z.B. #Supermarkt, #Drogerie, #Baumarkt) und optimiere deinen Einkauf. `,
  ],
  [`shoppinglist.editModal.tags.info`]: [
    `Add tags (ex. #Supermarket) to items and filter the shopping list by them.`,
    `Füge Tags (z.B. #Supermarkt) zu Einträgen hinzu, um die Einkaufsliste nach diesen zu filtern.`,
  ],
  [`Tags`]: [
    `Tags`,
    `Tags`,
  ],
  [`# Tags`]: [
    `# Tags`,
    `# Tags`,
  ],
  [`Without Tags`]: [
    `Without Tags`,
    `Ohne Tags`,
  ],
  [`Create multiple shopping lists with Flatastic Premium`]: [
    `Create multiple shopping lists with Flatastic Premium`,
    `Führe mehrere Einkaufs&shy;listen mit Flatastic Premium`,
  ],
  [`Instead of multiple lists you can organize your shared shopping list with tags. Assign tags to items and filter by them. Get started now by creating your own tags.`]: [
    `Instead of multiple lists you can organize your shared shopping list with tags. Assign tags to items and filter by them.`,
    `Anstelle von mehreren Listen kannst du die Einkaufsliste mit Tags organisieren. Weise Einträgen Tags zu und filtere nach diesen.`,
  ],
  [`Popular tags are: #Supermarket, #Hardware Store, #Drug Store`]: [
    `Popular tags are: #Supermarket, #Hardware Store, #Drug Store`,
    `Beliebte Tags sind: #Supermarkt, #Baumarkt, #Drogerie`,
  ],
  [`Sort`]: [
    `Sort`,
    `Sortieren`,
  ],
  [`Sort by`]: [
    `Sort by`,
    `Sortieren nach`,
  ],
  [`shl.orderBy.categoryId`]: [
    `Category`,
    `Kategorie`,
  ],
  [`shl.orderBy.date`]: [
    `Most Recent`,
    `Neuste`,
  ],
  [`Sort shopping list entries with Flatastic Premium`]: [
    `Sort shopping list entries with Flatastic Premium`,
    `Sortiere Einträge auf der Einkaufsliste mit Flatastic Premium`,
  ],
  [`shl.orderBy.alphabet`]: [
    `Alphabetically`,
    `Alphabetisch`,
  ],
  [`shl.orderBy.userId`]: [
    `Added by`,
    `Hinzugefügt von`,
  ],
  [`shl.orderBy.tag`]: [
    `Tag`,
    `Tag`,
  ],

  //// Legacy
  [`REFRESH`]: [
    `Refresh`,
    `Aktualisieren`,
  ],
  [`DELETE`]: [
    `Delete`,
    `Löschen`,
  ],
  [`SHL__INTERACT__EDIT`]: [
    `Edit`,
    `Bearbeiten`,
  ],
  [`SHL__INTERACT__BUY`]: [
    `Check`,
    `Abhaken`,
  ],
  [`SHL__INTERACT__BACK_TO_LIST`]: [
    `Back to list`,
    `Auf die Liste`,
  ],
  [`BTN_CANCEL`]: [
    `Cancel`,
    `Abbrechen`,
  ],

  [`SHL__DELETE_ITEM-TITLE`]: [
    `Delete {{ name }}`,
    `{{ name }} löschen`,
  ],
  [`SHL__DELETE_ITEM-MSG`]: [
    `Do you really want to delete the item "{{ name }}"?`,
    `Möchtest du wirklich den Eintrag "{{ name }}" von der Einkaufsliste löschen?`,
  ],

  [`shoppinglist.removeBoughtItems.confirmDialog.title`]: [
    `Shopping List`,
    `Einkaufsliste`,
  ],
  [`shoppinglist.removeBoughtItems.confirmDialog.message`]: [
    `Do you want to remove all the items you've bought from the list?`,
    `Möchtest du alle gekauften Artikel aus der Liste löschen?`,
  ],

  [`SHL__REMOVE_BOUGHT_ERROR-TITLE`]: [
    `Shopping List`,
    `Einkaufsliste`,
  ],
  [`SHL__REMOVE_BOUGHT_ERROR-MSG`]: [
    `Unfortunatelly it didn\'t work. The bought Items were not removed from the shopping list. Maybe there was no internet connection? Please try again.`,
    `Das hat leider noch nicht geklappt. Die gekauften Artikel wurden nicht aus der Einkaufliste gelöscht. Vielleicht hattest du keinen Internetempfang? Versuch es doch noch einmal.`,
  ],
  [`SHL__DELETE_ITEM-TITLE`]: [
    `Delete {{ name }}`,
    '{{ name }} löschen',
  ],
  [`SHL__DELETE_ITEM-MSG`]: [
    `Do you really want to delete the item "{{ name }}"?`,
    `Möchtest du wirklich den Eintrag "{{ name }}" von der Einkaufsliste löschen?`,
  ],
  [`SHL__SYNC_BUTTON`]: [
    `Sync`,
    `Synchronisieren`,
  ],
  [`SHL__SYNC-TITLE`]: [
    `Shopping List`,
    `Einkaufsliste`,
  ],
  [`SHL__SYNC-MSG`]: [
    `There was bad internet connection and your shopping list has not been synchronised. \nSync now?`,
    `Du hattest vermutlich eine schlechte Internetverbindung und die Einkaufliste ist nicht synchronisiert.\nJetzt synchronisieren?`,
  ],
  [`SHL__SYNC_SUCCESS-TITLE`]: [
    `Shopping List`,
    `Einkaufsliste`,
  ],
  [`SHL__SYNC_SUCCESS-MSG`]: [
    `Flatastic! Your shopping list is up to date.`,
    `Flatastisch! Deine Einkaufsliste ist wieder synchronisiert.`,
  ],

  [`SHL__SYNC_ERROR-TITLE`]: [
    `Shopping List`,
    `Einkaufsliste`,
  ],
  [`SHL__SYNC_ERROR-MSG`]: [
    `The synchronization failed. Please ensure you have a good internet connection and try again.`,
    `Das Synchornisieren hat nicht funktioniert. Überprüfe deine Internetverbindung und versuche es noch einmal.`,
  ],
  [`Edit item`]: [
    `Edit item`,
    `Eintrag bearbeiten`,
  ],
  [`The changes haven't been synchronized with our server.`]: [
    `The changes haven't been synchronized with our server. Do you have a bad internet connection?`,
    `Die Änderung konnte nicht mit unserem Server synchronisiert werden. Hast du eine schlechte Internetverbindung?`,
  ],

}
