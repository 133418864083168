// ['en', 'de']
export default {
  [`Delete Task`]: [
    `Delete Task`,
    `Aufgabe löschen`,
  ],
  [`Do you really want to delete the task?`]: [
    `Do you really want to delete the task "{{ title }}"?`,
    `Möchtest du die Aufgabe "{{ title }}" wirklich löschen?`,
  ],
  [`Delete`]: [
    `Delete`,
    `Löschen`,
  ],
  [`Cancel`]: [
    `Cancel`,
    `Abbrechen`,
  ],
  [`Task deleted`]: [
    `Deleted task "{{ title }}"`,
    `Die Aufgabe "{{ title }}" wurde gelöscht`,
  ],
  [`Deleting task failed`]: [
    `Deleting task "{{ title }}" failed`,
    `Das Löschen der Aufgabe "{{ title }}" ist fehlgeschlagen.`,
  ],
  [`CHO__NEW-TITLE`]: [
    `Create a new task`,
    `Aufgabe hinzufügen`,
  ],
  [`CHO__NEW_ERROR-TITLE`]: [
    `Add a task`,
    `Aufgabe hinzufügen`,
  ],
  [`CHO__NEW_ERROR-MSG`]: [
    `An error occured while setting up the task: {{ error }}`,
    `Beim Anlegen der Aufgabe ist ein Fehler aufgetreten: {{ error }}`,
  ],
  [`CHO__NEW-BTN`]: [
    `New Task`,
    `Neue Aufgabe`,
  ],
  [`CHO__NEW__GO_BACK-TITLE`]: [
    `Chore Schedule`,
    `Putzplan`,
  ],
  [`CHO__NEW__GO_BACK-MSG`]: [
    `Do you really want to go back without saving?`,
    `Möchtest du wirklich zurück ohne vorher noch zu speichern?`,
  ],
  [`CHO__EDIT`]: [
    `Edit task`,
    `Aufgabe bearbeiten`,
  ],
  [`CHO__FORM-TITLE`]: [
    `Title`,
    `Titel`,
  ],
  [`CHO__FORM-TITLE_PLACEHOLDER`]: [
    `e.g. Clean the bathroom`,
    `z.B. Bad putzen`,
  ],
  [`CHO__FORM-START_AT`]: [
    `Start`,
    `Erste Frist`,
  ],
  [`CHO__FORM-AT`]: [
    `On`,
    `Am`,
  ],
  [`CHO__FORM-FREQUENCY`]: [
    `Frequency`,
    `Frequenz`,
  ],
  [`CHO__FORM-EFFORT`]: [
    `Effort`,
    `Aufwand`,
  ],
  [`CHO__EFFORT__WITHOUT_RATING`]: [
    `Without rating`,
    `Ohne Wertung`,
  ],
  [`CHO__FORM-EFFORT__SMALL`]: [
    `Small`,
    `Klein`,
  ],
  [`CHO__FORM-EFFORT__NORMAL`]: [
    `Normal`,
    `Normal`,
  ],
  [`CHO__FORM-EFFORT__BIG`]: [
    `Big`,
    `Groß`,
  ],
  [`CHO__FORM-EFFORT__HUGE`]: [
    `Huge`,
    `Riesig`,
  ],
  [`CHO__FORM-FIXED_WEEKDAY`]: [
    `Always on {{ day }}?`,
    `Immer am {{ day }}?`,
  ],
  [`CHO__FORM-SELECT_FREQUENCY`]: [
    `Click on the profile images below to change the order:`,
    `Bestimme die Reihenfolge durch Klicken der Profilbilder`,
  ],
  [`CHO__FORM-SELECT_PARTICIPANTS`]: [
    `Choose the participants`,
    `Zuständig:`,
  ],
  [`CHO__FORM-WHO_IS_FIRST`]: [
    `Add persons`,
    `Wähle Personen aus`,
  ],
  [`CHO__FORM-WHO_IS_NEXT`]: [
    `Add more if necessary`,
    `Füge, falls nötig, weitere Personen hinzu`,
  ],
  [`CHO__FORM-WHO_IS_IN`]: [
    `Who is in?`,
    `Wer ist dabei?`,
  ],
  [`CHO_FORM-EVERYONE_SELECTED`]: [
    `The selected order`,
    `Die gewählte Reihenfolge:`,
  ],
  [`CHO__FORM-CONFIRM_EDIT`]: [
    `Save changes`,
    `Änderungen speichern`,
  ],
  [`CHO__FORM-CONFIRM_NEW`]: [
    `Done`,
    `Fertig`,
  ],
  [`POINT`]: [
    `Point`,
    `Punkt`,
  ],
  [`POINTS`]: [
    `Points`,
    `Punkte`,
  ],
  [`YES`]: [
    `Yes`,
    `Ja`,
  ],
  [`NO`]: [
    `No`,
    `Nein`,
  ],
}
