import { getDefaultMiddleware } from '@reduxjs/toolkit'
import ngRedux from 'ng-redux'
import {
  FLUSH, PAUSE,
  PERSIST, persistStore, PURGE,
  REGISTER, REHYDRATE
} from 'redux-persist'
import FlatasticConfig from '../../scripts/config'
import flatasticMonetization from '../../scripts/modules/flatasticMonetization'
import { persistedReducer } from './store'

export default angular.module('ft.store', [
  ngRedux,
  FlatasticConfig,
  flatasticMonetization,
])

.config(['$ngReduxProvider', function($ngReduxProvider) {
  const defaultMiddlewares = getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  })
  $ngReduxProvider.createStoreWith(
    persistedReducer,
    [
      ...defaultMiddlewares,
      'reduxMonetizationMiddleware',
      process.env.NODE_ENV !== 'production' ? measurePerformanceMiddleware : undefined,
     ].filter(Boolean),
    [
      (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__)
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : undefined,
    ].filter(Boolean)
  )
}])

.run(['$ngRedux', '$rootScope', 'FlatasticEvents', function($ngRedux, $rootScope, FlatasticEvents) {
  persistStore($ngRedux)
  $rootScope.$on(FlatasticEvents.user.didLogout, function() {
    $ngRedux.dispatch({ type: 'CLEAR_STATE' })
  })
}])
.name

function measurePerformanceMiddleware(store) {
  return next => action => {
    console.group(action.type)
    console.log(action, store.getState())
    console.time(action.type)
    const res = next(action)
    console.timeEnd(action.type)
    console.groupEnd(action.type)
    return res
  }
}
