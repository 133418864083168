import angular from 'angular'
import svc_expenseItem from '../../modules/expenses/svc_expense-item'
import UserService from '../../modules/user/svc_user'
import WgService from '../../modules/wg/svc_wg'
import CordovaModule from '../../scripts/modules/yptCordova'
import KeyboardModule from '../../scripts/modules/yptKeyboard'
import FilterCurrencyInput from '../directives/currencyInput'
import './expense-create.scss'

export default angular.module('ft.finances.controllers.expenses-create', [
  UserService,
  WgService,
  CordovaModule,
  KeyboardModule,
  FilterCurrencyInput,
  svc_expenseItem,
])
.controller('ExpensesCreateController', ExpenseCreateController)
.name


ExpenseCreateController.$inject = ['Wg', '$state', '$translate', 'yptNotify', 'User', '$scope', 'yptKeyboard', '$timeout', 'ExpenseItem']
export function ExpenseCreateController(Wg, $state, $translate, yptNotify, User, $scope, yptKeyboard, $timeout, ExpenseItem) {
  const vm = this
  vm.currency = Wg.currency
  vm.deleteExpense = deleteExpense
  vm.isCreditor = isCreditor
  vm.pullOut = pullOut
  vm.creator = User.properties
  vm.focusProductName = focusProductName
  vm.closeKeyboard = closeKeyboard
  vm.enableAddingMode = enableAddingMode
  vm.selectAllMembers = selectAllMembers
  vm.enterAddingMode = enterAddingMode
  vm.hideFooter = hideFooter
  vm.isFooterHidden = false
  vm.init = init
  vm.addExpense = addExpense
  vm.canSave = canSave
  vm.addMoreItems = addMoreItems
  vm.expense = undefined

  let focusTimeout
  let legacyAddExpense
  function init(expense, addExpense) {
    expense.totalSum = expense.totalSum || 0
    vm.expense = expense
    legacyAddExpense = addExpense
    if (expense.items.length() === 0) {
      focusTimeout = $timeout(() => $scope.$broadcast('expense:eventName'), 750)
    }

    $scope.$watch(() => expense.items.length(), (newValue, oldValue, scope) => {
      if (!(newValue === 1 && oldValue > 1)) {
        return
      }
      if ($scope.$parent.itemData.isValid()) {
        return
      }
      $timeout(() => { $scope.$parent.itemData = expense.items.removeHead() }, 0)
    })
  }

  function cleanUp() {
    if (focusTimeout) {
      $timeout.cancel(focusTimeout)
    }
  }

  function canSave(expense, item) {
    if (expense.items.length() === 0 && item && item.isValid()) {
      return true
    }
    return expense.name && expense.items.length() > 0 && expense.items.sum('price') > 0
  }

  function quickAdd(expense, item) {
    if (!item.isValid()) {
      return
    }
    expense.title = item.name
    expense.name = item.name
    expense.addItem(item)
    legacyAddExpense()
  }

  function addMoreItems(expense, item) {
    if (expense.items.length() === 0 &&!item.isValid()) {
      return
    }
    if (expense.items.length() === 0 && item.isValid()) {
      expense.addItem(item)
    }
    $scope.$parent.itemData = ExpenseItem.scaffold(User.properties.id, [...item.sharers])
    enterAddingMode()
  }

  function addExpense(expense, item) {
    if (expense.items.length() === 0) {
      return quickAdd(expense, item)
    }
    expense.title = expense.name
    if (!expense.name && expense.items.totalSum !== 0) {
      return
    }
    legacyAddExpense()
  }

  // For some reason ExpenseNewCtrl (parent) isn't destroyed when this controller is there as well
  // The following lines are a dirty workaround
  let i = 0
  $scope.$on('$destroy', function () {
    cleanUp()
    try {
      if (i++ < 1) {
        $scope.$parent && $scope.$parent.$destroy()
      }
    } catch (e) {
      // Error: null is not an object (evaluating 't.parent')
    }
  })

  function selectAllMembers() {
    $scope.$parent.itemData.sharers = Wg.flatmates.map(member => member.id)
    focusProductPrice()
  }

  // Debouncing so footer doesn't disappear when focus switches from one input to the other
  let debounceTimer
  function enableAddingMode(isEnabled) {
    if (debounceTimer) {
      $timeout.cancel(debounceTimer)
    }
    if (isEnabled) {
      pullOut()
    }
    debounceTimer = $timeout(() => {
      vm.isAddingItems = isEnabled
      if (!isEnabled) {
        $scope.$broadcast('resizable-footer-content.pull-in')
        if ($scope.$parent.itemData.isValid()) {
          $scope.$parent.addItem($scope.$parent.itemData)
        }
        if ($scope.$parent.expense.items.length() === 1) {
          $scope.$parent.itemData = $scope.$parent.expense.items.removeHead()
        }
      }
      debounceTimer = undefined
    }, 200)
    return debounceTimer || Promise.resolve()
  }

  function enterAddingMode() {
    enableAddingMode(true).then(() => $timeout(0)).then(() => focusProductName())
  }

  // Required when user wants to edit an item
  $scope.$on('expenses:productName', () => {
    if (!vm.isAddingItems) {
      hideFooter(false)
      $timeout(() => enterAddingMode(), 0)
    }
  })

  function hideFooter(isHidden) {
    vm.isFooterHidden = isHidden
  }

  function closeKeyboard() {
    yptKeyboard.close()
  }

  function focusProductPrice() {
    $scope.$broadcast('expenses:productPrice');
  }
  function focusProductName() {
    $scope.$broadcast('expenses:productName')
  }

  function pullOut() {
    $scope.$broadcast('resizable-footer-content.pull-out')
  }

  function isCreditor(expense) {
    return expense.creatorId === User.properties.id
  }

  function deleteExpense(expense) {
    yptNotify.confirm({
      message: $translate.instant('EXP__DELETE-MSG', {
        name: expense.name,
      }),
      title: $translate.instant('EXP__DELETE-TITLE'),
      buttonLabels: [
        $translate.instant('BTN_CANCEL'),
        $translate.instant('DELETE'),
      ],
      callback: function(answer) {
        if (answer !== true) {
          return
        }
        expense.removeFromCollection().delete()
        $state.go('household.finances-listing')
      },
    })
  }
}
