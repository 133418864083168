import flatasticConfig from '../scripts/config'
import FlatasticLogo from '../tabs/assets/flatastic-logo-ft.svg'
import { moduleAbbreviationToScreenName } from '../utils/helpers'

export default angular.module('flatastic.notifications.handler', [
  flatasticConfig,
  'ionic',
])

.run(['PushHandler', '$rootScope',
    function(PushHandler, $rootScope) {

  // Is triggered when a push notification arrives (also when app is open via push)
  $rootScope.$on('push-receive', function(event, notification) {
    PushHandler.handlePushNotification(notification);
  });

  // Is triggered when app is opened because user tapped on push notification
  document.addEventListener('push-notification', function(event) {
    // Nothing to do
    $rootScope.$broadcast('push-notification', event.notification);
  });
}])

.factory('PushHandler',
    ['$rootScope', '$state', 'User', 'FlatasticEvents', '$ionicHistory', '$timeout',
    function($rootScope, $state, User, FlatasticEvents, $ionicHistory, $timeout) {

  return {
    handlePushNotification,
  };

  //// Functions

  function goToViewByNotification(data) {
    if (data && data.m && moduleAbbreviationToScreenName(data.m)) {
      // Make sure that the back button will not appear
      $timeout(function() {
        $ionicHistory.clearHistory();
      });
      $timeout(function() {
        $state.go(moduleAbbreviationToScreenName(data.m));
      }, 750);
    }
  };

  function handlePushNotification(notification) {
    console.debug('handlePushNotification', notification)
    if (!User.isInWg()) {
      return;
    }
    notification.userdata = notification.userdata || {}
    // Ignore test notifications
    if (notification && notification.userdata && notification.userdata.uuid) {
      return;
    }
    // if notification was swiped in startscreen
    if (notification.onStart === true) {
      goToViewByNotification(notification.userdata);
    } else {
      // show inAppNotification
      if (notification.title === notification.message) {
        notification.title = undefined
      }
      $rootScope.$broadcast('inAppNotification-show', {
        title: notification.title || 'Flatastic',
        image: notification.image || FlatasticLogo,
        message: notification.message,
        module: notification.userdata.m,
      });

      $timeout(function() {
        $rootScope.$broadcast('inAppNotification-close');
      }, 7000);
    }
    var affectedModule = notification.userdata && notification.userdata.m;
    $rootScope.$broadcast(FlatasticEvents.badge.update);
    $rootScope.$broadcast('track-event', {
      event: 'did_receive_push_notification',
      module: affectedModule,
    })
    $rootScope.$broadcast('track-event', {
      category: 'Push-Notification',
      action: 'Did receive push notification',
      label: affectedModule,
    });

    // Update corresponding module
    switch (affectedModule) {
      case 'bb':
        return $rootScope.$broadcast(FlatasticEvents.shouts.update);
      case 'cho':
        return $rootScope.$broadcast(FlatasticEvents.chores.update);
      case 'exp':
        return $rootScope.$broadcast(FlatasticEvents.expenses.update);
      case 'shl':
        return $rootScope.$broadcast(FlatasticEvents.shoppinglist.update);
      case 'wg':
        return $rootScope.$broadcast(FlatasticEvents.wg.didUpdate);
      case 'sbs':
        return $rootScope.$broadcast(FlatasticEvents.subscriptions.update);
      default:
        // Trigger data fetching in all modules
        return $rootScope.$broadcast(FlatasticEvents.user.didEnterWg);
    }
  }

}])

.name;
