import IconAlphabetActive from './assets/icon-alpha-order-active.svg'
import IconAlphabetInactive from './assets/icon-alpha-order-inactive.svg'
import IconCategoryActive from './assets/icon-apple-active.svg'
import IconCategoryInactive from './assets/icon-apple-inactive.svg'
import IconCalenderActive from './assets/icon-calendar-date-active.svg'
import IconCalenderInactive from './assets/icon-calendar-date-inactive.svg'
import IconHashtagActive from './assets/icon-hashtag-active.svg'
import IconHashtagInactive from './assets/icon-hashtag-inactive.svg'
import IconProfileActive from './assets/icon-profile-active.svg'
import IconProfileInactive from './assets/icon-profile-inactive.svg'

const ShoppinglistSortOptions = [{
  value: 'date',
  label: 'shl.orderBy.date',
  iconActive: IconCalenderActive,
  iconInactive: IconCalenderInactive,
}, {
  value: 'categoryId',
  label: 'shl.orderBy.categoryId',
  iconActive: IconCategoryActive,
  iconInactive: IconCategoryInactive,
}, {
  value: 'alphabet',
  label: 'shl.orderBy.alphabet',
  iconActive: IconAlphabetActive,
  iconInactive: IconAlphabetInactive,
}, {
  value: 'userId',
  label: 'shl.orderBy.userId',
  iconActive: IconProfileActive,
  iconInactive: IconProfileInactive,
}, {
  value: 'tag',
  label: 'shl.orderBy.tag',
  iconActive: IconHashtagActive,
  iconInactive: IconHashtagInactive,
}]

export default angular.module('flatastic.shoppinglist.sort-options', [
])
.value('ShoppinglistSortOptions', ShoppinglistSortOptions)
.name
