import UserService from '../modules/user/svc_user'
import FlatasticConfig from '../scripts/config'

export default angular.module('ft.controller.global', [
  UserService,
  FlatasticConfig,
])
.controller('GlobalController', GlobalController)
.name

GlobalController.$inject = ['User', 'FlatasticEvents', '$timeout', '$rootScope']
function GlobalController(User, FlatasticEvents, $timeout, $rootScope) {
  const vm = this
  vm.User = User
  vm.refreshEverything = refreshEverything

  // TODO: Fix it later, this is too hard core
  function refreshEverything() {
    $rootScope.$broadcast(FlatasticEvents.user.didEnterWg)
    User.get()
    $timeout(() => {
      $rootScope.$broadcast('scroll.refreshComplete')
    }, 1000)
  }
}
