// ['en', 'de']

export default {

  [`Finances`]: [
    `Finances`,
    `Finanzen`,
  ],
  [`Settle up`]: [
    `Settle up`,
    `Kassensturz durchführen`,
  ],
  //// Legacy

  // Settlement
  [`EXP__BALANCE_RESET`]: [
    `Clear expenses`,
    `Kassensturz durchführen`,
  ],
  [`EXP__BALANCE_RESET_CONFIRM_TEXT`]: [
    `When you clear the expenses, the statistics are set back to zero. Everyone receives an email with the old statistics. In the end all expenses are moved to the archive.\n\nDo you want to continue?`,
    `Der Kassensturz setzt alle Finanzen wieder auf Null. Jeder erhält eine E-Mail mit der aktuellen Statistik und wer wem was zu zahlen hat. Im Anschluss werden alle Finzanzeinträge ins Archiv verschoben.\n\nMöchtest du jetzt abrechnen?`,
  ],
  [`EXP__BALANCE_RESET-MSG`]: [
    `{{ creditor }} gets {{ credit }} from {{ debtor }}.`,
    `{{ creditor }} bekommt {{ credit }} von {{ debtor }}.`,
  ],
  [`EXP__BALANCE_RESET-MSG-ENDING`]: [
    `Click on OK after the money is handed over.`,
    `Klicke auf OK nachdem ihr euch das Geld ausgezahlt habt.`,
  ],
  [`EXP__BALANCE_BALANCED`]: [
    `Your expenses are now balanced again.`,
    `Die Bilanz wurde ausgeglichen.`,
  ],
  [`CONTINUE`]: [
    `Continue`,
    `Fortfahren`,
  ],

  // Stats clicking
  [`EXP__STATISTICS_INFO-EVEN`]: [
    `The statistics of {{ name }} are balanced. Congratulations :)`,
    `Die Finanzen von {{ name }} sind genau ausgeglichen. Das ist einfach unglaublich :)`,
  ],
  [`EXP__STATISTICS_INFO-MINUS`]: [
    `{{ name }} owes {{ value }} and should probably be the next one doing the shopping.`,
    `{{ name }} ist {{ value }} im Minus und sollte vielleicht mal wieder Einkaufen gehen.`,
  ],
  [`EXP__STATISTICS_INFO-PLUS`]: [
    `{{ name }} is in credit, {{ value }}. Somebody else should do the shopping.`,
    `{{ name }} ist {{ value }} im Plus. Es könnten auch mal die anderen Einkaufen gehen.`,
  ],
  [`EXP__STATISTICS_INFO-TITLE`]: [
    `Balance of {{ name }}`,
    `Bilanz von {{ name }}`,
  ],
  [``]: [
    ``,
    ``,
  ],

}
