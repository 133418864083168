export default {
  [`Chores`]: [
    `Chores`,
    `Aufgaben`,
  ],
  [`Shopping List`]: [
    `Shopping List`,
    `Einkaufen`,
  ],
  [`Bulletin Board`]: [
    `Bulletin Board`,
    `Pinnwand`,
  ],
  [`Finances`]: [
    `Finances`,
    `Finanzen`,
  ],
  [`Settings`]: [
    `Settings`,
    `Einstellungen`,
  ],
  [`Back`]: [
    `Back`,
    `Zurück`,
  ],
  [`API__ERROR__5XX`]: [
    `Oh oh. A server error 👿. Click here for more information.`,
    `Oh oh. Ein Serverfehler 👿. Klicke hier für mehr Informationen.`,
  ],
  [`API__ERROR__NO_INTERNET`]: [
    `Can't connect to our servers. Please check your internet connection.`,
    `Unser Server ist nicht erreichbar. Bitte überprüfe deine Internetverbindung.`,
  ],
}

