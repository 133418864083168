import { anyPass, defaultTo, isEmpty, isNil } from 'ramda'

export const getLanguageFromCountryId = countryId => defaultTo('en')({
  1: 'en',
  2: 'de',
  3: 'de',
  4: 'de',
}[countryId])

export const getCountryIdFromAlpha3Code = alpha3Code => defaultTo(1)(({
  'DEU': 2,
  'AUT': 3,
  'CHE': 4,
})[alpha3Code])

export const moduleAbbreviationToScreenName = moduleFromData => (({
  'wg': 'household.settings',
  'cho': 'household.chores.listing',
  'exp': 'household.finances-listing',
  'fdb': 'household.settings-feedback',
  'usr': 'household.settings',
  'shl': 'household.shoppinglist',
  'bb': 'household.chat.messages',
  'iap': 'household.purchase',
  'sbs': 'household.finances-contracts',
})[moduleFromData])

export function wait(durationInMs) {
  return new Promise(resolve => setTimeout(resolve, durationInMs))
}

export const isNilOrEmpty = anyPass([isNil, isEmpty])
export const isUndefinedOrEmpty = anyPass([isEmpty, val => val === undefined])

export const waitForCondition = (fn, conditionFn, retries = 5, startWaitTimeInMs = 10) => {
  return fn().then(res => {
    if (conditionFn(res)) {
      return res
    }
    if (retries === 0) {
      return Promise.reject(null)
    }
    return wait(startWaitTimeInMs).then(
      () => waitForCondition(fn, conditionFn, retries - 1, startWaitTimeInMs * 2)
    )
  })
}

// Taken from: https://stackoverflow.com/a/44195856
export function decodeHtmlEntities(encodedString) {
    var translate_re = /&(nbsp|amp|quot|lt|gt);/g;
    var translate = {
        nbsp: ' ',
        amp: '&',
        quot: '"',
        lt: '<',
        gt: '>',
    };
    return encodedString.replace(translate_re, function(match, entity) {
        return translate[entity];
    }).replace(/&#(\d+);/gi, function(match, numStr) {
        var num = parseInt(numStr, 10);
        return String.fromCharCode(num);
    });
}

export function roundToNumDecimals(numDecimalPlaces, value) {
  return Math.round(value * 10**numDecimalPlaces) / 10**numDecimalPlaces
}

export function isUuidV4(str) {
  return /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(str)
}
