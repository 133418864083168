import { mapObjIndexed, mergeDeepLeft } from 'ramda'
import IntroTranslations from '../modules/intro/translations'
import PaywallTranslations from '../modules/paywall/translations'
import ChatTranslations from './chat/translations'
import Chores from './chores/translations'
import PremiumBannerTranslations from './components/premium-banner/translations'
import PremiumListTranslations from './components/premium-list/translations'
import FinancesTranslations from './finances/translations'
import HomelessTranslations from './homeless/translations'
import WelcomeModalTranslations from './modals/welcome-modal/translations'
import PurchaseTranslations from './purchase/translations'
import SettingsTranslations from './settings/translations'
import ShoppinglistTranslations from './shoppinglist/translations'
import TabsTranslations from './tabs.trans'
import CountryTranslations from './translations/countries.trans'
import LegacyTranslations from './translations/legacy.trans'

const allTranslations = {
  ...TabsTranslations,
  ...Chores,
  ...ShoppinglistTranslations,
  ...PaywallTranslations,
  ...FinancesTranslations,
  ...ChatTranslations,
  ...PurchaseTranslations,
  ...SettingsTranslations,
  ...CountryTranslations,
  ...LegacyTranslations,
  ...HomelessTranslations,
  ...WelcomeModalTranslations,
  ...PremiumListTranslations,
  ...PremiumBannerTranslations,
}

export default mergeDeepLeft({
  en: mapObjIndexed((val, key, obj) => val[0])(allTranslations),
  de: mapObjIndexed((val, key, obj) => val[1])(allTranslations),
})(IntroTranslations)
