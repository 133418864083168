export default angular.module('ft.chat.filter.url', [])

.filter('parseUrl', function () {
  var regExp = /((http|ftp|https):\/\/)?([äüöß\w_-]+(?:(?:\.[äüöß\w_-]+)+))([äüöß\w.,@?^=%&:/~+#-]*[äüöß\w@?^=%&/~+#-])?/gi;
  return function (text, target = '_blank') {
      return text.replace(regExp, `<span class="underline text-link text-opacity-100" target="${target}" href="$&" rel="noopener">$&</span>` );
  };
})

.name
