import AngularTranslate from 'angular-translate'
import FlatasticConfig from '../../scripts/config'
import FlatasticApi from '../../scripts/modules/api'
import YepptCollectionService from '../../scripts/modules/collection'
import PushNotificationService from '../../scripts/modules/pushnotification'
import UserService from '../user/svc_user'
import ChoreService from './svc_chore'

export const internalChoresEvents = {
  addToCollection: 'Chores:addToCollection',
  removeFromCollection: 'Chores:RemoveFromCollection',
}

export default angular.module('flatastic.chores.service.chores', [
  AngularTranslate,
  FlatasticApi,
  FlatasticConfig,
  ChoreService,
  PushNotificationService,
  YepptCollectionService,
  UserService,
])

.run(['Chores', '$rootScope', 'User', 'FlatasticEvents',
  function(Chores, $rootScope, User, FlatasticEvents) {

    if (User.isLoggedIn() && User.properties.groupId == 2) {
      Chores.getFromServer();
      Chores.getStatisticsFromServer();
    }

    // Update events
    [
      FlatasticEvents.chores.update,
      FlatasticEvents.user.didEnterWg,
    ].forEach(function(event) {
      $rootScope.$on(event, function() {
        console.debug(`Chores update triggered by "${event}"`)
        Chores.getFromServer();
        Chores.getStatisticsFromServer();
      })
    });

    $rootScope.$on(internalChoresEvents.removeFromCollection, function(event, chore) {
      Chores.delete(chore.id)
    })
    $rootScope.$on(internalChoresEvents.addToCollection, function(event, chore) {
      Chores.push(chore);
    })

    $rootScope.$on(FlatasticEvents.user.didLogout, function() {
      Chores.clear();
      Chores.clearStatistics();
    });
    $rootScope.$on(FlatasticEvents.chores.didCompleteChore, function(event, chore) {
      Chores.getStatisticsFromServer();
    });
  }])

.factory('Chores',
  ['Api', 'Chore', 'YepptCollection', '$translate', '$rootScope', 'PushNotification', 'FlatasticEvents',
    function (Api, Chore, YepptCollection, $translate, $rootScope, PushNotification, FlatasticEvents) {

      var chores = new YepptCollection();

      var myStatus = '';

      var getFromServer = function() {
        myStatus = 'loading';
        return Api.get('/chores')
          .success(function(data) {
            var temp = [];
            data.forEach(function(chore) {
              temp.push(new Chore(chore));
            });
            chores.remove();
            chores.push(temp);
          })
          .finally(function() {
            myStatus = '';
            $rootScope.$broadcast(FlatasticEvents.badge.update);
          });
      };

      var clear = function() {
        chores.remove();
      };

      var numToDo = function(userId) {
        return chores.reduce(function(pre, cur) {
          return pre + (userId == cur.currentUser);
        }, 0);
      };

      var statistics = {};
      var ranking = []
      var getStatisticsFromServer = function() {
        statistics.status = 'loading';
        return Api.get('/chores/statistics')
          .success(function(data) {
            statistics = data;
            setRanking(statistics.chore)
            delete statistics.error;
          })
          .error(function(data) {
            data = data || {};
            statistics.error = data.error || $translate.instant('NO_INTERNET');
          })
          .finally(function() {
            delete statistics.status;
          });
      };

      var resetStatistics = function() {
        return Api.get('/chores/reset_statistics')
          .success(function(data) {
            $rootScope.$broadcast(FlatasticEvents.chores.didResetStatistics)
            statistics = data;
            ranking = setRanking(data.chore)
          });
      };

      var setOverdueBadgeCount = function(choreCollection, userId) {
        var overdueCounter = 0;
        choreCollection.forEach(function(chore) {
          if (chore.timeLeftNext <= 1 && chore.currentUser.toString() === userId) {
            overdueCounter++;
          }
        });
        console.log('Chores.setOverdueBadgeCount to', overdueCounter);
        PushNotification.setBadgeNumber(overdueCounter);
      };

      function setRanking(choreStatistcs) {
        const temp = Object.entries(choreStatistcs)
        temp.sort(([keyA, valA], [keyB, valB]) => valB - valA)
        ranking = temp.map(row => ({ userId: row[0], points: row[1] }))
        return ranking
      }

      // Public API here
      return {
        Chore: Chore,
        getFromServer: getFromServer,
        resetStatistics: resetStatistics,
        clear: clear,
        numToDo: numToDo,
        collection: chores,
        length: function() {
          return chores.length();
        },
        get: function(id) {
          return chores.get(id);
        },
        getRanking: function() {
          return ranking
        },
        statistics: function() {
          return statistics;
        },
        getStatisticsFromServer: getStatisticsFromServer,
        clearStatistics: function() {
          statistics = {};
        },
        delete: function(id) {
          return chores.remove(id);
        },
        push: function(chore, unshift) {
          if (chore.constructor.name !== 'Chore') {
            chore = new Chore(chore);
          }
          return chores.push(chore, unshift);
        },
        status: function() {
          return myStatus;
        },
        setOverdueBadgeCount: setOverdueBadgeCount,
      };
    }])

    .name;
