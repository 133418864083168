import EnumFilter from '../../../../modules/general/filter_enumeration'
import UserService from '../../../../modules/user/svc_user'
import WgService from '../../../../modules/wg/svc_wg'
import ActionsheetModule from '../../../../scripts/modules/yptActionsheet'
import template from './template.html'

export default angular.module('ft.chat.components.activity-message', [
  WgService,
  ActionsheetModule,
  UserService,
  EnumFilter,
])
.component('activityMessage', {
  template,
  bindings: {
    message: '<',
  },
  controller,
})
.name

controller.$inject = ['User', 'Wg', '$state', '$translate', 'yptActionsheet']
function controller(User, Wg, $state, $translate, yptActionsheet) {
  const $ctrl = this
  $ctrl.currency = Wg.currency
  $ctrl.isMine = $ctrl.message.creatorId === User.properties.id
  $ctrl.person = Wg.flatmates._get($ctrl.message.creatorId)
  $ctrl.interact = interact

  function interact(event) {
    let options = {}
    let actions = []
    let buttonLabels = []

    const text = $ctrl.message.liked ? $translate.instant('Unlike') : $translate.instant('Like')
    buttonLabels.push(text);
    actions.push(likeShout);

    buttonLabels.push($translate.instant('Open module'));
    actions.push(goToModule);

    // Trim title length if neccessary
    var title = $ctrl.message.shout.substr(0, 80);
    if ($ctrl.message.shout.length > 80) {
      title += '…';
    }

    options = angular.extend(options, {
      'title': title,
      'buttonLabels': buttonLabels,
      'androidEnableCancelButton': false,
      'addCancelButtonWithLabel': $translate.instant('Cancel'),
    });

    yptActionsheet.show(options, actions);

      function likeShout() {
      $ctrl.message.like()
    };
    function goToModule() {
      const type = $ctrl.message.shoutType.split('_')[0]
      const dict = {
        shoppinglist: 'household.shoppinglist',
        chore: 'household.chores.listing',
        cashflow: 'household.finances-listing',
        wg: 'household.settings',
        profile: 'household.settings',
      }
      const route = dict[type]
      if (!route) {
        return
      }
      $state.go(route)
    };
  }

}
