export default {
  de: [
    { name: `Milch`, categoryId: 3 },
    { name: `Eier`, categoryId: 3 },
    { name: `Tomaten`, categoryId: 1 },
    { name: `Brot`, categoryId: 2 },
    { name: `Klopapier`, categoryId: 11 },
    { name: `Bananen`, categoryId: 1 },
    { name: `Gurke`, categoryId: 1 },
    { name: `Käse`, categoryId: 3 },
    { name: `Paprika`, categoryId: 1 },
    { name: `Joghurt`, categoryId: 3 },
    { name: `Salat`, categoryId: 1 },
    { name: `Butter`, categoryId: 3 },
    { name: `Obst`, categoryId: 1 },
    { name: `Zwiebeln`, categoryId: 1 },
    { name: `Kartoffeln`, categoryId: 1 },
    { name: `Äpfel`, categoryId: 1 },
    { name: `Toast`, categoryId: 2 },
    { name: `Feta`, categoryId: 3 },
    { name: `Kaffee`, categoryId: 9 },
    { name: `Olivenöl`, categoryId: 5 },
    { name: `Zucchini`, categoryId: 1 },
    { name: `Nudeln`, categoryId: 7 },
    { name: `Frischkäse`, categoryId: 3 },
    { name: `Sahne`, categoryId: 3 },
    { name: `Mozzarella`, categoryId: 3 },
    { name: `Toilettenpapier`, categoryId: 11 },
    { name: `Gemüse`, categoryId: 1 },
    { name: `Aufschnitt`, categoryId: 4 },
    { name: `Wasser`, categoryId: 1 },
    { name: `Parmesan`, categoryId: 3 },
    { name: `Wurst`, categoryId: 4 },
    { name: `Hafermilch`, categoryId: 3 },
    { name: `Knoblauch`, categoryId: 1 },
    { name: `Avocado`, categoryId: 1 },
    { name: `Haferflocken`, categoryId: 7 },
    { name: `Karotten`, categoryId: 1 },
    { name: `Mais`, categoryId: 1 },
    { name: `Küchenrolle`, categoryId: 10 },
    { name: `Brötchen`, categoryId: 2 },
    { name: `Müsli`, categoryId: 7 },
    { name: `Pilze`, categoryId: 1 },
    { name: `Salz`, categoryId: 5 },
    { name: `Zahnpasta`, categoryId: 11 },
    { name: `Quark`, categoryId: 3 },
    { name: `Mehl`, categoryId: 7 },
    { name: `Zewa`, categoryId: 10 },
    { name: `Saft`, categoryId: 9 },
    { name: `Bier`, categoryId: 7 },
    { name: `Hackfleisch`, categoryId: 4 },
    { name: `Möhren`, categoryId: 1 },
    { name: `Eis`, categoryId: 6 },
    { name: `Tomatenmark`, categoryId: 5 },
    { name: `Erdbeeren`, categoryId: 1 },
    { name: `Waschmittel`, categoryId: 10 },
    { name: `Schinken`, categoryId: 4 },
    { name: `Zitrone`, categoryId: 1 },
    { name: `Passierte Tomaten`, categoryId: 5 },
    { name: `Reis`, categoryId: 7 },
    { name: `Backpapier`, categoryId: 10 },
    { name: `Salami`, categoryId: 4 },
    { name: `Zitronen`, categoryId: 1 },
    { name: `Zucker`, categoryId: 5 },
    { name: `Müllbeutel`, categoryId: 10 },
    { name: `Seife`, categoryId: 11 },
    { name: `Brokkoli`, categoryId: 1 },
    { name: `Kokosmilch`, categoryId: 5 },
    { name: `Spülmittel`, categoryId: 10 },
    { name: `Baguette`, categoryId: 2 },
    { name: `Spüli`, categoryId: 10 },
    { name: `Champignons`, categoryId: 1 },
    { name: `Aufbackbrötchen`, categoryId: 2 },
    { name: `Spülmaschinentabs`, categoryId: 10 },
    { name: `Ketchup`, categoryId: 5 },
    { name: `Spinat`, categoryId: 1 },
    { name: `Nutella`, categoryId: 8 },
    { name: `Chips`, categoryId: 8 },
    { name: `Lachs`, categoryId: 4 },
    { name: `Pesto`, categoryId: 5 },
    { name: `Creme Fraiche`, categoryId: 3 },
    { name: `Wraps`, categoryId: 5 },
    { name: `Senf`, categoryId: 5 },
    { name: `Cola`, categoryId: 9 },
    { name: `Spaghetti`, categoryId: 7 },
    { name: `Ingwer`, categoryId: 1 },
    { name: `Deo`, categoryId: 11 },
    { name: `Frühlingszwiebeln`, categoryId: 1 },
    { name: `Oliven`, categoryId: 1 },
    { name: `Schmand`, categoryId: 3 },
    { name: `Rucola`, categoryId: 1 },
    { name: `Kichererbsen`, categoryId: 7 },
    { name: `Pizza`, categoryId: 6 },
    { name: `Öl`, categoryId: 5 },
    { name: `Orangensaft`, categoryId: 9 },
    { name: `Tofu`, categoryId: 7 },
    { name: `Taschentücher`, categoryId: 10 },
    { name: `Mozarella`, categoryId: 3 },
    { name: `Duschgel`, categoryId: 11 },
    { name: `Hefe`, categoryId: 5 },
    { name: `Pfeffer`, categoryId: 5 },
    { name: `Pommes`, categoryId: 6 },
    { name: `Sonnenblumenöl`, categoryId: 5 },
    { name: `Wassermelone`, categoryId: 1 },
    { name: `Aubergine`, categoryId: 1 },
    { name: `Schwämme`, categoryId: 10 },
    { name: `Thunfisch`, categoryId: 4 },
    { name: `Margarine`, categoryId: 3 },
    { name: `Hähnchen`, categoryId: 4 },
    { name: `Fleisch`, categoryId: 4 },
    { name: `Aufstrich`, categoryId: 3 },
    { name: `Skyr`, categoryId: 3 },
    { name: `Gemüsebrühe`, categoryId: 5 },
    { name: `Streukäse`, categoryId: 3 },
    { name: `Speck`, categoryId: 4 },
    { name: `Balsamico`, categoryId: 5 },
    { name: `Pinienkerne`, categoryId: 8 },
    { name: `Honig`, categoryId: 8 },
    { name: `Mayo`, categoryId: 5 },
    { name: `Petersilie`, categoryId: 5 },
    { name: `Melone`, categoryId: 1 },
    { name: `Schokolade`, categoryId: 8 },
    { name: `Shampoo`, categoryId: 11 },
    { name: `Magerquark`, categoryId: 3 },
    { name: `Wein`, categoryId: 9 },
    { name: `Basilikum`, categoryId: 5 },
    { name: `WC-Reiniger`, categoryId: 10 },
    { name: `Katzenfutter`, categoryId: 12 },
    { name: `Reibekäse`, categoryId: 3 },
    { name: `Marmelade`, categoryId: 8 },
    { name: `Weichspüler`, categoryId: 10 },
    { name: `Getrocknete Tomaten`, categoryId: 1 },
    { name: `Rapsöl`, categoryId: 5 },
    { name: `Tortellini`, categoryId: 7 },
    { name: `Badreiniger`, categoryId: 10 },
    { name: `Nüsse`, categoryId: 5 },
    { name: `Getränke`, categoryId: 9 },
    { name: `Gouda`, categoryId: 3 },
    { name: `Lauch`, categoryId: 1 },
    { name: `Limetten`, categoryId: 1 },
    { name: `Hummus`, categoryId: 3 },
    { name: `Würstchen`, categoryId: 4 },
    { name: `Maultaschen`, categoryId: 7 },
    { name: `Apfelsaft`, categoryId: 9 },
    { name: `Beeren`, categoryId: 1 },
    { name: `Essig`, categoryId: 5 },
    { name: `Kidneybohnen`, categoryId: 5 },
    { name: `Gnocchi`, categoryId: 7 },
    { name: `Tomate`, categoryId: 1 },
    { name: `Bohnen`, categoryId: 5 },
    { name: `Handseife`, categoryId: 10 },
    { name: `Erdnussbutter`, categoryId: 5 },
    { name: `Apfelmus`, categoryId: 5 },
    { name: `Geriebener Käse`, categoryId: 3 },
    { name: `Schafskäse`, categoryId: 3 },
    { name: `Bacon`, categoryId: 4 },
    { name: `Minze`, categoryId: 5 },
    { name: `Koriander`, categoryId: 5 },
    { name: `Spülmaschinensalz`, categoryId: 10 },
    { name: `Naturjoghurt`, categoryId: 3 },
    { name: `Kleine Tomaten`, categoryId: 1 },
    { name: `Mango`, categoryId: 1 },
    { name: `Blumenkohl`, categoryId: 1 },
    { name: `Zitronensaft`, categoryId: 5 },
    { name: `Cornflakes`, categoryId: 7 },
    { name: `Himbeeren`, categoryId: 1 },
    { name: `Walnüsse`, categoryId: 5 },
    { name: `Glasreiniger`, categoryId: 10 },
    { name: `Gurken`, categoryId: 1 },
    { name: `Nektarinen`, categoryId: 1 },
    { name: `Süßkartoffel`, categoryId: 1 },
    { name: `Grillkãse`, categoryId: 3 },
    { name: `Snacks`, categoryId: 8 },
    { name: `Weintrauben`, categoryId: 1 },
    { name: `Backpulver`, categoryId: 5 },
    { name: `Radieschen`, categoryId: 1 },
    { name: `Banane`, categoryId: 1 },
    { name: `Heidelbeeren`, categoryId: 1 },
    { name: `Mülltüten`, categoryId: 10 },
    { name: `Kochschinken`, categoryId: 4 },
    { name: `Kekse`, categoryId: 8 },
    { name: `Limette`, categoryId: 1 },
    { name: `Hack`, categoryId: 4 },
    { name: `Sonnencreme`, categoryId: 11 },
    { name: `Fischstäbchen`, categoryId: 6 },
    { name: `Orangen`, categoryId: 1 },
    { name: `Oregano`, categoryId: 5 },
    { name: `Trauben`, categoryId: 1 },
    { name: `Klarspüler`, categoryId: 10 },
    { name: `Windeln`, categoryId: 11 },
    { name: `Sojasauce`, categoryId: 5 },
    { name: `Couscous`, categoryId: 7 },
    { name: `Kohlrabi`, categoryId: 1 },
    { name: `Erbsen`, categoryId: 1 },
    { name: `Fisch`, categoryId: 4 },
    { name: `Grillfleisch`, categoryId: 4 },
    { name: `Rote Zwiebeln`, categoryId: 1 },
    { name: `Sekt`, categoryId: 9 },
    { name: `Feuchttücher`, categoryId: 11 },
    { name: `Mandelmilch`, categoryId: 3 },
    { name: `Tomatensauce`, categoryId: 5 },
    { name: `Buttermilch`, categoryId: 3 },
    { name: `Blätterteig`, categoryId: 2 },
    { name: `Katzenstreu`, categoryId: 12 },
    { name: `Saure Sahne`, categoryId: 3 },
    { name: `Zwiebel`, categoryId: 1 },
    { name: `Ananas`, categoryId: 1 },
    { name: `Alufolie`, categoryId: 10 },
    { name: `Dosentomaten`, categoryId: 5 },
    { name: `Sojasoße`, categoryId: 5 },
    { name: `Cocktailtomaten`, categoryId: 1 },
    { name: `Körniger Frischkäse`, categoryId: 3 },
    { name: `Spätzle`, categoryId: 7 },
    { name: `Blaubeeren`, categoryId: 1 },
    { name: `Räuchertofu`, categoryId: 7 },
    { name: `Wattestäbchen`, categoryId: 11 },
    { name: `Wattepads`, categoryId: 11 },
    { name: `Leberwurst`, categoryId: 4 },
    { name: `Weisswein`, categoryId: 9 },
    { name: `Pizzateig`, categoryId: 2 },
    { name: `Tomatensoße`, categoryId: 5 },
    { name: `Zimt`, categoryId: 5 },
    { name: `Knäckebrot`, categoryId: 2 },
    { name: `Vanillezucker`, categoryId: 5 },
    { name: `Frühlingszwiebel`, categoryId: 1 },
    { name: `Gehackte Tomaten`, categoryId: 5 },
    { name: `Osaft`, categoryId: 9 },
    { name: `Kirschen`, categoryId: 1 },
    { name: `Waschpulver`, categoryId: 10 },
    { name: `Lauchzwiebeln`, categoryId: 1 },
    { name: `Kräuterbutter`, categoryId: 3 },
    { name: `Kaffeefilter`, categoryId: 10 },
    { name: `Radler`, categoryId: 9 },
    { name: `Kräuterbaguette`, categoryId: 2 },
    { name: `Datteln`, categoryId: 1 },
    { name: `Gewürzgurken`, categoryId: 5 },
    { name: `Schnittlauch`, categoryId: 5 },
    { name: `Spargel`, categoryId: 1 },
    { name: `Kiwi`, categoryId: 1 },
    { name: `Kakao`, categoryId: 9 },
    { name: `Halloumi`, categoryId: 3 },
    { name: `Hähnchenbrust`, categoryId: 4 },
    { name: `Küchenpapier`, categoryId: 10 },
    { name: `Fleischwurst`, categoryId: 4 },
    { name: `Rosinen`, categoryId: 5 },
    { name: `Garnelen`, categoryId: 4 },
    { name: `Schupfnudeln`, categoryId: 7 },
    { name: `Cheddar`, categoryId: 3 },
    { name: `Süßkartoffeln`, categoryId: 1 },
    { name: `Tee`, categoryId: 9 },
    { name: `Eisbergsalat`, categoryId: 1 },
    { name: `Agavendicksaft`, categoryId: 5 },
    { name: `Birnen`, categoryId: 1 },
    { name: `Haarspray`, categoryId: 11 },
    { name: `Puderzucker`, categoryId: 5 },
    { name: `Cherrytomaten`, categoryId: 1 },
    { name: `Eistee`, categoryId: 9 },
    { name: `Kirschtomaten`, categoryId: 1 },
    { name: `Flüssigseife`, categoryId: 11 },
    { name: `Mandeln`, categoryId: 5 },
    { name: `Rohrreiniger`, categoryId: 10 },
    { name: `Pfefferkörner`, categoryId: 5 },
    { name: `Eiswürfel`, categoryId: 6 },
    { name: `Sojamilch`, categoryId: 3 },
    { name: `Kaffeebohnen`, categoryId: 9 },
    { name: `Dinkelmehl`, categoryId: 7 },
    { name: `Rosmarin`, categoryId: 5 },
    { name: `Toastbrot`, categoryId: 2 },
    { name: `Burgerbrötchen`, categoryId: 2 },
    { name: `Griechischer Joghurt`, categoryId: 3 },
    { name: `Slipeinlagen`, categoryId: 11 },
    { name: `Sprüdel`, categoryId: 9 },
    { name: `Rote Linsen`, categoryId: 5 },
    { name: `Schlagsahne`, categoryId: 3 },
    { name: `Sonnenblumenkerne`, categoryId: 5 },
    { name: `Penne`, categoryId: 7 },
    { name: `Sellerie`, categoryId: 1 },
    { name: `Feldsalat`, categoryId: 1 },
    { name: `Biomüllbeutel`, categoryId: 10 },
    { name: `Remoulade`, categoryId: 5 },
    { name: `Bratöl`, categoryId: 5 },
    { name: `Kaugummi`, categoryId: 8 },
    { name: `Camembert`, categoryId: 3 },
    { name: `Gelbe Säcke`, categoryId: 10 },
    { name: `Frischhaltefolie`, categoryId: 10 },
    { name: `Jogurt`, categoryId: 3 },
    { name: `Rote Beete`, categoryId: 1 },
    { name: `Ziegenkäse`, categoryId: 3 },
    { name: `Lappen`, categoryId: 10 },
    { name: `TK Pizza`, categoryId: 6 },
    { name: `Dill`, categoryId: 5 },
    { name: `Masken`, categoryId: 11 },
    { name: `Hühnchen`, categoryId: 4 },
    { name: `Essigessenz`, categoryId: 5 },
    { name: `Kräuterquark`, categoryId: 3 },
    { name: `Röstzwiebeln`, categoryId: 5 },
    { name: `Schinkenwürfel`, categoryId: 4 },
    { name: `Leinsamen`, categoryId: 5 },
    { name: `Fleischsalat`, categoryId: 4 },
    { name: `Müsliriegel`, categoryId: 8 },
    { name: `Cashews`, categoryId: 5 },
    { name: `Erdnusse`, categoryId: 5 },
    { name: `Energy`, categoryId: 9 },
    { name: `Sojajoghurt`, categoryId: 3 },
    { name: `Fladenbrot`, categoryId: 2 },
    { name: `Vanilleeis`, categoryId: 6 },
    { name: `Scheibenkäse`, categoryId: 3 },
    { name: `Grillzeug`, categoryId: 10 },
    { name: `WC Reiniger`, categoryId: 10 },
    { name: `Früchte`, categoryId: 1 },
    { name: `Salatgurke`, categoryId: 1 },
    { name: `Zahnbürste`, categoryId: 11 },
    { name: `Schnitzel`, categoryId: 4 },
    { name: `Essiggurken`, categoryId: 5 },
    { name: `Apfelessig`, categoryId: 5 },
    { name: `Schmelzkäse`, categoryId: 3 },
    { name: `Paprikapulver`, categoryId: 5 },
    { name: `Schoki`, categoryId: 8 },
    { name: `Tempos`, categoryId: 11 },
    { name: `Hygienespüler`, categoryId: 10 },
    { name: `Mundspülung`, categoryId: 11 },
    { name: `Kräuterfrischkäse`, categoryId: 3 },
    { name: `Ruccola`, categoryId: 1 },
    { name: `Pudding`, categoryId: 3 },
    { name: `Ahornsirup`, categoryId: 5 },
    { name: `Binden`, categoryId: 11 },
    { name: `Peperoni`, categoryId: 1 },
    { name: `Linsen`, categoryId: 5 },
    { name: `Kaffeepads`, categoryId: 9 },
    { name: `Schokomüsli`, categoryId: 7 },
    { name: `Zuchini`, categoryId: 1 },
    { name: `Bratwurst`, categoryId: 4 },
    { name: `Milchreis`, categoryId: 3 },
    { name: `Majo`, categoryId: 5 },
    { name: `Rotwein`, categoryId: 9 },
    { name: `Trinken`, categoryId: 9 },
    { name: `Suppengrün`, categoryId: 1 },
    { name: `Mate`, categoryId: 9 },
    { name: `TK Beeren`, categoryId: 6 },
    { name: `Sodastream`, categoryId: 10 },
    { name: `Spezi`, categoryId: 9 },
    { name: `Karotte`, categoryId: 1 },
    { name: `TK Gemüse`, categoryId: 6 },
    { name: `Biomülltüten`, categoryId: 10 },
    { name: `Spülschwämme`, categoryId: 10 },
    { name: `Tonic`, categoryId: 9 },
    { name: `Blattspinat`, categoryId: 1 },
    { name: `Allzweckreiniger`, categoryId: 10 },
    { name: `Alsan`, categoryId: 3 },
    { name: `TK Himbeeren`, categoryId: 6 },
    { name: `Limo`, categoryId: 9 },
    { name: `Glasnudeln`, categoryId: 7 },
    { name: `Nachos`, categoryId: 8 },
    { name: `Toasties`, categoryId: 7 },
    { name: `Magnesium`, categoryId: 11 },
    { name: `Zahnseide`, categoryId: 11 },
    { name: `Gummibärchen`, categoryId: 8 },
    { name: `Humus`, categoryId: 5 },
    { name: `Wiener`, categoryId: 4 },
    { name: `Fenchel`, categoryId: 1 },
    { name: `Haferdrink`, categoryId: 3 },
    { name: `Saure Gurken`, categoryId: 5 },
    { name: `Feuchtes Toilettenpapier`, categoryId: 11 },
    { name: `Gnocci`, categoryId: 7 },
    { name: `Espresso`, categoryId: 9 },
    { name: `Speckwürfel`, categoryId: 4 },
    { name: `Kürbiskerne`, categoryId: 5 },
    { name: `Brauner Zucker`, categoryId: 5 },
    { name: `Tampons`, categoryId: 11 },
    { name: `Pflaster`, categoryId: 11 },
    { name: `Trockenshampoo`, categoryId: 11 },
    { name: `Thymian`, categoryId: 5 },
    { name: `Birne`, categoryId: 1 },
    { name: `Curry`, categoryId: 5 },
    { name: `Cola Zero`, categoryId: 9 },
    { name: `Hüttenkäse`, categoryId: 3 },
    { name: `Cherry Tomaten`, categoryId: 1 },
    { name: `Pasta`, categoryId: 7 },
    { name: `Kokosöl`, categoryId: 5 },
    { name: `2 Paprika`, categoryId: 1 },
    { name: `Pfirsich`, categoryId: 1 },
    { name: `Risottoreis`, categoryId: 7 },
    { name: `Quinoa`, categoryId: 7 },
    { name: `Reisnudeln`, categoryId: 7 },
  ],
  en: [
    { name: `Milk`, categoryId: 3 },
    { name: `Eggs`, categoryId: 3 },
    { name: `Tomatoes`, categoryId: 1 },
    { name: `Bread`, categoryId: 2 },
    { name: `Toilet Paper`, categoryId: 11 },
    { name: `Bananas`, categoryId: 1 },
    { name: `Cucumber`, categoryId: 1 },
    { name: `Cheese`, categoryId: 3 },
    { name: `Pepper`, categoryId: 1 },
    { name: `Yoghurt`, categoryId: 3 },
    { name: `Salad`, categoryId: 1 },
    { name: `Butter`, categoryId: 3 },
    { name: `Fruits`, categoryId: 1 },
    { name: `Onions`, categoryId: 1 },
    { name: `Potatoes`, categoryId: 1 },
    { name: `Apples`, categoryId: 1 },
    { name: `Toast`, categoryId: 2 },
    { name: `Feta`, categoryId: 3 },
    { name: `Coffee`, categoryId: 9 },
    { name: `Olive Oil`, categoryId: 5 },
    { name: `Zucchini`, categoryId: 1 },
    { name: `Pasta`, categoryId: 7 },
    { name: `Cream Cheese`, categoryId: 3 },
    { name: `Cream`, categoryId: 3 },
    { name: `Mozzarella`, categoryId: 3 },
    { name: `Toilet Paper`, categoryId: 11 },
    { name: `Vegetables`, categoryId: 1 },
    { name: `Cold Cuts`, categoryId: 4 },
    { name: `Water`, categoryId: 1 },
    { name: `Parmesan`, categoryId: 3 },
    { name: `Sausage`, categoryId: 4 },
    { name: `Oak Milk`, categoryId: 3 },
    { name: `Garlic`, categoryId: 1 },
    { name: `Avocado`, categoryId: 1 },
    { name: `Oatmeal`, categoryId: 7 },
    { name: `Carrots`, categoryId: 1 },
    { name: `Corn`, categoryId: 1 },
    { name: `Kitchen Roll`, categoryId: 10 },
    { name: `Cereals`, categoryId: 7 },
    { name: `Mushrooms`, categoryId: 1 },
    { name: `Salt`, categoryId: 5 },
    { name: `Toothpaste`, categoryId: 11 },
    { name: `Curd Cheese`, categoryId: 3 },
    { name: `Flour`, categoryId: 7 },
    { name: `Juice`, categoryId: 9 },
    { name: `Beer`, categoryId: 7 },
    { name: `Minced Meat`, categoryId: 4 },
    { name: `Carrots`, categoryId: 1 },
    { name: `Ice Cream`, categoryId: 6 },
    { name: `Tomato Paste`, categoryId: 5 },
    { name: `Strawberries`, categoryId: 1 },
    { name: `Detergent`, categoryId: 10 },
    { name: `Ham`, categoryId: 4 },
    { name: `Lemon`, categoryId: 1 },
    { name: `Sieved Tomatoes`, categoryId: 5 },
    { name: `Rice`, categoryId: 7 },
    { name: `Baking Powder`, categoryId: 5 },
    { name: `Salami`, categoryId: 4 },
    { name: `Lemons`, categoryId: 1 },
    { name: `Sugar`, categoryId: 5 },
    { name: `Garbage Bags`, categoryId: 10 },
    { name: `Soap`, categoryId: 11 },
    { name: `Broccoli`, categoryId: 1 },
    { name: `Coconut Milk`, categoryId: 5 },
    { name: `Dish Liquid`, categoryId: 10 },
    { name: `Baguette`, categoryId: 2 },
    { name: `Dish Liquid`, categoryId: 10 },
    { name: `White Mushrooms`, categoryId: 1 },
    { name: `Dishwasher Tabs`, categoryId: 10 },
    { name: `Ketchup`, categoryId: 5 },
    { name: `Spinach`, categoryId: 1 },
    { name: `Nutella`, categoryId: 8 },
    { name: `Crisps`, categoryId: 8 },
    { name: `Salmon`, categoryId: 4 },
    { name: `Pesto`, categoryId: 5 },
    { name: `Creme Fraiche`, categoryId: 3 },
    { name: `Wraps`, categoryId: 5 },
    { name: `Mustard`, categoryId: 5 },
    { name: `Cola`, categoryId: 9 },
    { name: `Spaghetti`, categoryId: 7 },
    { name: `Ginger`, categoryId: 1 },
    { name: `Antiperspirant`, categoryId: 11 },
    { name: `Green onions`, categoryId: 1 },
    { name: `Olives`, categoryId: 1 },
    { name: `Sour Cream`, categoryId: 3 },
    { name: `Rocket`, categoryId: 1 },
    { name: `Chickpea`, categoryId: 7 },
    { name: `Pizza`, categoryId: 6 },
    { name: `Oil`, categoryId: 5 },
    { name: `Orange Juice`, categoryId: 9 },
    { name: `Tofu`, categoryId: 7 },
    { name: `Tissues`, categoryId: 10 },
    { name: `Mozarella`, categoryId: 3 },
    { name: `Shower Gel`, categoryId: 11 },
    { name: `Yeast`, categoryId: 5 },
    { name: `Black Pepper`, categoryId: 5 },
    { name: `Fries`, categoryId: 6 },
    { name: `Sunflower Oil`, categoryId: 5 },
    { name: `Water Melon`, categoryId: 1 },
    { name: `Eggplant`, categoryId: 1 },
    { name: `Sponges`, categoryId: 10 },
    { name: `Tuna`, categoryId: 4 },
    { name: `Margarine`, categoryId: 3 },
    { name: `Chicken`, categoryId: 4 },
    { name: `Meat`, categoryId: 4 },
    { name: `Spread`, categoryId: 3 },
    { name: `Skyr`, categoryId: 3 },
    { name: `Vegetable Stock`, categoryId: 5 },
    { name: `Grated Cheese`, categoryId: 3 },
    { name: `Bacon`, categoryId: 4 },
    { name: `Balsamico`, categoryId: 5 },
    { name: `Pine Nuts`, categoryId: 8 },
    { name: `Honey`, categoryId: 8 },
    { name: `Mayonnaise`, categoryId: 5 },
    { name: `Parsley`, categoryId: 5 },
    { name: `Melon`, categoryId: 1 },
    { name: `Chocolate`, categoryId: 8 },
    { name: `Shampoo`, categoryId: 11 },
    { name: `Low Fat Curd Cheese`, categoryId: 3 },
    { name: `Wine`, categoryId: 9 },
    { name: `Basil`, categoryId: 5 },
    { name: `Toilet Cleaner`, categoryId: 10 },
    { name: `Cat Food`, categoryId: 12 },
    { name: `Grated Cheese`, categoryId: 3 },
    { name: `Jam`, categoryId: 8 },
    { name: `Softener`, categoryId: 10 },
    { name: `Dried Tomatoes`, categoryId: 1 },
    { name: `Rapeseed Oil`, categoryId: 5 },
    { name: `Tortellini`, categoryId: 7 },
    { name: `Bathroom Cleaner`, categoryId: 10 },
    { name: `Nuts`, categoryId: 5 },
    { name: `Drinks`, categoryId: 9 },
    { name: `Gouda Cheese`, categoryId: 3 },
    { name: `Leek`, categoryId: 1 },
    { name: `Limes`, categoryId: 1 },
    { name: `Hummus`, categoryId: 3 },
    { name: `Small Sausages`, categoryId: 4 },
    { name: `Apple Juice`, categoryId: 9 },
    { name: `Berries`, categoryId: 1 },
    { name: `Vinegar`, categoryId: 5 },
    { name: `Kidney Beans`, categoryId: 5 },
    { name: `Gnocchi`, categoryId: 7 },
    { name: `Tomato`, categoryId: 1 },
    { name: `Beans`, categoryId: 5 },
    { name: `Hand Soap`, categoryId: 10 },
    { name: `Peanut Butter`, categoryId: 5 },
    { name: `Applesauce`, categoryId: 5 },
    { name: `Grated Cheese`, categoryId: 3 },
    { name: `Sheep Cheese`, categoryId: 3 },
    { name: `Bacon`, categoryId: 4 },
    { name: `Mint`, categoryId: 5 },
    { name: `Coriander`, categoryId: 5 },
    { name: `Dishwasher Salt`, categoryId: 10 },
    { name: `Natural yogurt`, categoryId: 3 },
    { name: `Small tomatoes`, categoryId: 1 },
    { name: `Mango`, categoryId: 1 },
    { name: `Cauliflower`, categoryId: 1 },
    { name: `Lemon Juice`, categoryId: 5 },
    { name: `Cornflakes`, categoryId: 7 },
    { name: `Raspberries`, categoryId: 1 },
    { name: `Walnuts`, categoryId: 5 },
    { name: `Glass Cleaner`, categoryId: 10 },
    { name: `Cucumbers`, categoryId: 1 },
    { name: `Nectarines`, categoryId: 1 },
    { name: `Sweet Potatoes`, categoryId: 1 },
    { name: `Grill Cheese`, categoryId: 3 },
    { name: `Snacks`, categoryId: 8 },
    { name: `Grapes`, categoryId: 1 },
    { name: `Radish`, categoryId: 1 },
    { name: `Banana`, categoryId: 1 },
    { name: `Bluberries`, categoryId: 1 },
    { name: `Garbage Bags`, categoryId: 10 },
    { name: `Boiled Ham`, categoryId: 4 },
    { name: `Biscuits`, categoryId: 8 },
    { name: `Lime`, categoryId: 1 },
    { name: `Minced Meat`, categoryId: 4 },
    { name: `Sunscreen`, categoryId: 11 },
    { name: `Fish Sticks`, categoryId: 6 },
    { name: `Oranges`, categoryId: 1 },
    { name: `Oregano`, categoryId: 5 },
    { name: `Grapes`, categoryId: 1 },
    { name: `Rinse Aid`, categoryId: 10 },
    { name: `Diapers`, categoryId: 11 },
    { name: `Soy Sauce`, categoryId: 5 },
    { name: `Couscous`, categoryId: 7 },
    { name: `Kohlrabi`, categoryId: 1 },
    { name: `Peas`, categoryId: 1 },
    { name: `Fish`, categoryId: 4 },
    { name: `Barbecue Meat`, categoryId: 4 },
    { name: `Red Onions`, categoryId: 1 },
    { name: `Sparkling Wine`, categoryId: 9 },
    { name: `Wet Wipes`, categoryId: 11 },
    { name: `Almond Milk`, categoryId: 3 },
    { name: `Tomato Sauce`, categoryId: 5 },
    { name: `Buttermilch`, categoryId: 3 },
    { name: `Puff Pastry`, categoryId: 2 },
    { name: `Cat Litter`, categoryId: 12 },
    { name: `Sour Cream`, categoryId: 3 },
    { name: `Onion`, categoryId: 1 },
    { name: `Pineapple`, categoryId: 1 },
    { name: `Aluminium Foil`, categoryId: 10 },
    { name: `Canned Tomatoes`, categoryId: 5 },
    { name: `Soy Sauce`, categoryId: 5 },
    { name: `Cocktail Tomatoes`, categoryId: 1 },
    { name: `Grained cream cheese`, categoryId: 3 },
    { name: `Spaetzle`, categoryId: 7 },
    { name: `Blueberries`, categoryId: 1 },
    { name: `Smoked Tofu`, categoryId: 7 },
    { name: `Cotton Swab`, categoryId: 11 },
    { name: `Cotton Pads`, categoryId: 11 },
    { name: `Liver Sausage`, categoryId: 4 },
    { name: `White Wine`, categoryId: 9 },
    { name: `Pizza Dough`, categoryId: 2 },
    { name: `Tomato Sauce`, categoryId: 5 },
    { name: `Cinnamon`, categoryId: 5 },
    { name: `Crispbread`, categoryId: 2 },
    { name: `Vanilla Sugar`, categoryId: 5 },
    { name: `Green Onion`, categoryId: 1 },
    { name: `Chopped Tomatoes`, categoryId: 5 },
    { name: `Orange Juice`, categoryId: 9 },
    { name: `Cherries`, categoryId: 1 },
    { name: `Washing Powder`, categoryId: 10 },
    { name: `Spring Onion`, categoryId: 1 },
    { name: `Herb Butter`, categoryId: 3 },
    { name: `Cofee Filter`, categoryId: 10 },
    { name: `Shandy`, categoryId: 9 },
    { name: `Herbal Baguette`, categoryId: 2 },
    { name: `Dates`, categoryId: 1 },
    { name: `Pickles`, categoryId: 5 },
    { name: `Chives`, categoryId: 5 },
    { name: `Asparagus`, categoryId: 1 },
    { name: `Kiwi`, categoryId: 1 },
    { name: `Cacao`, categoryId: 9 },
    { name: `Halloumi`, categoryId: 3 },
    { name: `Chicken Breast`, categoryId: 4 },
    { name: `Pork Sausage`, categoryId: 4 },
    { name: `Raisins`, categoryId: 5 },
    { name: `Prawn`, categoryId: 4 },
    { name: `Potato Noodles`, categoryId: 7 },
    { name: `Cheddar`, categoryId: 3 },
    { name: `Sweet Potatoes`, categoryId: 1 },
    { name: `Tea`, categoryId: 9 },
    { name: `Iceberg Lettuce`, categoryId: 1 },
    { name: `Agave Syrup`, categoryId: 5 },
    { name: `Pears`, categoryId: 1 },
    { name: `Cherry Tomatoes`, categoryId: 1 },
    { name: `Almonds`, categoryId: 5 },
  ]
}
