import { combineReducers } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import shoppinglistSlice from '../shoppinglist/store/shoppinglistSlice'

const persistConfig = {
  key: 'flatasticRedux',
  storage,
}

const rootReducer = combineReducers({
  shoppinglist: shoppinglistSlice.reducer,
})

export const persistedReducer = persistReducer(persistConfig, rootReducer)

