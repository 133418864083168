import { defaultTo } from 'ramda'
import UserService from '../../../../modules/user/svc_user'
import WgService from '../../../../modules/wg/svc_wg'
import IconHomeRent from './assets/icon-home-rent.svg'
import IconInsurancesHouseholdContent from './assets/icon-insurances-household-content.svg'
import IconMediaMusic from './assets/icon-media-music.svg'
import IconMediaPopcorn from './assets/icon-media-popcorn.svg'
import IconMemberships from './assets/icon-memberships.svg'
import IconUtilityElectricity from './assets/icon-utility-electricity.svg'
import IconUtilityInternet from './assets/icon-utility-internet.svg'
import IconUtilityWater from './assets/icon-utility-water.svg'
import template from './template.html'

const iconMap = {
  'ion-ios-lightbulb': IconUtilityElectricity,
  'ion-ios-home': IconHomeRent,
  'ion-ios-world-outline': IconUtilityInternet,
  'ion-clipboard': IconInsurancesHouseholdContent,
  'ion-ios-people': IconMemberships,
  'ion-headphone': IconMediaMusic,
  'ion-ios-videocam': IconMediaPopcorn,
  'ion-waterdrop': IconUtilityWater,
}

export default angular.module('ft.fincances.components.subscription-proposal', [
  WgService,
  UserService,
])
.component('subscriptionProposal', {
  template,
  bindings: {
    proposal: '<',
  },
  controller,
})
.name

controller.$inject = ['User', 'Wg']
function controller(User, Wg) {
  const $ctrl = this
  $ctrl.currency = Wg.currency
  $ctrl.icon = defaultTo($ctrl.proposal.data.icon)(iconMap[$ctrl.proposal.data.icon])
}
