export default {
  [`Shoppinglist:EmptyState:Text`]: [
    `This List is empty. Below are some ideas to get you started. Maybe one of the others will buy it on their way home!`,
    `Eure Einkaufsliste ist leer. Unten findest du ein paar Ideen um loszulegen. Vielleicht bringt jemand auf dem Heimweg bereits etwas mit.`,
  ],
  [`Shoppinglist:EmptyState:Heading`]: [
    `Ideas to get you started`,
    `Ideen zum Starten`,
  ],
}
