// ['en', 'de']

export default {
  [`NFDB__TITLE`]: [
    `Notifications`,
    `Benachrichtigungen`,
  ],
  [`NFDB__REPORT-PROBLEM`]: [
    `Report problem`,
    `Problem melden`,
  ],
  [`NFDB__TEST-NOTIFICATIONS`]: [
    `Test notifications`,
    `Benachrichtigungen testen`,
  ],
  [`NFDB__PUSHES_ARE_ACTIVATED`]: [
    `Push notifications are enabled`,
    `Zugriff auf Push-Benachrichtigungen ist erteilt`,
  ],
  [`NFDB__DEVICE_REGISTERED`]: [
    `Device is registered`,
    `Gerät ist registriert`,
  ],
  [`NFDB__TEST_SUCCESSFUL`]: [
    `Test successful. We couldn\'t find any problems.`,
    `Test erfolgreich. Es wurden keine Probleme festgestellt.`,
  ],
  [`NFDB__TEST_RESULT`]: [
    `You received {{ numSuccess }} of {{ numTotal }} notifications`,
    `Du hast {{ numSuccess }} von {{ numTotal }} Benachrichtigungen erhalten`,
  ],
  [`NFDB__NOTIFICATION_PENDING`]: [
    `Waiting for notification ({{ type }})`,
    `Warte auf Benachrichtigung ({{ type }})`,
  ],
  [`NFDB__NOTIFICATION_RECEIVED`]: [
    `Received notification ({{ type }})`,
    `Benachrichtigung erhalten ({{ type }})`,
  ],
  [`NFDB__NOTIFICATION_NOT_RECEIVED`]: [
    `Did not receive notification ({{ type }})`,
    `Benachrichtigung nicht erhalten ({{ type }})`,
  ],
  [`NFDB__RERUN_TEST`]: [
    `Restart test`,
    `Test erneut starten`,
  ],
  [`NFDB__DEVICE_TOKEN_STORED`]: [
    `Device token is stored`,
    `Gerät ist für Benachrichtigungen aktiviert`,
  ],
  [`MENU__SHOUTS`]: [
    `Bulletin Board`,
    `Pinnwand`,
  ],
  [`MENU__CHORES`]: [
    `Chores`,
    `Aufgaben`,
  ],
  [`MENU__EXPENSES`]: [
    `Finances`,
    `Finanzen`,
  ],
  [`MENU__SHOPPING_LIST`]: [
    `Shopping List`,
    `Einkaufen`,
  ],
}
