import Config from '../../../../scripts/config'
import template from './template.html'
export default angular.module('ft.components.shoppinglist.quick-edit', [
  Config,
])
.component('shlQuickEdit', {
  template,
  controller,
  transclude: true,
  bindings: {
    isPremium: '<',
  },
})
.name


controller.$inject = ['$window', 'LocalStorageKeys']
function controller($window, LocalStorageKeys) {
  const vm = this

  vm.isInfoTextHidden = Boolean($window.localStorage[LocalStorageKeys.shoppinglist.quickEditInfoDismissed])
  vm.hideInfoText = hideInfoText

  vm.isQuickEditDisabled = Boolean($window.localStorage[LocalStorageKeys.shoppinglist.quickEditDeferredAt])
  vm.disableQuickEdit = disableQuickEdit

  function hideInfoText() {
    vm.isInfoTextHidden = true
    $window.localStorage[LocalStorageKeys.shoppinglist.quickEditInfoDismissed] = true
  }

  function disableQuickEdit() {
    vm.isQuickEditDisabled = true
    $window.localStorage[LocalStorageKeys.shoppinglist.quickEditDeferredAt] = true
  }

}
