import FlatasticConfig from '../../scripts/config'
import FirebaseModule from '../../scripts/modules/flatasticFirebase'

export default angular.module('flatastic.analtyics', [
  FirebaseModule,
  FlatasticConfig,
])

.run(['$rootScope', 'FlatasticEvents', 'Firebase', function($rootScope, FlatasticEvents, Firebase) {

  const eventsForFirebase = [
    // Expenses
    [FlatasticEvents.expenses.didCreate, 'exp__did_add_entry'],
    [FlatasticEvents.expenses.didResetBalance, 'exp__did_reset_balance'],
    // Shopping list
    [FlatasticEvents.shoppinglist.didAddItem, 'shl__did_add_item'],
    [FlatasticEvents.shoppinglist.didCheckItem, 'shl__did_check_item'],
    [FlatasticEvents.shoppinglist.didRemoveBoughtItems, 'shl__did_remove_bought_items'],
    // Shouts
    [FlatasticEvents.shouts.didCreate, 'bb__did_send_message'],
    [FlatasticEvents.shouts.didDelete, 'bb__did_delete_message'],
    [FlatasticEvents.shouts.didLike, 'bb__did_like_message'],
    [FlatasticEvents.shouts.didUnlike, 'bb__did_unlike_message'],
    // Chores
    [FlatasticEvents.chores.update, 'cho__did_update_chore'],
    [FlatasticEvents.chores.didCreateChore, 'cho__did_add_chore'],
    [FlatasticEvents.chores.didDeleteChore, 'cho__did_delete_chore'],
    [FlatasticEvents.chores.didResetStatistics, 'cho__did_reset_statistics'],
    [FlatasticEvents.chores.didCompleteChore, 'cho__did_complete_chore'],
    // Subscriptions
    [FlatasticEvents.subscriptions.didCreateEntry, 'sub__did_add_entry'],
    [FlatasticEvents.subscriptions.didDeleteEntry, 'sub__did_delete_entry'],
    [FlatasticEvents.subscriptions.didUpdateEntry, 'sub__did_update_entry'],
    // Wg
    [FlatasticEvents.wg.didShareInvitationCode, 'home__did_share_invitation_code'],
  ]

  eventsForFirebase.forEach(([eventName, firebaseEvent]) =>
    $rootScope.$on(eventName, (event, data = undefined) => Firebase.logEvent(firebaseEvent, data))
  )

}])

.factory('Analytics', ['Firebase', function(Firebase) {
  return {
    logEvent: Firebase.logEvent,
  }
}])

.directive('trackOn', ['Firebase', '$parse', function(Firebase, $parse) {
  return {
    restrict: 'A',
    link: function($scope, $element, $attrs) {
      $element.bind($attrs.trackOn, function($event) {
        let eventParams
        if ($attrs.trackParams) {
          try {
            eventParams = $parse($attrs.trackParams)($scope)
          } catch (e) {
            console.debug('Could not parse trackParams', e)
          }
        }
        const eventName = $attrs.trackName
        Firebase.logEvent(eventName, eventParams)
      })
    },
  }
}])

// Legacy tracking directive from mcmGoogleAnalytics
.directive('analyticsOn', ['$rootScope', function($rootScope) {
  return {
    restrict: 'A',
    link: function($scope, $element, $attrs) {
      $element.bind($attrs.analyticsOn, function($event) {
        $rootScope.$broadcast('track-event', getProperties($attrs));
      });
    },
  };

  function getProperties(attrs) {
    var properties = {};
    angular.forEach(attrs.$attr, function(val, key) {
      if (isAnalyticsProperty(val)) {
        properties[val.substr(10)] = attrs[key];
      }
    });
    return properties;
  }

  function isAnalyticsProperty(attr) {
    return /^analytics-(category|action|label|value)$/i.test(attr)
  }
}])

.name
