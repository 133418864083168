import AngularTranslate from 'angular-translate'
import * as moment from 'moment'
import flatasticConfig from '../../scripts/config'
import yepptApi from '../../scripts/modules/api'
import yepptCollection from '../../scripts/modules/collection'
import flatasticUserService from '../user/svc_user'
import flatasticExpensesServiceExpense from './svc_expense'


export default angular.module('flatastic.expenses.service', [
  AngularTranslate,
  flatasticConfig,
  flatasticExpensesServiceExpense,
  flatasticUserService,
  yepptApi,
  yepptCollection,
])

.run(['Expenses', '$rootScope', 'User', 'FlatasticEvents',
    function(Expenses, $rootScope, User, FlatasticEvents) {

  if (User.isLoggedIn() && User.properties.groupId == 2) {
    Expenses.getFromServer();
    Expenses.getStatisticsFromServer();
    Expenses.fetchPersonalSpendings(User.isPremium());
  }

  [
    FlatasticEvents.expenses.update,
    FlatasticEvents.user.didEnterWg,
    FlatasticEvents.user.didGetPremium,
  ].forEach(function(event) {
    $rootScope.$on(event, function() {
      Expenses.getFromServer();
      Expenses.getStatisticsFromServer();
      Expenses.fetchPersonalSpendings(User.isPremium());
    });
  });

  $rootScope.$on(FlatasticEvents.user.didLogout, function() {
    Expenses.clear();
  });

  $rootScope.$on('Expense:deleted', function(event, expenseId) {
    console.log('Expense:deleted', expenseId);
    Expenses.removeExpense(expenseId);
  });
  $rootScope.$on('Expense:create', function(event, newExpense) {
    console.log('Adding Expense to Expenses', newExpense);
    Expenses.addExpense(newExpense);
  });
  $rootScope.$on('Expense:updated', function(event, newExpense) {
    console.log('Updating Statistics');
    Expenses.getStatisticsFromServer();
    Expenses.fetchPersonalSpendings(User.isPremium());
  });
}])

.factory('Expenses',
    ['YepptCollection', 'Api', '$rootScope', 'Expense', '$translate', '$q', 'FlatasticEvents',
    function (YepptCollection, Api, $rootScope, Expense, $translate, $q, FlatasticEvents) {

  var expenses = new YepptCollection('id');
  var statistics = {
    data: [],
  };
  var personalSpendings = [];

  var myStatus = '';
  var myError = '';
  var limitGet = 10;
  var moreCanBeLoaded = false;

  var getFromServer = function(obj) {
    myStatus = 'loading';
    obj = obj || {};
    return Api.get('/cashflow', { params: {
      offset: obj.offset || 0,
      limit: obj.limit  || limitGet,
    } })
    .then(function({ data }) {
      var temp = [];
      data.forEach(function(expense) {
        temp.push(new Expense(expense));
      });
      expenses.remove();
      expenses.push(temp);
      moreCanBeLoaded = (data.length >= limitGet);
      myStatus = '';
      myError = '';
    })
    .catch(function(errorResponse) {
      const { data, status, statusText } = errorResponse
      myStatus = 'error';
      myError = status === -1 ? $translate.instant('NO_INTERNET') : (statusText || data);
    });
  };

  var loadMore = function(obj) {
    obj = obj || {};
    var params = {
      limit: obj.limit  || limitGet,
      offset: obj.offset || expenses.length(),
    };
    return Api.get('/cashflow', { params: params })
    .success(function(data) {
      var temp = [];
      data.forEach(function(expense) {
        temp.push(new Expense(expense));
      });
      expenses.push(temp);
      // if 10 items are requested and only 7 returned, there aren't any more…
      moreCanBeLoaded = (data.length >= limitGet);
    });
  };

  var getStatisticsFromServer = function() {
    statistics.status = 'loading';
    return Api.get('/cashflow/statistics')
    .success(function(data) {
      statistics.data = data;
      delete statistics.status;
      delete statistics.error;
    })
    .error(function(data) {
      statistics.status = 'error';
      statistics.error = data || $translate.instant('NO_INTERNET');
      delete statistics.status;
    });
  };

  var resetBalance = function() {
    return Api.get('/cashflow/settlement');
  };

  var doResetBalance = function() {
    return Api.get('/cashflow/archive_all')
    .success(function() {
      console.log('clearing expenses after settlement_execute');
      clear();
      $rootScope.$broadcast(FlatasticEvents.expenses.didResetBalance)
      $rootScope.$broadcast('Expense:updated');
    });
  };

  // Public API here
  return {
    length() {
      return expenses.length();
    },
    get(id) {
      return expenses.get(id);
    },
    addExpense,
    removeExpense,
    clear,
    getFromServer,
    fetchPersonalSpendings,
    fillUpSpendingsIfNecessary,
    getPersonalSpendings,
    getStatisticsFromServer,
    loadMore,
    resetBalance,
    doResetBalance,
    getStatistics() {
      return statistics;
    },
    status() {
      return myStatus
    },
    error() {
      return myError ? myError : false;
    },
    moreCanBeLoaded() {
      return moreCanBeLoaded;
    },
  };


  //// Functions

  function getPersonalSpendings() {
    return personalSpendings;
  }

  function formatPersonalSpendingsResponse(response) {
    return response.data.map(function(row) {
      row.month = Number(row.month);
      row.year = Number(row.year);
      row.sum = Math.round(row.sum * 100) / 100;
      return row;
    })
  }

  function fetchPersonalSpendings(isPremium) {
    return (isPremium
      ? Api.get('/cashflow/my_expenses')
        .then(formatPersonalSpendingsResponse)
      : $q.resolve(generateFakeSpendings()))
      .then(function(result) {
        result = result
          .filter(function(item){
            return moment().diff(new Date(item.year, item.month), 'months') <= 11;
          })
        result = fillUpSpendingsIfNecessary(6, result);

        var finalResult = result
          .map(function(row) {
            return {
              t: moment([row.year, row.month, 1].join('-'), 'YYYY-MM-DD').toDate(),
              y: row.sum,
            }
          })
          .sort(function(a, b) {
            return a.t - b.t
          });

        var lastEntry = finalResult[finalResult.length - 1];

        if (lastEntry && moment().diff(lastEntry.t, 'month') > 0) {
          finalResult.push({
            t: moment().toDate(),
            y: 0,
          });
        }

        return personalSpendings = finalResult;
      })
      .catch(function(data) {
        console.error(data);
      });
  }

  function fillUpSpendingsIfNecessary(minMonths, entries) {
    if (entries.length >= minMonths) {
      return entries
    }
    for (var i = 0; i < minMonths; i++){
      var date = moment().subtract({ month: minMonths - i - 1 });
      var year = Number(date.format('YYYY'));
      var month = Number(date.format('M'));
      var hasEntry = entries.filter(function(entry) {
        return entry.month === month && entry.year === year;
      }).length > 0;
      if (!hasEntry) {
        entries.push({
          year,
          month,
          sum: 0,
        });
      }
    }
    return entries;
  }

  function generateFakeSpendings() {
    return Array.apply(null, Array(8)).map(function(val, idx) {
      var date = moment().subtract({ month: 5 - idx });
      return {
        year: Number(date.format('YYYY')),
        month: Number(date.format('M')),
        sum: Math.floor((Math.random() * 50000)) / 100,
      }
    })
  }

  function removeExpense(expense) {
    expenses.remove(expense);
  }

  function clear() {
    expenses.remove();
  }

  function addExpense(expense) {
    expenses.push(expense, true);
  }

}])

.name;
