export default {
  // Others are not invited
  [`WG__FLATMATE_REMINDER-TITLE`]: [
    `Invite the others.`,
    `Lade deine Mitbewohner ein`,
  ],
  [`WG__FLATMATE_REMINDER-MSG`]: [
    `Living alone is no fun. Send invitations to the other members of your home now.`,
    `Alleine macht das Zusammenleben keinen Spaß. Lade jetzt deine Mitbewohner ein.`,
  ],
  [`WG__FLATMATE_REMINDER-BTN`]: [
    `Invite the others`,
    `Mitbewohner einladen`,
  ],
  // Others haven't moved in yet
  [`WG__INCOMPLETE_REMINDER-TITLE`]: [
    `Somebody is missing…`,
    `Da fehlt noch jemand…`,
  ],
  [`WG__INCOMPLETE_REMINDER-MSG`]: [
    `Not all members have moved in yet. Do you want to remind them and resend them the access code?`,
    `Es sind noch nicht alle deine Mitbewohner eingezogen. Willst du sie erinnern und den Zugangscode noch einmal versenden?`,
  ],
  [`WG__INCOMPLETE_REMINDER-BTN_SHARE`]: [
    `Remind the others`,
    `Mitbewohner erinnern`,
  ],
  [`WG__INCOMPLETE_REMINDER-BTN_LATER`]: [
    `Not now`,
    `Nicht jetzt`,
  ],

  //Former Member

  [`WG__FORMER_FLATMATE-FIRST_NAME`]: [
    `Moved out`,
    `Ausgezogen`,
  ],

  // ADS
  [`ADS__WARN_DIALOG-TITLE`]: [
    `Ads 😫`,
    `Werbung 😫`,
  ],
  [`ADS__WARN_DIALOG-MSG`]: [
    `Every now an then, we show some ads so that we can pay for the servers and keep on improving Flatastic. You hate ads? With Flatastic Premium you get an ad-free experience and unlock lots of additional features.`,
    `Hin und wieder zeigen wir Werbung, damit wir Flatastic am Laufen halten und weiterentwickeln können.\n\nDu magst Werbung nicht? Mit Flatastic Premium erhältst du eine werbefreie App mit vielen nützlichen Zusatzfunktionen.`,
  ],
  [`ADS__WARN_DIALOG-BTN_PRO_ADS`]: [
    `OK`,
    `Werbung zeigen`,
  ],
  [`ADS__WARN_DIALOG-BTN_PREMIUM`]: [
    `Get Flatstic Premium`,
    `Flatastic Premium holen`,
  ],

  // RATE
  [`RATE__DIALOG-TITLE`]: [
    `Rate Flatastic`,
    `Bewerte Flatastic`,
  ],
  [`RATE__DIALOG-MSG`]: [
    `If you enjoy using Flatastic, would you mind taking a moment to rate it? It won\'t take more than a minute.\nThanks for your support!`,
    `Dir gefällt Flatastic? Dann bedanke dich mit einer Bewertung. Es dauert weniger als eine Minute.\nVielen Dank für deine Unterstützung!`,
  ],
  [`RATE__DIALOG-BTN_NO_THANKS`]: [
    `No, Thanks`,
    `Nein, Danke`,
  ],
  [`RATE__DIALOG-BTN_REMIND_ME_LATER`]: [
    `Remind Me Later`,
    `Später`,
  ],
  [`RATE__DIALOG-BTN_RATE_IT_NOW`]: [
    `Rate It Now`,
    `Jetzt Bewerten`,
  ],

  // PUSH Primer
  [`PUSH_PRIMER__TITLE`]: [
    `Push Notifications`,
    `Push Benachrichtigungen`,
  ],
  [`PUSH_PRIMER__BODY`]: [
    `In order to get notified when chores are due, when someone writes a message, adds an expense or goes shopping you'll need to give your permission to receive push notifications.`,
    `Um Nachrichten zu erhalten, an Aufgaben erinnert zu werden und mitzubekommen, wenn z.B. einer deiner Mitbewohnber einkaufen geht, benötigst du Push-Benachrichtigungen`,
  ],
  [`PUSH_PRIMER__OK`]: [
    `Confirm`,
    `Geht klar`,
  ],
  [`PUSH_PRIMER__LATER`]: [
    `Not now`,
    `Nicht jetzt`,
  ],
}
