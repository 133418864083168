import ChoreService from '../../modules/chores/svc_chore'
import ChoresService from '../../modules/chores/svc_chores'
import EffortValuesService from '../../modules/chores/svc_effort-values'
import RotationTimeService from '../../modules/chores/svc_rotation-times'
import WgService from '../../modules/wg/svc_wg'
import ToastModule from '../../scripts/modules/mcmToast'
import CordovaService from '../../scripts/modules/yptCordova'

export default angular.module('flatastic.chores.controller.new-chore', [
  'ionic',
  ChoreService,
  ChoresService,
  WgService,
  CordovaService,
  RotationTimeService,
  EffortValuesService,
  ToastModule,
])

.controller('ChoresNewCtrl',
    ['$scope', '$rootScope', 'Chores', 'Chore', '$state', '$translate', 'User',
    'Wg', 'yptNotify', 'RotationTimes', 'EffortValues', '$ionicHistory', '$timeout', 'mcmToast',
    function ($scope, $rootScope, Chores, Chore, $state, $translate, User,
    Wg, yptNotify, RotationTimes, EffortValues, $ionicHistory, $timeout, mcmToast) {

  let focusTimeout

  $scope.deleteTask = deleteTask
  $scope.showHelp = showHelp
  $scope.showFixedWeekday = showFixedWeekday
  $scope.orderSorter = orderSorter
  $scope.toggleSelected = toggleSelected
  $scope.selectedNameArray = selectedNameArray
  $scope.addNewChore = addNewChore
  $scope.getWeekday = getWeekday
  $scope.goBack = goBack
  $scope.showPremiumPaywall = showPremiumPaywall;
  $scope.editMode = false;
  $scope.rotationTimes = RotationTimes.values;
  $scope.flatmates = Wg.flatmates.get();
  $scope.points = EffortValues.values;
  $scope.user = User

  init()
  $scope.$on('$destroy', cleanUp)

  function init() {
     // Check if edit or create chore
    if ($state.params && $state.params.id) {
      $scope.editMode = true;
      $scope.chore = Chores.get($state.params.id);
      if (!$scope.chore) {
        $scope.chore = {}
      }
      var nextTime = ($scope.chore.lastDoneDate +
          $scope.chore.rotationTime) * 1000;
      $scope.chore.newData = {
        title: $scope.chore.title,
        startDate: new Date(nextTime),
        rotationTime: $scope.chore.rotationTime,
        fixed: $scope.chore.fixed ? 1 : 0,
        users: [...$scope.chore.users],
        points: $scope.chore.points,
        details: $scope.chore.details,
      };
    } else {
      $scope.chore = new Chore({
        local: true,
      });
      var date = new Date();
      date.setDate(date.getDate() + 3);
      $scope.chore.newData.startDate = date;
      $scope.chore.newData.fixed = 1;
      $scope.chore.newData.users = Wg.flatmates.accumulate('id');
      focusTimeout = $timeout(() => $scope.$broadcast('chore:new:title'), 750)
    }
    $scope.chore.newData.rotationTime = $scope.chore.newData.rotationTime || 7 * 60 * 60 * 24;
    $scope.chore.newData.points = $scope.chore.newData.points || 1;
  }

  function cleanUp() {
    if (focusTimeout) {
      $timeout.cancel(focusTimeout)
    }
  }

  // Check if it is save to go back
  // overwrite ionic's back button functionality
  var unchangedData = angular.copy($scope.chore.newData);
  $scope.unchangedData = unchangedData;
  function goBack() {
    var isSafeToGoBack = true;
    var keys = [
      'title',
      'rotationTime',
      'fixed',
      'users',
      'points',
      'startDate',
    ];

    keys.forEach(function(key) {
        isSafeToGoBack = isSafeToGoBack &&
          (JSON.stringify(unchangedData[key])
              == JSON.stringify($scope.chore.newData[key]));
    });

    if (isSafeToGoBack) {
      return $ionicHistory.goBack();
    }

    yptNotify.confirm({
      title: $translate.instant('CHO__NEW__GO_BACK-TITLE'),
      message: $translate.instant('CHO__NEW__GO_BACK-MSG'),
      buttonLabels: [
        $translate.instant('BTN_CANCEL'),
        $translate.instant('YES'),
      ],
      callback: function(answer) {
        if (answer !== true) {
          return
        }
        $ionicHistory.goBack()
      },
    });
  };

  function showPremiumPaywall() {
    if (!User.isPremium()) {
      const label = 'Chore notes'
      const message = $translate.instant('Get Flatastic Premium to add notes to a chore and unlock many more useful features.')
      const cta = $translate.instant('Get Premium')
      return $state.go('premium-paywall', { label, message, cta })
    }
  }

  function deleteTask(chore) {
    yptNotify.confirm({
      title: $translate.instant('Delete Task'),
      message: $translate.instant('Do you really want to delete the task?', {
        title: chore.title,
      }),
      buttonLabels: [
        $translate.instant('Cancel'),
        $translate.instant('Delete'),
      ],
      callback: function(answer) {
        if (answer !== true) {
          return
        }
        $ionicHistory.goBack()
        chore.delete()
          .then(() => mcmToast.show({
            message: $translate.instant('Task deleted', { title: chore.title }),
          }))
          .catch(() => mcmToast.show({
            message: $translate.instant(`Deleting task failed`, { title: chore.title }),
          }))
      },
    })
  }

  function showHelp() {
    yptNotify.alert({
      title: $translate.instant('CHO__HELP-TITLE'),
      message: $translate.instant('CHO__HELP-MSG'),
      trackEvent: {
        name: 'showHelp',
        parameters: {
          context: 'chores__new',
        },
      },
    });
    $rootScope.$broadcast('track-event', {
      category: 'Help',
      action: 'Clicked',
      label: '/chores-new',
    });
  };

  // Fix Weekday
  function showFixedWeekday() {
    return ($scope.chore.newData.rotationTime / 60 / 60 / 24) % 7 === 0;
  };

  var weekdays = [
    $translate.instant('TIME__SUNDAY'),
    $translate.instant('TIME__MONDAY'),
    $translate.instant('TIME__TUESDAY'),
    $translate.instant('TIME__WEDNESDAY'),
    $translate.instant('TIME__THURSDAY'),
    $translate.instant('TIME__FRIDAY'),
    $translate.instant('TIME__SATURDAY'),
  ];
  function getWeekday() {
    return weekdays[new Date($scope.chore.newData.startDate).getDay()];
  };
  $scope.fixedDayAnswers = [{
    value: 1,
    label: $translate.instant('YES'),
  }, {
    value: 0,
    label: $translate.instant('NO'),
  }];

  function orderSorter(elem) {
    if (!$scope.chore.newData.users) {
      return;
    }
    var value = $scope.chore.newData.users.indexOf(elem.id);
    if (value === -1) {
      value = elem.id;
    }
    return value;
  };
  function toggleSelected(flatmate) {
    var idx = $scope.chore.newData.users.indexOf(flatmate.id);
    if (idx > -1) {
      $scope.chore.newData.users.splice(idx, 1);
    } else {
      $scope.chore.newData.users.push(flatmate.id);
    }
  };
  function selectedNameArray() {
    if (!$scope.chore.newData.users) {
      return;
    }
    return $scope.chore.newData.users.map(function(id) {
      return Wg.flatmates._get(id).firstName;
    });
  };

  function addNewChore() {

    $scope.requestPending = true;

    // Only weekly rotation times can have the flag fixed
    if (!$scope.showFixedWeekday()) {
      $scope.chore.newData.fixed = 0;
    }

    // Due dates time is always 23:59:59:999
    $scope.chore.newData.startDate =
        new Date($scope.chore.newData.startDate);
    $scope.chore.newData.startDate.setHours(23);
    $scope.chore.newData.startDate.setMinutes(59);
    $scope.chore.newData.startDate.setSeconds(59);
    $scope.chore.newData.startDate.setMilliseconds(999);

    // Edit Mode
    if ($scope.editMode) {
      $scope.chore.users = $scope.chore.newData.users;
      $scope.chore.update();
      delete $scope.requestPending;
      $state.go('household.chores.listing');
      return;
    }

    // New Chore
    $scope.chore.newData.currentUser = $scope.chore.newData.users[0];

    $scope.chore
      .set($scope.chore.newData)
      .toCollection()
      .create()
      .success(function() {
        Chores.getFromServer();
        $state.go('household.chores.listing');
      })
      .error(function(data) {
        $scope.chore.removeFromCollection();
        var temp = [];
        for (var key in data) {
          temp.push(data[key]);
        }

        yptNotify.alert({
          title: $translate.instant('CHO__NEW_ERROR-TITLE'),
          message: $translate.instant('CHO__NEW_ERROR-MSG', {
            error: data ? temp.join('\n') : $translate.instant('NO_INTERNET'),
          }),
        });
      })
      .finally(function() {
        delete $scope.requestPending;
      });
  };
}])

.name;
