import ResizableFooterContent from '../../directives/resizable-footer-content'
import './style.scss'
import template from './template.html'
export const InputFooter = angular.module('ft.component.input-footer', [
  ResizableFooterContent,
])
.component('inputFooter', {
  transclude: true,
  bindings: {
    onSubmit: '&',
    onOverPullIn: '&',
    ngModel: '=',
  },
  template,
  controllerAs: 'vm',
  controller: function($scope, $element, $attrs) {
    const vm = this
    angular.element($element.parent()[0].querySelector('ion-content')).addClass('has-input-footer')
    vm.onPullIn = () => {console.log('inputFooter', 'onPullIn')}
    vm.onPullOut = () => console.log('inputFooter', 'onPullOut')
    vm.focusInput = () => $scope.$emit('input-footer.focus-input')
    console.log({ $attrs, $scope, $element })
  },
})
.name
