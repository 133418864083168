export default angular.module('ft.directive.bottom-drawer', [
  'ionic',
])

.directive('bottomDrawer', ['$ionicGesture', function($ionicGesture) {
  return {
    restrict: 'A',
    link,
  }

  function link($scope, $element, $attr, ctrl) {
    const modal = $element.parent()
    const originalTransition = modal.css('transition')
    const ionContent = modal[0].querySelector('ion-content')
    const gestureOpts = { stop_browser_behavior: false };
    let isDismissibleViaSwipeDown = false
    let scrollController
    let isScrollFrozen = false

    $ionicGesture.on('dragdown', onHeaderDrag, $element, gestureOpts)
    $ionicGesture.on('dragup', onHeaderDrag, $element, gestureOpts)
    $ionicGesture.on('release', onHeaderRelease, $element, gestureOpts)
    $ionicGesture.on('touch', onHeaderTouch, $element, gestureOpts)

    $scope.$on('destroy', function() {
      $ionicGesture.off('dragdown', onHeaderDrag, $element, gestureOpts)
      $ionicGesture.off('dragup', onHeaderDrag, $element, gestureOpts)
      $ionicGesture.off('release', onHeaderRelease, $element, gestureOpts)
      $ionicGesture.off('touch', onHeaderTouch, $element, gestureOpts)
    })

    if (ionContent) {
      const ionContentElement = angular.element(ionContent)
      $ionicGesture.on('touch', onContentTouch, ionContentElement, gestureOpts)
      $ionicGesture.on('dragdown', onContentDrag, ionContentElement, gestureOpts)
      $ionicGesture.on('release', onContentRelease, ionContentElement, gestureOpts)
      $scope.$on('destroy', function() {
        $ionicGesture.off('touch', onContentTouch, ionContentElement, gestureOpts)
        $ionicGesture.off('dragdown', onContentDrag, ionContentElement, gestureOpts)
        $ionicGesture.off('release', onContentRelease, ionContentElement, gestureOpts)
      })
    }


    function onContentTouch(ev) {
      modal.css('transition', `none`)
      scrollController = angular.element(ionContent).controller('$ionicScroll')
      isScrollFrozen = false
      scrollController.freezeScroll(false)
      isDismissibleViaSwipeDown = scrollController.getScrollPosition().top <= 0
    }

    function onContentDrag(ev) {
      const { gesture: { deltaY } } = ev
      if (!isDismissibleViaSwipeDown) {
        return true
      }
      if (deltaY > 0) {
        if (!isScrollFrozen) {
          scrollController.freezeScroll(true)
          isScrollFrozen = true
        }
        modal.css('transform', `translate3d(0, ${deltaY}px, 0)`)
      } else {
        if (isScrollFrozen) {
          isScrollFrozen = false
          scrollController.freezeScroll(false)
        }
      }
    }

    function onContentRelease(ev) {
      if (!isDismissibleViaSwipeDown) {
        return
      }
      const { gesture: { deltaY } } = ev
      if (deltaY > 150 && $attr.onPullDown) {
        $scope.$apply($attr.onPullDown)
      }
      modal.css('transition', originalTransition)
      modal.css('transform', ``)
    }

    function onHeaderDrag(ev) {
      const { gesture: { deltaY } } = ev
      if (deltaY < 0) {
        return
      }
      modal.css('transform', `translate3d(0, ${deltaY}px, 0)`)
    }

    function onHeaderTouch(ev) {
      modal.css('transition', `none`)
    }

    function onHeaderRelease(ev) {
      const { gesture: { deltaY } } = ev
      if (deltaY > 150 && $attr.onPullDown) {
        $scope.$apply($attr.onPullDown)
      }
      modal.css('transition', originalTransition)
      modal.css('transform', ``)
    }
  }
}])

.name
