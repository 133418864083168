export default {
  [`shoppinglist.hashtag-modal.explanation`]: [
    `Create dynamic lists by assigning tags (ex. <span class="text-info text-opacity-100">#Supermarket</span>) to items and filter by them here.`,
    `Erstelle dynamische Listen indem du Einträgen Tags zuweist (z.B. <span class="text-info text-opacity-100">#Supermarkt</span>) und hier nach diesen filterst.`,
  ],
  [`shoppinglist.tags.explanation.details.text`]: [
    `Prefix words in the details with a <span class="text-info text-opacity-100">#</span> to group/filter items.`,
    `Starte Wörter mit einem <span class="text-info text-opacity-100">#</span>, um Einträge zu gruppieren/filtern.`,
  ],
  [`shoppinglist.tags.explanation.details.example`]: [
    `#supermarket, #drugstore, #hardware store`,
    `#Supermarkt, #Baumarkt, #Drogerie, …`,
  ],
  [`shoppinglist.tags.explanation.ok`]: [
    `OK`,
    `OK`,
  ],
  [`shoppinglist.tags.explanation.create`]: [
    `+ Create New Tag`,
    `+ Neuen Tag erstellen`,
  ],
}
