import { mapObjIndexed } from 'ramda'

const meta = ['en', 'de']
const trans = {
  [`Add members`]: [
    `Add members`,
    `Mitbewohner einladen`,
  ],
  [`Members of your home`]: [
    `Members of "{{ householdName }}"`,
    `Lade alle Mitbewohner von «{{ householdName }}» ein`,
  ],
  [`Moved In`]: [
    `Moved In`,
    `Eingezogen`,
  ],
  [`Invited`]: [
    `Invited`,
    `Eingeladen`,
  ],
  [`+ Invite someone else`]: [
    `+ Invite someone else`,
    `+ Weitere Person hinzufügen`,
  ],
  [`I invited all members`]: [
    `I invited all members`,
    `Fertig`,
  ],
  [`Living alone is boring. Please add at least one more person to your home.`]: [
    `Living alone is boring. Please add at least one more person to your home.`,
    `Alleine leben macht keinen Spaß. Füge mindestens eine weitere Person zu deinem Haushalt dazu.`,
  ],
  [`You can always invite more later!`]: [
    `You can always invite more later!`,
    `Du kannst später immer noch weitere Personen hinzufügen`,
  ],
  [`Title: Interact with member`]: [
    `{{ name }}\nInvitation code: {{ invitationCode }}`,
    `{{ name }}\nZugangscode: {{ invitationCode }}`,
  ],
  [`Share invitation code`]: [
    `Share invitation code`,
    `Zugangscode versenden`,
  ],
  [`Delete invitation`]: [
    `Delete member`,
    `Person löschen`,
  ],
  [`(me)`]: [
    `(me)`,
    `(ich)`,
  ],
  [`Please share the invitation code so the other person can move into your home`]: [
    `Please share the invitation code so {{ name }} can move into your home`,
    `Teile den Zugangscode, damit {{ name }} in deinen Haushalt einziehen kann.`,
  ],
  [`Please share the invitation code so the other person can move into your home`]: [
    `Please share the invitation code so {{ name }} can move into your home`,
    `Teile den Zugangscode damit {{ name }} in den Haushalt einziehen kann`,
  ],
  [`There was an issue. The person's invitation wasn't revoked.`]: [
    `There was an issue. {{ name }}'s invitation wasn't revoked.`,
    `Es gab ein Problem. Die Einladung von {{ name }} wurde nicht gelöscht.`,
  ],
  [`Please enter the person's first name`]: [
    `Please enter the person's first name.`,
    `Bitte gib den Vornamen von der Person ein:`,
  ],
  [`Title: Add person`]: [
    `Add person`,
    `Person hinzufügen`,
  ],
  [`You need to enter a name…`]: [
    `You need to enter a name…`,
    `Du musst schon einen Vornamen eingeben…`,
  ],
  [`Room for person is being prepared…`]: [
    `Room for {{ name }} is being prepared…`,
    `Zimmer für {{ name }} wird vorbereitet…`,
  ],
  [`Person has been successfully added to your home. Now, share the invitation code with person`]: [
    `{{ name }} has been successfully added to your home. Now, share the invitation code with {{ name }}.`,
    `{{ name }} wurde erfolgreich zum Haushalt hinzugefügt. Teile als nächstes den Einladungscode mit {{ name }}.`,
  ],
  [`An app for our home`]: [
    `An app for our home`,
    `Eine App für unser Zuhause`,
  ],
  [`Message for sharing invitation code`]: [
    `Hey {{ name }},\n
I made us a new Flatastic Home – It’s the perfect app to make living together a breeze. You can download it below, create an account, then use the key "{{ invitationCode }}" to join our Home.\n
Download the Flatastic App (https://flatastic-app.com/app) and move in!`,
    `Hey {{ name }},\n
Ich habe für uns ein neues Zuhause bei Flatastic angelegt. Es ist die perfekte App um den Haushalt zu organisieren und zu führen.
Du kannst sie unten downloaden und mit dem Zugangscode "{{ invitationCode }}" einziehen.\n
Hier der Link zur App: https://flatastic-app.com/app`,
  ],
  [`Button Label: Share invitation code`]: [
    `Share invitation code`,
    `Einladungscode teilen`,
  ],
  [`Button Label: Copy invitation code`]: [
    `Copy invitation code`,
    `Einladungscode kopieren`,
  ],
  [`Copied invitation code`]: [
    `Copied invitation code`,
    `Einladungscode kopiert`,
  ],
  [`Unfortunatelly something went wrong… Please try again.`]: [
    `Unfortunatelly something went wrong… Please try again.`,
    `Da ist leider was schief gelaufen… Versuche es noch einmal.`,
  ],
  [`Title: Share invitation code`]: [
    `Share invitation code`,
    `Einladungscode teilen`,
  ],
  [`Title: Well done.`]: [
    `Well done 👍`,
    `Gut gemacht 👍`,
  ],
  [`Nice! By entering the invitation code the person can move into your home.`]: [
    `By entering the invitation code {{ name }} can move into your home.`,
    `Mit Eingabe des Einladungscode kann {{ name }} nun in deinen Haushalt einziehen.`,
  ],
  [`Please share the invitation code so the other person can move into your home`]: [
    `Please share the invitation code so {{ name }} can move into your home`,
    `Teile den Einladungscode damit {{ name }} in deinen Haushalt einziehen kann.`,
  ],
  [`Title: Delete invitation`]: [
    `Delete invitation`,
    `Einladung löschen`,
  ],
  [`Do you really want to remove that person?`]: [
    `Do you really want to remove {{ name }}?`,
    `Möchtest du {{ name }} wirklich wieder löschen?`,
  ],
  [`Button Label: Delete invitation`]: [
    `Delete invitation`,
    `Einladung löschen`,
  ],
  [`The invitation was succesfully revoked`]: [
    `The invitation was succesfully revoked`,
    `Die Einladung wurde erfolgreich gelöscht`,
  ],
  [`There was an issue. The person's invitation wasn't revoked.`]: [
    `There was an issue. {{ name }}'s invitation wasn't revoked.`,
    `Es gab ein Problem. Die Einladung von {{ name }} konnte nicht gelöscht werden`,
  ],

  // Legacy

  [`ADD`]: [
    `Add`,
    `Hinzufügen`,
  ],
}

export default {
  en: mapObjIndexed((val, key, obj) => val[0])(trans),
  de: mapObjIndexed((val, key, obj) => val[1])(trans),
}
