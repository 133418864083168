import { mapObjIndexed } from 'ramda'

const meta = ['en', 'de']
const trans = {
  [`Sign in to your account`]: [
    'Sign in to your account',
    'Logge dich in deinen bestehenden Account ein',
  ],
  [`Enter Email`]: [
    'Enter Email',
    'z.B. meine@email.com',
  ],
  [`Enter Password`]: [
    'Enter Password',
    '· · · · ·',
  ],
  [`Your Email`]: [
    `Your Email`,
    `Deine Email`,
  ],
  [`Your Password`]: [
    `Your Password`,
    `Dein Passwort`,
  ],
  [`Login`]: [
    `Login`,
    `Anmelden`,
  ],
  [`Forgot Password?`]: [
    `Forgot Password?`,
    `Passwort vergessen?`,
  ],

  //// Legacy
  [`ATH__LOGIN_ERROR-TITLE`]: [
    `Login failed`,
    `Login fehlgeschlagen`,
  ],
  [`ATH__LOGIN_ERROR-MSG`]: [
    `Login was unsuccessful. Please check your email and password.`,
    `Wir konnten dich leider nicht anmelden. Bitte überprüfe noch einmal die E-Mail-Adresse und das Passwort.`,
  ],
  [`ATH__FORGOT_PASSWORD`]: [
    `Forgot your password`,
    `Passwort vergessen`,
  ],
  [`ATH__FORGOT_PASSWORD-MSG`]: [
    `Enter your email address and we will send you a new one.`,
    `Gib deine E-Mail-Adresse ein, damit wir dir ein neues Passwort schicken können.`,
  ],
  [`ATH__FORGOT_PASSWORD-CONFIRM`]: [
    `Forgot password`,
    `Passwort vergessen`,
  ],
  [`ATH__FORGOT_PASSWORD-SUCCESS`]: [
    `We have sent a new password to "{{ email }}" . Please change it at soon as you login.`,
    `Wir haben ein neues Passwort an "{{ email }}" geschickt. Bitte ändere es direkt nach dem Einloggen.`,
  ],
  [`ATH__FORGOT_PASSWORD-ERROR`]: [
    `It was not possible to send you a new password:\n\n{{ error }}`,
    `Wir konnten dir kein neues Passwort schicken:\n\n{{ error }}`,
  ],
}

export default {
  en: mapObjIndexed((val, key, obj) => val[0])(trans),
  de: mapObjIndexed((val, key, obj) => val[1])(trans),
}
