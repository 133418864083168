import AngularTranslate from 'angular-translate'
import SubscriptionService from '../../modules/subscriptions/svc_subscription'
import SubscriptionsService from '../../modules/subscriptions/svc_subscriptions'
import WgService from '../../modules/wg/svc_wg'
import ActionsheetModule from '../../scripts/modules/yptActionsheet'
import CordovaService from '../../scripts/modules/yptCordova'
import DatepickerModule from '../../scripts/modules/yptDatepicker'
import KeyboardService from '../../scripts/modules/yptKeyboard'

export default angular.module('ft.finances.subscriptions.controller', [
  'ionic',
  AngularTranslate,
  SubscriptionsService,
  WgService,
  CordovaService,
  SubscriptionService,
  KeyboardService,
  ActionsheetModule,
  DatepickerModule,
])
.controller('SubscriptionsController', SubscriptionsController)
.name

let i = 0

SubscriptionsController.$inject = ['Subscriptions', '$scope', 'Subscription', 'Wg', '$ionicHistory', 'yptActionsheet', '$translate', '$state', '$stateParams']
function SubscriptionsController(Subscriptions, $scope, Subscription, Wg, $ionicHistory, yptActionsheet, $translate, $state, $stateParams) {
  const vm = this
  vm.proposals = Subscriptions.proposals
  vm.monthlySum = Subscriptions.monthlySum
  vm.subscriptions = Subscriptions.subscriptions
  vm.refresh = refresh
  vm.isNotOnList = isNotOnList
  vm.createSubscription = createSubscription
  vm.currency = Wg.currency
  vm.interactWithItem = interactWithItem
  vm.newSubscription = {
    cycle: 'TIME__MONTHLY',
    firstBill: new Date(),
  }

  vm.features = {
    filter: false,
    hideProposals: false,
  }

  init()

  //// Functions

  function createSubscription(data) {
    const changes = {};
    if (vm.isEditing) {
      for (const key in vm.newSubscription) {
        const newValue = JSON.stringify(vm.newSubscription[key]);
        const oldValue = JSON.stringify($stateParams.subscription[key]);
        if (newValue !== oldValue) {
          changes[key] = vm.newSubscription[key];
        }
      }
      const subscription = Subscriptions.subscriptions.get($stateParams.id)
      return subscription.update(changes)
        .then(() => $ionicHistory.goBack())
        .catch(() => {})
    }
    const subscription = new Subscription(data)
    const id = Subscriptions.subscriptions.push(subscription)
    Subscriptions.subscriptions.get(id).save()
      .then(() => $ionicHistory.goBack())
      .catch(() => {})
  }

  function init() {
    Subscriptions.getFromServer()
    Subscriptions.getLocalProposals()


    if ($stateParams.subscription) {
      console.log('subscription is present', $stateParams, $stateParams.subscription, $stateParams.subscription.id  )
      vm.newSubscription = angular.copy($stateParams.subscription)
      vm.isEditing = !!$stateParams.subscription.id
    }
    console.log('$stateParams', $stateParams, vm.isEditing )
  }

  function refresh() {
    Subscriptions.getFromServer()
      .finally(function() {
        $scope.$broadcast('scroll.refreshComplete');
      });
  }

  function isNotOnList(item) {
    return Subscriptions.subscriptions.accumulate('title').indexOf(item.title) === -1
  }

  function interactWithItem(subscription) {
    const options = {
      title: subscription.title,
      buttonLabels: [$translate.instant('EDIT')],
      addDestructiveButtonWithLabel: $translate.instant('DELETE'),
      androidEnableCancelButton: false,
      addCancelButtonWithLabel: $translate.instant('BTN_CANCEL'),
    };
    const actions = [function() {
      const subscriptions = Subscriptions.subscriptions
      if (!subscription[subscriptions.uniqueKey]) {
        console.debug('Not deleting', subscription, subscriptions)
        return false;
      }
      return deleteSubscription(subscription)
    }, function() {
      return $state.go('household.finances-contracts-edit', { id: subscription.id, subscription })
    }];
    yptActionsheet.show(options, actions);
  }

  function deleteSubscription(subscription) {
    return subscription.delete()
      .then(function() {
        Subscriptions.subscriptions.remove(subscription.id);
      })
      .catch(() => {});
  }

}
