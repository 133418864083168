import { hasPath } from 'ramda'
import CordovaModule from './yptCordova'

export default angular.module('ft.clipboard', [
  CordovaModule,
])
.factory('Clipboard', Clipboard)
.name

Clipboard.$inject = ['$window', 'yptCordova']
function Clipboard($window, yptCordova) {
  return {
    clear,
    copy,
    paste,
  }

  // Functions

  function getPlugin() {
    return yptCordova.ready.then(function() {
      if (!hasPath(['cordova', 'plugins', 'clipboard'])($window)) {
        return Promise.reject(firebaseNotInstalledError);
      }
        return Promise.resolve($window.cordova.plugins.clipboard);
    });
  }

  function copy(text) {
    return getPlugin()
      .then(plugin => {
        console.log(plugin)
        new Promise((resolve, reject) => plugin.copy(text, resolve, reject))
      }).then(() => console.log('copied', text))
  }

  function paste() {
    return getPlugin()
      .then(plugin =>
        new Promise((resolve, reject) => plugin.paste(resolve, reject))
      )
  }

  function clear() {
    return getPlugin()
    .then(plugin =>
      new Promise((resolve, reject) => plugin.clear(resolve, reject))
    )
  }
}
