import LegacyExpensesController from '../../modules/expenses/ctrl_expenses'
import LegacyExpensesDetailsController from '../../modules/expenses/ctrl_expenses-details'
import LegacyExpensesControllerNew from '../../modules/expenses/ctrl_expenses-new'
import LegacySettlemenEntryExpenseController from '../../modules/expenses/ctrl_settlementEntries-details'
import LegacySettlementsController from '../../modules/expenses/ctrl_settlements'
import SettlementsEntriesService from '../../modules/expenses/svc_settlementEntries'
import SettlementsService from '../../modules/expenses/svc_settlements'
import { hideAccessoryBar, showAccessoryBar } from '../../modules/keyboardController'
import { ExpenseItemEntryComponent, NavTabsComponent } from './components'
import BalanceEntryComponent from './components/balance-entry/index'
import ExpenseEntryComponent from './components/expense-entry/index'
import PersonalSpendingsComponent from './components/personal-spendings'
import SettlementEntryComponent from './components/settlement-entry/index'
import SubscriptionEntryComponent from './components/subscription-entry/index'
import SubscriptionProposalEntry from './components/subscription-proposal/index'
import ExpensesCreateController from './expense-create.ctrl'
import expenseCreateTemplate from './expense-create.html'
import ExpensesDetailsController from './expense-details.ctrl'
import expenseDetailsTemplate from './expense-details.html'
import financesContractsTemplate from './finances-contracts.html'
import financesHistoryEntryTemplate from './finances-history-entry.html'
import financesHistoryTemplate from './finances-history.html'
import financesListingTemplate from './finances-listing.html'
import financesStatisticsTemplate from './finances-statistics.html'
import controller from './finances.ctrl'
import './finances.scss'
import SettlementDetailsController from './settlement-details.ctrl'
import SubscriptionsCreateTemplate from './subscription-create.html'
import SubscriptionsController from './subscriptions.ctrl'
import './subscriptions.scss'

export default angular.module('ft.finances', [
  ExpenseEntryComponent,
  SettlementEntryComponent,
  NavTabsComponent,
  BalanceEntryComponent,
  LegacyExpensesController,
  LegacySettlementsController,
  LegacyExpensesDetailsController,
  LegacyExpensesControllerNew,
  LegacySettlemenEntryExpenseController,
  ExpenseItemEntryComponent,
  ExpensesDetailsController,
  ExpensesCreateController,
  SubscriptionsController,
  SubscriptionEntryComponent,
  SubscriptionProposalEntry,
  SettlementDetailsController,
  SettlementsService,
  SettlementsEntriesService,
  PersonalSpendingsComponent,
])
  .config(Finances)
  .name;


Finances.$inject = ['$stateProvider']
function Finances($stateProvider) {
  $stateProvider
    .state('household.finances', {
      url: '/finances',
      views: {
        'finances-tab': {
          abstract: true,
          controller,
          controllerAs: 'fin',
        },
      },
    })
    .state('household.finances-listing', {
      url: '/finances/expenses',
      views: {
        'finances-tab': {
          template: financesListingTemplate,
          controller: 'ExpensesCtrl',
        },
      },
    })
    .state('household.expense-create', {
      url: '/finances/expenses/create',
      views: {
        'finances-tab': {
          template: expenseCreateTemplate,
          controller: 'ExpensesNewCtrl',
        },
      },
    })
    .state('household.expense-edit', {
      url: '/finances/expenses/:id/edit',
      views: {
        'finances-tab': {
          template: expenseCreateTemplate,
          controller: 'ExpensesNewCtrl',
        },
      },
    })
    .state('household.expense-details', {
      url: '/finances/expenses/{id:int}',
      views: {
        'finances-tab': {
          template: expenseDetailsTemplate,
          controller: 'ExpensesDetailsCtrl',
        },
      },
    })
    .state('household.finances-statistics', {
      url: '/finances/statistics',
      views: {
        'finances-tab': {
          template: financesStatisticsTemplate,
          controller: 'ExpensesCtrl',
        },
      },
    })
    .state('household.finances-history', {
      url: '/finances/settlements',
      views: {
        'finances-tab': {
          template: financesHistoryTemplate,
          controller: 'SettlementsCtrl',
        },
      },
    })
    .state('household.finances-history-entry', {
      url: '/finances/settlements/:settlementId',
      params: {
        settlement: null,
      },
      resolve: {
        settlement: ['Settlements', '$stateParams', function (Settlements, $stateParams) {
          if ($stateParams.settlement) {
            return Promise.resolve($stateParams.settlement)
          }
          return Settlements.fetch().then(() => Settlements.findLocally($stateParams.settlementId))
            .then(res => {
              $stateParams.settlement = res
              console.log({ res }) || res
              return res
            })
        }],
      },
      views: {
        'finances-tab': {
          template: financesHistoryEntryTemplate,
          controller: 'SettlementDetailsController',
          controllerAs: 'vm',
        },
      },
    })
    .state('household.finances-history-entry-expense-details', {
      url: '/finances/settlements/:settlementId/expenses/:expenseId',
      views: {
        'finances-tab': {
          controller: 'SettlementEntriesDetailsCtrl',
          template: expenseDetailsTemplate,
        },
      },
      resolve: {
        settlement: ['Settlements', 'SettlementEntries', '$stateParams', function(Settlements, SettlementEntries, $stateParams) {
          const { settlementId } = $stateParams
          const settlement = Settlements.findLocally(settlementId)
          if (settlement) {
            return Promise.resolve(settlement)
          }
          return SettlementEntries.fetch({ settlementId })
        }],
      },
    })
    .state('household.finances-contracts', {
      url: '/finances/contracts-subscriptions',
      views: {
        'finances-tab': {
          template: financesContractsTemplate,
          controller: 'SubscriptionsController',
          controllerAs: 'vm',
        },
      },
    })
    .state('household.finances-contracts-new', {
      url: '/finances/contracts-subscriptions/create',
      views: {
        'finances-tab': {
          template: SubscriptionsCreateTemplate,
          controller: 'SubscriptionsController',
          controllerAs: 'vm',
        },
      },
      params: {
        subscription: undefined,
      },
      onEnter: showAccessoryBar,
      onExit: hideAccessoryBar,
    })
    .state('household.finances-contracts-edit', {
      url: '/finances/contracts-subscriptions/:id/edit',
      views: {
        'finances-tab': {
          template: SubscriptionsCreateTemplate,
          controller: 'SubscriptionsController',
          controllerAs: 'vm',
        },
      },
      params: {
        subscription: undefined,
      },
      onEnter: showAccessoryBar,
      onExit: hideAccessoryBar,
    })
}
