// [en, de]

export default {
  [`All Tasks`]: [
    `All Tasks`,
    `Übersicht`,
  ],
  [`Statistics`]: [
    `Statistics`,
    `Statistik`,
  ],
  [`History`]: [
    `History`,
    `Verlauf`,
  ],
  [`Edit`]: [
    `Edit`,
    `Bearbeiten`,
  ],
  [`Save`]: [
    `Save`,
    `Speichern`,
  ],
  [`Close`]: [
    `Close`,
    `Schließen`,
  ],
  [`Cancel`]: [
    `Cancel`,
    `Abbrechen`,
  ],
  [`When needed`]: [
    `When needed`,
    `Bei Bedarf`,
  ],
  [`Responsible`]: [
    `Responsible`,
    `Verantwortlich`,
  ],
  [`Remind & Motivate`]: [
    `Remind & Motivate`,
    `Erinnern & Motivieren`,
  ],
  [`Delete Task`]: [
    `Delete Task`,
    `Aufgabe löschen`,
  ],
  [`Mark as done`]: [
    `Mark as done`,
    `Abhaken`,
  ],
  [`Add reminder`]: [
    `Add reminder`,
    `Erinnerung hinzufügen`,
  ],
  [`Effort`]: [
    `Effort`,
    `Aufwand`,
  ],
  [`First due date`]: [
    `First due date`,
    `Datum der 1. Frist`,
  ],
  [`Due Date`]: [
    `Due Date`,
    `Frist`,
  ],
  [`Just once`]: [
    `Just once`,
    `Einmalig`,
  ],
  [`Repeat`]: [
    `Repeat`,
    `Wiederholen`,
  ],
  [`Repeating Task`]: [
    `Repeating Task`,
    `Wiederkehrende Aufgabe`,
  ],
  [`Recurring tasks are used for chores, appointments, etc.`]: [
    `Recurring tasks are used for chores, appointments, etc.`,
    `Nutze wiederkehrende Aufgaben für's Putzen, Termine, usw.`,
  ],
  [`On Due Date`]: [
    `On Due Date`,
    `Am Fälligkeitsdatum`,
  ],
  [`Checklists help you make sure that everything you need to get done, actually gets done.`]: [
    `Checklists help you make sure that everything you need to get done, actually gets done.`,
    `Checklisten helfen euch, damit nichts vergessen wird und sich keiner beschweren kann`,
  ],
  [`Add item`]: [
    `Add item`,
    `Eintrag hinzufügen`,
  ],
  [`Add a note`]: [
    `Add a note`,
    `Notiz hinzufügen`,
  ],
  [`Notes`]: [
    `Notes`,
    `Notizen`,
  ],
  [`In days`]: [
    `In {{ days }} day{{ days > 1 ? 's' : '' }}`,
    `In {{ days }} Tag{{ days > 1 ? 'en' : '' }}`,
  ],
  [`Due yesterday`]: [
    `Due yesterday`,
    `Gestern fällig`,
  ],
  [`Days due`]: [
    `{{ days }} day{{ days > 1 ? 's' : '' }} due`,
    `{{ days }} Tag{{ days > 1 ? 'e' : '' }} fällig`,
  ],
  [`Due today`]: [
    `Due today`,
    `Heute fällig`,
  ],
  [`Due tomorrow`]: [
    `Due tomorrow`,
    `Morgen fällig`,
  ],
  [`Overdue`]: [
    `Overdue`,
    `Überfällig`,
  ],
  [`Your chores will appear here`]: [
    `Your chores will appear here`,
    `Die Aufgaben werden hier angezeigt`,
  ],
  [`Filter`]: [
    `Filter`,
    `Filter`,
  ],
  [`All Chores`]: [
    `All Chores`,
    `Alle Aufgaben`,
  ],
  [`Error while loading`]: [
    `Error while loading`,
    `Fehler beim Aktualisieren`,
  ],
  [`Remind & Motivate`]: [
    `Remind & Motivate`,
    `Erinnern & Motivieren`,
  ],
  [`Due in X days`]: [
    `Due in {{ days }} days`,
    `In {{ days }} Tag{{ days > 1 ? 'en' : ''}} fällig`,
  ],
  [`Due Tomorrow`]: [
    `Due Tomorrow`,
    `Morgen fällig`,
  ],
  [`Due Yesterday`]: [
    `Due Yesterday`,
    `Seit gestern fällig`,
  ],
  [`Due X days ago`]: [
    `Due {{ days }} days ago`,
    `Seit {{ days }} Tagen fällig`,
  ],
  [`Due today`]: [
    `Due today`,
    `Heute fällig`,
  ],
  [`Last done: Time ago`]: [
    `Last done: {{ timeAgo }}`,
    `Zuletzt erledigt: {{ timeAgo }}`,
  ],
  [`Due Date: Date`]: [
    `Due at {{ date | amDateFormat:'MMM d, YYYY' }}`,
    `Fällig am {{ date | amDateFormat:'DD.MM.YYYY' }}`,
  ],
  [`Never done yet`]: [
    `Never done yet`,
    `Noch nie erledigt`,
  ],
  [`Today`]: [
    `Today`,
    `Heute`,
  ],
  [`Yesterday`]: [
    `Yesterday`,
    `Gestern`,
  ],
  [`Congrats. You completed the task.`]: [
    `🎉 Awesome! You completed "{{ title }}" and earned {{ points }} point{{ points !== 1 ? '' : 's' }}!`,
    `🎉 Flatastisch! Du hast die Aufgabe "{{ title }}" erledigt und erhältst {{ points }} Punkt{{ points !== 1 ? '' : 'e' }}!`,
  ],
  [`Another person is responsible for this task. What do you want to do?`]: [
    `{{ name }} is responsible for this task. What do you want to do?`,
    `{{ name }} ist für diese Aufgabe verantwortlich. Was möchtest du tun?`,
  ],
  [`Check task for person`]: [
    `Check task for {{ name }}`,
    `Aufgabe für {{ name }} abhaken`,
  ],
  [`I take over the task`]: [
    `I take over the task`,
    `Ich übernehme die Aufgabe`,
  ],
  [`X Points`]: [
    `{{ points }} Point{{ points < 2 ? '' : 's' }}`,
    `{{ points }} Punkt{{ points < 2 ? '' : 'e' }}`,
  ],
  [`Use notes to say what needs to be done and how`]: [
    `Use notes to say what needs to be done and how`,
    `Beschreibe was und wie die Aufgabe zu erledigen ist`,
  ],
  [`Get Flatastic Premium to add notes to a chore and unlock many more useful features.`]: [
    `Get Flatastic Premium to add notes to a chore and unlock many more useful features.`,
    `Hol dir Flatastic Premium, um Notizen zu einer Aufgabe hinzuzufügen und viele weitere nützliche Features freizuschalten.`,
  ],

  //// Legacy
  [`OF_COURSE`]: [
    `Of course`,
    `Ja klar`,
  ],
  [`CHO__REMIND_USER-MSG`]: [
    `🔔 {{ name }} got a reminder to complete the chore "{{ title }}".`,
    `🔔 {{ name }} wurde an die die Aufgabe "{{ title }}" erinnert.`,
  ],
  [`CHO__REMIND_USER-CONFIRM`]: [
    `Erinnern`,
  ],
  [`CHO__NEXT-TITLE_TAKE`]: [
    `Take over a task`,
    `Aufgabe übernehmen`,
  ],
  [`CHO__NEXT-MSG_TAKE`]: [
    `Have you taken over the task from {{ name }}? You're so nice! Confirm that you really did it by choosing who's next.`,
    `Du hast die Aufgabe von {{ name }} übernommen? Super nett von dir! Bestätige, dass du es wirklich gemacht hast, indem du auswählst, wer als nächstes an der Reihe ist.`,
  ],
  [`CHO__NEXT-TITLE_OWN`]: [
    `Done`,
    `Aufgabe erledigen`,
  ],
  [`CHO__NEXT-MSG_OWN`]: [
    `Have you really completed the task "{{ title }}"?`,
    `Hast du die Aufgabe "{{ title }}" wirklich erledigt?`,
  ],
  [`CHO__CHECK_FOR_PIC-TITLE`]: [
    `Check for {{ name }}`,
    `Für {{ name }} abhaken`,
  ],
  [`CHO__CHECK_FOR_PIC-MSG`]: [
    `Has {{ name }} really completed the task "{{ title }}" already? {{ name }} will earn the points.`,
    `Hat {{ name }} die Aufgabe "{{ title }}" wirklich bereits erledigt? {{ name }} erhält dann auch die Punkte.`,
  ],
}
