import { is } from 'ramda'
import yepptApi from '../../scripts/modules/api'
import yepptCollection from '../../scripts/modules/collection'
import { isNilOrEmpty, roundToNumDecimals } from '../../utils/helpers'

export default angular.module('flatastic.expenses.service.expense.item', [
  yepptCollection,
  yepptApi,
])

.factory('ExpenseItem', [function () {

  function ExpenseItem(initObj) {
    this.set(initObj);
    return this;
  }

  ExpenseItem.prototype.set = function(initObj) {
    var self = this;
    for (var key in initObj) {
      self[key] = initObj[key];
      if (key === 'sum') {
        self.sum = roundToNumDecimals(4, initObj.sum)
      }
    }
    if (Array.isArray(self.sharers)) {
      this.sharers = this.sharers.map(function(userId) {
        return parseInt(userId, 10);
      });
    }
  };

  // Returns how much the user with id <userId> gets or has to pay.
  ExpenseItem.prototype.shareOf = function(userId) {
    var self = this;
    var output;
    // user is sharer of item
    if (self.sharers.indexOf(userId) !== -1) {
      var numSharers = self.sharers.length
      output = self.price /  numSharers * (numSharers - 1);
    } else {
      output = self.price;
    }
    self.sum = roundToNumDecimals(4, output);
    return output;
  };

  ExpenseItem.prototype.personPays = function(userId) {
    const self = this
    if (!self.sharers.includes(userId)) {
      return 0
    }
    return self.price / self.sharers.length
  }

  ExpenseItem.prototype.isEmpty = function() {
    const self = this
    return isNilOrEmpty(self.name) && isNilOrEmpty(self.price)
  }

  ExpenseItem.prototype.isValid = function() {
    const self = this
    // Check name
    if (!is(String, self.name) || self.name.length < 1 ) {
      return false
    }
    // Check price
    if (!is(Number, self.price) || self.price <= 0) {
      return false
    }
    // Check quantity
    if (!is(Number, self.quantity) || self.quantity < 1) {
      return false
    }
    // Check sharers
    if (!is(Array, self.sharers) || self.sharers.length < 1) {
      return false
    }
    // Check creatorId
    if (!self.creatorId) {
      console.debug('ExpenseItem.creatorId is invalid')
      return false
    }
    return true
  }

  ExpenseItem.scaffold = function(userId, sharers = []) {
   return new ExpenseItem({
      name: '',
      price: null,
      quantity: 1,
      sharers,
      creatorId: userId,
    });
  };

  return ExpenseItem;
}])

.name;
