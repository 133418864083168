// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../premium-banner/assets/crown.svg");
var ___HTML_LOADER_IMPORT_1___ = require("../premium-banner/assets/woman-waving.svg");
var ___HTML_LOADER_IMPORT_2___ = require("./assets/check-premium.svg");
var ___HTML_LOADER_IMPORT_3___ = require("./assets/hourglass.svg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var code = "<div class=\"bg-white bg-opacity-100 rounded-xl flex flex-col overflow-hidden\"> <div class=\"bg-primary bg-opacity-100 mb-20 flex flex-row\"> <div class=\"flex flex-col items-start pl-20 py-20\"> <div class=\"flex flex-row items-center mb-2\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" alt=\"\" class=\"mr-5 pb-4\"> <span class=\"text-white text-opacity-75 font-medium text-sm\" translate>Premium</span> </div> <h2 class=\"font-semibold text-lg text-white text-opacity-100\" ng-transclude>Put some reason to buy Flatastic premium here</h2> </div> <div class=\"p-15 relative\"> <span class=\"bg-white bg-opacity-100 rounded-xs px-4 py-1 text-2xs text-primary text-opacity-100 font-medium whitespace-nowrap\" translate>Upgrade Now!</span> <div class=\"absolute\" style=\"bottom:-34px;right:15px\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" alt=\"\"> </div> </div> </div> <div class=\"flex flex-row pl-10 pr-15 py-10 items-start\" ng-repeat=\"item in $ctrl.items\"> <img ng-if=\"::!item.isComingSoon\" src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\" class=\"mr-10 h-30 w-30\"> <img ng-if=\"::item.isComingSoon\" src=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\" class=\"mr-10 h-30 w-30\"> <div class=\"flex flex-col mt-5\"> <h4 class=\"text-black text-opacity-50 text-xs font-semibold mb-5\" ng-if=\"::item.isComingSoon\" translate>Coming Soon</h4> <h3 class=\"text-lg text-black text-opacity-100 font-semibold mb-5\">{{ item.title }}</h3> <div class=\"text-sm text-black text-opacity-50\">{{ item.text }}</div> </div> </div> <div class=\"p-30\"> <button class=\"c-button u-full-width\" type=\"button\" ui-sref=\"household.purchase\" translate>Get Premium</button> </div> </div> ";
// Exports
module.exports = code;