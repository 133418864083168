import AngularTranslate from 'angular-translate'
import UserService from '../../modules/user/svc_user'
import WgService from '../../modules/wg/svc_wg'
import FlatasticConfig from '../../scripts/config'
import ActionsheetModule from '../../scripts/modules/yptActionsheet'
import CordovaModule from '../../scripts/modules/yptCordova'

export const HomelessControllerName = 'HomelessController'
export default angular.module('ft.homeless.controller', [
  AngularTranslate,
  ActionsheetModule,
  CordovaModule,
  FlatasticConfig,
  WgService,
  UserService,
])
.controller(HomelessControllerName, HomelessController)
.name

HomelessController.$inject = ['$rootScope', '$state', 'Wg', 'User', 'yptNotify', '$translate', '$ionicLoading', 'yptActionsheet', '$ionicHistory', '$timeout']
function HomelessController($rootScope, $state, Wg, User, yptNotify, $translate, $ionicLoading, yptActionsheet, $ionicHistory, $timeout) {
  const vm = this
  console.log({ vm })
  vm.moveIn = moveIn
  vm.showMore = showMore
  vm.createHousehold = createHousehold

  function moveIn(code) {
    Wg.moveIn(code)
      .then((data) => User.set(data.user).storeLocally())
      .then(() => $state.go('household.chat.messages'))
      .then(() => $timeout(() => $ionicHistory.clearHistory(), 500))
      .catch(() => {
        yptNotify.alert({
          title: $translate.instant('HML__MOVE_IN-TITLE'),
          message: $translate.instant('HML__MOVE_IN-ERROR'),
        });
      })
      .finally(function() {
        $ionicLoading.hide();
      })
  }

  function deleteAccount() {
    return new Promise((resolve, reject) => {
      yptNotify.confirm({
        title: $translate.instant('Delete Account'),
        message: $translate.instant(`Do you really want to delete your account?`),
        buttonLabels: [
          $translate.instant('Cancel'),
          $translate.instant('Delete Account'),
        ],
        callback: function(answer) {
          if (answer !== true) {
            return reject()
          }
          return resolve()
        },
      })
    })
        .then(() => User.delete())
        .then(() => yptNotify.alert({
          title: $translate.instant('Delete Account'),
          message: $translate.instant('Your account was successfully deleted'),
        }))
  }

  function showMore() {
    const options = {
      title: $translate.instant('Please choose'),
      addCancelButtonWithLabel: $translate.instant('Cancel'),
      buttonLabels: [
        $translate.instant('Logout'),
        $translate.instant('Delete Account'),
      ],
      actions: [
        User.logout,
        deleteAccount,
      ],
    }
    return yptActionsheet.show(options, options.actions)
  }

  function createHousehold() {
    Wg.setIsSettingUp(true)
    $ionicLoading.show({
      template: $translate.instant('Creating a new home…'),
    });
    Wg.createWg()
      .then(function(data) {
        $rootScope.$broadcast('track-event', {
          'category': 'Homeless',
          'action': 'Created WG',
          'label': 'Success',
        });
        User.set(data.user).storeLocally();
        return $state.go('intro-household-type')
          .then(() => $timeout(750))
          .then(() => $ionicHistory.clearHistory())
      })
      .catch(function() {
        Wg.setIsSettingUp(false)
        yptNotify.alert({
          title: $translate.instant('New Home'),
          message: $translate.instant('Something went wrong while creating your new home.'),
        });
      })
      .finally(function() {
        $ionicLoading.hide();
      });
  }
}
