import { mapObjIndexed } from 'ramda'

const meta = ['en', 'de']
const trans = {
  [`BACK`]: [
    `Back`,
    `Zurück`,
  ],
  [`Keep your home harmonious`]: [
    'Keep your home harmonious',
    'Für ein harmonisches Zuhause',
  ],
  [`Flatastic helps you run and organize the household. Simple and hassle-free!`]: [
    'Organize your home in a surprisingly simple way. Together and hassle-free!',
    'Organisiere dein Zuhause. Gemeinsam, gerecht und überraschend einfach.',
  ],
  [`Swipe to learn more 👉`]: [
    'Tap or swipe to learn more 👉',
    'Swipe um mehr zu erfahren  👉',
  ],
  [`Better Chore Management`]: [
    'Better Chore Management',
    'Putzplan',
  ],
  [`Never forget whose turn it is to clean the bathroom again. Always know who's slacking off.`]: [
    `Don't forget your chores. Get reminded. Remind others. Collect points.`,
    `Denke an deine Aufgaben. Lass dich erinnern. Erinnere andere. Bedanke dich.`,
  ],
  [`Shared Shopping List`]: [
    `Shared Shopping List`,
    `Geteilte Einkaufsliste`,
  ],
  [`Put all needed items on a home wide shared list. So you don't end up with either three or no cartons of milk.`]: [
    `Synchronized with everyone. In real time. Always know what's needed. Never forget what to buy.`,
    `Im Supermarkt auch das einkaufen, was zu Hause wirklich gebraucht wird.`,
  ],
  [`Expenses`]: [
    `Expenses`,
    `Ausgaben`,
  ],
  [`Never get into fights because of money again. Always know who paid for what. Get a clear overview of your expenses.`]: [
    `Track your expenses. See who owes who how much. No need for discussions.`,
    `Geld ausgeben, ausleihen, zurückgeben. Und den Überblick nicht verlieren.`,
  ],
  [`Contracts & Subscriptions`]: [
    `Contracts & Subscriptions`,
    `Verträge & Abos`,
  ],
  [`List your recurring expenses. Keep an overview. Optimize.`]: [
    `List your recurring expenses. Keep an overview. Optimize.`,
    `Alle Kosten im Blick behalten. Keine Kündigungsfristen verpassen.`,
  ],
  [`Bulletin Board`]: [
    `Bulletin Board`,
    `Pinnwand`,
  ],
  [`Warm water is out? Planning a dinner with friends? Want to pin pictures of your hike? Keep everybody up to date!`]: [
    `Everyone is in the loop. Never miss or forget anything important.`,
    `Halte alle auf dem Laufenden. Immer und überall.`,
  ],
  [`Get started`]: [
    `Get started`,
    `Auf geht's`,
  ],
  [`Sign in`]: [
    `Sign in`,
    `Anmelden`,
  ],
}

export default {
  en: mapObjIndexed((val, key, obj) => val[0])(trans),
  de: mapObjIndexed((val, key, obj) => val[1])(trans),
}
