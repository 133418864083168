import { propOr } from 'ramda'
import firebaseModule from '../../scripts/modules/flatasticFirebase'
// templates
import modalPremiumFeatureView from './generic_premium_feature.html'
import oldPremiumPaywallView from './modal_premium_feature.html'
import './modal_premium_feature.scss'

const paywallTemplates = {
  legacy: oldPremiumPaywallView,
  generic: modalPremiumFeatureView,
}

export default angular.module('flatastic.paywall', [
  'ionic',
  firebaseModule,
])

.config(
  ['$stateProvider', function ($stateProvider) {

  $stateProvider
    .state('premium-paywall', {
      url: '/premium/paywall/generic',
      params: {
        label: null,
        message: null,
        title: null,
        cta: null,
      },
      resolve: {
        paywallModal: ['$ionicModal', '$rootScope', 'Firebase', '$stateParams', function($ionicModal, $rootScope, Firebase, $stateParams) {
          return Firebase.getConfigValue('paywall_template', 'generic')
            .then(paywallTemplate => {
              const template = propOr(oldPremiumPaywallView, paywallTemplate)(paywallTemplates)
              const scope = $rootScope.$new()
              scope.title = $stateParams.title
              scope.message = $stateParams.message
              scope.cta = $stateParams.cta
              return $ionicModal.fromTemplate(template, {
                scope,
                animation: 'slide-in-up',
              })
            })
        }],
      },
      onEnter: ['$rootScope', '$stateParams', 'paywallModal', function($rootScope, $stateParams, paywallModal) {
        if ($rootScope.previousScreenWasModal) {
          history.back();
          $rootScope.previousScreenWasModal = false;
          return
        }
        $rootScope.previousScreenWasModal = true;
        $rootScope.$broadcast('track-event', {
          category: 'Premium',
          action: 'Did discover premium feature',
          label: $stateParams.label,
        });
        $rootScope.$broadcast('track-event', {
          event: 'did_discover_premium_feature',
          label: $stateParams.label,
        });
        paywallModal.show()
      }],
      onExit: ['paywallModal', '$rootScope', function(paywallModal, $rootScope) {
        if ($rootScope.isBackExit) {
          $rootScope.previousScreenWasModal = false;
          $rootScope.isBackExit = false;
        }
        paywallModal.hide().then(paywallModal.destroy)
      }],
    });

}])

.run(['$rootScope', '$timeout', function($rootScope, $timeout) {
  // Ensure multiple dont' result in going back more than once
  let didJustGoBack= false;
  $rootScope.paywallGoBack = function() {
    if (didJustGoBack) {
      return
    }
    $rootScope.isBackExit = true
    history.back()
    didJustGoBack = true;
    $timeout(750).then(() => (didJustGoBack = false));
  }
}])

.name;
