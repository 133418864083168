import ngRedux from 'ng-redux'
import flatasticConfig from '../../scripts/config'
import { fetchItems, selectCheckedItems, selectUncheckedItems } from '../../tabs/shoppinglist/store/shoppinglistSlice'
import FlatasticStore from '../../tabs/store/angular-store'
import flatasticUserService from '../user/svc_user'

export default angular.module('flatastic.shoppinglist.service', [
  flatasticConfig,
  flatasticUserService,
  ngRedux,
  FlatasticStore,
])

.run(['Shoppinglist', 'User', '$rootScope', 'FlatasticEvents',
      function(Shoppinglist, User, $rootScope, FlatasticEvents) {

  if (User.isInWg()) {
    Shoppinglist.getFromServer()
  }

  [
    FlatasticEvents.shoppinglist.update,
    FlatasticEvents.user.didEnterWg,
  ].forEach(function(event) {
    $rootScope.$on(event, function() {
      Shoppinglist.getFromServer()
    });
  });

}])

.factory('Shoppinglist',
    ['$ngRedux',
    function ($ngRedux) {

  return {
    getFromServer,
    getCheckedItems,
    numItems,
  }

  //// Functions

  function numItems(isChecked = false) {
    if (isChecked === false ) {
      return selectUncheckedItems($ngRedux.getState()).length
    }
    return selectCheckedItems($ngRedux.getState()).length
  }

  function getFromServer() {
    return $ngRedux.dispatch(fetchItems())
  }

  function getCheckedItems() {
    return selectCheckedItems($ngRedux.getState())
  }
}])

.name;
