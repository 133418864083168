// ['en', 'de']

export default {
  [`Bulletin Board`]: [
    `Bulletin Board`,
    `Pinnwand`,
  ],
  [`Messages`]: [
    `Messages`,
    `Nachrichten`,
  ],
  [`Activities`]: [
    `Activities`,
    `Aktivitäten`,
  ],
  [`Send`]: [
    `Send`,
    `Senden`,
  ],
  [`AND`]: [
    `and`,
    `und`,
  ],
  [`Your message …`]: [
    `Your message …`,
    `Deine Nachricht …`,
  ],
  [`Nobody wrote anything yet. Why don't you write a message to welcome everybody?`]: [
    `Nobody wrote anything yet. Why don't you write a message to welcome everybody?`,
    `Bisher hat noch keiner etwas geschrieben. Schreib doch eine nette Nachricht an die anderen.`,
  ],
}
