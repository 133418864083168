import { mapObjIndexed } from 'ramda'

const meta = ['en', 'de']
const trans = {
  [`Step 2 of 2`]: [
    `Step 2 of 2`,
    `Schritt 2 von 2`,
  ],
  [`Hi, add your photo`]: [
    `Hi {{ firstName }}, add your photo`,
    `Hi {{ firstName }}, wähle dein Profilfoto`,
  ],
  [`Help everybody know who you are in Flatastic!`]: [
    `Help everybody know who you are in Flatastic!`,
    `Dank deines Profilfotos können dich deine Mitbewohner schnell erkennen`,
  ],
  [`Add photo`]: [
    `Add photo`,
    `Foto auswählen`,
  ],
  [`Finish account and setup home`]: [
    `Finish account and setup home`,
    `Weiter zum Einrichten des Haushalts`,
  ],
  [`Finish account and join home`]: [
    `Finish account and join home`,
    `Haushalt beitreten`,
  ],
  [`Skip`]: [
    `Skip`,
    `Überspringen`,
  ],
}

export default {
  en: mapObjIndexed((val, key, obj) => val[0])(trans),
  de: mapObjIndexed((val, key, obj) => val[1])(trans),
}
