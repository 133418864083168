import FilterWgCurrency from '../../../../modules/general/filter_wg-currency'
import UserService from '../../../../modules/user/svc_user'
import WgService from '../../../../modules/wg/svc_wg'
import template from './template.html'

export default angular.module('ft.fincances.components.expense-item-entry', [
  WgService,
  UserService,
  FilterWgCurrency,
])
.component('expenseItemEntry', {
  template,
  bindings: {
    expenseItem: '<',
  },
  transclude: true,
  controller,
})
.name


controller.$inject = ['User', 'Wg']
function controller(User, Wg) {
  const $ctrl = this
  $ctrl.user = User.properties
  $ctrl.currency = Wg.currency
  $ctrl.sharers = $ctrl.expenseItem.sharers.map(userId => Wg.flatmates._get(userId).firstName).join(', ')
  $ctrl.isCreditor = $ctrl.expenseItem.buyer === undefined || $ctrl.expenseItem.buyer === User.properties.id
}
