import UserService from '../../../../modules/user/svc_user'
import template from './template.html'

export default angular.module('ft.fincances.components.nav-tabs', [
  UserService
])
.directive('scrollIntoView', [function(){
  return {
    restrict: 'A',
  }
}])
.component('financesNavTabs', {
  template,
  controller,
})
.name

controller.$inject = ['User']
function controller(User) {
  const vm = this
  vm.User = User
}
