export default angular.module('flatastic.directives.blur-on', [
])

.directive('blurOn',
    [function() {
  return function(scope, elem, attr) {
    let removeListener
    removeListener = scope.$on(attr.blurOn, function(e, selectContent) {
      elem[0].blur();
    });

    scope.$on('$destroy', function() {
      cleanUp()
    })

    function cleanUp() {
      removeListener()
    }
  };
}])

.name
