const translations = {
  [`PURCHASE__TITLE`]: [
    `Flatastic Premium`,
    ``,
  ],
  [`PURCHASE__TERMS_OF_SERVICE`]: [
    `Terms of Service`,
    `Rechtliches`,
  ],
  [`PURCHASE__PRIVACY`]: [
    `Privacy Policy`,
    `Datenschutz`,
  ],
  [`PURCHASE_MENU_TITLE`]: [
    `Flatastic Premium`,
    ``,
  ],
  [`PURCHASE_RESTORE`]: [
    `Restore purchases`,
    `Käufe wiederherstellen`,
  ],
  [`LOADING_PRODUCTS`]: [
    `Loading products…`,
    `Produkte werden geladen…`,
  ],
  [`PURCHASES_RESTORING`]: [
    `Restoring your purchases…`,
    `Deine Käufe werden wiederhergestellt…`,
  ],
  [`PURCHASES__DETAILS-TEXT`]: [
    `Recurring billing. Cancel anytime.`,
    ``,
  ],
  [`PURCHASE_MODAL_TITLE`]: [
    `Subscription details`,
    `Details zum Abonnement`,
  ],
  [`PURCHASE_MODAL_PARAGRAPH_1`]: [
    `After confirming the purchase, the amount will be charged from your {{ store }} account.`,
    `Der Betrag wird in deinem {{ store }}-Konto abgerechnet, nachdem du den Kauf bestätigt hast.`,
  ],
  [`PURCHASE_MODAL_PARAGRAPH_2`]: [
    `Your {{ store }} account will be charged again after your subscription is automatically renewed at the end of the subscription period. If you do not want this, you must disable auto-renewal at least 24 hours before the subscription expires.`,
    `Dein {{ store }}-Konto wird erneut belastet, wenn dein Abonnement am Ende des Abonnenmentzeitraums automatisch verlängert wird. Wenn du dies nicht möchtest, musst du die automatische Verlängerung mindestens 24 Stunden vor Ablauf des Abonnements deaktivieren.`,
  ],
  [`PURCHASE_MODAL_PARAGRAPH_3`]: [
    `You can manage or disable the auto-renewal option at any time after purchase in your Apple ID account settings.`,
    `Du kannst die Option zur automatischen Verlängerung jederzeit nach dem Kauf in den Einstellungen deines Apple ID-Kontos verwalten, oder deaktivieren.`,
  ],
  [`PURCHASE_MODAL_PARAGRAPH_4`]: [
    `Any unused portion of a free trial period, if offered, will be forfeited when you purchase a subscription to that publication, where applicable.`,
    `Unverbrauchte Artikel im kostenlosen Probezeitraum — falls angeboten — verfallen, wenn Du dir ein Abo für dieses Produkt kaufst.`,
  ],
  [`PURCHASES_PREMIUM_TITLE`]: [
    `Flatastic Premium`,
    ``,
  ],
  [`PURCHASES_MONTH`]: [
    `month`,
    `Monat`,
  ],
  [`PURCHASES_YEAR`]: [
    `year`,
    `Jahr`,
  ],
  [`PURCHASES_IS_PREMIUM_TEXT_RENEWING`]: [
    `Your Premium account will automatically renew on {{ renewDate | date }}. This way you can keep all premium features and keep sending us love!`,
    `Dein Premium-Account wird sich am {{ renewDate | date }} automatisch verlängern. Dir bleiben also automatisch alle Premium-Funktionen erhalten.`,
  ],
  [`PURCHASES_IS_PREMIUM_TEXT_ENDING`]: [
    `Your Premium account will end on {{ endDate | date }}. Until this date you will keep all premium features.`,
    `Deine Premium-Mitgliedschaft endet am {{ endDate | date }}. Bis dahin bleiben dir alle Premium-Funktionen erhalten.`,
  ],
  [`PURCHASE_MODAL_HEADLINE`]: [
    `Purchases`,
    `Zahlungen`,
  ],
  [`PREMIUM_FEATURE_MODAL_HEADER`]: [
    `Premium-Feature`,
    `Premium-Feature`,
  ],
  [`PREMIUM_FEATURE_MODAL_HEADLINE`]: [
    `You have discovered a premium feature.`,
    `Du hast ein Premium-Feature entdeckt.`,
  ],
  [`PREMIUM_FEATURE_MODAL_PARAGRAPH`]: [
    `Unlock this feature and many more by becoming a premium member. As an addition you'll support our work.`,
    `Schalte dieses und viele weitere Features frei, indem du Premium-Mitglied wirst. Zusätzlich unterstützt du uns so.`,
  ],
  [`PREMIUM_FEATURE_MODAL_BUTTON_TEXT`]: [
    `Get premium`,
    `Premium holen`,
  ],
  [`PREMIUM_FEATURE_MODAL_CLOSE_BUTTON`]: [
    `Close`,
    `Schließen`,
  ],
  [`PURCHASE__GET_PREMIUM`]: [
    `Get premium`,
    `Premium holen`,
  ],
  [`PURCHASES__SUBSCRIBING`]: [
    `Preparing purchase…`,
    `Kauf wird vorbereitet…`,
  ],
  [`PURCHASE_THANKS_FOR_PREMIUM`]: [
    `Thanks for beeing premium, {{ firstName }}`,
    `Danke für deine Premium-Mitgliedschaft, {{ firstName }}`,
  ],
  [`PURCHASE_HEADLINE`]: [
    `Live better together with Flatastic Premium`,
    `Noch einfacheres Zusammenleben mit Flatastic Premium`,
  ],
  [`PURCHASE_BTN-ADV`]: [
    `Your Advantages`,
    `Deine Vorteile`,
  ],
  [`PURCHASE_BTN-REVIEW`]: [
    `User Reviews`,
    `Nutzermeinungen`,
  ],
  [`PURCHASE_BTN-GO-PREMIUM`]: [
    `Go Premium`,
    `Premium holen`,
  ],
  [`PURCHASE_INFO-TEXT`]: [
    `Automatic renewal, cancellable at any time.`,
    `Automatische Verlängerung, jederzeit kündbar.`,
  ],
  [`PURCHASE_ADV-HEADLINE`]: [
    `Your advantages with Premium`,
    `Deine Vorteile mit Premium`,
  ],
  [`PURCHASE_REVIEW-HEADLINE`]: [
    `That's what our users say`,
    `Das sagen unsere Nutzer`,
  ],
  [`PURCHASE_OFFER-HEADLINE`]: [
    `Are you ready to make your living together a little easier?`,
    `Bist du bereit, dein Zusammenleben ein wenig einfacher zu machen?`,
  ],
  [`PURCHASE_OFFER-PARAGRAPH`]: [
    `Get premium and support us. You also activate additional features for you.`,
    `Werde Premium-Mitglied und unterstütze uns. Schalte dir so zusätzliche Features frei.`,
  ],
  [`COMING_SOON`]: [
    `Coming soon`,
    `Kommt bald`,
  ],
  [`NEW`]: [
    `New`,
    `Neu`,
  ],
}

export default translations
