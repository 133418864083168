// ['en', 'de']

export default {
  [`Settlement Details`]: [
    `Settlement Details`,
    `Kassensturz`,
  ],
  [`All entries have been loaded`]: [
    `That's it ({{ count }} {{ count > 1 ? 'entries' : 'entry' }})`,
    `Das war's ({{ count }} {{ count > 1 ? 'Finanzeinträge' : 'Finanzeintrag' }})`,
  ],
}
