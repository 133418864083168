import { keys, pick } from 'ramda'
import ProfileImage from '../../images/avatar-unknown.svg'
import flatasticConfig from '../../scripts/config'
import yepptApi from '../../scripts/modules/api'
import FileSystemModule from '../../scripts/modules/fileSystem'
import FirebasePlugin from '../../scripts/modules/flatasticFirebase'
import PushNotificationModule from '../../scripts/modules/pushnotification'
import { getLanguageFromCountryId } from '../../utils/helpers'

export default angular.module('flatastic.user.service', [
  yepptApi,
  flatasticConfig,
  FirebasePlugin,
  FileSystemModule,
  PushNotificationModule,
])

.run(['$rootScope', 'User', 'Firebase', function($rootScope, User, Firebase) {

  // Check if user is logged in…
  if (User.isLoggedIn()) {
    User.get()
      .then(({ data: user }) => Promise.all([
        Firebase.setUserProperty('is_premium', user.isPremium).catch(console.error),
        Firebase.setUserProperty('created_at', user.createdOn).catch(console.error),
        Firebase.setUserId(user.id).catch(console.error),
      ]))
      .then((res) => console.debug('Firebase: Set user properties succeeded', res))
      .catch(err => console.error('Firebase: Set user properties failed', err))
  }

  // log user out if a 401 or 403 error occurs.
  ['Api:Error:401', 'Api:Error:403'].forEach(eventName => {
    $rootScope.$on(eventName, function() {
      User.clear()
      User.logout()
    })
  })
}])

.service('User',
    ['$rootScope', 'Api', '$window', 'Config', 'FlatasticEvents', 'LocalStorageKeys', 'FileSystem', 'PushNotification',
    function($rootScope, Api, $window, Config, FlatasticEvents, LocalStorageKeys, FileSystem, PushNotification) {

  function initUser() {
    if ($window.localStorage[LocalStorageKeys.user.store]) {
      return JSON.parse($window.localStorage[LocalStorageKeys.user.store]);
    }
    return angular.copy({ properties: {} });
  }

  var User = initUser();

  User.set = function(userJson) {
    for (var key in userJson) {
      if (key === 'dob' && (userJson[key] instanceof Date === false)) {
        User.properties[key] = new Date(userJson[key] * 1000);
      } else if (key === 'language') {
        User.properties[key] = parseInt(userJson[key], 10);
      } else if (key === 'id' && typeof(userJson[key]) === 'string') {
        User.properties[key] = parseInt(userJson[key], 10);
      } else {
        User.properties[key] = userJson[key];
      }
    }
    User.properties.profileImage = User.properties.profileImage || ProfileImage
    return User;
  };

  // Bring the data to the right format
  User.set(User.properties || {});

  User.get = function() {
    return Api.get('/user')
    .success(function(data) {
      User.clear().set(data).storeLocally();
    })
    .error(function(data) {
      User.error = data;
    });
  };

  User.rawData = function() {
    var data = angular.copy(User.properties);
    data.dob = data.dob.getTime() / 1000;
    return data;
  };

  User.update = function(dataToChange) {
    var propertiesBackup = angular.copy(User.properties);
    User.status = 'updating';
    // DoB can have following format: 1987-07-28
    if (dataToChange.dob && dataToChange.dob instanceof Date === false) {
      dataToChange.dob = new Date(dataToChange.dob);
    }
    User.set(dataToChange);
    const changes = pick(keys(dataToChange), User.rawData())
    return Api.post('/user', changes)
      .then(function({ data }) {
        delete User.error;
        delete User.status;
        User.set(data).storeLocally();
        $rootScope.$broadcast(FlatasticEvents.user.didUpdate, User.properties);
        return User.propeties
      })
      .catch(function({ data }) {
        User.set(propertiesBackup);
        User.error = data;
        return Promise.reject(data)
      });
  };

  User.getNewActivationCode = function() {
    return Api.get('/user/activate');
  };

  User.activate = function(code) {
    return Api.post('/user/activate', { code: code });
  };

  User.storeLocally = function() {
    var copy = angular.copy(User);
    delete copy.status;
    delete copy.profileImageUploading;
    $window.localStorage[LocalStorageKeys.user.store] = JSON.stringify(copy);
    return User
  };

  User.clear = function() {
    User.properties = {};
    delete $window.localStorage[LocalStorageKeys.user.store];
    return User;
  };

  User.getLanguage = function() {
    return getLanguageFromCountryId(User.properties.language)
  }

  User.register = function(credentials, doNotBroadcast) {
    credentials.language = credentials.language || 2;
    console.debug('User.register');
    return Api.post('/auth/register', credentials)
    .success(function(data) {
      Api.setApiKey(data['X-API-KEY']);
      User.set(data.user).storeLocally();
      $rootScope.$broadcast('track-event', {
        event: 'sign_up',
        method: 'email',
      });
      if (!doNotBroadcast) {
        console.log('broadcasting register event');
        $rootScope.$broadcast(FlatasticEvents.user.didRegister);
      }
    });
  };

  User.changeEmail = function(email) {
    User.status = 'changing email';
    return Api.post('/user/change_mail', { email })
      .success(function(data) {
        User.set(data).storeLocally();
        $rootScope.$broadcast(FlatasticEvents.user.didUpdate, User.properties);
        delete User.status;
      })
      .error(function(data) {
        delete User.status;
      });
  };

  User.changePassword = function(oldPassword, newPassword) {
    User.status = 'changing password';
    return Api.post('/auth/change_pw', {
      oldPassword,
      newPassword,
    })
      .success(function(data) {
        delete User.status;
      })
      .error(function(data) {
        delete User.status;
      });
  };

  /*
   * data = {
   *   type: <string>,
   *   data: <object>
   * }
   */
  User.sendData = function(data) {
    if (data.type === 'wifi') {
      return Api.post('/user/data', data);
    }

    // Send device data only when version changed
    if ($window.localStorage[LocalStorageKeys.user.dataSent] === Config.versionNumber) {
      return;
    }
    $window.localStorage[LocalStorageKeys.user.dataSent] = Config.versionNumber;
    return Api.post('/user/data', data);
  };

  User.login = function(credentials, doNotBroadcast) {
    return Api.post('/auth/login', credentials)
    .success(function(data) {
      Api.setApiKey(data['X-API-KEY']);
      User.set(data.user).storeLocally();
      $rootScope.$broadcast('track-event', {
        event: 'login',
        method: 'email',
      });
      if (!doNotBroadcast) {
        console.log('broadcasting login event');
        $rootScope.$broadcast(FlatasticEvents.user.didLogin, User.properties);
      }
    });
  };

  User.logout = function() {
    delete $window.localStorage[LocalStorageKeys.user.store];
    delete $window.localStorage[LocalStorageKeys.user.dataSent];
    const logout = User.isLoggedIn()
      ? PushNotification.unregisterDevice()
        .then(() => Api.get('/auth/logout'))
        .catch((error) => console.debug('An error happened while User.logout', error))
      : Promise.resolve()
    $rootScope.$broadcast('track-event', {
      event: 'logout',
    });

    return logout
      .then(() => Api.deleteApiKey())
      .then(() => $rootScope.$broadcast(FlatasticEvents.user.didLogout))
  };

  User.isLoggedIn = function() {
    return !!Api.getApiKey() && (Api.getApiKey() != 'publicKey');
  };

  User.isInWg = function() {
    return (User.isLoggedIn() && (User.properties.groupId == 2));
  };

  User.isHomeless = function() {
    return (User.isLoggedIn() && (User.properties.groupId == 4));
  };

  User.isPremium = function() {
    return localStorage.isPremium
    if (!User.isLoggedIn) {
      return false;
    }
    return User.properties.isPremium;
  };

  User.delete = function() {
    return Api.delete('/user')
      .then(User.logout)
  };

  User.setLocalProfilePicture = function(fileURL) {
    const localPath = FileSystem.getLocalFilePath(fileURL)
    User.set({ profileImage: localPath })
    return Promise.resolve(User)
  }

  User.uploadProfilePicture = function(fileURL, useBrowserUpload) {
    const upload = useBrowserUpload ? Api.browserFileUpload : Api.upload
    return upload(fileURL, '/user/userimage')
      .then(function({ data }) {
        User.set({ profileImage: data.profile_image.full_s3_url }).storeLocally()
        return User
      })
      .finally(function() {
        delete User.profileImageUploading
      })
  }

  return User;
}])

.name;
