export default angular.module('ft.directive.input-currency', [])
.directive('format', ['$filter', function ($filter) {
  return {
    require: '?ngModel',
    restrict: 'A',
    link: function (scope, elem, attrs, ctrl) {
      if (!ctrl) return

      ctrl.$formatters.unshift(function (a) {
        if (!a) {
          return a
        }
        return $filter(attrs.format)(ctrl.$modelValue, '')
      });

      elem.bind('blur', function(event) {
        const plainNumber = elem.val().replace(/[^\d|\-+|\.+]/g, '')
        if (!elem.val()) {
          return
        }
        elem.val($filter(attrs.format)(plainNumber, ''))
      })
    },
  }
}])
.name
