export default angular.module('yeppt.collection', [])

.factory('YepptCollection', ['$window', function($window) {

  function uniqueId() {
    var s4 = function() {
      var random = Math.floor((1 + Math.random()) * 0x10000);
      return random.toString(16).substring(1);
    };
    return new Date().getTime() + '-' + s4() + s4();
  }

  function YepptCollection(uniqueKey, initCollection, storageKey) {
    this.uniqueKey  = uniqueKey  || 'id';
    this.collection = [];
    if (angular.isArray(initCollection) || angular.isObject(initCollection)) {
      // Ensure that we don't have the same copy of items in the collection shared between modules
      var collection = initCollection.map(function(item) {
        return angular.copy(item);
      });
      this.push(collection);
    }

    if (storageKey) {
      this.persistent = true;
      this.storageKey = storageKey;
      if (this.length() === 0) {
        this.load();
      }
    }
  }

  YepptCollection.prototype = {
    store: function() {
      if (!this.persistent) {
        return this;
      }
      if (this.collection.length === 0) {
        console.debug('Collection.store: Removing key')
        delete $window.localStorage[this.storageKey];
      } else {
        $window.localStorage[this.storageKey] = JSON.stringify(this.collection);
      }
      return this;
    },
    load: function() {
      var data = $window.localStorage[this.storageKey];
      if (!data) {
        return this;
      }
      try {
        var data = JSON.parse(data);
        this.push(data);
      } catch (e) {
        console.error(e);
      }
      return this;
    },
    push: function(pushCollection, unshift) {
      var self = this;
      var ids = [];
      if (!angular.isArray(pushCollection)) {
        pushCollection = [pushCollection];
      }
      pushCollection.forEach(function (pushObj) {
        if (!pushObj[self.uniqueKey] || pushObj[self.uniqueKey] === 0) {
          pushObj[self.uniqueKey] = uniqueId();
        }
        self.collection.forEach(function(collectionObj) {
          if (collectionObj[self.uniqueKey] === pushObj[self.uniqueKey]) {
            throw new Error('Cannot push object, since id is already present!');
          }
        });
        if (unshift) {
          self.collection.unshift(pushObj);
          ids.unshift(pushObj[self.uniqueKey]);
        } else {
          self.collection.push(pushObj);
          ids.push(pushObj[self.uniqueKey]);
        }
      });

      this.store();
      return ids;
    },
    update: function(obj) {
      if (!obj[this.uniqueKey]) {
        console.error('Object need to have set a unique id');
        return false;
      }
      var item = this.get(obj[this.uniqueKey])
      if (!this.get(obj[this.uniqueKey])) {
        console.error('Object with ' + this.uniqueKey + '=' + id + ' does not exist');
        return null;
      }

      for (var key in obj) {
        item[key] = obj[key];
      }

      this.store();
      return item;
    },
    get: function(id) {
      var self = this;
      if (!id) {
        return self.collection;
      }
      var returnObj = null;
      self.collection.forEach(function(collectionObj) {
        if (collectionObj[self.uniqueKey] == id) {
          returnObj = collectionObj;
        }
      });
      return returnObj;
    },
    position: function(id) {
      var self = this;
      if (!id) {
        return -1;
      }
      var pos = -1;
      for (var pos=0; pos < self.collection.length; pos++) {
        if (self.collection[pos][self.uniqueKey] === id) {
          return pos;
        }
      }
      return -1;
    },
    insert: function(position, obj) {
      var self = this;
      if ((!position && position !== 0) || !obj) {
        return false;
      }
      if (!obj[self.uniqueKey] || obj[self.uniqueKey] === 0) {
        obj[self.uniqueKey] = uniqueId();
      }
      self.collection.splice(position, 0, obj);
      this.store();
    },
    toggle: function(id, obj) {
      if (!id) {
        throw new Error('You need to set id');
      }
      var self = this;
      if (!self.get(id)) {
        self.push(obj);
      } else {
        self.remove(id);
      }
      this.store();
    },
    removeHead: function() {
      var self = this;
      var head = self.collection.shift()
      self.store()
      return head
    },
    remove: function(id) {
      var self = this;
      if (!id) {
        this.collection = [];
        return;
      }
      var removeIndex = null;
      this.collection.forEach(function(collectionObj, index) {
        if (collectionObj[self.uniqueKey] === id) {
          removeIndex = index;
        }
      });
      if (removeIndex !== null) {
        this.collection.splice(removeIndex, 1);
        return this.store();
      } else {
        return this;
      }
    },
    removeWhere: function(where) {
      var self = this;
      this.collection = this.collection.filter(function(collectionObj) {
        for (var key in where) {
          if (collectionObj[key] === where[key]) {
            return false;
          }
        }
        return true;
      });
      this.store();
    },
    accumulate: function(key) {
      var self = this;
      return self.collection.map(function(elem) {
        return elem[key];
      });
    },
    sum: function(key) {
      var self = this;
      return self.collection.reduce(function(processedValue, newValue) {
        return processedValue + newValue[key];
      }, 0);
    },
    length: function() {
      return this.collection.length;
    },
    reduce: function(func, start) {
      return this.collection.reduce(func, start);
    },
    filter: function(func) {
      return this.collection.filter(func);
    },
    map: function(func) {
      return this.collection.map(func);
    },
    toJSON: function() {
      var self = this;
      return self.collection;
    },
    manipulate: function(func) {
      var that = this;
      this.collection.forEach(function(elem, i) {
        that.collection[i] = func(elem);
      });
      this.store();
    },
    getElements: function(arrayWithIds, key) {
      var self = this;
      return arrayWithIds.filter(function(id) {
        return self.get(id) !== null;
      })
      .map(function(id) {
        return key ? self.get(id)[key] : self.get(id);
      });
    },
  };

  return YepptCollection;
}])

.name;
