export default angular.module('flatastic.shoppinglist.item', [])

.factory('ShoppinglistItem', [function () {

  function ShoppinglistItem() {  }

  ShoppinglistItem.getScaffold = function(inserterId) {
    var scaffold = {
      name: '',
      bought: '0',
    };
    if (inserterId) {
      scaffold.inserterId = inserterId;
    }
    return scaffold;
  };

  return ShoppinglistItem;
}])

.name;
