export default angular.module('flatastic.directives.on-touchdown', [
])

.directive('onBlurPreventingClick',
    ['$rootScope', '$parse', '$exceptionHandler', function($rootScope, $parse, $exceptionHandler) {

  return {
    restrict: 'A',
    compile,
  }

  function compile($element, attr) {
    const fn = $parse(attr['onBlurPreventingClick'])

    return function ftEventHandler(scope, elem) {
      elem[0].addEventListener('touchstart', onTouchStart, { passive: true })
      elem[0].addEventListener('click', (event) => {
        var callback = function() {
          fn(scope, { $event: event })
        }
        if (!$rootScope.$$phase) {
          scope.$apply(callback);
        } else {
          try {
            callback();
          } catch (error) {
            $exceptionHandler(error);
          }
        }
      }, { passive: true })

      let isClickLike
      let startTime
      function onTouchStart(event) {
        stopBubble(event)
        if (elem[0].disabled) {
          return
        }
        isClickLike = true
        startTime = event.timeStamp
        elem[0].addEventListener('touchmove', onTouchMove, { passive: true })
        elem[0].addEventListener('touchend', onTouchEnd)

      }
      function onTouchMove(event) {
        isClickLike  = false
        elem[0].removeEventListener('touchmove', onTouchMove)
      }
      function onTouchEnd(event) {
        stopBubble(event);
        elem[0].removeEventListener('touchend', onTouchEnd)
        if (!isClickLike || event.timeStamp - startTime > 350) {
          return
        }
        var callback = function() {
          fn(scope, { $event: event })
        }
        if (!$rootScope.$$phase) {
          scope.$apply(callback);
        } else {
          try {
            callback();
          } catch (error) {
            $exceptionHandler(error);
          }
        }
      }
    }
  }

  function stopBubble(event) {
    if (event.cancelable) {
     event.preventDefault();
    }
    event.stopPropagation();
  }
}])

.name
