import { hasPath } from 'ramda'

export default angular.module('yeppt.decimalnumberfix', [])

.directive('yptDecimalFix',
    ['device',
    function (device) {

  var deviceModelsToBeFixed = [
    'ASUS_T00J',    // Asus Zenfone 5
    'C2105',        // Sony Xperia L C2105
    'GT-I8190',     // Samsung Galaxy S3 Mini
    'GT-I8200N',    // Samsung Galaxy S3 Mini Value Edition
    'GT-I9060',
    'GT-I9060',     // Samsung Galaxy Grand Neo
    'GT-I9060I',
    'GT-I9100G',    // Samsung Galaxy S2 powered by the OMAP 4460 chip
    'GT-I9105',     // Samsung Galaxy S2 Plus
    'GT-I9105P',    // Samsung Galaxy S2 Plus
    'GT-I9195',     // Samsung Galaxy S4 Mini
    'GT-I9295',     // Samsung Galaxy S4 Active
    'GT-I9300',     // Samsung Galaxy S3
    'GT-I9301I',    // Samsung Galaxy S3 Neo (White)
    'GT-I9305',     // Samsung Galaxy S3
    'GT-I9500',     // Samsung Galaxy S4
    'GT-I9505',     // Samsung Galaxy S4
    'GT-I9506',     // Samsung Galaxy S4
    'GT-I9515',     // Samsung Galaxy S4
    'GT-N7100',     // Samsung Galaxy Note 2
    'GT-S7275R',    // Samsung Galaxy Ace 3
    'GT-S7580',
    'GT-S7580',     // Samsung Galaxy Trend Plus
    'GT-S7582',     // Samsung Galaxy Trend Plus
    'HTC Desire 526G dual sim',
    'HTC Desire 626G dual sim',
    'HTC One M7',
    'HTC One M8',
    'HTC One mini 2',
    'HTC One mini',
    'HTC One',
    'HTC One_M7',
    'HTC One_M8 dual sim',
    'HTC One_M8',
    'LG-D331',
    'LG-D331',
    'LG-D620',
    'LG-D686',
    'LG-D722',
    'LG-D802',
    'LG-E975',
    'LG-H815',
    'LG-H818',
    'LG-H830',
    'LG-H840',
    'LG-H850',
    'LG-H870',
    'LG-H870DS',
    'LG-K220',
    'LG-M700',
    'LG-K430',
    'LM-G710',      // LGE LG G7 ThinQ
    'ONE E1001',
    'PadFone 2',    // Asus Padfone2
    'SM-G357FZ',    // Samsung Galaxy Ace 4
    'SM-G388F',     // Samsung Galaxy Xcover 3
    'SM-G7102',     // Samsung Galaxy Grand 2
    'SM-G800F',     // Samsung Galaxy S5 Mini
    'SM-G850F',     // Samsung Galaxy Alpha
    'SM-G850F',     // Samsung Galaxy Alpha 32GB
    'SM-G900F',     // Samsung Galaxy S5
    'SM-G901F',     // Samsung Galaxy S5 LTE+
    'SM-G920F',
    'SM-J100H',
    'SM-J100H',     // Samsung Galaxy J1
    'SM-N9005',     // Samsung Galaxy Note 3
    'SM-T560',      // Samsung Galaxy Tab E
    'SM-T800',      // Samsung Tab S
    'SM-T800',      // Samsung Tab S
  ];

  function addDecimalDivider(val) {
    val.replace(',', '.');
    var period = val.indexOf('.');
    if (period > -1) {
      val = val.substring(0, period) + val.substring(period + 1);
    }

    // extend too short strings so that 1 becomes 001 --> 0.01
    while (val.length < 3) {
      val = '0' + val;
    }
    var len = val.length;
    val = val.substring(0, len - 2) + '.' + val.substring(len - 2, len);
    while (val.length > 4 && (val[0] == 0 || isNaN(val[0]))) {
      val = val.substring(1);
    }
    if (val[0] == '.') {
      val = '0' + val;
    }

    return val;
  }

  return {
    restrict: 'A',
    link: function (scope, iElement, iAttrs) {
      iElement.bind('keydown', function(e) {
        if (e.key !== ',') {
          return
        }
        e.preventDefault()
        e.target.value = `${e.target.value}.`
      })
      if (!hasPath(['device', 'info'], window)) {
        return;
      }
      if (device.platform === 'iOS') {
        return;
      }
      if (deviceModelsToBeFixed.indexOf(device.info.model) === -1) {
        return;
      }
      iElement.bind('keyup', function(e) {
        var val = iElement.val();
        iElement.val(addDecimalDivider(val));
        // TODO: make it general
        scope.itemData.price = Number(iElement.val());
      });
    },
  };
}])

.name;
