// ['en', 'de']

export default {
  [`New Entry`]: [
    `New Entry`,
    `Neuer Eintrag`,
  ],
  [`Edit Expense`]: [
    `Edit Entry`,
    `Eintrag bearbeiten`,
  ],
  [`Expense Details`]: [
    `Details`,
    `Details`,
  ],
  [`Item title`]: [
    `Item title`,
    `Artikelbezeichnung`,
  ],
  [`Bought Products`]: [
    `Bought Products`,
    `Gekaufte Produkte`,
  ],
  [`Date`]: [
    `Date`,
    `Datum`,
  ],
  [`Paid by person for everyone split equal`]: [
    `Paid by {{ name }} for everyone split equal`,
    `Von {{ name }} bezahlt für alle gleichmässig aufgeteilt`,
  ],
  [`I get back`]: [
    `I get back`,
    `Ich bekomme zurück`,
  ],
  [`Description`]: [
    `Description`,
    `Beschreibung`,
  ],
  [`Add more items`]: [
    `Add more items`,
    `Weitere Produkte hinzufügen`,
  ],
  [`Add`]: [
    `Add`,
    `OK`,
  ],
  [`For`]: [
    `For`,
    `Für`,
  ],
  [`Select All`]: [
    `Select All`,
    `Alle auswählen`,
  ],

  //// Legacy
  [`EXP__NEW_DEFAULT_EVENT`]: [
    `Shopping`,
    `Einkauf`,
  ],
  [`EDIT`]: [
    `Edit`,
    `Bearbeiten`,
  ],
  [`EXP__NEW_CONFIRM_GO_BACK-TITLE`]: [
    `Expenses`,
    `Finanzen`,
  ],
  [`EXP__NEW_CONFIRM_GO_BACK-MSG`]: [
    `Caution, the entry has not been saved. Do you really want to discard the changes?`,
    `Achtung, der Eintrag ist noch nicht gespeichert. Möchtest du die Änderungen wirklich verwerfen?`,
  ],
  [`DISCARD`]: [
    `Discard`,
    `Verwerfen`,
  ],
  [`SAVE`]: [
    `Save`,
    `Speichern`,
  ],
  [`EXP__DELETE_ITEM-TITLE`]: [
    `Delete`,
    `Löschen`,
  ],
  [`EXP__DELETE_ITEM-MSG`]: [
    `Do you really want to delete this entry?`,
    `Möchtest du diesen Eintrag wirklich löschen?`,
  ],
  [`EXP__NEW_NO_SELECTED_FM-TITLE`]: [
    `Add event`,
    `Eintrag hinzufügen`,
  ],
  [`EXP__NEW_NO_SELECTED_FM-MSG`]: [
    `Please select at least one person in order to add the item "{{ name }}".`,
    `Bitte wähle mindestens einen Mitbewohner aus, um den Eintrag "{{ name }}" hinzuzufügen.`,
  ],
  [`EXP__NEW_ERROR-TITLE`]: [
    `Expenses`,
    `Ausgaben`,
  ],
  [`EXP__NEW_NO_NEGATIVE_NUMBERS-MSG`]: [
    `Negative values are not allowed`,
    `Es dürfen keine negativen Zahlen eingegeben werden`,
  ],
  [`EXP__NEW_NO_TOO_LARGE_NUMBERS-MSG`]: [
    `Wow. That is expensive. For these kind of sums you may need a different tool.`,
    `Wow. Das ist teuer. Für solche Summen benutzt du besser ein anderes Tool.`,
  ],
  [`EXP__EDIT_ITEM-TITLE`]: [
    `Edit entry`,
    `Eintrag bearbeiten`,
  ],
  [`EXP__EDIT_ITEM-MSG`]: [
    `You are already editing an entry. Please finish that instance first.`,
    `Du bearbeitest bereits einen Eintrag. Schließe den Vorgang zunächst ab.`,
  ],
}
