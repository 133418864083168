import HouseholdPhotoController from '../../modules/intro/household-photo.ctrl'
import HouseholdController, { HouseholdControllerName } from '../../modules/intro/household.ctrl'
import { hideAccessoryBar, showAccessoryBar } from '../../modules/keyboardController'
import LegacyPurchaseController from '../../modules/purchase/ctrl_purchase'
import LegacyFeedbackController, { FeedbackControllerName } from '../../modules/settings/ctrl_feedback'
import LegacyNotificationsFeedbackController, { NotificationsFeedbackControllerName } from '../../modules/settings/ctrl_notificationsFeedback'
import LegacySettingsController, { SettingsControllerName } from '../../modules/settings/ctrl_settings'
import LegacyUserController, { UserControllerName } from '../../modules/user/ctrl_user'
import LegacyWgController from '../../modules/wg/ctrl_wg'
import LegacyInviteFlatmatesController from '../../modules/wg/ctrl_wg-invite-flatmates'
import FeedbackTemplate from './feedback.html'
import HouseholdMembersController from './household-members.ctrl'
import HouseholdMembersTemplate from './household-members.html'
import NotificationsTestTemplate from './notifications-test.html'
import template from './settings.html'
import './settings.scss'
import UserPasswordTemplate from './user-password.html'

export default angular.module('ft.settings', [
  LegacySettingsController,
  LegacyWgController,
  LegacyInviteFlatmatesController,
  LegacyUserController,
  LegacyNotificationsFeedbackController,
  LegacyFeedbackController,
  HouseholdController,
  HouseholdPhotoController,
  HouseholdMembersController,
  LegacyPurchaseController,
])
.config(SettingsConfig)
.name;


SettingsConfig.$inject = ['$stateProvider']
function SettingsConfig($stateProvider) {
  $stateProvider
    .state('household.settings', {
      url: '/settings',
      views: {
        'settings-tab': {
          controller: SettingsControllerName,
          controllerAs: 'vm',
          template,
        },
      },
      onEnter: showAccessoryBar,
      onExit: hideAccessoryBar,
    })
    .state('household.settings-members', {
      url: '/settings/household/members',
      views: {
        'settings-tab': {
          controller: HouseholdControllerName,
          controllerAs: 'vm',
          template: HouseholdMembersTemplate,
        },
      },
    })
    .state('household.settings-notifications-test', {
      url: '/settings/notifications/test',
      views: {
        'settings-tab': {
          controller: NotificationsFeedbackControllerName,
          controllerAs: 'vm',
          template: NotificationsTestTemplate,
        },
      },
    })
    .state('household.settings-user-password', {
      url: '/settings/user/password',
      views: {
        'settings-tab': {
          controller: UserControllerName,
          template: UserPasswordTemplate,
        },
      },
    })
    .state('household.settings-feedback', {
      url: '/settings/feedback',
      views: {
        'settings-tab': {
          controller: FeedbackControllerName,
          template: FeedbackTemplate,
        },
      },
    })
}
