import KeyboardModule from '../../scripts/modules/yptKeyboard'

export default angular.module('flatastic.directives.focus-on', [
  KeyboardModule,
])

.directive('focusOn',
    ['$timeout', 'yptKeyboard', function($timeout, yptKeyboard) {
    return function(scope, elem, attr) {
    let timeout
    let removeListener
    removeListener = scope.$on(attr.focusOn, function(e, selectContent) {
      elem[0].focus();
      if (selectContent) {
        elem[0].select();
      }
    });

    scope.$on('$destroy', function() {
      cleanUp()
    })


    if (attr.focusAfter) {
      timeout = $timeout(function() {
        scope.$broadcast(attr.focusOn);
      }, attr.focusAfter);
    }

    function cleanUp() {
      removeListener()
      if (timeout) {
        $timeout.cancel(timeout)
      }
    }
  };
}])

.name
