/*
 * As regular expressions are sometimes hard to debug here an explaination
 *
 --> ((http|https|ftp):\/\/)?
 *   URL may start with http://, https:// or ftp://
 *
 --> ([a-z1-9]+\.)+?
 *   an optional subdomain e.g. ns1 www., piwik., ns.aws..
 --> (
 -->   ([-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6})
 *     domain with ending
 -->   |
 *     or
 -->   (([0-9]{1,3}\.){3}[0-9]{1,3})
 *     IP address e.g. 192.168.1.123
 -->  )
 -->  \b
 -->  ([-a-z0-9@:%_\+.~#?&//=,]*)
 *    Some parameters.
 */

export default angular.module('flatastic.filters.parse-url', [])

.filter('parseUrl', function () {

    var regExp = /((http|https|ftp):\/\/)?(([a-z1-9]+\.)+)?(([-a-z0-9]{2,256}\.[a-z]{2,6})|(([0-9]{1,3}\.){3}[0-9]{1,3}))\b([-a-z0-9@:%_\+.~#?&//=,]*)/ig;
    return function (text, target) {
        return text.replace(regExp, '<span class="ft-card__link" target="' + target + '" href="$&">$&</span>' );
    };
})

.name
