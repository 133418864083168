import BalanceEntryTranslations from './components/balance-entry/translations'
import ExpenseEntryTranslations from './components/expense-entry/translations'
import NavTabsTranslations from './components/nav-tabs/translations'
import PersonalSpendingsTranslations from './components/personal-spendings/translations'
import SettlementEntryTranslations from './components/settlement-entry/translations'
import ExpensesCreateTranslations from './expense-create.trans'
import ExpensesDetailsTranslations from './expense-details.trans'
import FinanceHistoryEntryTranslations from './finances-history-entry.trans'
import FinanceHistoryTranslations from './finances-history.trans'
import FinanceListingTranslations from './finances-listing.trans'
import FinanceStatisticsTranslations from './finances-statistcs.trans'
import SubscriptionsCreateTranslations from './subscription-create.trans'
import SubscriptionsTranslations from './subscriptions.trans'

export default {
  ...SettlementEntryTranslations,
  ...ExpenseEntryTranslations,
  ...NavTabsTranslations,
  ...ExpensesDetailsTranslations,
  ...ExpensesCreateTranslations,
  ...FinanceStatisticsTranslations,
  ...FinanceListingTranslations,
  ...BalanceEntryTranslations,
  ...SubscriptionsTranslations,
  ...SubscriptionsCreateTranslations,
  ...FinanceHistoryTranslations,
  ...FinanceHistoryEntryTranslations,
  ...PersonalSpendingsTranslations,
}
