export default angular.module('flatastic.directives.on-return', [])

.directive('onReturn', [function() {
  return function(scope, elem, attr) {
    elem.bind('keydown', function(event) {
      if (event.which === 13 || event.which === 9) {
        scope.$emit(attr.onReturn);
        return false;
      }
    });
  };
}])

.name;
