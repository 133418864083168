import UserService from '../../../../modules/user/svc_user'
import WgService from '../../../../modules/wg/svc_wg'
import template from './template.html'

export default angular.module('ft.fincances.components.subscription-entry', [
  WgService,
  UserService,
])
.component('subscriptionEntry', {
  template,
  bindings: {
    subscription: '<',
  },
  controller,
})
.name

controller.$inject = ['User', 'Wg']
function controller(User, Wg) {
  const $ctrl = this
  $ctrl.subscription.data = $ctrl.subscription.data || {}
  $ctrl.currency = Wg.currency
  $ctrl.isMe = $ctrl.subscription.payerId === User.properties.id
}
