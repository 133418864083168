// [en, de]

export default {
  [`(me)`]: [
    `(me)`,
    `(ich)`,
  ],
  [`gets back total`]: [
    `gets back total`,
    `erhält total`,
  ],
  [`owes total`]: [
    `owes total`,
    `schuldet total`,
  ],
  [`is settled up`]: [
    `is settled up`,
    `ist ausgeglichen`,
  ],
}
