// en, de
export default {
  [`Chores:Proposals:Text`]: [
    `You haven't added any chores to your cleaning schedule yet. Here are some ideas to get you started:`,
    `Es wurden noch keine Aufgaben hinzugefügt. Hier findest du ein paar Ideen zum Loslegen:`,
  ],
  [`Chores:Proposals:Recurring`]: [
    `Recurring`,
    `Wiederkehrend`,
  ],
  [`Chores:Proposals:WhenNeeded`]: [
    `When needed`,
    `Bei Bedarf`,
  ],
  [`Chores:Proposals:Todo`]: [
    `One-time task`,
    `Einmalige Aufgabe`,
  ],
  [`Chores:Proposals:BtnLbl:AddChore`]: [
    `Add Chore`,
    `Hinzufügen`,
  ],
  [`Chores:Proposals:CatalogTeaser`]: [
    `Need more inspiration or fine-control? Our catalog has a ton of pre-made chores.`,
    `Du brauchst mehr Inspiration? Unser Katalog hat viele vorkonfigurierte Aufgaben.`,
  ],
  [`Chores:Proposals:CatalogCTA`]: [
    `See whole catalog`,
    `Katalog ansehen`,
  ],
  [`Chores:Proposals:CreateOwnTeaser`]: [
    `Haven't found what you're looking for? Setup your own chore.`,
    `Nicht gefunden, was du sucht? Erstelle deine eigene Aufgabe.`,
  ],
  [`Chores:Proposals:CreateCTA`]: [
    `Create Chore`,
    `Aufgabe erstellen`,
  ],
}
