import FeedbackTranslations from './feedback.trans'
import HouseholdMembersTranslations from './household-members.trans'
import NotificationsTestTranslations from './notifications-test.trans'
import SettingsTranslations from './settings.trans'
import UserPasswordTranslations from './user-password.trans'

export default {
  ...SettingsTranslations,
  ...HouseholdMembersTranslations,
  ...NotificationsTestTranslations,
  ...UserPasswordTranslations,
  ...FeedbackTranslations,
}
