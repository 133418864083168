export default angular.module('flatastic.directive.keyboard-attach', [])
.directive('ftKeyboardAttach', function() {
  return function(scope, element, attrs) {
    ionic.on('native.keyboardshow', onShow, window);
    ionic.on('native.keyboardhide', onHide, window);

    var scrollCtrl;
    var keyboardHeight
    let onHideTimeout
    const el = attrs.ftKeyboardAttachReferenceElement
      ? element[0].querySelector(attrs.ftKeyboardAttachReferenceElement)
      : element[0]

    function onShow(e) {
      if (ionic.Platform.isAndroid() && !ionic.Platform.isFullScreen) {
        return;
      }
      if (onHideTimeout) {
        clearTimeout(onHideTimeout)
        onHideTimeout = undefined
      }
      keyboardHeight = e.keyboardHeight || (e.detail && e.detail.keyboardHeight);
      const clientHeight = keyboardAttachGetClientHeight(el)
      element.css('bottom', keyboardHeight + "px");
      resizeScrollView(keyboardHeight, clientHeight)
      if (attrs.ftKeyboardAttach) {
        scope.$apply(attrs.ftKeyboardAttach)
      }
    }
    if (attrs.ftKeyboardAttachResizeWhen) {
      scope.$on(attrs.ftKeyboardAttachResizeWhen, function() {
        const clientHeight = keyboardAttachGetClientHeight(el)
        resizeScrollView(keyboardHeight, clientHeight)
        setTimeout(() => {
          const clientHeight = keyboardAttachGetClientHeight(el)
          resizeScrollView(keyboardHeight, clientHeight)
        }, 250)
      })
    }

    function resizeScrollView(keyboardHeight, clientHeight) {
      scrollCtrl = element.controller('$ionicScroll');
      if (scrollCtrl) {
        scrollCtrl.scrollView.__container.style.bottom = keyboardHeight + clientHeight + "px";
      }
    }

    function onHide() {
      if (ionic.Platform.isAndroid() && !ionic.Platform.isFullScreen) {
        return;
      }
      keyboardHeight = undefined

      element.css('bottom', '');
      if (onHideTimeout) {
        clearTimeout(onHideTimeout)
      }
      onHideTimeout = setTimeout(() => element.css('bottom', ''), 25);
      if (scrollCtrl) {
        scrollCtrl.scrollView.__container.style.bottom = '';
      }
      if (attrs.ftKeyboardAttach) {
        scope.$apply(attrs.ftKeyboardAttach)
      }
    }

    scope.$on('$destroy', function() {
      ionic.off('native.keyboardshow', onShow, window);
      ionic.off('native.keyboardhide', onHide, window);
    });
  };
})
.name;

function keyboardAttachGetClientHeight(element) {
  return element ? element.clientHeight : 0;
}
