import FlatasticConfig from '../../scripts/config'
import ApiService from '../../scripts/modules/api'
import IconHomeRent from './assets/icon-home-rent.svg'
import IconInsurancesHouseholdContent from './assets/icon-insurances-household-content.svg'
import IconMediaMusic from './assets/icon-media-music.svg'
import IconMediaPopcorn from './assets/icon-media-popcorn.svg'
import IconMemberships from './assets/icon-memberships.svg'
import IconUtilityElectricity from './assets/icon-utility-electricity.svg'
import IconUtilityInternet from './assets/icon-utility-internet.svg'
import IconUtilityWater from './assets/icon-utility-water.svg'

const iconMap = {
  'ion-ios-lightbulb': IconUtilityElectricity,
  'ion-ios-home': IconHomeRent,
  'ion-ios-world-outline': IconUtilityInternet,
  'ion-clipboard': IconInsurancesHouseholdContent,
  'ion-ios-people': IconMemberships,
  'ion-headphone': IconMediaMusic,
  'ion-ios-videocam': IconMediaPopcorn,
  'ion-waterdrop': IconUtilityWater,
}

export default angular.module('flatastic.subscriptions.service.subscription', [
  ApiService,
  FlatasticConfig,
])

.factory('Subscription', ['Api', '$rootScope', 'FlatasticEvents', function(Api, $rootScope, FlatasticEvents) {

  var apiEndpoint = '/subscriptions';
  var dateFields = ['createdAt', 'updatedAt', 'firstBill'];

  function Subscription(data) {
    this.status = '';
    return this.set(data);
  }

  Subscription.prototype.update = function(data) {
    if (Object.keys(data).length <= 0) { return; }
    var self = this;
    self.status = 'updating';
    const postBody = self.makeDataApiConfirm(data)
    return Api.put(apiEndpoint, postBody)
      .then(function({ data }) {
        self.set(data);
        $rootScope.$broadcast(FlatasticEvents.subscriptions.didUpdateEntry)
        self.status = '';
      })
      .catch(function(error) {
        self.status = 'error';
        throw error
      });
  };

  Subscription.prototype.delete = function() {
    self = this;
    self.status = 'deleting';
    return Api.delete(`${apiEndpoint}/id/${self.id}`)
      .then(function() {
        self.status = '';
        $rootScope.$broadcast(FlatasticEvents.subscriptions.didDeleteEntry)
      })
      .catch(function(err) {
        self.status = 'error';
        throw err
      });
  };

  Subscription.prototype.save = function() {
    var self = this;
    self.status = 'saving';
    return Api.post(apiEndpoint, self.makeDataApiConfirm(self))
      .then(function({ data }) {
        self.set(data);
        self.status = '';
        $rootScope.$broadcast(FlatasticEvents.subscriptions.didCreateEntry)
      })
      .catch(function(error) {
        console.error('Saving subscription failed', error)
        self.status = 'error';
        throw error
      });
  };


  Subscription.prototype.makeDataApiConfirm = function(data) {
    var output = {};
    if (!data.id) {
      output.id = this.id;
    }
    for (var key in data) {
      var value = data[key];
      if (value instanceof Date) {
        value = parseInt(value.getTime() / 1000, 10);
      }
      output[key] = value;
    }
    delete output.status;
    return output;
  };

  Subscription.prototype.set = function(data) {
    for (var key in data) {
      var value = data[key];
      if (dateFields.indexOf(key) > -1 && !(value instanceof Date)) {
        if (typeof value === 'string') {
          value = new Date(value);
         } else {
          value = new Date(value * 1000);
        }
      }
      if (key === 'data') {
        this.icon = iconMap[value.icon]
      }
      this[key] = value;
    }
    return this;
  };

  return Subscription;

}])

.name;
