import shoppinglistProposalsService from '../shoppinglist/svc_shoppinglistproposals'

export default angular.module('flatastic.expenses.proposals', [
  shoppinglistProposalsService,
])

.factory('ExpensesProposals', ['ShoppinglistProposals',
    function (ShoppinglistProposals) {

  return ShoppinglistProposals;
}])

.name;
