export default {
  [`shoppinglist.quickedit.info`]: [
    `Add details to the item you just added or assign tags for filtering. Scroll down for the suggestions.`,
    `Füge Details zu dem gerade hinzugefügten Eintrag oder weise Tags zum Filtern zu. Scrolle runter für die Vorschläge.`,
  ],
  [`shoppinglist.quickedit.btn.disable`]: [
    `Disable`,
    `Deaktivieren`,
  ],
  [`shoppinglist.quickedit.btn.unlock`]: [
    `Get Premium`,
    `Premium holen`,
  ],
  [`shoppinglist.quickedit.btn.ok`]: [
    `OK`,
    `OK`,
  ],
}
