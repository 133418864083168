import { defaultTo, find, pipe, pluck, prop, propEq, sortBy } from 'ramda'
import flatasticUserService from '../../modules/user/svc_user'
import flatasticConfig from '../../scripts/config'
import yepptApi from '../../scripts/modules/api'

export const defaultIapOfferings = {
  ios: [
    {
      productId: 'com.flatastic.app.premium.monthly',
      prio: 1,
    },
    {
      productId: 'com.flatastic.app.premium.yearly',
      prio: 2,
    },
    {
      productId: 'com.flatastic.app.flat.yearly',
      prio: 3,
      isNew: true,
    },
  ],

  android: [
    {
      productId: 'monthly.premium',
      prio: 1,
    },
    {
      productId: 'yearly.premium',
      prio: 2,
    },
    {
      productId: 'flat.yearly',
      prio: 3,
      isNew: true,
    },
  ],
}

export default angular.module( 'flatastic.purchase.service', [
  flatasticConfig,
  flatasticUserService,
  yepptApi,
  'ionic',
])

.run(['Purchase', 'User',
  function(Purchase, User) {
    if (User.isPremium()) { return; }
    Purchase.getProducts();
  }])

.factory('Purchase',
  ['Api', '$q', 'User', '$ionicPlatform', '$rootScope', 'FlatasticEvents', 'Firebase',
    function(Api, $q, User, $ionicPlatform, $rootScope, FlatasticEvents, Firebase) {

      var products;
      var getProductsFromStorePromise

      // Public API here
      return {
        getProducts,
        subscribeToItem,
        restorePurchases,
      };

      //// Functions

      function getProductsFromStore() {
        if (typeof inAppPurchase === 'undefined') {
          return $q.reject('No physical device detected. Cannot return store items.');
        }
        let offerings
        getProductsFromStorePromise = getOffering()
          .then(products => {
            offerings = products
            return pluck('productId')(products)
          })
          .then(inAppPurchase.getProducts)
          .then(function(items) {
            return items.map(item => ({
              ...item,
              period: /yearly/gi.test(item.productId) ? 'yearly' : 'monthly',
              ...find(propEq('productId', item.productId))(offerings),
            }))
          })
          .then(sortBy(prop('prio')))
          .then(function(items) {
            products = items
            return products
          });
        return getProductsFromStorePromise
      }

      function getProducts() {
        return $ionicPlatform.ready().then(function() {
          return products
            ? $q.resolve(products)
            : getProductsFromStorePromise || getProductsFromStore();
        });
      }


      function subscribeToItem(productId) {
        if (typeof inAppPurchase === 'undefined') {
          return $q.reject('No physical device detected. Cannot subscribe to item.');
        }
        return inAppPurchase.subscribe(productId)
          .then(function(purchase) {
            $rootScope.$broadcast('track-event', {
              category: 'InAppPurchases',
              action: 'Did purchase',
              label: productId,
            });
            console.log('Purchase service successfully subscribed to item ', purchase);
            // Add the product id and current platform to response data
            purchase.productId = productId;
            purchase.platform = device.platform;
            /* data will be received in this format
              {
                transactionId: ...
                receipt: ...
                signature: ...
              }
            */
            return saveUserPurchase(purchase);
          });
      }

      function getOffering() {
        const platform = device.platform.toLowerCase()
        return Firebase.getConfigValue('iap_offerings', defaultIapOfferings)
          .then(pipe(prop(platform), defaultTo([])))
      }

      /**
       * Android statuses are: 0 - ACTIVE, 1 - CANCELLED, 2 - REFUNDED
       * Data will have the following format:
       *   [{
       *     transactionId: ...
       *     productId: ...
       *     state: ...
       *     date: ...
       *   }, …]
       *
       */
      function restorePurchases() {
        if (typeof inAppPurchase === 'undefined') {
          return $q.reject('No physical device detected. Cannot restore purchases.');
        }
        return (!ionic.Platform.isIOS()
          ? inAppPurchase.restorePurchases()
          : inAppPurchase.getReceipt().then(function(receipt) {
              return {
                platform: 'iOS',
                receipt,
              };
            })
        )
          .then(function(restoredPurchases) {
            $rootScope.$broadcast('track-event', {
              category: 'InAppPurchases',
              action: 'Did restore purchases',
            });
            return Api.post('/purchases/restore', restoredPurchases)
              .success(function(user) {
                User.clear().set(user).storeLocally();
                return $q.resolve(user);
              });
          })
          .catch(function(error) {
            $rootScope.$broadcast('track-event', {
              category: 'InAppPurchases',
              action: 'Did fail to restore purchases',
              label: JSON.stringify(error),
            });
            console.error(error);
            $q.reject(error);
          });
      }

      function saveUserPurchase(purchase) {
        return Api.post('/purchases', purchase)
          .success(function(user) {
            User.clear().set(user).storeLocally();
            $rootScope.$broadcast(FlatasticEvents.user.didGetPremium, purchase)
            return purchase;
          })
          .catch(function(error) {
            console.error('Error while saving purchase to server', error);
            return $q.reject(error);
          });
      }

    }])

.name;
