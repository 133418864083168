// ['en', 'de']

export default {
  [`Finances`]: [
    `Finances`,
    `Finanzen`,
  ],
  [`Create New Expense`]: [
    `Create New Expense`,
    `Finanzeintrag erstellen`,
  ],
  [`Oh no!`]: [
    `Oh no!`,
    `Oh nein!`,
  ],
  [`Something went wrong.`]: [
    `Something went wrong.`,
    `Etwas ist schief gelaufen.`,
  ],
  [`Your expenses couldn't be fetched.`]: [
    `Your expenses couldn't be fetched.`,
    `Deine Einträge konnten nicht geladen werden.`,
  ],
  [`Retry`]: [
    `Retry`,
    `Erneut versuchen`,
  ],

  //// Legacy

  // Export Expense Entries
  [`EXP__EXPORT_CURRENT_EXPENSES`]: [
    `Export expenses`,
    `Finanzeinträge exportieren`,
  ],
  [`EXP__EXPORT_SUCCESS_DIALOG__TITLE`]: [
    `Export successful ✅`,
    `Export erfolgreich ✅`,
  ],
  [`EXP__EXPORT_SUCCESS_DIALOG__MESSAGE`]: [
    `Please choose what you want to do with it in the next step.`,
    `Im nächsten Schritt kannst du wählen, was du mit der Datei machen möchtest.`,
  ],
  [`EXP__EXPORT_SHARE_DIALOG__TITLE`]: [
    `Export of our expenses`,
    `Export unserer Finanzeinträge`,
  ],
  [`EXP__EXPORT_SHARE_DIALOG__MESSAGE`]: [
    `Attached you can find all our expenses as a .csv file.`,
    `Hier sind all unsere aktuellen Finanzeinträge als .csv-Datei.`,
  ],
  [`You haven't added any expenses yet. What about adding one below now?`]: [
    `You haven't added any expenses yet. What about adding one below now?`,
    `Es gibt noch keine Finanzeinträge. Sei der Erste, der einen erstellt.`,
  ],
  [`All Entries`]: [
    `All Entries`,
    `Alle Einträge`,
  ],
  [`Export`]: [
    `Export`,
    `Exportieren`,
  ],
}
