import { draggableFooter } from '../components'
import KeyboardAttachDirective from '../directives/keyboardAttach'
import ShoppinglistComponentQuickEdit from './components/shl-quick-edit'
import ShoppinglistComponentEmptyState from './components/shopping-empty-state'
import ShoppinglistComponentTagsExplanation from './components/tags-explanation'
import ShoppinglistListController from './shl.ctrl'
import './shoppinglist-create-list.scss'
import template from './shoppinglist.html'
import './shoppinglist.scss'


export default angular.module('ft.shoppinglist', [
  KeyboardAttachDirective,
  draggableFooter,
  ShoppinglistListController,
  ShoppinglistComponentEmptyState,
  ShoppinglistComponentTagsExplanation,
  ShoppinglistComponentQuickEdit,
])
.config(ShoppinglistConfig)
.name


ShoppinglistConfig.$inject = ['$stateProvider']
function ShoppinglistConfig($stateProvider) {
  $stateProvider
    .state('household.shoppinglist', {
      onExit: ['$ionicHistory', function($ionicHistory){
        $ionicHistory.nextViewOptions({ disableAnimate: true })
      }],
      url: '/shoppinglist',
      views: {
        'shopping-tab': {
          controller: 'ShoppinglistListController',
          controllerAs: 'vm',
          template,
        },
      },
    })
}
