// [en, de]

export default {
  [`People liked this`]: [
    `{{ likedBy }} liked this`,
    `{{ likedBy }} gefällt das`,
  ],
  [`You wrote`]: [
    `You wrote`,
    `Du schreibst`,
  ],
  [`Say thanks`]: [
    `Say thanks`,
    `Danke sagen`,
  ],
  [`Open`]: [
    `Open`,
    `Öffne`,
  ],
  [`Open module`]: [
    `Open module`,
    `Modul öffnen`,
  ],
  [`Delete entry?`]: [
    `Delete message?`,
    `Nachricht löschen?`,
  ],
  [`Chat:Message:Delete:Message`]: [
    `Do you really want to delete this message? The others will no longer see this message either.`,
    `Möchtest du diese Nachricht wirklich löschen? Auch deine Mitbewohner werden die Nachricht dann nicht mehr sehen.`,
  ],
  [`Delete`]: [
    `Delete`,
    `Löschen`,
  ],
  [`Like`]: [
    `Like`,
    `Gefällt mir`,
  ],
  [`Unlike`]: [
    `Unlike`,
    `Gefällt mir nicht mehr`,
  ],

  /// Legacy

}
