import FilterWgCurrency from '../../../../modules/general/filter_wg-currency'
import UserService from '../../../../modules/user/svc_user'
import WgService from '../../../../modules/wg/svc_wg'
import template from './template.html'

export default angular.module('ft.fincances.components.expense-entry', [
  WgService,
  UserService,
  FilterWgCurrency,
])
.component('expenseEntry', {
  template,
  bindings: {
    expense: '<',
  },
  controller,
})
.name


controller.$inject = ['User', 'Wg', '$state']
function controller(User, Wg, $state) {
  const $ctrl = this
  $ctrl.user = User.properties
  $ctrl.currency = Wg.currency
  $ctrl.isCreditor = isCreditor
  $ctrl.getItemsString = getItemsString
  $ctrl.creditor = Wg.flatmates._get($ctrl.expense.creatorId)

  function getItemsString(expense) {
    return expense.items.map(i => i.name).join(', ')
  }

  function isCreditor(expense) {
    return expense.creatorId == User.properties.id
  }
}
