import PremiumPaywall from '../modules/paywall/cfg_paywall'
import ToasterDirective from '../modules/toaster/directive_toaster'
import AppReview from './appReview'
import Chat from './chat'
import Chores from './chores'
import { InputFooter, PremiumBanner } from './components'
import PremiumListComponents from './components/premium-list'
import './directives/toaster.scss'
import Finances from './finances'
import GlobalController from './global.ctrl'
import Homeless from './homeless'
import LegacyRun from './legacy/index'
import WelcomeModalService from './modals/welcome-modal'
import Purchase from './purchase'
import Settings from './settings'
import ShoppingList from './shoppinglist'
import TabsController from './tabs.ctrl'
import tabsTemplate from './tabs.html'
import './tabs.scss'

export default angular.module('flatastic.tabs', [
  'ionic',
  LegacyRun,
  GlobalController,
  ShoppingList,
  Chores,
  Chat,
  Finances,
  Settings,
  Homeless,
  InputFooter,
  PremiumPaywall,
  PremiumBanner,
  Purchase,
  AppReview,
  TabsController,
  ToasterDirective,
  PremiumListComponents,
  WelcomeModalService,
])

.config(['$stateProvider', function($stateProvider) {

  $stateProvider
    .state('household', {
      url: '/household',
      views: {
        root: {
          abstract: true,
          template: tabsTemplate,
          controller: 'TabsController as vm',
        },
      },
    })
}])

.name
