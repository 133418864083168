import '@uirouter/angularjs'
import 'angular'
import 'angular-animate'
import AngularMoment from 'angular-moment'
import 'angular-sanitize'
import AngularTranslate from 'angular-translate'
import 'ionic-bower/js/ionic.js'
import 'ionic-bower/css/ionic.min.css'
import 'ionic-bower/js/ionic-angular.js'
import CustomURLHandler from '../modules/customUrlSchema_handler'
import OnReturnDirective from '../modules/general/directive_on-return'
import DirectiveOpenLink from '../modules/general/directive_open-link'
import Intro from '../modules/intro/intro.cfg'
import '../scripts/helpers'
import flatasticMonetization from '../scripts/modules/flatasticMonetization'
import SplashscreenModule from '../scripts/modules/flatasticSplashscreen'
import KeyboardModule from '../scripts/modules/yptKeyboard'
import FocusOnDirective from './directives/focusOn'
import BlurOnDirective from './directives/blurOn'
import OnBlurPreventingClick from './directives/on-blur-preventing-click'
import BottomDrawer from './directives/bottom-drawer'
import Initializer from './initializer'
import Tabs from './tabs.cfg'
import translations from './translations'
import ErrorLogging from '../scripts/modules/firebaseErrorLogging'
import ErrorReporting from '../scripts/modules/errorReporting'
import InAppNotificationComponent from '../modules/inAppNotification/inAppNotification.comp'
import PushNotificationsHandler from '../modules/pushnotification_handler'
import BadgeService from '../modules/general/svc_badge'
import DecimalNumberFix from '../scripts/modules/yptDecimalNumberFix'

const app = angular.module('flatastic.redesign', [
  'ionic',
  AngularMoment,
  AngularTranslate,
  KeyboardModule,
  FocusOnDirective,
  BlurOnDirective,
  OnBlurPreventingClick,
  SplashscreenModule,
  Tabs,
  Intro,
  OnReturnDirective,
  DirectiveOpenLink,
  Initializer,
  flatasticMonetization,
  CustomURLHandler,
  ErrorLogging,
  ErrorReporting,
  InAppNotificationComponent,
  PushNotificationsHandler,
  BadgeService,
  DecimalNumberFix,
  BottomDrawer,
])

.config(['$ionicConfigProvider', function($ionicConfigProvider) {
  $ionicConfigProvider.tabs.style('standard')
  $ionicConfigProvider.tabs.position('bottom')
}])

.config(['$urlRouterProvider',
    function ($urlRouterProvider) {

  $urlRouterProvider.otherwise('/');

}])

.config(['$translateProvider', function($translateProvider) {
  $translateProvider
    .registerAvailableLanguageKeys(['de', 'en'])
    .fallbackLanguage('en')
    .preferredLanguage('en')
    .translations('en', translations.en)
    .translations('de', translations.de)
}])

.run(['Splashscreen', 'yptCordova', function(Splashscreen, yptCordova){
  yptCordova.ready.then(Splashscreen.hide)
}])

.name

angular.bootstrap(document, [app])
