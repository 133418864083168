import angular from 'angular'
import StatusBarModule from '../../scripts/modules/statusbar'

export default angular.module('ft.runners.statusbar', [
  StatusBarModule,
])
.run(StatusBarRunner)
.name

StatusBarRunner.$inject = ['StatusBar']
function StatusBarRunner(StatusBar) {
  return Promise.all([
    StatusBar.backgroundColorByHexString('#ffffff'),
    StatusBar.setStyleDefault(),
  ]).catch(console.warn)
}
