export default {
  byId: {
    0: {
      id: '0',
      title: 'Frozen',
      translations: {
        de: 'TK',
        en: 'Frozen',
      },
    },
    1: {
      id: '1',
      title: 'Organic',
      translations: {
        de: 'Bio',
        en: 'Organic',
      },
    },
    2: {
      id: '2',
      title: 'Vegetarian',
      translations: {
        de: 'Vegetarisch',
        en: 'Vegetarian',
      },
    },
    3: {
      id: '3',
      title: 'Vegan',
      translations: {
        de: 'Vegan',
        en: 'Vegan',
      },
    },
    4: {
      id: '4',
      title: 'Lactose free',
      translations: {
        de: 'Laktosefrei',
        en: 'Lactose free',
      },
    },
    5: {
      id: '5',
      title: 'Gluten free',
      translations: {
        de: 'Glutenfrei',
        en: 'Gluten free',
      },
    },
    6: {
      id: '6',
      title: 'Light',
      translations: {
        de: 'Light',
        en: 'Light',
      },
    },
    7: {
      id: '7',
      title: '2 pcs',
      translations: {
        de: '2 Stk.',
        en: '2 pcs.',
      },
    },
    8: {
      id: '9',
      title: '100 g',
      translations: {
        de: '100 g',
        en: '100 g',
      },
    },
    9: {
      id: '9',
      title: '500 g',
      translations: {
        de: '500 g',
        en: '500 g',
      },
    },
    10: {
      id: '10',
      title: '1 kg',
      translations: {
        de: '1 kg',
        en: '1 kg',
      },
    },

  },
  i18n: {
    en: {
      allIds: [0, 1, 6, 2, 3, 4, 5, 7, 8, 9, 10],
    },
    de: {
      allIds: [0, 1, 6, 2, 3, 4, 5, 7, 8, 9, 10],
    },
  },
}
