import ResizableFooterContent from '../../directives/resizable-footer-content'
import './style.scss'
import template from './template.html'
export const draggableFooter = angular.module('ft.component.draggable-footer', [
  ResizableFooterContent,
])
.component('draggableFooter', {
  transclude: {
    body: 'draggableFooterBody',
    header: 'draggableFooterHeader',
    footer: 'draggableFooterFooter',
  },
  bindings: {
    onOverPullIn: '&',
    onOverPullOut: '&',
    onPullIn: '&',
    onPullOut: '&',
    disabled: '<',
    onContentScroll: '&',
    isPulledOut: '=',
  },
  template,
  controllerAs: 'vm',
  controller: function($scope, $element, $attrs) {
    const ionContent = angular.element($element.parent()[0].querySelector('ion-content'))
    ionContent.addClass('has-draggable-footer')

    const footerContent = angular.element($element[0].querySelector('.draggable-footer-content'))
    footerContent.on('scroll', onFooterContentScroll)

    if ($attrs.scrollAreaName) {
      $scope.$on(`${$attrs.scrollAreaName}.scrollTop`, function() {
        footerContent[0].scrollTop = 0
      })
    }

    let isFooterContentScrolled = false
    function onFooterContentScroll(e) {
      if ($scope.vm.onContentScroll) {
        $scope.$apply(() => $scope.vm.onContentScroll())
      }
      if (e.target.scrollTop > 0 && !isFooterContentScrolled){
        isFooterContentScrolled = true
        $element.addClass('footer-content-is-scrolled')
      }
      if (e.target.scrollTop <= 0 && isFooterContentScrolled) {
        $element.removeClass('footer-content-is-scrolled')
        isFooterContentScrolled = false
      }
    }

    $scope.$on('$destroy', () => {
      footerContent.off('scroll', onFooterContentScroll)
      ionContent.removeClass('has-draggable-footer')
    })
  },
})
.name
