export const HouseholdMembersControllerName = 'HouseholdMembersController'
export default angular.module('ft.settings.controllers.household-members', [

])
.controller(HouseholdMembersControllerName, HouseholdMembersController)
.name

function HouseholdMembersController() {

}
