export default angular.module('flatastic.expenses.filters.statistics', [
])

.filter('stats', [function() {
  return function(val, param) {
    var maxVal = val.reduce(function(pre, cur) {
      return (cur.balance > pre) ? cur.balance : pre;
    }, -Infinity);
    var minVal = val.reduce(function(pre, cur) {
      return (cur.balance < pre) ? cur.balance : pre;
    }, +Infinity);
    var greatest = Math.abs(maxVal) > Math.abs(minVal) ?
                      Math.abs(maxVal) : Math.abs(minVal);
    var output = val.map(function(elem) {
      elem.width = Math.round(49 * Math.abs(elem.balance) / greatest);
      return elem;
    });
    return output.sort(compare);
  };

  // we want great values at top therefor other way around
  function compare(a, b) {
    if (a.balance > b.balance) {
      return -1;
    } else if (a.balance < b.balance) {
      return 1;
    }
    return 0;
  }
}])

.filter('balanceSum', [function() {
  return function(val, param) {
    return val.reduce(function(pre, cur) {
      if (param < 0) {
        return pre + (cur.balance < 0 ? cur.balance : 0);
      } else {
        return pre + (cur.balance > 0 ? cur.balance : 0);
      }
    }, 0);
  };
}])

.name
