import yepptCordova from './yptCordova'

export default angular.module('flatastic.splashscreen', [
  yepptCordova,
])

.run(['Splashscreen', function(Splashscreen) {
  // After cordova is ready the splashscreen will hide
  Splashscreen.hide()
}])

.factory('Splashscreen', ['$window', '$q', 'yptCordova', function($window, $q, yptCordova) {

  return {
    hide,
    show,
  }

  //// Functions

  function getPlugin() {
    return yptCordova.ready.then(function() {
      if (!$window.navigator || !$window.navigator.splashscreen) {
        return $q.reject('Plugin is not installed')
      }
      return $q.resolve($window.navigator.splashscreen);
    });
  }

  function hide() {
    return getPlugin()
      .then(function(splashscreen) {
        return splashscreen.hide();
      });
  }

  function show() {
    return getPlugin()
      .then(function(splashscreen) {
        return splashscreen.show();
      });
  }

}])

.name;
