import { mapObjIndexed } from 'ramda'

const meta = ['en', 'de']
const trans = {
  [`Step 3 of 3`]: [
    `Step 3 of 3`,
    `Schritt 3 von 3`,
  ],
  [`Where is your home located?`]: [
    `Where is "{{ householdName }}" located?`,
    `Wo liegt «{{ householdName }}»?`,
  ],
  [`We customize the Flatastic experience based on the location of your home.`]: [
    `Flatastic will be customized based on the location of your home, e.g. holiday alerts or the currency.`,
    `Funktionen von Flatastic werden an den Wohnort angepasst, wie z.B. der Feiertagsalarm oder die Währung.`,
  ],
  [`Postal Code`]: [
    `Postal Code`,
    `Postleitzahl`,
  ],
  [`e.g. 1234`]: [
    `e.g. 1234`,
    `z.B. 12345`,
  ],
  [`City`]: [
    `City`,
    `Stadt`,
  ],
  [`e.g. Flatastic Town`]: [
    `e.g. Flatastic Town`,
    `z.B. Flatastic Stadt`,
  ],
  [`Country`]: [
    `Country`,
    `Land`,
  ],
  [`Finish`]: [
    `Finish`,
    `Speichern & Mitbewohner einladen`,
  ],
  [`Skip`]: [
    `Skip`,
    `Überspringen`,
  ],

  //// Legacy
  [`WG__PLEASE_CHOOSE`]: [
    `Please choose`,
    `Bitte wählen`,
  ],
}

export default {
  en: mapObjIndexed((val, key, obj) => val[0])(trans),
  de: mapObjIndexed((val, key, obj) => val[1])(trans),
}
