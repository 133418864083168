import AngularTranslate from 'angular-translate'
import FlatatsicConfig from '../../scripts/config'
import ActionsheetService from '../../scripts/modules/yptActionsheet'
import CordovaService from '../../scripts/modules/yptCordova'
import LanguageService from '../../scripts/modules/yptLanguage'
import SocialSharingService from '../../scripts/modules/yptSocialsharing'
import UserService from '../user/svc_user'

export const SettingsControllerName = 'SettingsCtrl'

export default angular.module('flatastic.settings.controller', [
  AngularTranslate,
  CordovaService,
  SocialSharingService,
  ActionsheetService,
  LanguageService,
  FlatatsicConfig,
  UserService,
])

.controller(SettingsControllerName,
    ['$scope', 'yptNotify', 'yptSocialsharing', 'yptActionsheet', 'yptCordova',
    'Config', 'User', 'yptLanguage', '$window',
    '$rootScope', 'Wg', '$translate',
    function ($scope, yptNotify, yptSocialsharing, yptActionsheet, yptCordova,
    Config, User, yptLanguage, $window,
    $rootScope, Wg, $translate) {

  $scope.versionNumber = Config.versionNumber;

  $scope.showHelp = function() {
    yptNotify.alert({
      title: $translate.instant('SET__HELP-TITLE'),
      message: $translate.instant('SET__HELP-MSG'),
      trackEvent: {
        name: 'showHelp',
        parameters: {
          context: 'settings',
        },
      },
    });
    $rootScope.$broadcast('track-event', {
      category: 'Help',
      action: 'Clicked',
      label: '/settings',
    });
  };

  $scope.isMobileApp = ionic.Platform.isIOS() || ionic.Platform.isAndroid();

  $scope.Wg = Wg
  $scope.User = User;
  $scope.languages = [{
    label: 'English',
    value: 1,
  }, {
    label: 'Deutsch',
    value: 2,
  }, {
    label: 'Schwiizerdütsch',
    value: 3,
  }, {
    label: 'Österreichisch',
    value: 4,
  }];
  $scope.setLanguage = function() {
    User.update({ language: User.properties.language })
      .then(() => $window.location.reload())
    var languages = ['en', 'de', 'de', 'de'];
    var chosenLanguage = languages[User.properties.language - 1];
    $window.localStorage['Flatastic.userLanguage'] = chosenLanguage;
    yptLanguage.set(chosenLanguage);
  };

  $scope.share = function() {
    yptSocialsharing.share({
      subject: $translate.instant('SET__SHARE-TITLE'),
      message: $translate.instant('SET__SHARE-MSG'),
      url: 'https://flatastic-app.com/app',
      trackEvent: {
        event: 'Share',
        context: 'settings',
        content: $translate.instant('SET__SHARE-TITLE'),
      },
    });
  };

  $scope.showLawStuff = function() {
    var options = {
      title: $translate.instant('SET__LAW_STUFF-TITLE'),
      buttonLabels: [
        $translate.instant('SET__LAW_STUFF-BTN_AGB'),
        $translate.instant('SET__LAW_STUFF-BTN_PRIVACY'),
      ],
      'androidEnableCancelButton': false,
      'addCancelButtonWithLabel': $translate.instant('BTN_CANCEL'),
    };
    var actions = [
      function() {
        yptCordova.openLink('https://flatastic-app.com/impressum?utm_source=flatastic&utm_medium=app&utm_campaign=settings');
      },
      function() {
        yptCordova.openLink('https://flatastic-app.com/privacy?utm_source=flatastic&utm_medium=app&utm_campaign=settings');
      },
    ];
    yptActionsheet.show(options, actions);
  };

}])

.name;
