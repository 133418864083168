import FlatasticConfig from '../../scripts/config'
import ApiService from '../../scripts/modules/api'
import CordovaService from '../../scripts/modules/yptCordova'

export default angular.module('flatastic.feedback.service', [
  ApiService,
  FlatasticConfig,
  CordovaService,
])

.factory('Feedback',
    ['Api', 'device', 'Config',
    function (Api, device, Config) {

  var Feedback = {};
  Feedback.submit = function(message) {
    Feedback.status = 'sending';
    console.log(message);
    return Api.post('/feedback', {
      message: message,
      device: window.device && device.info || undefined,
      version: Config.versionNumber,
    })
    .success(function(data) {
      delete Feedback.status;
    })
    .error(function(data) {
      delete Feedback.status;
    });
  };
  // Public API here
  return Feedback;
}])

.name;
