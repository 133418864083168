import StackTrace from 'stacktrace-js'
import FirebasePlugin from './flatasticFirebase'

/*
 * Source: https://gsmplusinfotech.com/blog/angularjs-front-end-error-logging-with-google-analytics/
 *
 * Needs: Report uncaught errors via Google Analytics
 *
 */

export default angular.module('flatastic.errorReporting', [
  FirebasePlugin,
])

.config(
    ['$provide',
    function($provide) {

  $provide.decorator('$exceptionHandler', function($delegate, $injector){
    return function(exception, cause) {
      let stringifiedException
      let $rootScope = $injector.get('$rootScope');
      let Firebase = $injector.get('Firebase');
      $delegate(exception, cause);
      if (exception.toString() === '[object Object]') {
        stringifiedException = JSON.stringify(exception);
      } else {
        stringifiedException = exception.toString();
      }

      $rootScope.$broadcast('track-event', {
        category: 'Uncaught Error',
        action: stringifiedException || '',
        label: cause || '',
      });

      if (!(exception instanceof Error)) {
        return
      }

      return StackTrace.fromError(exception)
        .then(stackTrace => Firebase.logError(stringifiedException, stackTrace))
    };
  });
}])

.name;
