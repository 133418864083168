// ['en', 'de']

export default {
  [`You don't have any subscriptions yet. We added some ideas below to get you started.`]: [
    `You don't have any subscriptions yet. We added some ideas below to get you started.`,
    `Es wurden noch keine Abos & Verträge hinzugefügt. Unten findest du ein paar Ideen zum Starten.`,
  ],
  [`Ongoing Contracts`]: [
    `Ongoing Contracts`,
    `Laufende Verträge`,
  ],
  [`Suggestions`]: [
    `Suggestions`,
    `Vorschläge`,
  ],
  [`Hide`]: [
    `Hide`,
    `Ausblenden`,
  ],
  [`Average Expenses`]: [
    `Average Expenses`,
    `Durchschnittliche Ausgaben`,
  ],
  [`New Subscription`]: [
    `New Subscription`,
    `Neuer Eintrag`,
  ],
  [`Edit Subscription`]: [
    `Edit Subscription`,
    `Eintrag bearbeiten`,
  ],
  [`Add new Subscription`]: [
    `Add new Subscription`,
    `Neuen Eintrag hinzufügen`,
  ],
  [`Monthly`]: [
    `Monthly`,
    `Monatlich`,
  ],

  //// Legacy
  [`TITLE`]: [
    `Name`,
    `Name`,
  ],
  [`SUB__FIRST_BILL`]: [
    `First Bill`,
    `Erste Rechnung`,
  ],
  [`SUB__CYCLE`]: [
    `Cycle`,
    `Periode`,
  ],
  [`SUB__DESCRIPTION`]: [
    `Details`,
    `Details`,
  ],
  [`SUB__TITLE`]: [
    `Subscriptions`,
    `Verträge und Abos`,
  ],
  [`PRICE`]: [
    `Price`,
    `Kosten`,
  ],
  [`ADD`]: [
    `Add`,
    `Hinzufügen`,
  ],
  [`CANCEL`]: [
    `Cancel`,
    `Abbrechen`,
  ],
}
