import AngularTranslate from 'angular-translate'
import FlatasticConfig from '../../scripts/config'
import PushNotificationsModule from '../../scripts/modules/pushnotification'

export default angular.module('flatastic.intro.notifications.controller', [
  'ionic',
  AngularTranslate,
  FlatasticConfig,
  PushNotificationsModule,
])
.controller('NotificationsController', NotificationsController)
.name;


NotificationsController.$inject = [
  '$state',
  '$timeout',
  'PushNotification',
  '$ionicHistory',
  '$translate',
]
function NotificationsController($state, $timeout, PushNotification, $ionicHistory, $translate) {

  const vm = this;

  vm.enablePushNotifications = enablePushNotifications
  vm.goToMainApp = goToMainApp
  vm.getLanguage = function() { return $translate.proposedLanguage() }

  //// Functions

  function enablePushNotifications() {
    PushNotification.getDeviceTokenAndTellBackend()
      .catch(error => {
        console.error('Error when enabling Push Notifications', error)
      })
      .then(goToMainApp)

  }

  function goToMainApp() {
    return $state.go('household.chat.messages')
      .then(() => $timeout(750))
      .then($ionicHistory.clearHistory)
  }

}
