import FirebaseModule from '../../scripts/modules/flatasticFirebase'
import WelcomeModalModule from '../../tabs/modals/welcome-modal/index'
import LoginController from '../auth/ctrl_login'
import { showAccessoryBar } from '../keyboardController'
import HouseholdLocationTemplate from './household-location.html'
import HouseholdMembersTemplate from './household-members.html'
import HouseholdNameTemplate from './household-name.html'
import HouseholdPhotoController from './household-photo.ctrl'
import HouseholdPhotoTemplate from './household-photo.html'
import HouseholdTypeTemplate from './household-type.html'
import HouseholdController from './household.ctrl'
import IntroController from './intro.ctrl'
import IntroTemplate from './intro.html'
import './intro.scss'
import LoginTemplate from './login.html'
import NotificationsController from './notifications.ctrl'
import NotificationsTemplate from './notifications.html'
import StartTemplate from './start.html'
import UserAvatarController from './user-avatar.ctrl'
import UserAvatarTemplate from './user-avatar.html'
import UserNameController from './user-name.ctrl'
import UserNameTemplate from './user-name.html'
import SignUpController from './user-signup.ctrl'
import SignupTemplate from './user-signup.html'

export default angular.module('flatastic.intro', [
  'ionic',
  IntroController,
  LoginController,
  SignUpController,
  UserNameController,
  UserAvatarController,
  HouseholdController,
  HouseholdPhotoController,
  NotificationsController,
  FirebaseModule,
  WelcomeModalModule,
])

.config(['$stateProvider', function($stateProvider) {
  $stateProvider
    .state('intro', {
      url: '/intro',
      views: {
        root: {
          controller: 'IntroController',
          controllerAs: 'vm',
          template: IntroTemplate,
        },
      },
    })
    .state('intro-login', {
      url: '/intro-login',
      views: {
        root: {
          controller: 'LoginCtrl',
          template: LoginTemplate,
        },
      },
    })
    .state('intro-create-account', {
      url: '/intro-create-account?code',
      views: {
        root: {
          controller: 'SignUpController',
          controllerAs: 'vm',
          template: SignupTemplate,
        },
      },
      params: {
        code: undefined,
      },
    })
    .state('intro-user-name', {
      url: '/intro-user-name',
      views: {
        root: {
          controller: 'UserNameController',
          controllerAs: 'vm',
          template: UserNameTemplate,
        },
      },
    })
    .state('intro-avatar', {
      url: '/intro-avatar',
      views: {
        root: {
          controller: 'UserAvatarController',
          controllerAs: 'vm',
          template: UserAvatarTemplate,
        },
      },
    })
    .state('intro-household-type', {
      url: '/intro-household-type',
      views: {
        root: {
          controller: 'HouseholdController',
          controllerAs: 'vm',
          template: HouseholdTypeTemplate,
        },
      },
    })

    .state('intro-household-name', {
      url: '/intro-household-name',
      views: {
        root: {
          controller: 'HouseholdController',
          controllerAs: 'vm',
          template: HouseholdNameTemplate,
        },
      },
    })
    .state('intro-household-photo', {
      url: '/intro-household-photo',
      views: {
        root: {
          controller: 'HouseholdPhotoController',
          controllerAs: 'vm',
          template: HouseholdPhotoTemplate,
        },
      },
    })
    .state('intro-household-location', {
      url: '/intro-household-location',
      views: {
        root: {
          controller: 'HouseholdController',
          controllerAs: 'vm',
          template: HouseholdLocationTemplate,
        },
      },
      onEnter: showAccessoryBar,
    })
    .state('intro-household-members', {
      url: '/intro-household-members',
      views: {
        root: {
          controller: 'HouseholdController',
          controllerAs: 'vm',
          template: HouseholdMembersTemplate,
        },
      },
    })
    .state('intro-notifications', {
      url: '/intro-notifications',
      views: {
        root: {
          controller: 'NotificationsController',
          controllerAs: 'vm',
          template: NotificationsTemplate,
        },
      },
      onEnter: onEnterNotifications,
      onExit: onExitNotifications,
    })
    .state('intro-start', {
      url: '/intro-start',
      views: {
        root: {
          controller: 'IntroController',
          controllerAs: 'vm',
          template: StartTemplate,
        },
      },
    })

}])

.name;

onExitNotifications.$inject = ['WelcomeModal']
function onExitNotifications(WelcomeModal) {
  return WelcomeModal.showWelcomeModal()
}

onEnterNotifications.$inject = ['Firebase', '$state', '$timeout', '$ionicHistory']
function onEnterNotifications(Firebase, $state, $timeout, $ionicHistory) {
  $timeout().then($ionicHistory.clearHistory)
  Firebase.hasPermission()
    .then(hasPermission => {
      // Skip screen when user has already given permissions
      if (hasPermission) {
        return enterHousehold()
      }
    })
    .catch((error) => {
      console.warn(`onEnterNotifications`, error)
      return enterHousehold()
    })

    function enterHousehold() {
      return $state.go('household.chat.messages')
        .then(() => $timeout(750))
        .then(() => $ionicHistory.clearHistory())
    }
}
