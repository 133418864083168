import angular from 'angular'
import SettlementEntriesService from '../../modules/expenses/ctrl_settlementEntries'
import UserService from '../../modules/user/svc_user'
import WgService from '../../modules/wg/svc_wg'
import CordovaModule from '../../scripts/modules/yptCordova'

export default angular.module('ft.finances.controllers.settlement-details', [
  UserService,
  WgService,
  CordovaModule,
  SettlementEntriesService,
])
.controller('SettlementDetailsController', SettlementDetailsController)
.name


SettlementDetailsController.$inject = ['Wg', '$scope', 'SettlementEntries', '$stateParams', '$translate', 'yptNotify', 'User']
export function SettlementDetailsController(Wg, $scope, SettlementEntries, $stateParams, $translate, yptNotify, User) {
  console.log($stateParams, SettlementEntries)
  const vm = this
  vm.currency = Wg.currency
  vm.settlement = $stateParams.settlement
  vm.refresh  = refresh
  vm.loadMore = loadMore;
  vm.moreCanBeLoaded = SettlementEntries.moreCanBeLoaded;
  vm.expenseEntries = []
  vm.triggerer = Wg.flatmates._get($stateParams.settlement.triggeredBy)
  vm.isLoading = isLoading


  init()

  function init() {
    fetchData()
  }

  function isLoading() {
    return SettlementEntries.get().status;
  }

  function fetchData() {
    return SettlementEntries
      .fetch($stateParams)
      .then(updateData)
  }

  function refresh() {
    $scope.hideLoadingIndicator = true;
    return fetchData()
      .then(function() {
        $scope.$broadcast('scroll.refreshComplete');
        $scope.hideLoadingIndicator = false;
      });
  }

  function updateData() {
    vm.expenseEntries = SettlementEntries.get().data;
  }

  function loadMore() {
    $scope.hideLoadingIndicator = true;
    return SettlementEntries
      .loadMore($stateParams)
      .success(updateData)
      .finally(function() {
        $scope.$broadcast('scroll.infiniteScrollComplete');
        $scope.hideLoadingIndicator = false;
      });
  }
}
