import ChoresService from '../modules/chores/svc_chores'
import ShoppinglistService from '../modules/shoppinglist/svc_shoppinglist'
import UserService from '../modules/user/svc_user'
import FirebasePlugin from '../scripts/modules/flatasticFirebase'

export default angular.module('TabsController', [
  ShoppinglistService,
  ChoresService,
  UserService,
  FirebasePlugin,
])
  .controller('TabsController', TabsController)
  .name


TabsController.$inject = ['Shoppinglist', 'Chores', 'User', 'Firebase']
function TabsController(Shoppinglist, Chores, User, Firebase) {
  const vm = this
  vm.User = User
  vm.getBadge = getBadge
  vm.trackEvent = trackEvent

  function trackEvent({ name, params }) {
    Firebase.logEvent(name, params)
  }

  function getBadge(module) {
    let val = ({
      'Shoppinglist': Shoppinglist.numItems(0),
      'Expenses': 0,
      'Shouts': 0,
      'Chores': Chores.numToDo(User.properties.id),
    })[module];
    return val
  };
}
