/**
 * Wrapper for https://github.com/appfeel/admob-google-cordova
 */
export default angular.module('mcm.admob', [
  'ionic',
])

.constant('AdMobPublisherIds', {
  ios: {
    banner: 'ca-app-pub-1576024766679504/3902385472',
    interstitial: 'ca-app-pub-4916967444564921/5187661896',
  },
  android: {
    banner: 'ca-app-pub-1576024766679504/5518719474',
    interstitial: 'ca-app-pub-4916967444564921/5327262695',
  },
})

.factory('AdMob',
    ['$q', '$ionicPlatform', 'AdMobPublisherIds', '$document', '$window',
    function($q, $ionicPlatform, AdMobPublisherIds, $document, $window) {

  let plugin;
  let interstitial

  init();

  return {
    init,
    getInterstitial,
    showInterstitialAd,
  };


  //// Functions

  function getAdUnitId(type) {
    const adMobId = ionic.Platform.isAndroid()
      ? AdMobPublisherIds.android
      : AdMobPublisherIds.ios;
    return adMobId[type]
  }

  function init() {
    console.debug('Initializing AdMob');

    return plugin = $ionicPlatform.ready()
      .then(function() {
        if (!$window.admob) {
          console.debug('AdMob plugin is not installed.');
          return $q.reject('AdMob plugin is not installed.');
        }

        interstitial = new admob.InterstitialAd({
          adUnitId: getAdUnitId('interstitial'),
          npa: ionic.Platform.isIOS() ? '1' : undefined, // Enables non personalized ads for iOS
        })

        return admob.start()
      })
      .then((pluginInfo) => {
        console.debug(`Initialized AdMob`, pluginInfo)
        $document[0].addEventListener(admob.Events.interstitialDismiss, () => interstitial.load())
      })
  }

  function getInterstitial() {
    return plugin
      .then(() => interstitial.load())
      .catch(function(error) {
        console.error('Error while getting interstitial', error);
        return $q.reject(error);
      });
  }

  function showInterstitialAd() {
    return plugin.then(interstitial.show());
  }

}])

.name;
