// [en, de]

export default {
  [`Leaderboard`]: [
    `Leaderboard`,
    `Rangliste`,
  ],
  //  Legacy
  [`Reset Statistics`]: [
    `Reset Statistics`,
    `Statistik zurücksetzen`,
  ],
  [`CHO__STATISTICS_RESET_BTN`]: [
    `Reset Statistics`,
    `Statistik zurücksetzen`,
  ],
  [`CHO__STATISTICS_RESET_CONFIRM-TITLE`]: [
    `Reset Statistics`,
    `Statistik zurücksetzen`,
  ],
  [`CHO__STATISTICS_RESET_CONFIRM-MSG`]: [
    `Do you really want to reset the statistics? This action can't be undone.`,
    `Möchtest du die Statistik wirklich zurücksetzen? Diese Aktion kann nicht rückgängig gemacht werden.`,
  ],
  [`CHO__STATISTICS_RESET_SUCCESS-TITLE`]: [
    `Reset Statistics`,
    `Statistik zurücksetzen`,
  ],
  [`CHO__STATISTICS_RESET_SUCCESS-MSG`]: [
    `Statistics have been reset.`,
    `Die Putzplan-Statistik wurde zurückgesetzt.`,
  ],
  [`CHO__STATISTICS_RESET_ERROR-TITLE`]: [
    `Reset Statistics`,
    `Statistik zurücksetzen`,
  ],
  [`CHO__STATISTICS_RESET_ERROR-MSG`]: [
    `Unfortunatelly an error occured:\n{{ error }}`,
    `Leider gab es einen Fehler:\n{{ error }}`,
  ],
}
