import AppReviewsTranslations from './app-reviews.trans'
import PremiumAdvantagesTranslations from './premium-advantages.trans'
import PremiumFeedbackTranslations from './premium-feedback.trans'
import PurchaseTranslations from './purchase.trans'

export default {
  ...PurchaseTranslations,
  ...AppReviewsTranslations,
  ...PremiumAdvantagesTranslations,
  ...PremiumFeedbackTranslations,
}
