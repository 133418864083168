import { propEq } from 'ramda'
import flatasticUserService from '../user/svc_user'
import flatasticWgService from '../wg/svc_wg'
import flatasticSettlementEntriesService from './svc_settlementEntries'

export default angular.module('flatastic.settlementEntries.details.controller', [
   flatasticSettlementEntriesService,
   flatasticWgService,
   flatasticUserService,
])

.controller('SettlementEntriesDetailsCtrl',
    ['$scope', 'SettlementEntries', 'User', '$stateParams', 'Wg',
    function ($scope, SettlementEntries, User, $stateParams, Wg) {

  const expenseId = Number($stateParams.expenseId)
  $scope.user = User.properties;
  $scope.flatmates = Wg.flatmates;
  $scope.expense = {
    ...SettlementEntries.get().data.find(propEq('id', expenseId)),
    isArchived: true,
  }
}])

.name;
