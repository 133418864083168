export default [
  {
    name: 'Germany',
    currency: 'EUR',
    flag: '🇩🇪',
    alpha3: 'DEU',
  }, {
    name: 'Switzerland',
    currency: 'CHF',
    flag: '🇨🇭',
    alpha3: 'CHE',
  }, {
    name: 'Austria',
    currency: 'EUR',
    flag: '🇦🇹',
    alpha3: 'AUT',
  }, {
    name: 'United Kingdom',
    currency: 'GBP',
    flag: '🇬🇧',
    alpha3: 'GBR',
  }, {
    name: 'America',
    currency: 'USD',
    flag: '🇺🇸',
    alpha3: 'USA',
  }, {
    name: 'Australia',
    currency: 'AUD',
    flag: '🇦🇺',
    alpha3: 'AUS',
  }, {
    name: 'France',
    currency: 'EUR',
    flag: '🇫🇷',
    alpha3: 'FRA',
  }, {
    name: 'Netherlands',
    currency: 'EUR',
    flag: '🇳🇱',
    alpha3: 'NLD',
  }, {
    name: 'Belgium',
    currency: 'EUR',
    flag: '🇧🇪',
    alpha3: 'BEL',
  }, {
    name: 'Brazil',
    currency: 'BRL',
    flag: '🇧🇷',
    alpha3: 'BRA',
  }, {
    name: 'Portugal',
    currency: 'EUR',
    flag: '🇵🇹',
    alpha3: 'PRT',
  }, {
    name: 'Italy',
    currency: 'EUR',
    flag: '🇮🇹',
    alpha3: 'ITA',
  }, {
    name: 'Spain',
    currency: 'EUR',
    flag: '🇪🇸',
    alpha3: 'ESP',
  }, {
    name: 'Hungary',
    currency: 'HUF',
    flag: '🇭🇺',
    alpha3: 'HUN',
  }, {
    name: 'Canada',
    currency: 'CAD',
    flag: '🇨🇦',
    alpha3: 'CAN',
  }, {
    name: 'Norway',
    currency: 'NOK',
    flag: '🇳🇴',
    alpha3: 'NOR',
  }, {
    name: 'Sweden',
    currency: 'SEK',
    flag: '🇸🇪',
    alpha3: 'SWE',
  }, {
    name: 'Ireland',
    currency: 'EUR',
    flag: '🇮🇪',
    alpha3: 'IRL',
  }, {
    name: 'Turkey',
    currency: 'TRY',
    flag: '🇹🇷',
    alpha3: 'TUR',
  }, {
    name: 'Mexico',
    currency: 'MXN',
    flag: '🇲🇽',
    alpha3: 'MEX',
  }, {
    name: 'China',
    currency: 'CNY',
    flag: '🇨🇳',
    alpha3: 'CHN',
  }, {
    name: 'India',
    currency: 'INR',
    flag: '🇮🇳',
    alpha3: 'IND',
  }, {
    name: 'Japan',
    currency: 'JPY',
    flag: '🇯🇵',
    alpha3: 'JPN',
  }, {
    name: 'Russia',
    currency: 'RUB',
    flag: '🇷🇺',
    alpha3: 'RUS',
  }, {
    name: 'Bulgaria',
    currency: 'BGN',
    flag: '🇧🇬',
    alpha3: 'BGR',
  }, {
    name: 'Greece',
    currency: 'EUR',
    flag: '🇬🇷',
    alpha3: 'GRC',
  }, {
    name: 'Finland',
    currency: 'EUR',
    flag: '🇫🇮',
    alpha3: 'FIN',
  }, {
    name: 'Argentinia',
    currency: 'ARS',
    flag: '🇦🇷',
    alpha3: 'ARG',
  }, {
    name: 'Denmark',
    currency: 'DKK',
    flag: '🇩🇰',
    alpha3: 'DNK',
  }, {
    name: 'Poland',
    currency: 'PLN',
    flag: '🇵🇱',
    alpha3: 'POL',
  }, {
    name: 'New Zealand',
    currency: 'NZD',
    flag: '🇳🇿',
    alpha3: 'NZL',
  },
];
