import QuickEditTranslations from './components/shl-quick-edit/translations'
import EmptyStateTranslations from './components/shopping-empty-state/translations'
import TagsExplanation from './components/tags-explanation/translations'
import { translations as CategoryTranslations } from './data/categories'
import ShoppingListTranslations from './shoppinglist.trans'

export default {
  ...ShoppingListTranslations,
  ...EmptyStateTranslations,
  ...TagsExplanation,
  ...CategoryTranslations,
  ...QuickEditTranslations,
}
