import { propOr } from 'ramda'
import IconCategoryBeverageActive from '../assets/icon-category-beverage-active.svg'
import IconCategoryBeverage from '../assets/icon-category-beverage.svg'
import IconCategoryBreadActive from '../assets/icon-category-bread-active.svg'
import IconCategoryBread from '../assets/icon-category-bread.svg'
import IconCategoryCustomActive from '../assets/icon-category-custom-active.svg'
import IconCategoryCustom from '../assets/icon-category-custom.svg'
import IconCategoryDiaryActive from '../assets/icon-category-diary-active.svg'
import IconCategoryDiary from '../assets/icon-category-diary.svg'
import IconCategoryFrozenActive from '../assets/icon-category-frozen-active.svg'
import IconCategoryFrozen from '../assets/icon-category-frozen.svg'
import IconCategoryFruitsActive from '../assets/icon-category-fruits-active.svg'
import IconCategoryFruits from '../assets/icon-category-fruits.svg'
import IconCategoryGrainsActive from '../assets/icon-category-grains-active.svg'
import IconCategoryGrains from '../assets/icon-category-grains.svg'
import IconCategoryHealthAndCareActive from '../assets/icon-category-health-and-care-active.svg'
import IconCategoryHealthAndCare from '../assets/icon-category-health-and-care.svg'
import IconCategoryHomeAndGardenActive from '../assets/icon-category-home-and-garden-active.svg'
import IconCategoryHomeAndGarden from '../assets/icon-category-home-and-garden.svg'
import IconCategoryHouseholdActive from '../assets/icon-category-household-active.svg'
import IconCategoryHousehold from '../assets/icon-category-household.svg'
import IconCategoryMeatActive from '../assets/icon-category-meat-active.svg'
import IconCategoryMeat from '../assets/icon-category-meat.svg'
import IconCategoryPetActive from '../assets/icon-category-pet-active.svg'
import IconCategoryPet from '../assets/icon-category-pet.svg'
import IconCategorySpicesActive from '../assets/icon-category-spices-active.svg'
import IconCategorySpices from '../assets/icon-category-spices.svg'
import IconCategorySweetsActive from '../assets/icon-category-sweets-active.svg'
import IconCategorySweets from '../assets/icon-category-sweets.svg'

const defaultCategory = {
  id: null,
  name: `Uncategorized`,
  icon: IconCategoryCustom,
  iconActive: IconCategoryCustomActive,
}

const categories = {
  null: defaultCategory,
  1: {
    id: 1,
    name: `Fruits & Vegetables`,
    icon: IconCategoryFruits,
    iconActive: IconCategoryFruitsActive,
  },
  2: {
    id: 2,
    name: `Bread & Pastries`,
    icon: IconCategoryBread,
    iconActive: IconCategoryBreadActive,
  },
  3: {
    id: 3,
    name: `Milk & Cheese`,
    icon: IconCategoryDiary,
    iconActive: IconCategoryDiaryActive,
  },
  4: {
    id: 4,
    name: `Meat & Fish`,
    icon: IconCategoryMeat,
    iconActive: IconCategoryMeatActive,
  },
  5: {
    id: 5,
    name: `Ingredients & Spices`,
    icon: IconCategorySpices,
    iconActive: IconCategorySpicesActive,
  },
  6: {
    id: 6,
    name: `Frozen & Convenience`,
    icon: IconCategoryFrozen,
    iconActive: IconCategoryFrozenActive,
  },
  7: {
    id: 7,
    name: `Grain Products`,
    icon: IconCategoryGrains,
    iconActive: IconCategoryGrainsActive,
  },
  8: {
    id: 8,
    name: `Snacks & Sweets`,
    icon: IconCategorySweets,
    iconActive: IconCategorySweetsActive,
  },
  9: {
    id: 9,
    name: `Beverage`,
    icon: IconCategoryBeverage,
    iconActive: IconCategoryBeverageActive,
  },
  10: {
    id: 10,
    name: `Household`,
    icon: IconCategoryHousehold,
    iconActive: IconCategoryHouseholdActive,
  },
  11: {
    id: 11,
    name: `Care & Health`,
    icon: IconCategoryHealthAndCare,
    iconActive: IconCategoryHealthAndCareActive,
  },
  12: {
    id: 12,
    name: `Pet Supplies`,
    icon: IconCategoryPet,
    iconActive: IconCategoryPetActive,
  },
  13: {
    id: 13,
    name: `Home & Garden`,
    icon: IconCategoryHomeAndGarden,
    iconActive: IconCategoryHomeAndGardenActive,
  },
}

export default categories


export function getCategoryById(categoryId) {
  return propOr({
    defaultCategory,
  }, categoryId, categories)
}

export const translations = {
  [`Fruits & Vegetables`]: [
    `Fruits & Vegetables`,
    `Obst & Gemüse`,
  ],
  [`Bread & Pastries`]: [
    `Bread & Pastries`,
    `Brot & Gebäck`,
  ],
  [`Milk & Cheese`]: [
    `Milk & Cheese`,
    `Milch & Käse`,
  ],
  [`Meat & Fish`]: [
    `Meat & Fish`,
    `Fleisch & Fisch`,
  ],
  [`Ingredients & Spices`]: [
    `Ingredients & Spices`,
    `Zutaten & Gewürze`,
  ],
  [`Frozen & Convenience`]: [
    `Frozen & Convenience`,
    `Fertig- & Tiefkühlprodukte`,
  ],
  [`Grain Products`]: [
    `Grain Products`,
    `Getreideprodukte`,
  ],
  [`Snacks & Sweets`]: [
    `Snacks & Sweets`,
    `Snacks & Süßwaren`,
  ],
  [`Beverage`]: [
    `Beverage`,
    `Getränke`,
  ],
  [`Household`]: [
    `Household`,
    `Haushalt`,
  ],
  [`Care & Health`]: [
    `Care & Health`,
    `Pflege & Gesundheit`,
  ],
  [`Pet Supplies`]: [
    `Pet Supplies`,
    `Tierbedarf`,
  ],
  [`Home & Garden`]: [
    `Home & Garden`,
    `Baumarkt & Garten`,
  ],
  [`Uncategorized`]: [
    `Uncategorized`,
    `Unkategorisiert`,
  ],
}
