import FilterWgCurrency from '../../../../modules/general/filter_wg-currency'
import UserService from '../../../../modules/user/svc_user'
import WgService from '../../../../modules/wg/svc_wg'
import template from './template.html'

export default angular.module('ft.fincances.components.settlement-entry', [
  WgService,
  UserService,
  FilterWgCurrency,
])
.component('settlementEntry', {
  template,
  bindings: {
    settlement: '<',
  },
  controller,
})
.name


controller.$inject = ['User', 'Wg', '$state']
function controller(User, Wg, $state) {
  const $ctrl = this
  $ctrl.user = User.properties
  $ctrl.currency = Wg.currency
  $ctrl.myShare = $ctrl.settlement.balance[User.properties.id]
  $ctrl.triggerer = Wg.flatmates._get($ctrl.settlement.triggeredBy)
}
