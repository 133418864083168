export default {
  [`Person paid currencyAmount`]: [
    `{{ name }} paid {{ currencyAmount }}`,
    `{{ name }} hat {{ currencyAmount }} gezahlt`,
  ],
  [`I paid currencyAmount`]: [
    `I paid {{ currencyAmount }}`,
    `Ich habe {{ currencyAmount }} gezahlt`,
  ],
  [`Items`]: [
    `Items`,
    `Artikel`,
  ],
  [`Click to try again`]: [
    `(Click to try again)`,
    `(Klicke um es erneut zu versuchen)`,
  ],

  //// Legacy
  [`NO_INTERNET`]: [
    `No Internet?`,
    `Kein Internet?`,
  ],
  [`EXP__SAVE_ERROR-TITLE`]: [
    `Try again`,
    `Erneut versuchen`,
  ],
  [`EXP__SAVE_ERROR-MSG`]: [
    `An error occured while saving:\n{{ error }}`,
    `Beim Speichern ist ein Fehler aufgetreten:\n{{ error }}`,
  ],
  [`EXP__SAVE_ERROR-CONFIRM`]: [
    `OK`,
    `OK`,
  ],
}
