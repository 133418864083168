import AngularTranslate from 'angular-translate'
import EnumFilter from '../../../../modules/general/filter_enumeration'
import UserService from '../../../../modules/user/svc_user'
import WgService from '../../../../modules/wg/svc_wg'
import ActionsheetModule from '../../../../scripts/modules/yptActionsheet'
import CordovaModule from '../../../../scripts/modules/yptCordova'
import template from './template.html'
import UrlFilter from './url.filter'

export default angular.module('ft.chat.components.chat-message', [
  AngularTranslate,
  CordovaModule,
  ActionsheetModule,
  WgService,
  UserService,
  EnumFilter,
  UrlFilter,
])
.component('chatMessage', {
  template,
  bindings: {
    message: '<',
  },
  controller,
})
.name

controller.$inject = ['User', 'Wg', 'yptActionsheet', 'yptNotify', '$translate', 'yptCordova']
function controller(User, Wg, yptActionsheet, yptNotify, $translate, yptCordova) {
  const $ctrl = this
  $ctrl.currency = Wg.currency
  $ctrl.isMine = $ctrl.message.creatorId == User.properties.id
  $ctrl.person = Wg.flatmates._get($ctrl.message.creatorId)
  $ctrl.interact = interact

  function interact(event) {
    let options = {}
    let actions = []
    let buttonLabels = []

    if ($ctrl.isMine) {
      actions.push(deleteShout);
      options.addDestructiveButtonWithLabel = $translate.instant('DELETE');
    }

    const text = $ctrl.message.liked ? $translate.instant('Unlike') : $translate.instant('Like')
    buttonLabels.push(text);
    actions.push(likeShout);

    if (event && event.toElement.tagName === 'SPAN' && event.toElement.attributes.href) {
      let url = event.target.innerHTML;
      buttonLabels.push($translate.instant('Open') + ' ' + event.target.innerHTML);

      if (url.indexOf('http') < 0) {
        url = 'http://' + url;
      }
      actions.push(followLink(url));
    }

    // Trim title length if neccessary
    var title = $ctrl.message.shout.substr(0, 80);
    if ($ctrl.message.shout.length > 80) {
      title += '…';
    }

    options = angular.extend(options, {
      'title': title,
      'buttonLabels': buttonLabels,
      'androidEnableCancelButton': false,
      'addCancelButtonWithLabel': $translate.instant('Cancel'),
    });

    yptActionsheet.show(options, actions);

    function followLink(url) {
      return function() {
        yptCordova.openLink(url);
      };
    };

    function likeShout() {
      $ctrl.message.like()
    };

    function deleteShout() {
      yptNotify.confirm({
        title: $translate.instant('Delete entry?'),
        message: $translate.instant('Chat:Message:Delete:Message'),
        buttonLabels: [
          $translate.instant('Cancel'),
          $translate.instant('Delete'),
        ],
        callback: function(answer) {
          if (answer === true) {
            $ctrl.message.removeFromCollection().delete();
          }
        },
      });
    };

  }
}
