import ngRedux from 'ng-redux'
import { equals, pipe, uniq } from 'ramda'
import { v4 as generateUuid } from 'uuid'
import PersonalProposalsService from '../../modules/shoppinglist/svc_personalproposals'
import ShoppinglistItemService from '../../modules/shoppinglist/svc_shoppinglistitem'
import WgService from '../../modules/wg/svc_wg'
import FlatasticConfig from '../../scripts/config'
import ActionsheetModule from '../../scripts/modules/yptActionsheet'
import CordovaModule from '../../scripts/modules/yptCordova'
import KeyboardModule from '../../scripts/modules/yptKeyboard'
import yepptSocialSharing from '../../scripts/modules/yptSocialsharing'
import { isNilOrEmpty, isUndefinedOrEmpty } from '../../utils/helpers'
import DragawayableDirective from '../directives/dragawayable'
import CategoryModal from './category.modal.html'
import categories, { getCategoryById } from './data/categories'
import ItemDetailsService from './item-details'
import ItemDetailModal from './item-details.modal.html'
import './item-details.scss'
import ItemEditModal from './item-edit.modal.html'
import './item-edit.modal.scss'
import SortModal from './sort.modal.html'
import SortOptions from './sortOptions'
import { actions, selectCheckedItems, selectIsBusy, selectLastAddedItem, selectOrderBy, selectSelectedItem, selectSelectedTags, selectShowWithoutTags, selectTags, selectUncheckedItems, selectUnsyncedItems, selectWithoutTagsCount } from './store/shoppinglistSlice'
import ShoppinglistSuggestions from './suggestions_v1.svc'
import TagsModal from './tags.modal.html'

export default angular.module('ft.shoppinglist.controller', [
  'ionic',
  WgService,
  DragawayableDirective,
  CordovaModule,
  ActionsheetModule,
  KeyboardModule,
  ShoppinglistSuggestions,
  ngRedux,
  FlatasticConfig,
  PersonalProposalsService,
  ItemDetailsService,
  yepptSocialSharing,
  ShoppinglistItemService,
  SortOptions,
])

.controller('ShoppinglistListController', ShopinglistListController)
.name;

ShopinglistListController.$inject = [
  '$scope',
  '$ionicModal',
  '$window',
  'Wg',
  '$timeout',
  '$ionicScrollDelegate',
  'yptNotify',
  '$translate',
  'User',
  'yptActionsheet',
  'yptKeyboard',
  'ShoppinglistSuggestions',
  'ShoppinglistItem',
  'PersonalProposals',
  '$ngRedux',
  'ShoppinglistItemDetails',
  'yptSocialsharing',
  'LocalStorageKeys',
  'ShoppinglistSortOptions',
]
function ShopinglistListController(
  $scope,
  $ionicModal,
  $window,
  Wg,
  $timeout,
  $ionicScrollDelegate,
  yptNotify,
  $translate,
  User,
  yptActionsheet,
  yptKeyboard,
  ShoppinglistSuggestions,
  ShoppinglistItem,
  PersonalProposals,
  $ngRedux,
  ShoppinglistItemDetails,
  yptSocialsharing,
  LocalStorageKeys,
  ShoppinglistSortOptions
) {
  const vm = this;

  let unsubscribe = $ngRedux.connect(mapStateToThis, actions)(vm)
  $scope.$on('$destroy', unsubscribe)

  let lastAddedItem = null
  let cancelTimer = null
  vm.isEditing = false

  vm.features = {
    photo: false,
    details: true,
    category: false,
    multipleLists: false,
  }

  $scope.newItem = ShoppinglistItem.getScaffold(User.properties.id);
  vm.getMember = Wg.flatmates._get
  vm.interact = interact
  vm.focusInput = focusInput
  vm.onFocusInput = onFocusInput
  vm.onDone = onDone
  vm.onToggleItem = onToggleItem
  vm.onAdd = onAdd
  vm.saveChanges = saveChanges
  vm.onPlusButtonClick = onPlusButtonClick
  vm.onBlurInput = onBlurInput
  vm.onDeleteItem = onDeleteItem
  vm.onEditItem = onEditItem
  vm.onRemoveCheckedItems = onRemoveCheckedItems
  vm.editedData = {}
  vm.isUserPremium = User.isPremium
  vm.selectItem = selectItem
  vm.onAddSuggestion = onAddSuggestion
  vm.onSyncItems = onSyncItems
  vm.isSelectedItemChanged = isSelectedItemChanged
  vm.isAddingDetails = false
  vm.onSaveDetails = onSaveDetails
  vm.onSelectCategoryId = onSelectCategoryId
  vm.toggleItemDetail = toggleItemDetail
  vm.shareShoppinglist = shareShoppinglist
  vm.itemDetailSuggestions = getItemDetailSuggestions()
  vm.suggestions = ShoppinglistSuggestions.get()
  vm.showAddWithEnterHint = false
  vm.showInputAsSuggestion = false
  vm.refresh = refresh
  vm.showTagsModal = showTagsModal
  vm.closeTagsModal = closeTagsModal
  vm.itemTags = getItemTags()
  vm.onCreateTag = onCreateTag
  vm.toggleItemTag = toggleItemTag
  vm.toggleLastAddedItemTag = toggleLastAddedItemTag
  vm.toggleLastAddedItemDetail = toggleLastAddedItemDetail
  vm.selectedOrderByValue = vm.orderBy
  vm.showCategoryModal = showCategoryModal
  vm.closeCategoryModal = closeCategoryModal
  vm.showSortModal = showSortModal
  vm.closeSortModal = closeSortModal
  vm.updateValues = updateValues
  vm.orderByOptions = ShoppinglistSortOptions
  vm.getCategoryById = getCategoryById
  vm.categories = categories
  vm.isDrawerPulledOut = false
  vm.isTagsInfoTextDismissed = $window.localStorage[LocalStorageKeys.shoppinglist.tagsInfoDismissed] || false
  vm.hideTagsInfoText = function() {
    vm.isTagsInfoTextDismissed = true
    $window.localStorage[LocalStorageKeys.shoppinglist.tagsInfoDismissed] = true
  }


  $scope.$watch('vm.orderBy', function(newVal, oldVal, scope) {
    vm.selectedOrderByValue = newVal
  })
  $scope.$watch('newItem.name', function(newVal, oldVal, scope) {
    updateSuggestions(newVal)
  })
  $scope.$watch('vm.checkedItems.length + vm.uncheckedItems.length', function() {
    updateSuggestions()
    vm.lastAddedItemTags = getLastAddedItemTags()
  })
  function updateSuggestions(val = '') {
    vm.suggestions = ShoppinglistSuggestions.get(val, 500)
    vm.showInputAsSuggestion = !!val && !ShoppinglistSuggestions.includes(val)
    vm.showAddWithEnterHint = vm.suggestions.length === 1 && !vm.showInputAsSuggestion || vm.showInputAsSuggestion && vm.suggestions.length === 0
  }

  function getItemDetailSuggestions() {
    return ShoppinglistItemDetails.getSuggestions().map(i => ({
      ...i,
      isAdded: (vm.editedData.details || '').split(',').map(i => i.trim()).includes(i),
      isTag: i.type === 'tag',
    }))
  }

  function updateValues() {
    console.time('updateValues')
    vm.itemTags = getItemTags()
    console.timeLog('updateValues')
    vm.lastAddedItemTags = getLastAddedItemTags()
    vm.lastAddedItemSuggestions = getLastAddedItemDetailSuggestions()
    console.timeLog('updateValues')
    updateItemDetailSuggestions()
    console.timeEnd('updateValues')
  }
  function updateItemDetailSuggestions() {
    const data =  (vm.editedData.details || '').split(',').map(str => str.trim())
    vm.itemDetailSuggestions = getItemDetailSuggestions().map(i => {
      return {
        ...i,
        isAdded: data.includes(i.name),
      }
    })
  }
  function getItemTags(details) {
    details = details || vm.editedData.details
    return vm.tags.map(tag => ({
      ...tag,
      isAdded: (details || '').split(',').map(i => i.trim()).includes(tag.name),
    }))
  }

  function refresh() {
    vm.hideLoading = true
    vm.fetchItems().then(result => {
      if (result.error ) {
        console.error(result.error)
      }
      vm.hideLoading = false
      $scope.$broadcast('scroll.refreshComplete');
    })
  }

  function shareShoppinglist(itemsToBuy) {
    const items = itemsToBuy.map(({ name, details }) => ` - ${name}${details ? ` (${details})` : ''}`).join('\n')
    yptSocialsharing.share({
      subject: $translate.instant('shoppinglist.share.title'),
      message: $translate.instant(`shoppinglist.share.message`, { items }),
      trackEvent: {
        name: 'Share Shopping List',
        parameters: {
          context: 'shoppinglist-nav-bar',
          content: $translate.instant(`shoppinglist.share.title`),
        },
      },
    });
  }

  function toggleItemTag(tag) {
    const splitted = (vm.editedData.details || '').split(',').map(i => i.trim()).filter(Boolean)
    const idx = splitted.indexOf(tag.name)
    tag.isAdded = !tag.isAdded
    if (idx === -1) {
      splitted.push(tag.name)
    } else {
      splitted.splice(idx, 1)
    }
    if (splitted.length === 0) {
      vm.editedData.details = null
    } else {
      vm.editedData.details = splitted.join(', ')
    }
  }

  vm.lastAddedItemTags = getLastAddedItemTags()
  function getLastAddedItemTags() {
    const details = vm?.lastAddedItem?.details
    return vm.tags.map(tag => ({
      ...tag,
      isAdded: (details || '').split(',').map(i => i.trim()).includes(tag.name),
    }))
  }

  vm.lastAddedItemSuggestions = getLastAddedItemDetailSuggestions()
  function getLastAddedItemDetailSuggestions() {
    const details = vm?.lastAddedItem?.details
    return ShoppinglistItemDetails.getSuggestions().map(suggestion => ({
      ...suggestion,
      isAdded: (details || '').split(',').map(i => i.trim()).includes(suggestion.name),
      isTag: suggestion.type === 'tag',
    }))
  }

  function toggleLastAddedItemTag(tag) {
    let { details } = vm.lastAddedItem
    const splitted = (details || '').split(',').map(i => i.trim()).filter(Boolean)
    const idx = splitted.indexOf(tag.name)
    tag.isAdded = !tag.isAdded
    if (idx === -1) {
      splitted.push(tag.name)
    } else {
      splitted.splice(idx, 1)
    }
    if (splitted.length === 0) {
      details = null
    } else {
      details = splitted.join(', ')
    }
    vm.editItem({ item: vm.lastAddedItem, changes: { details } })
    updateValues()
  }

  function toggleLastAddedItemDetail(proposal = {}) {
    let details = vm.lastAddedItem?.details
    const splitted = (details || '').split(',').map(i => i.trim()).filter(Boolean)
    const idx = splitted.indexOf(proposal.name)
    const entry = vm.itemDetailSuggestions.find(i => i.name === proposal.name)
    if (!entry) {
      return
    }
    if (idx === -1) {
      entry.isAdded = true
      splitted.push(proposal.name)
    } else {
      entry.isAdded = false
      splitted.splice(idx, 1)
    }
    if (splitted.length === 0) {
      details = null
    } else {
      details = splitted.join(', ')
    }
    vm.editItem({ item: vm.lastAddedItem, changes: { details } })
    updateValues()
  }

  function toggleItemDetail(proposal = {}) {
    const splitted = (vm.editedData.details || '').split(',').map(i => i.trim()).filter(Boolean)
    const idx = splitted.indexOf(proposal.name)
    const entry = vm.itemDetailSuggestions.find(i => i.name === proposal.name)
    if (!entry) {
      return
    }
    if (idx === -1) {
      entry.isAdded = true
      splitted.push(proposal.name)
    } else {
      entry.isAdded = false
      splitted.splice(idx, 1)
    }
    if (splitted.length === 0) {
      vm.editedData.details = null
    } else {
      vm.editedData.details = splitted.join(', ')
    }
    updateValues()
  }

  function focusInput() {
    $scope.$broadcast('shoppinglist.input.focus')
  }

  function onSaveDetails() {
    vm.isAddingDetails = false
    const changes = getChangesOfSelectedItem()
    const item = vm.selectedItem
    $timeout(focusInput, 350)
    return vm.editItem({item, changes })
  }

  let formerOrderByValue = vm.orderBy
  function onFocusInput() {
    if (cancelTimer) {
      $timeout.cancel(cancelTimer)
    }
    formerOrderByValue = vm.orderBy
    vm.setOrderBy('date')
    vm.isEditing = true
    $ionicScrollDelegate.scrollTop()
    showFooterContent()
  }

  function onBlurInput() {
    vm.setOrderBy(formerOrderByValue)
    cancelTimer = $timeout(() => {
      vm.isEditing = false
    }, 100)
  }

  function onDone() {
    vm.isAddingDetails = false
    yptKeyboard.close()
    $scope.$broadcast('shoppinglist.input.blur')
    $scope.$broadcast('resizable-footer-content.pull-in')
  }

  function onToggleItem(item) {
    if (lastAddedItem) {
      delete lastAddedItem.isMostRecentlyAddedItem
    }
    if (item.isChecked) {
      PersonalProposals.add(item.name)
      vm.isAddingDetails = true
    } else {
      vm.isAddingDetails = false
    }
    vm.toggleItem({item, userId: User.properties.id })
    updateSuggestions()
  }

  function attachTagsToItemDetails(item, tags) {
    const itemDetails = (item.details || '').split(',').map(i => i.trim()).filter(Boolean)
    return uniq(itemDetails.concat(tags)).join(', ')
  }

  function attachSelectedTagsToDetails(item) {
    const itemDetails = (item.details || '').split(',').map(i => i.trim()).filter(Boolean)
    const selectedTags = vm.selectedTags
    return {
      ...item,
      details: uniq(itemDetails.concat(selectedTags)).join(', ')
    }
  }

  function getCategoryId(itemName) {
    return undefined;
  }
  function attachItemCategoryId({ name, categoryId, ...rest }) {
    return {
      name,
      categoryId: categoryId || getCategoryId(name),
      ...rest,
    }
  }

  function onAdd(item) {
    const itemName = item.name
    if (!itemName) {
      console.warn(`Won't add items with empty name`)
      onDone()
      return
    }
    if (lastAddedItem) {
      delete lastAddedItem.isMostRecentlyAddedItem
    }
    PersonalProposals.add(itemName)
    let [itemOnList] = vm.checkedItems.filter(item => (!item.details && item.name.toLowerCase() === itemName.toLowerCase()))
    if (itemOnList) {
      onToggleItem(itemOnList)
    } else {
      const newItem = pipe(
        attachSelectedTagsToDetails,
        attachItemCategoryId,
      )({
        name: itemName,
        isChecked: false,
        inserterId: User.properties.id,
        categoryId: item.categoryId,
      })
      vm.addItem({
        item: newItem,
        localId: generateUuid(),
      })
    }
    $scope.newItem = ShoppinglistItem.getScaffold(User.properties.id);
    activateAddingDetailsMode()
    updateValues()
    updateSuggestions()
  }

  function activateAddingDetailsMode() {
    vm.isAddingDetails = true
    $scope.$broadcast('shoppinglist-suggestions.scrollTop')
  }

  function onAddSuggestion(suggestion) {
    const [item] = vm.checkedItems.filter(item => item.id === suggestion.id)
    if (item) {
      onToggleItem(item)
      $scope.newItem = ShoppinglistItem.getScaffold(User.properties.id);
      updateSuggestions()
      return
    }
    onAdd(suggestion)
  }

  const itemDetailsModal = $ionicModal.fromTemplate(ItemDetailModal, { scope: $scope })
  itemDetailsModal.name = 'itemDetailsModal'
  function selectItem(item) {
    vm.selectItemById(item.id)
    itemDetailsModal.show()
    vm.closeDetailsModal = () => {
      itemDetailsModal.hide()
    }
  }

  const modalStack = []
  $scope.$on('modal.shown', (event, modal) => {
    const [topModal] = modalStack
    if (!modalStack.includes(modal)) {
      modalStack.unshift(modal)
      if (topModal) {
        $timeout(() => topModal.hide(), 0)
      }
    }
  })
  $scope.$on('modal.hidden', (event, modal) => {
    const [topModal, secondModal] = modalStack

    if (topModal === modal) {
      modalStack.shift()
      if (secondModal) {
        secondModal?.show()
      }
    }
  })

  const tagsModal = $ionicModal.fromTemplate(TagsModal, { scope: $scope })
  tagsModal.name = 'tagsModal'
  function showTagsModal() {
    tagsModal.show()
  }
  function closeTagsModal() {
    tagsModal.hide()
  }
  const categoryModal = $ionicModal.fromTemplate(CategoryModal, { scope: $scope })
  categoryModal.name = 'categoryModal'
  let disableAutoSave
  vm.categoryModalData = {
    name: undefined,
    categoryId: undefined,
  }
  function showCategoryModal(item) {
    if (item) {
      vm.selectItemById(item.id)
    }
    vm.categoryModalData.name = item.name
    vm.categoryModalData.categoryId = item.categoryId
    disableAutoSave = (item === vm.editedData)
    categoryModal.show()
  }
  function closeCategoryModal() {
    categoryModal.hide()
  }
  function onSelectCategoryId(item, categoryId) {
    vm.closeCategoryModal()
    if (disableAutoSave) {
      vm.editedData.categoryId = categoryId
      return
    }
    const changes = { categoryId }
    vm.editItem({ item, changes })
  }

  const sortModal = $ionicModal.fromTemplate(SortModal, { scope: $scope })
  sortModal.name = 'sortModal'
  function showSortModal() {
    sortModal.show()
  }
  function closeSortModal() {
    sortModal.hide()
  }

  const itemEditModal = $ionicModal.fromTemplate(ItemEditModal, { scope: $scope })
  itemEditModal.name = 'itemEditModal'
  function onEditItem(item, focusDetailsInput) {
    vm.selectItemById(item.id)
    vm.editedData = angular.copy(item)
    updateValues()
    vm.isItemEditModalInputAutoFocused = focusDetailsInput
    itemEditModal.show()
      .then(() => {
        if (focusDetailsInput) {
          $scope.$broadcast('shl:edit:details')
          $timeout(500).then(() => {
            vm.isItemEditModalInputAutoFocused = false
          })
        }
      })
    vm.closeEditModal = () => {
      itemEditModal.hide()
    }
  }

  $scope.$on('$destroy', () => {
    itemDetailsModal.remove()
    itemEditModal.remove()
    categoryModal.remove()
    sortModal.remove()
    tagsModal.remove()
  })
  $scope.editItem = vm.editItem

  function onRemoveCheckedItems() {
    yptNotify.confirm({
      title: $translate.instant('shoppinglist.removeBoughtItems.confirmDialog.title'),
      message: $translate.instant('shoppinglist.removeBoughtItems.confirmDialog.message'),
      buttonLabels: [
        $translate.instant('BTN_CANCEL'),
        $translate.instant('YES'),
      ],
      callback: function(answer) {
        if (!answer) {
          return;
        }
        vm.removeCheckedItems()
        updateSuggestions()
        $ionicScrollDelegate.scrollTop();
      },
    })
  }

  function onDeleteItem(item) {
    yptNotify.confirm({
      title: $translate.instant('SHL__DELETE_ITEM-TITLE', { name: item.name }),
      message: $translate.instant('SHL__DELETE_ITEM-MSG', { name: item.name }),
      buttonLabels: [
        $translate.instant('BTN_CANCEL'),
        $translate.instant('YES'),
      ],
      callback: function(answer) {
        if ( ! answer) { return }
        vm.deleteItem(item);
        updateSuggestions()
        if (vm.closeEditModal) {
          vm.closeEditModal()
        }
        if (vm.closeDetailsModal){
          vm.closeDetailsModal()
        }
      },
    });
  }

  function showFooterContent() {
    vm.isEditing = true
    $scope.$broadcast('resizable-footer-content.pull-out')
  }

  function onPlusButtonClick() {
    vm.isAddingDetails = false
    if ($scope.newItem?.name?.length > 1) {
      return onAdd($scope.newItem)
    }
    $timeout(focusInput, 0);
  }

  function getChangesOfSelectedItem() {
    if (!vm.selectedItem || isNilOrEmpty(vm.editedData)) {
      return {}
    }
    const editableProperties = ['name', 'details', 'categoryId']
    const result = editableProperties.reduce((acc, prop) => {
      if (equals(vm.selectedItem[prop], vm.editedData[prop])) {
        return acc
      }
      if (isNilOrEmpty(vm.selectedItem[prop]) && isNilOrEmpty(vm.editedData[prop])) {
        return acc
      }
      return { ...acc, [prop]: vm.editedData[prop] }
    }, {})
    return result
  }

  function isSelectedItemChanged() {
    return !isUndefinedOrEmpty(getChangesOfSelectedItem())
  }

  function saveChanges(item, delayClosingTimeInMs = 0) {
    const changes = getChangesOfSelectedItem()
    if ('categoryId' in changes && changes.categoryId === undefined) {
      changes.categoryId = null
    }
    if (isUndefinedOrEmpty(changes)) {
      vm.closeEditModal && vm.closeEditModal()
      vm.closeCategoryModal && vm.closeCategoryModal()
      return
    }
    $timeout(() => {
      vm.closeEditModal && vm.closeEditModal()
      vm.closeCategoryModal && vm.closeCategoryModal()
    }, delayClosingTimeInMs)
    return vm.editItem({item, changes })
      .then((result) => {
        if (result.error) {
          yptNotify.alert({
            message: $translate.instant(`The changes haven't been synchronized with our server.`),
            title: $translate.instant('Edit item'),
            buttonName: $translate.instant(`OK`),
          })
        }
      })
  }

  function onSyncItems() {
    yptNotify.confirm({
      title: $translate.instant('SHL__SYNC-TITLE'),
      message: $translate.instant('SHL__SYNC-MSG'),
      buttonLabels: [
        $translate.instant('BTN_CANCEL'),
        $translate.instant('OK'),
      ],
      callback: function(answer) {
        if (!answer) {
          return;
        }
        vm.syncItems()
          .then(function(result) {
            if (result.error) {
              return yptNotify.alert({
                title: $translate.instant('SHL__SYNC_ERROR-TITLE'),
                message: $translate.instant('SHL__SYNC_ERROR-MSG'),
              });
            }
            yptNotify.alert({
              title: $translate.instant('SHL__SYNC_SUCCESS-TITLE'),
              message: $translate.instant('SHL__SYNC_SUCCESS-MSG'),
            });
          })
      },
    });
  }

  let isCreateTagPromptOpen = false
  function onCreateTag(itemToAutoAddTo) {
    if (isCreateTagPromptOpen) {
      return
    }
    isCreateTagPromptOpen = true
    yptNotify.prompt({
      title: $translate.instant('Create new tag'),
      message: $translate.instant('Use tags to filter items by them.'),
      buttonLabels: [$translate.instant('Cancel'), $translate.instant('Create Tag')],
    })
      .then(tag => {
        vm.addTag(tag)
        if (itemToAutoAddTo) {
          const newTag = tag.replace(/^#*/gi, '#')
          if (!tag || tag === '#') {
            return
          }
          const details = attachTagsToItemDetails(itemToAutoAddTo, [newTag])
          if (itemToAutoAddTo === vm.editedData) {
            vm.editedData.details = details
          } else {
            vm.editItem({ item: itemToAutoAddTo, changes: { details } })
          }
        }
      })
      .then(updateValues)
      .finally(() => {
        isCreateTagPromptOpen = false
      })
  }

  function interact(item) {
    var options = {
      title: item.name + ' (' + Wg.flatmates._get(item.inserterId).firstName + ')',
      addDestructiveButtonWithLabel: $translate.instant('DELETE'),
      buttonLabels: (!item.isChecked) ?
        [
          $translate.instant('SHL__INTERACT__EDIT'),
          $translate.instant('SHL__INTERACT__BUY'),
        ]
          :
        [
          $translate.instant('SHL__INTERACT__BACK_TO_LIST'),
        ],
      'androidEnableCancelButton': false,
      'addCancelButtonWithLabel': $translate.instant('BTN_CANCEL'),
    };
    var actions = [
      deleteItem,
      !item.isChecked ? editItem : undefined,
      toggleItem,
    ].filter(function(item) { return Boolean(item); });
    yptActionsheet.show(options, actions);

    function deleteItem() {
      vm.onDeleteItem(item)
    }

    function toggleItem() {
      vm.onToggleItem(item)
    }

    function editItem() {
      vm.onEditItem(item)
    }
  }
}

function mapStateToThis(state) {
  return {
    isBusy: selectIsBusy(state),
    uncheckedItems: selectUncheckedItems(state),
    uncheckedItemsLength: selectUncheckedItems(state).length,
    checkedItems: selectCheckedItems(state),
    checkedItemsLength: selectCheckedItems(state).length,
    selectedItem: selectSelectedItem(state),
    unsyncedItemsLength: selectUnsyncedItems(state).length,
    lastAddedItem: selectLastAddedItem(state),
    tags: selectTags(state),
    selectedTags: selectSelectedTags(state),
    showWithoutTags: selectShowWithoutTags(state),
    itemWithoutTagsCount: selectWithoutTagsCount(state),
    orderBy: selectOrderBy(state),
  }
}
