import UserService from '../../../../modules/user/svc_user'
import WgService from '../../../../modules/wg/svc_wg'
import template from './template.html'

export default angular.module('ft.fincances.components.balance-entry', [
  WgService,
  UserService,
])
.component('balanceEntry', {
  template,
  bindings: {
    balanceEntry: '<',
  },
  transclude: true,
  controller,
})
.name

controller.$inject = ['User', 'Wg']
function controller(User, Wg) {
  const $ctrl = this
  $ctrl.currency = Wg.currency
  $ctrl.absAmount = Math.abs($ctrl.balanceEntry.balance)
  $ctrl.balance = $ctrl.balanceEntry.balance
  $ctrl.isMe = $ctrl.balanceEntry.id === User.properties.id
  $ctrl.person = Wg.flatmates._get($ctrl.balanceEntry.id)
}
