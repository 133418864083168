import angular from 'angular'

export default angular.module('ft.runners.keyboard-open', [])
.run(KeyboardOpenRunner)
.name

KeyboardOpenRunner.$inject = ['$ionicHistory']
function KeyboardOpenRunner($ionicHistory) {
  window.addEventListener('native.keyboardshow', function () {
    document.body.classList.add('ft-keyboard-open')
  });
  window.addEventListener('native.keyboardhide', function () {
    document.body.classList.remove('ft-keyboard-open')
  });
}
