// [en, de]

export default {
  [`Person completed`]: [
    `{{ name }} completed`,
    `{{ name }} erledigte`,
  ],
  [`See your whole history with Flatastic Premium`]: [
    `See your whole chore history with Flatastic Premium`,
    `Sieh den gesamten Putzverlauf mit Flatastic Premium`,
  ],
  [`Get Premium`]: [
    `Get Premium`,
    `Premium holen`,
  ],
  [`There are no completed chores yet`]: [
    `There are no completed chores yet`,
    `Es wurden noch keine Aufgaben erledigt`,
  ],
}
