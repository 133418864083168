export default {
  [`Coming Soon`]: [
    `Coming Soon`,
    `Kommt bald`,
  ],
  // Finances
  [`Full History of every Entry`]: [
    `Full History of every Entry`,
    `Kassensturz-Historie`,
  ],
  [`Get a full history of all settlements including every entry.`]: [
    `Get a full history of all settlements including every entry.`,
    `Erhalte die Historie von allen Kassenstürzen inklusive aller Einträge.`,
  ],
  [`Export as CSV`]: [
    `Export as CSV`,
    `Export als CSV`,
  ],
  [`Export your current expenses and do your own data magic.`]: [
    `Export your current expenses as a CSV file and do your own data magic.`,
    `Exportiere alle aktuellen Ausgaben als CSV-Datei und mach deine eigene Big Data Magic`,
  ],
  [`Personal Spendings`]: [
    `Personal Spendings`,
    `Persönliche Ausgaben`,
  ],
  [`See how much money you've spent each month.`]: [
    `See how much money you've spent each month.`,
    `Siehe, wie viel Geld du jeden Monat ausgegeben hast.`,
  ],
  [`Add Images to Entries`]: [
    `Add Images to Entries`,
    `Füge Bilder zu Einträgen hinzu`,
  ],
  [`Take a photo of your receipts or the item you bought as a proof.`]: [
    `Take a photo of your receipts or the item you bought as a proof.`,
    `Mach ein Foto vom Kaufbeleg oder den Artikeln als Beweis.`,
  ],
  // Chores
  [`Full History`]: [
    `Full History`,
    `Putz-Historie`,
  ],
  [`Need to check who did or didn't do a chore? See the full history of completed chores with Flatastic Premium.`]: [
    `Need to check who did or didn't do a chore? See the full history of completed chores with Flatastic Premium.`,
    `Du möchtest nachschauen wann jemand eine Aufgabe erledigt hat? Mit Flatastic Premium siehst du den gesamten Verlauf.`,
  ],
  [`Sort & Filter`]: [
    `Sort & Filter`,
    `Sortieren & Filtern`,
  ],
  [`Get a quick overview who needs to do what next or who's chores are due.`]: [
    `Get a quick overview who needs to do what next or who's chores are due.`,
    `Erhalte einen schnellen Überblick darüber, wer was als nächstes tun muss oder wessen Aufgaben überfällig sind.`,
  ],
  [`Notes`]: [
    `Notes`,
    `Notizen`,
  ],
  [`Add details, instructions and tips to chores.`]: [
    `Add details, instructions and tips to chores.`,
    `Füge Details, Anweisungen und Tipps zu den Aufgaben hinzu.`,
  ],
}
