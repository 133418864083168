import AngularTranslate from 'angular-translate'
import UserService from '../../modules/user/svc_user'
import WgService from '../../modules/wg/svc_wg'
import itemDetails from './data/item-details'

export default angular.module('flatastic.shoppinglist.item-details', [
  UserService,
  WgService,
  AngularTranslate,
])
.factory('ShoppinglistItemDetails', ItemDetailsSuggestions)
.name

ItemDetailsSuggestions.$inject = ['User', 'Wg', '$translate', '$ngRedux']
function ItemDetailsSuggestions(User, Wg, $translate, $ngRedux) {
  return {
    getSuggestions,
  }

  ////


  function getSuggestions() {
    const base = []
    const lang = User.getLanguage()
    const members = Wg.flatmates.get().map(member => ({
      type: 'member',
      name: $translate.instant(`For member`, { name: member.firstName }),
      userId: member.id,
    }))
    const suggestions = itemDetails.i18n[lang].allIds.map(id => ({
      ...itemDetails.byId[id],
      type: 'suggestion',
      name: itemDetails.byId[id].translations[lang],
    }))
    return base.concat(suggestions).concat(members)
  }
}
