export default {
  [`Without Home`]: [
    `Without Home`,
    `Kein Zuhause`,
  ],
  [`More`]: [
    `More`,
    `Mehr`,
  ],
  [`Please choose`]: [
    `Please choose`,
    `Bitte wählen`,
  ],
  [`Delete Account`]: [
    `Delete Account`,
    `Account löschen`,
  ],
  [`Do you really want to delete your account?`]: [
    `Do you really want to delete your account?`,
    `Möchtest du wirklich deinen Account bei Flatastic löschen?`,
  ],
  [`Your account was successfully deleted`]: [
    `Your account was successfully deleted. We'll miss you!`,
    `Dein Account wurde erfolgreich gelöscht. Schade, dass du uns verlässt.`,
  ],
  [`Creating a new home…`]: [
    `Creating a new home…`,
    `Ein neuer Haushalt wird erstellt…`,
  ],
  [`New Home`]: [
    `New Home`,
    `Neuer Haushalt`,
  ],
  [`Something went wrong while creating your new home.`]: [
    `Something went wrong while creating your new home.`,
    `Beim Erstellen deines neuen Haushalts ist etwas schief gelaufen.`,
  ],
}
