import AngularTranslate from 'angular-translate'
import moment from 'moment'
import ChoresService from '../../modules/chores/svc_chores'
import UserService from '../../modules/user/svc_user'
import WgService from '../../modules/wg/svc_wg'
import ToastModule from '../../scripts/modules/mcmToast'
import ChoreProposalsComponent from './components/chore-proposals/chore-proposals'
import OrderMembersComponent from './components/order-members/order-members'
import detailsModalTemplate from './details.modal.html'
import editModalTemplate from './edit.modal.html'
import inputModalTemplate from './input.modal.html'

export default angular.module('ft.chores.controller', [
  'ionic',
  ToastModule,
  AngularTranslate,
  ChoresService,
  WgService,
  ChoreProposalsComponent,
  OrderMembersComponent,
  UserService,
])
.controller('ChoresListingController', ChoresController)
.name

ChoresController.$inject = ['Chores', '$ionicModal', '$scope', 'Wg', '$timeout', '$translate', 'User']
export function ChoresController(Chores, $ionicModal, $scope, Wg, $timeout, $translate, User) {
  const vm = this
  vm.chores = Chores
  vm.completeChore = completeChore
  vm.selectedChore = undefined
  vm.getMember = userId => Wg.flatmates._get(userId)
  vm.getMembers = () => Wg.flatmates.get()
  vm.getLastDoneString = getLastDoneString
  vm.user = User

  vm.features = {
    customReminders: false,
    notes: true,
    checklist: false,
    catalog: false,
  }

  vm.selectChore = selectChore

  const detailsModal = $ionicModal.fromTemplate(detailsModalTemplate, { scope: $scope })
  vm.closeDetailsModal = () => detailsModal.hide()

  const editModal = $ionicModal.fromTemplate(editModalTemplate, { scope: $scope })
  vm.closeEditModal = () => editModal.hide()
  vm.editChore = editChore
  function editChore(chore) {
    editModal.show()
  }

  function getLastDoneString(doneAt) {
    if (!doneAt) {
      return $translate.instant('Never')
    }
    const res = moment().endOf('day').diff(doneAt, 'days', true)
    const rounded = Math.ceil(res)
    if (rounded  === 0) {
      return $translate.instant('Today')
    }
    if (rounded === 1) {
      return $translate.instant('Yesterday')
    }
    return moment(doneAt).fromNow()
  }

  vm.getFirstNames = (userIds) => (userIds || []).map(userId => Wg.flatmates._get(userId).firstName).join(', ')
  vm.efforts = [{
    label: 'Small',
    value: 1,
  }, {
    label: 'Normal',
    value: 2,
  }, {
    label: 'Huge',
    value: 4,
  }]
  vm.showInputModal = showInputModal
  function showInputModal(property, initialValue) {
    let inputScope = $scope.$new()
    const inputModal = $ionicModal.fromTemplate(inputModalTemplate, { scope: inputScope })
    inputScope.property = property
    inputScope.value = angular.copy(initialValue)
    inputScope.$on('modal.hidden', function() {
      $timeout(400).then(() => inputModal.remove())
    })
    inputScope.closeInputModal = (newValue) => {
      if (newValue) {
        vm.selectedChore.newData[property] = newValue
      }
      inputScope.property = undefined
      inputScope.value = undefined
      inputModal.hide()
    }
    inputModal.show()
  }

  function selectChore(chore) {
    console.log(chore);
    vm.selectedChore = chore
    detailsModal.show()
  }

  function completeChore(chore) {
    chore.next()
      .then(() => mcmToast.show({
        message: $translate.instant(`Congrats. You completed the task.`, {
          points: chore.points,
          title: chore.title,
        }),
      }))

  }
}
