import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { append, ascend, concat, descend, difference, intersection, isEmpty, isNil, join, not, omit, pipe, prop, propOr, sortBy, sortWith, uniq, uniqBy, without, __ } from 'ramda'
import { api } from '../../../scripts/modules/api'
import { isUuidV4 } from '../../../utils/helpers'

export const fetchItems = createAsyncThunk('shoppinglist/fetchItems', () => {
  return api.get('/shoppinglist')
    .then(({ data }) => data.map(prepareItemForStore))
})

export const toggleItem = createAsyncThunk('shoppinglist/toggleItem', ({ item, userId }) => {
  return api.get('/shoppinglist/toggle_item', { params: { 'item_id': item.id }})
    .then(({ data }) => prepareItemForStore(data))
})

export const deleteItem = createAsyncThunk('shoppinglist/deleteItem', (item) => {
  // If item hasn't been synced with server yet we only need to delete it locally
  if (isLocalItem(item)) {
    return Promise.resolve(item)
  }
  return api.delete(`/shoppinglist/item/${item.id}`)
    .then(({ data }) => data)
})

export const editItem = createAsyncThunk('shoppinglist/editItem', ({ item, changes }) => {
  return api.patch(`/shoppinglist/${item.id}`, changes)
    .then(({ data }) => prepareItemForStore(data))
})

export const addItem = createAsyncThunk('shoppinglist/addItem', ({ item }) => {
  return api.post(`/shoppinglist`, prepareItemForServer(item))
    .then(({ data }) => prepareItemForStore(data))
})

export const syncLocalItem = createAsyncThunk('shoppinglist/syncLocalItem', (item) => {
  return api.post(`/shoppinglist`, prepareItemForServer(item))
    .then(({ data }) => prepareItemForStore(data))
})

export const syncServerItem = createAsyncThunk('shoppinglist/syncServerItem', (item) => {
  return api.post(`/shoppinglist/sync`, prepareItemForServer(item))
    .then(({ data }) => prepareItemForStore(data))
})

export const removeCheckedItems = createAsyncThunk('shoppinglist/removeCheckedItems', () => {
  return api.get('/shoppinglist/delete_bought_items')
    .then(({ data }) => data)
})

export const syncItems = createAsyncThunk('shoppinglist/syncItems', (arg, { dispatch, getState }) => {
  const unsyncedItems = selectUnsyncedItems(getState())
  const unsyncedLocalItems = unsyncedItems.filter(isLocalItem)
  const unsyncedServerItems = unsyncedItems.filter(pipe(isLocalItem, not))
  return Promise.all([
    ...unsyncedLocalItems.map(item => dispatch(syncLocalItem(item))),
    ...unsyncedServerItems.map(item => dispatch(syncServerItem(item))),
  ])
})

const initialState = {
  items: {
    status: 'idle',
    byId: {},
    allIds: [],
  },
  orderBy: 'date',
  tags: [],
  selectedItemId: undefined,
  lastAddedItemId: undefined,
  selectedTags: [],
  showWithoutTags: false,
}

const shoppinglistSlice = createSlice({
  name: 'shoppinglist',
  initialState,
  reducers: {
    toggleShowWithoutTags(state, action) {
      state.showWithoutTags = !state.showWithoutTags
    },
    setOrderBy(state, action ) {
      state.orderBy = action.payload
    },
    selectItemById(state, action) {
      state.selectedItemId = action.payload
    },
    addItems(state, action) {
      addBatch(state, action.payload)
    },
    removeItemById(state, action) {
      state.items.byId = omit([action.payload])(state.items.byId)
      state.items.allIds = state.items.allIds.filter(i => i.id !== action.payload)
    },
    removeItemByTitle(state, action) {
      const effectedIds = filter(i => i.title === action.payload)(state.items.byId)
      state.items.allIds = without(effectedIds)(state.items.allIds)
      state.items.byId = omit(effectedIds)(state.items.byId)
    },
    addTag(state, action) {
      if (!action.payload || action.payload === '#') { return }
      const newTag = action.payload.replace(/^#*/gi, '#')
      state.tags = [...new Set([...(state.tags || []), newTag])]
    },
    deleteTag(state, action) {
      state.tags = state.tags.filter(tag => tag !== action.payload)
    },
    toggleTag(state, action) {
      const selectedTags = state.selectedTags || []
      const tag = action.payload
      if (selectedTags.includes(tag)) {
        state.selectedTags = without([tag], selectedTags)
        return
      }
      state.selectedTags = append(tag, selectedTags)
    },
    clearSelectedTags(state, action) {
      state.selectedTags = []
    },
  },
  extraReducers: {
    ['CLEAR_STATE']: (state) => {
      state = initialState
      return state
    },
    // Remove checked items
    [removeCheckedItems.pending]: (state, action) => {
      const idsToDelete = state.items.allIds.filter(id => state.items.byId[id].isChecked === true)
      idsToDelete.forEach(id => {
        state.items.byId[id] = {
          ...state.items.byId[id],
          deletedAt: new Date().toISOString(),
          status: 'loading',
        }
      })
    },
    [removeCheckedItems.fulfilled]: (state, action) => {
      const idsToDelete = state.items.allIds.filter(id => state.items.byId[id].isChecked === true)
      state.items.allIds = without(idsToDelete)(state.items.allIds)
      state.items.byId = omit(idsToDelete)(state.items.byId)
    },
    [removeCheckedItems.rejected]: (state, action) => {
      const failedItemIds = state.items.allIds.filter(id => state.items.byId[id].deletedAt)
      failedItemIds.forEach(id => {
        state.items.byId[id] = {
          ...omit(['deletedAt'], state.items.byId[id]),
          status: 'failed',
        }
      })
    },

    // Add item
    [addItem.pending]: (state, action) => {
      const { item, localId } = action.meta.arg
      const localItem = { ...item, id: localId }
      state.items.byId[localItem.id] = {
        date: new Date().toISOString(),
        ...localItem,
        tags: extractTags(localItem.details),
        details: localItem.details || null,
        status: 'loading',
        error: null,
      }
      state.items.allIds.push(localItem.id)
      state.lastAddedItemId = localId
    },
    [addItem.fulfilled]: (state, action) => {
      const { localId } = action.meta.arg
      const itemId = action.payload.id
      state.items.byId[itemId] = action.payload
      delete state.items.byId[localId]
      state.items.allIds = state.items.allIds.filter(id => id !== localId).concat(itemId)
      state.lastAddedItemId = itemId
      state.items.byId[itemId].status = 'succeeded'
    },
    [addItem.rejected]: (state, action) => {
      const { localId } = action.meta.arg
      state.items.byId[localId].status = 'failed'
      state.items.byId[localId].error = action.error.message
    },

    // Sync local item
    [syncLocalItem.pending]: (state, action) => {
      const localItem = action.meta.arg
      state.items.byId[localItem.id] = {
        ...localItem,
        status: 'loading',
        error: null,
      }
    },
    [syncLocalItem.fulfilled]: (state, action) => {
      const localItem = action.meta.arg
      const itemId = action.payload.id
      state.items.byId[itemId] = action.payload
      delete state.items.byId[localItem.id]
      state.items.allIds = state.items.allIds.filter(id => id !== localItem.id).concat(itemId)
      state.items.byId[itemId].status = 'succeeded'
    },
    [syncLocalItem.rejected]: (state, action) => {
      const localItem = action.meta.arg
      state.items.byId[localItem.id].status = 'failed'
      state.items.byId[localItem.id].error = action.error.message
    },

    // Sync server item
    [syncServerItem.pending]: (state, action) => {
      const unsyncedItem = action.meta.arg
      state.items.byId[unsyncedItem.id] = {
        ...unsyncedItem,
        status: 'loading',
        error: null,
      }
    },
    [syncServerItem.fulfilled]: (state, action) => {
      const item = action.payload
      state.items.byId[item.id] = action.payload
      state.items.byId[item.id].status = 'succeeded'
    },
    [syncServerItem.rejected]: (state, action) => {
      const item = action.payload
      state.items.byId[item.id].status = 'failed'
      state.items.byId[item.id].error = action.error.message
    },

    // Edit item
    [editItem.pending]: (state, action) => {
      const { item: { id: itemId }, changes} = action.meta.arg
      const item = state.items.byId[itemId]
      state.items.byId[item.id] = {
        ...item,
        ...changes,
        status: 'loading',
        error: null,
      }
    },
    [editItem.fulfilled]: (state, action) => {
      const { item } = action.meta.arg
      state.items.byId[item.id] = {
        ...action.payload,
        status: 'succeeded',
      }
    },
    [editItem.rejected]: (state, action) => {
      const { item } = action.meta.arg
      state.items.byId[item.id] = {
        ...state.items.byId[item.id],
        status: 'failed',
        error: action.error.message,
      }
    },

    // Delete item
    [deleteItem.pending]: (state, action) => {
      const itemId = action.meta.arg.id
      let item = state.items.byId[itemId]
      state.items.byId[itemId] = {
        ...item,
        deletedAt: new Date().toISOString(),
        error: null,
        status: 'loading',
      }
    },
    [deleteItem.fulfilled]: (state, action) => {
      const itemId = action.meta.arg.id
      delete state.items.byId[itemId]
      state.items.allIds = state.items.allIds.filter(id => (id !== itemId))
    },
    [deleteItem.rejected]: (state, action) => {
      const itemId = action.meta.arg.id
      let item = state.items.byId[itemId]
      delete item.deletedAt
      state.items.byId[itemId] = {
        ...item,
        error: action.error.message,
        status: 'failed',
      }
    },

    // Toggle item
    [toggleItem.pending]: (state, action) => {
      const { item: { id: itemId }, userId } = action.meta.arg
      let currentItem = state.items.byId[itemId]
      state.lastAddedItemId = itemId
      state.items.byId[itemId] = {
        ...currentItem,
        isChecked: !currentItem.isChecked,
        buyerId: !currentItem.isChecked ? userId : null,
        inserterId: currentItem.isChecked ? userId : currentItem.inserterId,
        date: new Date().toISOString(),
        error: null,
        status: 'loading',
      }
    },
    [toggleItem.fulfilled]: (state, action) => {
      const { item: { id: itemId } } = action.meta.arg
      state.items.byId[itemId] = {
        ...action.payload,
        status: 'succeeded',
        error: null,
      }
    },
    [toggleItem.rejected]: (state, action) => {
      const { item: { id: itemId } } = action.meta.arg
      let item = state.items.byId[itemId]
      state.items.byId[itemId] = {
        ...item,
        status: 'failed',
        error: action.error.message,
      }
    },

    // Fetch items from server
    [fetchItems.pending]: (state, action) => {
      state.items.status = 'loading'
      state.error = null
    },
    [fetchItems.fulfilled]: (state, action) => {
      state.items.status = 'succeeded'
      state.error = null
      const idsNotOnServer = difference(state.items.allIds, action.payload.map(item => item.id))
      const itemIdsToDelete = idsNotOnServer.filter(item => !isLocalItem(item))
      state.byId = omit(itemIdsToDelete, state.byId)
      state.items.allIds = without(itemIdsToDelete, state.items.allIds)
      addBatch(state, action.payload)
    },
    [fetchItems.rejected]: (state, action) => {
      state.items.status = 'failed'
      state.error = action.error.message
    },

    // Sync items
    [syncItems.pending]: (state, action) => {
      state.items.status = 'loading'
      state.error = null
    },
    [syncItems.fulfilled]: (state, action) => {
      state.items.status = 'succeeded'
    },
    [syncItems.rejected]: (state, action) => {
      state.items.status = 'failed'
      state.error = action.error?.message
    },
  }
})

function addBatch(state, items) {
  const ids = items.map(item => item.id)
  items.forEach(item => {
    state.items.byId[item.id] = item
  })
  state.items.allIds = pipe(
    concat(ids),
    uniq,
    sortWith([descend(prop('date'))]),
  )(state.items.allIds)
}

function filterBySelectedTags(selectedTags = [], showWithoutTags = false) {
  return function (item) {
    if (isEmpty(selectedTags) && !showWithoutTags) {
      return true
    }
    if (showWithoutTags && item.tags.length === 0) {
      return true
    }
    const normalizedSelectedTags = selectedTags.filter(tag => tag !== null)
    const normalizedItemTags = item.tags
    const intersections = intersection(normalizedItemTags, normalizedSelectedTags)
    return !isEmpty(intersections)
  }
}

export const selectIsBusy = ({ [shoppinglistSlice.name]: state }) => state.items.status === 'loading'
export const selectAllItemIds = ({ [shoppinglistSlice.name]: state }) => state.items.allIds
export const selectAllItems = ({ [shoppinglistSlice.name]: state }) => state.items.allIds.map(id => state.items.byId[id])
export const selectUnsyncedItems = ({ [shoppinglistSlice.name]: state }) => state.items.allIds
  .map(id => state.items.byId[id])
  .filter(item => item.status === 'failed')
export const selectSelectedItem = ({ [shoppinglistSlice.name]: state }) => state.items.byId[state.selectedItemId]
export const selectLastAddedItem = ({ [shoppinglistSlice.name]: state }) => state.items.byId[state.lastAddedItemId]
export const selectUncheckedItems = ({ [shoppinglistSlice.name]: state }) => {
  const orderBy = getOrderBy(state)
  const uncheckedItems = state.items.allIds
    .map(id => state.items.byId[id])
    .filter(item => !item.isChecked && !item.deletedAt)
    .filter(filterBySelectedTags(state.selectedTags, state.showWithoutTags))
  const out = pipe(
      sortWith(orderBy)
  )(uncheckedItems)
  return out
}

const sortByCategory = ascend(prop('categoryId'))
const sortByDate = descend(prop('date'))
const sortAlphabetically = ascend(prop('name'))
const sortByUserId = ascend(prop('userId'))
const sortByTag = ascend(pipe(prop('tags'), join('')))

const getOrderBy = pipe(
  propOr('date', 'orderBy'),
  prop(__, {
    'categoryId': [sortByCategory, sortByDate],
    'date': [sortByDate],
    'alphabet': [sortAlphabetically, sortByDate],
    'userId': [sortByUserId, sortByDate],
    'tag': [sortByTag, sortByDate],
  }),
)

export const selectCheckedItems = ({ [shoppinglistSlice.name]: state }) => {
  const checkedItems = state.items.allIds
    .map(id => state.items.byId[id])
    .filter(item => item.isChecked && !item.deletedAt)
    .filter(filterBySelectedTags(state.selectedTags))
  return pipe(
    uniqBy(({ name, details }) => `${name}\n${details}`),
    sortWith([sortByDate]),
  )(checkedItems)
}

function processTag(tag, reducedTags, item) {
  if (!reducedTags[tag]) {
    reducedTags[tag] = { uncheckedItemCount: 0, itemCount: 0 }
  }
  reducedTags[tag].name = tag
  if (!item) {
    return
  }
  reducedTags[tag].uncheckedItemCount += item.isChecked ? 0 : 1
  reducedTags[tag].itemCount += 1
}

export const selectTags = ({ [shoppinglistSlice.name]: state }) => {
  const reducedTags = {}
  state.items.allIds.forEach(id => {
    const item = state.items.byId[id]
    item.tags.forEach(tag => processTag(tag, reducedTags, item))
  })
  const selectedTags = (state.selectedTags || [])
  if (isNil(state.tags)) {
    state.tags = []
  }
  state.tags.forEach(tag => processTag(tag, reducedTags))
  selectedTags.forEach(tag => processTag(tag, reducedTags))
  const tags = Object.values(reducedTags).map(({ uncheckedItemCount, itemCount, name }) => ({
    name,
    uncheckedItemCount,
    itemCount,
    isSelected: selectedTags.includes(name),
  }))
  return sortBy(prop('name'), tags)
}
export const selectSelectedTags = ({ [shoppinglistSlice.name]: state }) => state.selectedTags
export const selectShowWithoutTags = ({ [shoppinglistSlice.name]: state }) => state.showWithoutTags
export const selectWithoutTagsCount = ({ [shoppinglistSlice.name]: state }) => {
  return state.items.allIds
    .map(id => state.items.byId[id])
    .filter(item => item.tags.length === 0 && !item.isChecked)
    .length
}
export const selectOrderBy = ({ [shoppinglistSlice.name]: state }) => state.orderBy

const filteredProposalsSelector = state => {
  const inputValue = state[shoppinglistSlice.name].inputValue.toLowerCase()
  const res = state[shoppinglistSlice.name].proposals.filter(proposal => {
    return proposal.toLowerCase().includes(inputValue)
  })
  return res
}

export const proposalsSelector = createSelector(
  filteredProposalsSelector,
  selectUncheckedItems,
  (proposals, uncheckedItems) => {
    const set = new Set(uncheckedItems.map(i => i.title))
    return proposals.map(proposal => ({
      title: proposal,
      isOnList: set.has(proposal),
    }))
  }
)

export const actions = {
  ...shoppinglistSlice.actions,
  addItem,
  deleteItem,
  editItem,
  toggleItem,
  syncItems,
  removeCheckedItems,
  fetchItems,
}
export default shoppinglistSlice

export function prepareItemForServer(item) {
  return {
    ...item,
    bought: item.isChecked ? 1 : 0,
  }
}

export function prepareItemForStore(rawItem) {
  return {
    ...rawItem,
    name: rawItem.itemName,
    isChecked: Boolean(rawItem.bought),
    date: new Date(Math.min(Date.now(), rawItem.date * 1000)).toISOString(),
    tags: extractTags(rawItem.details)
  }
}

function extractTags(details) {
  const tags = (details || '').split(',').map(s => s.trim()).filter(i => /^#/gi.test(i))
  return uniq(tags)
}

function isLocalItem(item) {
  return isUuidV4(item.id)
}
