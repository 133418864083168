import FlatasticConfig from '../scripts/config'
import FirebasePlugin from '../scripts/modules/flatasticFirebase'
import UserService from './user/svc_user'
import WgService from './wg/svc_wg'

export default angular.module('flatastic.initializer', [
  'ionic',
  WgService,
  UserService,
  FlatasticConfig,
  FirebasePlugin,
])
.config(
  ['$stateProvider', function ($stateProvider) {

  $stateProvider
    .state('init', {
      url: '/',
      onEnter,
    })
}])
.name;

onEnter.$inject = ['$state', '$ionicHistory', '$window', 'LocalStorageKeys', 'User', 'Wg', '$timeout', 'Firebase']
function onEnter($state, $ionicHistory, $window, LocalStorageKeys, User, Wg, $timeout, Firebase) {

  // User is not logged in
  if (!User.isLoggedIn()) {
    return goToRouteAndClearHistory('intro')
  }

  // User should continue setting up the household
  if (Wg.isSettingUp()) {
    return goToRouteAndClearHistory('intro-household-type')
  }

  // User is homeless
  if (User.isHomeless()) {
    return goToRouteAndClearHistory('homeless')
  }

  // User is living in household
  const previousScreen = $window.localStorage[LocalStorageKeys.behavior.lastUsedModule]
  const screenName =  (previousScreen && previousScreen.startsWith('household')) ? previousScreen : 'household.chat.messages'
  return goToRouteAndClearHistory(screenName)

  ////

  function goToRouteAndClearHistory(route) {
    return $timeout(0) // Ensure that the new state is considered
      .then(() => $state.go(route))
      .then(() => $timeout(750))
      .then(() => $ionicHistory.clearHistory())
      .catch(error => {
        Firebase.logError(`goToRouteAndClearHistory failed: ${error}`)
        throw error
      })
  }
}
