// ['en', 'de']

export default {
  [`Old Password`]: [
    `Old Password`,
    `Altes Passwort`,
  ],
  [`New Password`]: [
    `New Password`,
    `Neues Passwort`,
  ],
  [`USR__CHANGE_PW_TITLE`]: [
    `Change password`,
    `Passwort ändern`,
  ],
  [`USR__CHANGE_PW_SUCCESS-MSG`]: [
    `Your password has been changed`,
    `Dein Passwort wurde geändert.`,
  ],
  [`USR__CHANGE_PW_FAILED-MSG`]: [
    `Your password has not been changed. You probably have a typo in your old password.`,
    `Dein Passwort wurde nicht geändert. Vermutlich hast du dein altes Passwort falsch eingegeben.`,
  ],
  [`USR__CHANGE_PW_ERROR-MSG`]: [
    `An error accured while changing the password: {{ error }} `,
    `Beim Ändern des Passworts ist ein Fehler aufgetreten: {{ error }} `,
  ],
}
