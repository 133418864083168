import flatasticPushNotification from '../../scripts/modules/pushnotification'
import flatasticNotificationsFeedbackService from './svc_notificationsFeedback'

export const NotificationsFeedbackControllerName = `NotificationsFeedbackCtrl`
export default angular.module('flatastic.notificationsFeedback.controller', [
  flatasticNotificationsFeedbackService,
  flatasticPushNotification,
  'ionic',
])

.controller(NotificationsFeedbackControllerName,
  ['$scope', '$q', 'TestNotification', 'PushNotification', '$translate',
  function ($scope, $q, TestNotification, PushNotification, $translate) {

    var notificationTypes = {
      bb: $translate.instant('MENU__SHOUTS'),
      cho: $translate.instant('MENU__CHORES'),
      exp: $translate.instant('MENU__EXPENSES'),
      shl: $translate.instant('MENU__SHOPPING_LIST'),
      // We don't test the following at the moment:
      // wg: $translate.instant('SET__LIST-WG'),
      // sbs: $translate.instant('SUB__TITLE'),
    };
    $scope.notificationItems = [];
    $scope.isAccessGranted = false;
    $scope.isDeviceRegistered = false;
    $scope.maxTestRuns = 2;
    $scope.test = {
      isRunning: false,
      result: '',
      runs: 0,
      wasSuccessful: undefined,
      wasUnsuccessful: undefined,
    }
    let deviceToken;

    $scope.hasBackendDeviceToken = 'pending'
    $scope.isDeviceRegistered = 'pending'
    $scope.isAccessGranted = 'pending'
    PushNotification.checkPermissionAndInit(true)
      .then(() =>
        Promise.all([
          PushNotification.getToken(),
          PushNotification.hasPermission(),
        ])
      )
      .then(([token, isAccessGranted]) => {
        deviceToken = token
        $scope.isDeviceRegistered = Boolean(deviceToken);
        $scope.isAccessGranted = Boolean(isAccessGranted);
      })
      .then(PushNotification.hasBackendDeviceToken)
      .then(function(hasBackendDeviceToken) {
        $scope.hasBackendDeviceToken = hasBackendDeviceToken;
      })
      .catch(function() {
        $scope.hasBackendDeviceToken = false
        $scope.isDeviceRegistered = false
        $scope.isAccessGranted = false
      })


    $scope.runNotificationsTest = function() {
      $scope.notificationItems = Object.keys(notificationTypes).map(function(key) {
        return new TestNotification(notificationTypes[key], key, deviceToken);
      });
      var promises = $scope.notificationItems.map(function(item) {
        return item.promise
          .then(function() { return true; })
          .catch(function() { return false; });
      });
      $scope.test.isRunning = true;
      $scope.test.runs++;
      $scope.test.result = '';
      $scope.test.wasSuccessful = undefined;
      $scope.test.wasUnsuccessful = undefined;
      $scope.notificationItems.forEach(function(notification) {
        notification.runTest();
      });
      $q.all(promises)
        .then(function(res) {
          $scope.test.numSuccess = res.filter(function(result) {
            return result === true;
          }).length;
          $scope.test.numTotal = res.length;
          $scope.test.numFailures = res.length - $scope.test.numSuccess;
          if ($scope.test.numTotal === $scope.test.numSuccess) {
            $scope.test.wasSuccessful = true;
          } else {
            $scope.test.wasUnsuccessful = true;
          }
        })
        .finally(function() {
          $scope.test.isRunning = false;
        })
    }

}])

.name;
