import StatusBarModule from '../../scripts/modules/statusbar'
import appReviews from './app-reviews.comp'
import iapPlans from './iap-plans.comp'
import premiumAdvantages from './premium-advantages.comp'
import premiumFeedback from './premium-feedback.comp'
import flatasticPurchaseController from './purchase.ctrl'
import purchaseView from './purchase.html'
import flatasticPurchaseService from './svc_purchase'

export default angular
.module('flatastic.purchase', [
  flatasticPurchaseService,
  flatasticPurchaseController,
  premiumAdvantages,
  premiumFeedback,
  StatusBarModule,
  appReviews,
  iapPlans,
])
.config(
    ['$stateProvider',
    function ($stateProvider) {

  $stateProvider
  // Redirect wg.purchase to household.purchase
  .state('wg', {
    abstract: true,
  })
  .state('wg.purchase', {
    resolve: {
      noop: ['$state', '$timeout', function($state, $timeout) {
        $timeout(() => $state.go('household.purchase'), 0)
        return Promise.reject()
      }],
    },
  })
  .state('household.purchase', {
    url: '/purchase',
    onEnter: ['PurchaseModal', 'StatusBar', '$timeout', function(PurchaseModal, StatusBar, $timeout) {
      PurchaseModal.show()
      $timeout(250)
        .then(() => Promise.all([
          StatusBar.setStyleDefault(),
          StatusBar.backgroundColorByHexString('#D3EFE0'),
        ]))
        .catch(console.warn)
    }],
    onExit: ['PurchaseModal', 'StatusBar', function(PurchaseModal, StatusBar) {
      PurchaseModal.hide()
      Promise.all([
        StatusBar.setStyleDefault(),
        StatusBar.backgroundColorByHexString('#ffffff'),
      ]).catch(console.warn)
    }],
  });
}])

.factory('PurchaseModal', ['$rootScope', '$ionicModal', '$timeout', function($rootScope, $ionicModal, $timeout) {
  const template = purchaseView

  let modal
  let scope

  function show() {
    scope = $rootScope.$new(true)
    scope.didJustGoBack = false
    scope.modalExit = function() {
      console.log('modalExit')
      if (scope.didJustGoBack) {
        return
      }
      history.back();
      scope.didJustGoBack = true
      $timeout(750).then(() => (scope.didJustGoBack = false))
    }
    modal = $ionicModal.fromTemplate(template, {
      scope,
      animation: 'slide-in-up',
    })
    modal.show()
  }

  function hide() {
    modal.remove()
  }

  return {
    show,
    hide,
  }
}])

// .run(['$rootScope', '$timeout', function($rootScope, $timeout) {
//   // Ensure multiple dont' result in going back more than once
//   let didJustGoBack = false
//   $rootScope.modalExit = function() {
//     if (didJustGoBack) {
//       return
//     }
//     history.back();
//     didJustGoBack = true
//     $timeout(750).then(() => (didJustGoBack = false))
//   }
// }])

.name;
