export default angular.module('ft.directive.resizable-footer-content', [

])
.directive('resizableFooterContent', function() {
  return {
    restrict: 'A',
    scope: {
      isPulledOut: '=',
    },
    link,
  }

  function link(scope, element, attrs) {
    element[0].addEventListener('touchstart', onTouchStart, { passive: true } )
    scope.$on('$destroy', function() {
      element[0].removeEventListener('touchend', onTouchEnd)
      element[0].removeEventListener('touchstart', onTouchStart)
      element[0].removeEventListener('touchstart', onTouchStart )
    })

    const container = element.parent()[0]
    const contentWrapper = container.querySelector(`.${attrs.resizableFooterContent || 'input-footer-content'}`)

    let formerTransform
    let formerHeight
    let startHeight
    let startYPos

    const contentSnapHeight = parseInt(attrs.contentSnapHeight, 10)

    function getYPos(e) {
      return  e.pageY || e.changedTouches[0].pageY
    }

    scope.$on('resizable-footer-content.pull-out', function() {
      contentWrapper.style.height = `${contentSnapHeight}px`;
    })
    scope.$on('resizable-footer-content.pull-in', function() {
      contentWrapper.style.height = ``;
    })

    function onTouchStart(e) {
      element[0].removeEventListener('touchstart', onTouchStart)
      element[0].addEventListener('touchmove', onTouchMove, { passive: true })
      element[0].addEventListener('touchend', onTouchEnd, { passive: true })
      formerTransform = container.style.transform
      formerHeight = contentWrapper.style.height
      startHeight = parseInt(formerHeight.replace(/[^\d]*/, '') || 0)
      startYPos = getYPos(e)
    }
    function onTouchMove(e) {
      contentWrapper.style.height = `${startHeight - (getYPos(e) - startYPos)}px`
    }
    function onTouchEnd(e) {
      element[0].removeEventListener('touchend', onTouchEnd)
      element[0].addEventListener('touchstart', onTouchStart, { passive: true } )
      element[0].removeEventListener('touchmove', onTouchMove)
      const animationTime = 0.25
      contentWrapper.style.transition = `height ${animationTime}s ease-out`
      const moveDistance = startYPos - getYPos(e)
      const contentHeight =  parseInt(contentWrapper.style.height || 0)
      const heightBefore = parseInt(formerHeight || 0)
      setTimeout(function() {
        contentWrapper.style.transition = ``
      }, animationTime * 1000)

      container.style.transform = formerTransform

      if (contentHeight > contentSnapHeight / 2) {
        scope.$emit('resizable-footer-content.change')
        contentWrapper.style.height = `${contentSnapHeight}px`;
      } else {
        scope.$emit('resizable-footer-content.change')
        contentWrapper.style.height = ''
      }

      if (attrs.onOverPullIn && moveDistance < 0 && contentHeight <= 10 && contentHeight + moveDistance <= 0) {
        console.debug('onOverPullIn')
        scope.$emit('resizable-footer-content.onOverPullIn')
        scope.$apply(attrs.onOverPullIn)
      }
      if (attrs.onPullIn && contentHeight > 0 && contentHeight < contentSnapHeight / 2 && moveDistance < 0) {
        console.debug('onPullIn')
        scope.$emit('resizable-footer-content.onPullIn')
        scope.$apply(attrs.onPullIn)
        scope.isPulledOut = false
      }
      if (attrs.onPullOut && contentHeight > contentSnapHeight / 2 && moveDistance > contentSnapHeight / 2 && heightBefore !== contentSnapHeight) {
        console.debug('onPullOut')
        scope.$emit('resizable-footer-content.onPullOut')
        scope.$apply(attrs.onPullOut)
        scope.isPulledOut = true
      }
      if (attrs.onOverPullOut && contentHeight > contentSnapHeight * 5 / 3 && moveDistance > contentSnapHeight) {
        console.debug('onOverPullOut', attrs, attrs.onOverPullOut)
        scope.$emit('resizable-footer-content.onOverPullOut')
        scope.$apply(attrs.onOverPullOut)
      }

    }
  }
})
.name
