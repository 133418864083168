export default angular.module('flatastic.directives.adaptive-textarea', [])

.directive('adaptiveTextarea', ['$timeout', function ($timeout ) {
  return {
    restrict: 'A',
    link: function (scope, iElement, iAttrs) {
      $timeout(function() {
        iElement.css({ height: 0 });
        iElement.css({ height: iElement[0].scrollHeight + 'px' });
      })

      iElement.bind('keyup blur focus', function(e) {
        iElement.css({ height: 0 });
        iElement.css({ height: iElement[0].scrollHeight + 'px' });
      });
    },
  };
}])

.name;
