// ['en', 'de']

export default {

  [`New Subscription`]: [
    `New Subscription`,
    `Neuer Eintrag`,
  ],
  [`Edit Subscription`]: [
    `Edit Subscription`,
    `Eintrag bearbeiten`,
  ],
  [`Name`]: [
    `Name`,
    `Name`,
  ],
  [`e.g. Electricity`]: [
    `e.g. Electricity`,
    `z.B. Strom`,
  ],
  [`Details`]: [
    `Details`,
    `Beschreibung`,
  ],
  [`e.g. Provider Name`]: [
    `e.g. Provider Name`,
    `z.B. Anbieter`,
  ],
  [`First Bill`]: [
    `First Bill`,
    `Erste Rechnung`,
  ],
  [`Cycle`]: [
    `Cycle`,
    `Zyklus`,
  ],
  [`Category`]: [
    `Category`,
    `Kategorie`,
  ],
  [`Add`]: [
    `Add`,
    `Hinzufügen`,
  ],
  [`Save`]: [
    `Save`,
    `Speichern`,
  ],
  //// Legacy


}
