// ['en', 'de']

export default {
  [`EXP__SPENDINGS__TITLE`]: [
    `Personal spendings`,
    `Persönliche Ausgaben`,
    ],
  [`EXP__SPENDINGS__GET_PREMIUM__TEXT`]: [
    `Get Flatastic Premium to see your personal spendings.`,
    `Hole dir Flatastic Premium, um deine persönlichen Ausgaben zu sehen.`,
  ],
  [`EXP__SPENDINGS__GET_PREMIUM__CTA`]: [
    `Get Premium`,
    `Premium abschließen`,
  ],
  [`EXP__SPENDINGS__NO-ENTRIES`]: [
    `There seem to be no expenses affecting you in the last 12 months.`,
    `In den letzten 12 Monaten gibt es keine Ausgaben, die dich betreffen.`,
  ],
}
