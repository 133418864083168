export default {
  [`Overview`]: [
    `Overview`,
    `Übersicht`,
  ],
  [`Statistics`]: [
    `Statistics`,
    `Statistik`,
  ],
  [`Archive`]: [
    `Archive`,
    `Archiv`,
  ],
  [`Contracts & Subscriptions`]: [
    `Contracts & Subscriptions`,
    `Abos & Verträge`,
  ],
}
