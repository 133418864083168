import ApiService from '../../scripts/modules/api'
import CollectionService from '../../scripts/modules/collection'
import WgService from '../wg/svc_wg'
import SubscriptionService from './svc_subscription'

export default angular.module('flatastic.subscriptions.service.subscriptions', [
  CollectionService,
  ApiService,
  SubscriptionService,
  WgService,
])

.run(['Subscriptions', '$rootScope', 'FlatasticEvents',
    function(Subscriptions, $rootScope, FlatasticEvents) {

  $rootScope.$on(FlatasticEvents.subscriptions.update, function() {
    Subscriptions.getFromServer();
  });

  $rootScope.$on(FlatasticEvents.user.didLogout, function() {
    Subscriptions.clear();
  });

}])

.factory('Subscriptions',
    ['Api', 'YepptCollection', '$http', 'Subscription', 'Wg',
    function (Api, YepptCollection, $http, Subscription, Wg) {

  var subscriptions = new YepptCollection('id', null, 'Flatastic.subscriptions');
  subscriptions.manipulate(function(subscription) {
    return new Subscription(subscription);
  });

  var countryToLanguage = {
    1: 'en_UK', // 'en_UK',
    2: 'de_DE', // 'de_DE',
    3: 'de_DE', // 'de_AT',
    4: 'de_DE', // 'de_CH',
  };

  var proposals = new YepptCollection('proposalId', null);

  var status;
  var error;

  function getLocalProposals() {
    $http.get('data/subscription_proposals.json')
    .then(function({ data }) {
      var language = countryToLanguage[Wg.country];
      proposals.remove();
      proposals.push(data[language].map(function(proposal) {
        proposal.isProposal = true;
        proposal.firstBill = new Date()
        return new Subscription(proposal);
      }));
    });
  }

  var cycleTimes = {
    TIME__WEEKLY: 12 / 52,
    TIME__MONTHLY: 1,
    TIME__EVERY_YEAR: 12,
    TIME__EVERY_HALF_YEAR: 6,
    TIME__QUARTERLY: 3,
  };

  function calculateMonthlySum() {
    return subscriptions.reduce(function(before, item) {
      return before + item.price / cycleTimes[item.cycle];
    }, 0);
  }

  function getFromServer() {
    status = 'loading';
    return Api.get('/subscriptions')
      .then(function({ data }) {
        var temp = [];
        status = '';
        data.forEach(function(elem) {
          temp.push(new Subscription(elem));
        });
        subscriptions.remove();
        subscriptions.push(temp);
        status = '';
      })
      .catch(function(err) {
        status = 'error';
        error = err;
        throw err
      });
  }

  function getStatus() {
    return status;
  }

  function clear() {
    subscriptions.remove();
  }

  return {
    clear,
    getStatus,
    getFromServer,
    getLocalProposals,
    monthlySum: calculateMonthlySum,
    proposals,
    subscriptions,
  };

}])

.name;
