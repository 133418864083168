import AdaptiveTextareaDirective from '../../modules/general/directive_adaptive-textarea'
import LegacyShoutsController from '../../modules/shouts/ctrl_shouts'
import chatActivitiesTemplate from './chat-activities.html'
import chatMessagesTemplate from './chat-messages.html'
import BulletinBoardController from './chat.ctrl'
import template from './chat.html'
import './chat.scss'
import ActivityMessageComponent from './components/activity-message/index'
import ChatMessageComponent from './components/chat-message/index'

export default angular.module('ft.chat', [
  LegacyShoutsController,
  ChatMessageComponent,
  ActivityMessageComponent,
  AdaptiveTextareaDirective,
  BulletinBoardController,
])
  .config(ChatConfig)
  .name;


ChatConfig.$inject = ['$stateProvider']
function ChatConfig($stateProvider) {
  $stateProvider
    .state('household.chat', {
      url: '/chat',
      onEnter: ['$ionicHistory', function($ionicHistory) {
        $ionicHistory.nextViewOptions({
          disableAnimate: true,
        })
      }],
      views: {
        'chat-tab': {
          abstract: true,
          controller: 'BulletinBoardController',
          controllerAs: 'vm',
          template,
        },
      },
    })
    .state('household.chat.messages', {
      url: '/messages',
      viewName: '/chat/messages',
      views: {
        'chat-content': {
          template: chatMessagesTemplate,
          controller: 'ShoutsCtrl',
        },
      },
    })
    .state('household.chat.activities', {
      url: '/activities',
      viewName: '/chat/activities',
      views: {
        'chat-content': {
          template: chatActivitiesTemplate,
          controller: 'ShoutsCtrl',
        },
      },
    })
}
