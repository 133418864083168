// ['en', 'de']

export default {
  [`Edit`]: [
    `Edit`,
    `Bearbeiten`,
  ],
  [`Added by person on date`]: [
    `Added by {{ name }} on {{ date }}`,
    `Von {{ name }} hinzugefügt am {{ date }}`,
  ],
  [`Added by me on date`]: [
    `Added by me on {{ date }}`,
    `Von mir hinzugefügt am {{ date }}`,
  ],
  [`I paid`]: [
    `I paid`,
    `Ich habe gezahlt`,
  ],
  [`Person paid`]: [
    `{{ name }} paid`,
    `{{ name }} hat gezahlt`,
  ],
  [`I'm owed`]: [
    `I'm owed`,
    `Ich erhalte zurück`,
  ],
  [`I owe person`]: [
    `I owe {{ name }}`,
    `Ich schulde {{ name }}`,
  ],
  [`Person owes`]: [
    `{{ name }} owes`,
    `{{ name }} schuldet`,
  ],
  [`Item title`]: [
    `Item title`,
    `Artikelbezeichnung`,
  ],
  [`Bought Products`]: [
    `Bought Products`,
    `Gekaufte Produkte`,
  ],
  [`Event Title`]: [
    `Event Title`,
    `Titel für Eintrag`,
  ],
  [`Date`]: [
    `Date`,
    `Datum`,
  ],
  [`Details:`]: [
    `Details:`,
    `Details:`,
  ],
  [`Delete Entry`]: [
    `Delete Entry`,
    `Eintrag löschen`,
  ],
  [`Paid by person for everyone split equal`]: [
    `Paid by {{ name }} for everyone split equal`,
    `Von {{ name }} bezahlt für alle gleichmässig aufgeteilt`,
  ],
  [`Show image`]: [
    `Show image`,
    `Bild anzeigen`,
  ],
  [`Soon you'll be able to add images to your expenses. Stay tuned.`]: [
    `Soon you'll be able to add images to your expenses. Stay tuned.`,
    `Bald kannst du Bilder zu Finanzeinträgen hinzüfugen. Sei gespannt.`,
  ],
  [`OK`]: [
    `OK`,
    `Alles klar`,
  ],

  //// Legacy

  // Delete Expense
  [`EXP__DELETE-TITLE`]: [
    `Delete entry`,
    `Eintrag löschen`,
  ],
  [`EXP__DELETE-MSG`]: [
    `Do you really want to delete the entry "{{ name }}"?`,
    `Möchtest du den Eintrag "{{ name }}" wirklich löschen?`,
  ],
  [``]: [
    ``,
    ``,
  ],
  [``]: [
    ``,
    ``,
  ],
  [``]: [
    ``,
    ``,
  ],
  [``]: [
    ``,
    ``,
  ],
}
