import FlatasticConfig from '../../scripts/config'
import ApiService from '../../scripts/modules/api'
import WgService from '../wg/svc_wg'
import ChoreProposalService from './svc_choreproposal'
import ChoresService from './svc_chores'

export default angular.module('flatastic.chores.proposals', [
  ApiService,
  ChoreProposalService,
  ChoresService,
  WgService,
  'ionic',
  FlatasticConfig,
])

.factory('ChoreProposals',
    ['Api', 'ChoreProposal', 'Chores', 'Wg', '$window',
    '$ionicScrollDelegate', 'LocalStorageKeys',
    function(Api, ChoreProposal, Chores, Wg, $window,
    $ionicScrollDelegate, LocalStorageKeys) {

  var config = {
    hideKey: LocalStorageKeys.choreProposals.hide,
    dataKey: LocalStorageKeys.choreProposals.data,
    updateInterval: 12 * 60 * 60 * 1000,
  };

  var data = {
    showMax: 3,
    canShowMore: true,
    show: true,
  };

  let isInitialized = false

  var proposals = [];
  init();

  return {
    canShowMore,
    get,
    hide,
    length,
    show,
    showMore,
  };

  ////////////

  function addToProposals(items) {
    if (!items || !Array.isArray(items)) {
      return;
    }
    items.sort(function(a, b) {
      return a.sortOrder - b.sortOrder;
    });
    items.forEach(function(item) {
      proposals.push(new ChoreProposal(item));
    });
  };

  function canShowMore() {
    return get('all').length > data.showMax;
  }

  function get(all) {
    var counter = 0;
    var addedTasks = Chores.collection.accumulate('title');
    return proposals.filter(function(proposal) {
      if (!all && counter >= data.showMax) {
        return false;
      }
      if (proposal.added || proposal.dismissed) {
        return false;
      }
      // Proposal is not already put on the chore list
      if (addedTasks.indexOf(proposal.title) > -1) {
        return false;
      }
      counter++;
      return true;
    });
  }

  function getFromServer(countryId) {
    return Api.get('/chores/proposals');
  }

  function hide() {
    data.show = false;
    $window.localStorage[config.hideKey] = true;
    $ionicScrollDelegate.resize();
  }

  function init() {
    if ($window.localStorage[config.dataKey]) {
      var data = JSON.parse($window.localStorage[config.dataKey]);
      var now = new Date().getTime();
      var updateIsNeeded = (now - config.updateInterval) > new Date(data.lastUpdate).getTime();
      if (!updateIsNeeded && data.language === Wg.country) {
        isInitialized = true
        addToProposals(data.items);
        return;
      }
    }
    getFromServer(Wg.country)
    .success(function(items) {
      isInitialized = true
      saveToLocalStorage(items);
      addToProposals(items);
    });
  }

  function length() {
    return get().length;
  }

  function saveToLocalStorage(items) {
    $window.localStorage[config.dataKey] = JSON.stringify({
      lastUpdate: new Date(),
      language: Wg.country,
      items: items,
    });
  }

  function show() {
    if (!isInitialized) {
      return false
    }
    data.show = !$window.localStorage[config.hideKey];
    return (data.show || Chores.length() === 0) && (length() > 0);
  }

  function showMore() {
    data.showMax += 3;
    $ionicScrollDelegate.resize();
  }

}])

.name;
