export default angular.module('flatastic.expenses.filter.proposals', [
])

.filter('expProposals', [function() {
  return function(val, searchString = '', resultLength, addedStrings) {

    var validItems = val.filter(function(item = '') {
      // must start with searchString
      if (searchString.length > 0 && item.toLowerCase().indexOf(searchString.toLowerCase()) !== 0) {
        return false;
      }

      // must not be in list
      if (angular.isArray(addedStrings) && searchString.length === 0) {
        for (var i = 0; i < addedStrings.length; i++) {
          if (addedStrings[i].name.toLowerCase() == item.toLowerCase()) {
            return false;
          }
        }
      }

      return true;
    });

    return validItems.slice(0, resultLength);
  };

}])

.name;
