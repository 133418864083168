import { hasPath } from 'ramda'
import CordovaModule from '../modules/yptCordova'

export default angular.module('flatastic.fileSystem', [
  CordovaModule,
])

.factory('FileSystem', ['yptCordova', '$window', function(yptCordova, $window) {

  let storageDirectory

  init()

  return {
    getFileBlob,
    getLocalFilePath,
  }

  ////

  function init() {
    return yptCordova.ready.then(() => {
      if (!hasPath(['cordova', 'file', 'applicationStorageDirectory'], window)) {
        return Promise.reject('flatastic.fileSystem: cordova.file.applicationStorageDirectory is not defined')
      }
      storageDirectory = cordova.file.applicationStorageDirectory
      return storageDirectory
    })
  }

  function getLocalFilePath(fileURL) {
    if (fileURL.constructor === File) {
      return (URL.createObjectURL || window.webkitURL)(fileURL)
    }
    if (ionic.Platform.isIOS()) {
      return WkWebView.convertFilePath(fileURL)
    }
    if (ionic.Platform.isAndroid() && storageDirectory) {
      console.log('Android updated value', fileURL.replace(storageDirectory, 'cdvfile://'))
      return fileURL.replace(storageDirectory, 'cdvfile://localhost/')
    }
    return fileURL
  }

  function getFileBlob(fileURL) {
    return resolvePath(fileURL)
      .then(getFileFromFileEntry)
      .then(createBlob)
  }

  function getFileFromFileEntry(fileEntry) {
    return new Promise(resolve => fileEntry.file(resolve))
  }

  function createBlob(file) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onloadend = function() {
        const blob = new Blob([new Uint8Array(this.result)], { type: file.type })
        resolve(blob)
      }
      reader.readAsArrayBuffer(file)
    })
  }

  function resolvePath(path) {
    return yptCordova.ready.then(() => new Promise((resolve, reject) => {
      if (!$window.resolveLocalFileSystemURL) {
        console.warn('window.resolveLocalFileSystemURL is not defined')
        return reject('window.resolveLocalFileSystemURL is not defined')
      }
      return resolveLocalFileSystemURL(path, resolve, reject)
    }))
  }

}])

.name;
