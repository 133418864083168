import ChoresFilterTranslations from './chores-filter.trans'
import ChoresHistoryTranslations from './chores-history.trans'
import ChoresNewTranslations from './chores-new.trans'
import ChoresStatisticsTranslations from './chores-statistics.trans'
import ChoresTranslations from './chores.trans'
import ChoreProposalsTranslations from './components/chore-proposals/translations'
import TimeTranslations from './time.trans'

export default {
  ...TimeTranslations,
  ...ChoresFilterTranslations,
  ...ChoreProposalsTranslations,
  ...ChoresNewTranslations,
  ...ChoresTranslations,
  ...ChoresHistoryTranslations,
  ...ChoresStatisticsTranslations,
}
