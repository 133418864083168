import './styles.scss'
import template from './template.html'

export const PremiumBanner = angular.module('ft.components.premium-banner', [
])
.component('premiumBanner', {
  template,
  transclude: true,
})
.name
