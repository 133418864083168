import AngularTranslate from 'angular-translate'
import template from './premium-advantages.comp.html'

export default angular.module('flatastic.component.premium-advantages', [
  AngularTranslate,
])

.component('premiumAdvantages', {
  controller: ['$translate', function($translate) {
    this.premiumAdvantages = [
      {
        title: $translate.instant('Purchase:Advantages:ChoresHistory:Title'),
        description: $translate.instant('Purchase:Advantages:ChoresHistory:Text'),
      }, {
        title: $translate.instant('Purchase:Advantages:PersonalSpendings:Title'),
        description: $translate.instant('Purchase:Advantages:PersonalSpendings:Text'),
      }, {
        title: $translate.instant('Purchase:Advantages:NoAds:Title'),
        description: $translate.instant('Purchase:Advantages:NoAds:Text'),
      }, {
        title: $translate.instant('Purchase:Advantages:ExpenseExport:Title'),
        description: $translate.instant('Purchase:Advantages:ExpenseExport:Text'),
      }, {
        title: $translate.instant('Purchase:Advantages:SettlementArchive:Title'),
        description: $translate.instant('Purchase:Advantages:SettlementArchive:Text'),
      }, {
        title: $translate.instant('Purchase:Advantages:ImageUpload:Title'),
        description: $translate.instant('Purchase:Advantages:ImageUpload:Text'),
        soon: true,
      },
    ]
  }],
  template,
})

.name;
