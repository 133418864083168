import flatasticWgService from '../wg/svc_wg'
import flatasticChoresHistoryService from './svc_chores-history'

export default angular.module('flatastic.chores.history.controller', [
  flatasticChoresHistoryService,
  flatasticWgService,
  'ionic',
])

.controller('ChoresHistoryCtrl',
    ['$scope', '$stateParams', 'ChoresHistory', 'Wg',
    function ($scope, $stateParams, ChoresHistory, Wg) {

  $scope.flatmates = Wg.flatmates;
  $scope.choresHistory = [];
  $scope.getError = getError;
  $scope.isLoading = isLoading;
  $scope.selectedUserId = $stateParams.userId || null

  ChoresHistory.fetch($stateParams)
    .success(updateData)

  $scope.refresh = function() {
    $scope.showLoadingIndicator = true;
    ChoresHistory.fetch($stateParams)
    .success(updateData)
    .finally(function() {
      $scope.$broadcast('scroll.refreshComplete');
      $scope.showLoadingIndicator = false;
    });
  };

  $scope.loadMore = function() {
    ChoresHistory.loadMore($stateParams)
    .success(updateData)
    .finally(function() {
      $scope.$broadcast('scroll.infiniteScrollComplete');
    });
  };
  $scope.moreCanBeLoaded = ChoresHistory.moreCanBeLoaded;

  ////

  function updateData() {
    $scope.choresHistory = ChoresHistory.get().data;
  }

  function isLoading() {
    return ChoresHistory.get().status;
  }

  function getError() {
    return ChoresHistory.get().error;
  }
}])

.name;
