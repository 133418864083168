import AngularTranslate from 'angular-translate'

export default angular.module('flatastic.chores.service.effort-values', [
  AngularTranslate,
])

.service('EffortValues', ['$translate', function($translate) {
  var values = [{
    label: $translate.instant('CHO__EFFORT__WITHOUT_RATING')
        + ' (0 ' + $translate.instant('POINTS') + ')',
    value: 0,
  }, {
    label: $translate.instant('CHO__FORM-EFFORT__NORMAL')
        + ' (1 ' + $translate.instant('POINT') + ')',
    value: 1,
  }, {
    label: $translate.instant('CHO__FORM-EFFORT__BIG')
        + ' (2 ' + $translate.instant('POINTS') + ')',
    value: 2,
  }, {
    label: $translate.instant('CHO__FORM-EFFORT__HUGE')
        + ' (4 ' + $translate.instant('POINTS') + ')',
    value: 4,
  }];

  this.values = values;
  this.getLabelFor = function(value) {
    for (var i = 0; i < values.length; i++) {
      var elem = values[i];
      if (value === elem.value) {
        return elem.label;
      }
    }
    return value;
  };

}])

.name;
