 // Countries
 export default {
  [`Argentina`]: [
    `Argentina`,
    `Argentinien`,
  ],
  [`Australia`]: [
    `Australia`,
    `Australien`,
  ],
  [`Austria`]: [
    `Austria`,
    `Österreich`,
  ],
  [`Belgium`]: [
    `Belgium`,
    `Belgien`,
  ],
  [`Brazil`]: [
    `Brazil`,
    `Brasilien`,
  ],
  [`Bulgaria`]: [
    `Bulgaria`,
    `Bulgarien`,
  ],
  [`Canada`]: [
    `Canada`,
    `Kanada`,
  ],
  [`China`]: [
    `China`,
    `China`,
  ],
  [`Denmark`]: [
    `Denmark`,
    `Dänemark`,
  ],
  [`Finland`]: [
    `Finland`,
    `Finland`,
  ],
  [`France`]: [
    `France`,
    `Frankreich`,
  ],
  [`Germany`]: [
    `Germany`,
    `Deutschland`,
  ],
  [`Greece`]: [
    `Greece`,
    `Griechenland`,
  ],
  [`India`]: [
    `India`,
    `Indien`,
  ],
  [`Ireland`]: [
    `Ireland`,
    `Irland`,
  ],
  [`Italy`]: [
    `Italy`,
    `Italien`,
  ],
  [`Japan`]: [
    `Japan`,
    `Japan`,
  ],
  [`Mexico`]: [
    `Mexico`,
    `Mexiko`,
  ],
  [`Netherlands`]: [
    `Netherlands`,
    `Niederlande`,
  ],
  [`Norway`]: [
    `Norway`,
    `Norwegen`,
  ],
  [`Poland`]: [
    `Poland`,
    `Polen`,
  ],
  [`Portugal`]: [
    `Portugal`,
    `Portugal`,
  ],
  [`Russia`]: [
    `Russia`,
    `Russland`,
  ],
  [`Spain`]: [
    `Spain`,
    `Spanien`,
  ],
  [`Hungary`]: [
    `Hungary`,
    `Ungarn`,
  ],
  [`Sweden`]: [
    `Sweden`,
    `Schweden`,
  ],
  [`Switzerland`]: [
    `Switzerland`,
    `Schweiz`,
  ],
  [`Turkey`]: [
    `Turkey`,
    `Türkei`,
  ],
  [`United Kingdom`]: [
    `United Kingdom`,
    `Großbritanien`,
  ],
  [`USA`]: [
    `USA`,
    `USA`,
  ],
}
