// ['en', 'de']

export default {

  [`(me)`]: [
    `(me)`,
    `(ich)`,
  ],
  [`Key:`]: [
    `Key:`,
    `Einladungscode:`,
  ],
  [`Invite someone else`]: [
    `Invite someone else`,
    `Weitere Person einladen`,
  ],

  //// Legacy

  // Cancel invitation
  [`WG__CANCEL_INVITATION_CONFIRM-TITLE`]: [
    `Delete invitation`,
    `Einladung zurücknehmen`,
  ],
  [`WG__CANCEL_INVITATION_CONFIRM-MSG`]: [
    `All expenses having to do with "{{ name }}" will be deleted. Do you really want to delete the invitation for "{{ name }}"?`,
    `Alle bisherigen Finanzeinträge, die mit “{{ name }}” in Verbindung stehen werden gelöscht. Möchtest du die Einladung für “{{ name }}” wirklich zurücknehmen?`,
  ],
  [`WG__CANCEL_INVITATION_CONFIRM-BTN`]: [
    `Delete invitation and expenses`,
    `Einladung und Einträge löschen`,
  ],
  [`WG__CANCEL_INVITATION_SUCCESS-TITLE`]: [
    `Invitation has been deleted.`,
    `Einladung wieder gelöscht`,
  ],
  [`WG__CANCEL_INVITATION_SUCCESS-MSG`]: [
    `{{ name }} has been removed from your household.`,
    `{{ name }} wurde aus deinem Haushalt entfernt.`,
  ],
  [`WG__CANCEL_INVITATION_ERROR-TITLE`]: [
    `Invitation has not been deleted.`,
    `Einladung nicht gelöscht`,
  ],
  [`WG__CANCEL_INVITATION_ERROR-MSG`]: [
    `An error occured. {{ name }} has not been removed from your household:\n{{ error }}',`,
    `Es ist ein Fehler aufgetreten. {{ name }} wurde nicht aus deinem Haushalt entfernt:\n{{ error }}`,
  ],
  [`Move out`]: [
    `Move out`,
    `Ausziehen`,
  ],
  [`Moving out will trigger a "settle up" for everyone in your home.`]: [
    `Moving out will trigger a "settle up" for everyone in your home.`,
    `Beim Ausziehen wird ein Kassensturz für den gesamten Haushalt veranlasst.`,
  ],
  // Kickout
  [`WG__INTERACT_TITLE`]: [
    `{{ name }}`,
    `{{ name }}`,
  ],
    [`WG__INTERACT_OPTIONS-TITLE`]: [
      `Please choose`,
      `Bitte wähle`,
  ],
    [`WG__INTERACT_OPTIONS-DELETE_INVITE`]: [
      `Delete invitation`,
      `Einladung löschen`,
  ],
    [`WG__INTERACT_OPTIONS-SEND_WG_CODE`]: [
      `Send access code`,
      `Code verschicken`,
  ],
    [`WG__INTERACT_OPTIONS-KICKOUT`]: [
      `Kick out`,
      `Rausschmeißen`,
  ],
  // Move out
  [`CONFIRM`]: [
    `OK`,
    `Alles klar`,
  ],
  [`WG__MOVE_OUT__START-TITLE`]: [
    `Move Out`,
    `Ausziehen`,
  ],
  [`WG__MOVE_OUT__START-MSG`]: [
    `Do you really want to move out? This action cannot be undone and you may loose data.`,
    `Bist du dir sicher, dass du ausziehen möchtest? Diese Aktion kann nicht rückgängig gemacht werden.`,
  ],
  [`WG__MOVE_OUT__START-CONFIRM`]: [
    `Move Out`,
    `Ausziehen`,
  ],
  [`WG__MOVE_OUT_CONFIRM`]: [
    `Are you sure you want to move out?`,
    `Bist du wirklich sicher, dass du ausziehen möchtest?`,
  ],
  [`WG__MOVE_OUT_CONFIRM-TITLE`]: [
    `Move out`,
    `Ausziehen`,
  ],
  [`WG__MOVE_OUT_CONFIRM_BUTTON`]: [
    `Move out now`,
    `Jetzt ausziehen`,
  ],
  [`WG__MOVE_OUT_CONFIRM_SUCCESS-TITLE`]: [
    `Move out`,
    `Auszug`,
  ],
  [`WG__MOVE_OUT_CONFIRM_SUCCESS-MSG`]: [
    `You succesfully moved out.`,
    `Du bist erfolgreich ausgezogen.`,
  ],
  [`WG__MOVE_OUT_CONFIRM_ERROR-TITLE`]: [
    `Move out`,
    `Auszug`,
  ],
  [`WG__MOVE_OUT_CONFIRM_ERROR-MSG`]: [
    `An error occured. Please send us an email to support@flatastic-app.com, so we can help you!`,
    `Es ist ein Fehler aufgetreten. Bitte schick uns eine Email an support@flatastic-app.com, damit wir weiterhelfen können!`,
  ],

  [`WG__SETTLEMENT_MESSAGE_PART`]: [
    `{{ sender }} pays {{ valueString }} to {{ receiver }}\n`,
    `{{ sender }} zahlt {{ valueString }} an {{ receiver }}\n`,
  ],
  [`WG__SETTLEMENT_CONFIRM-TITLE`]: [
    `Finances balanced`,
    `Finanzen ausgleichen`,
  ],
  [`WG__SETTLEMENT_CONFIRM-MSG`]: [
    `Before moving out you have to balance your finances. It will work out like this:\n\n{{ settlement }}`,
    'Zum Ausziehen müsst ihr erst eure Finanzen ausgleichen. Das geht bei euch wie folgt:\n\n{{ settlement }}',
  ],
  [`WG__SETTLEMENT_CONFIRM-NOT_YET`]: [
    `Not yet`,
    `Noch nicht`,
  ],
  [`WG__SETTLEMENT_CONFIRM-DO_NOW`]: [
    `Balance finances now`,
    `Jetzt Finanzen ausgleichen`,
  ],
  [`WG__SETTLEMENT_CONFIRM_SUCCESS_CONFIRM_MOVE_OUT`]: [
    `Your finances are now balanced. Are you still sure you want to move out?`,
    `Eure Finanzen sind jetzt ausgeglichen. Bist du weiterhin sicher, dass du ausziehen möchtest?`,
  ],
  [`WG__KICK_OUT_CONFIRM-TITLE`]: [
    `Kick out {{ name }}`,
    `{{ name }} rausschmeißen`,
  ],
  [`WG__KICK_OUT_CONFIRM-MESSAGE`]: [
    `Do you really want to kick {{ name }} out of your shared home? That\'s not nice and the action cannot be undone. To confirm type "{{ name }}".`,
    `Möchtest du {{ name }} wirklich aus der WG rausschmeißen? Das wäre nicht sehr nett und die Aktion kann auch nicht rückgängig gemacht werden. Schreibe zum Bestätigen "{{ name }}".`,
  ],
  [`WG__KICK_OUT_CONFIRM-BUTTON`]: [
    `Kick out`,
    `Rausschmeißen`,
  ],
  [`WG__KICK_OUT_ERROR-MESSAGE`]: [
    `There was a problem. Please write an email to support@flatastic-app.com.`,
    `Es gab ein Problem. Bitte schreibe eine E-Mail an support@flatastic-app.com.`,
  ],
  [`WG__KICK_OUT_SETTLEMENT_NEEDED`]: [
    `The expenses are not settled. Please do a settlement first.`,
    `Die Finanzen sind nicht ausgeglichen. Bitte mache zuerst einen Kassensturz.`,
  ],
  [`WG__KICK_OUT-LOADING`]: [
    `{{ name }} is being kicked out…`,
    `{{ name }} wird rausgeschmissen…`,
  ],
  [`WG__KICK_OUT__TYPE_ERROR-TITLE`]: [
    `Typing Mistake`,
    `Vertippt…`,
  ],
  [`WG__KICK_OUT__TYPE_ERROR-MESSAGE`]: [
    `The input did not match to your roomie\'s name.`,
    `Da hat sich wohl ein Tippfehler eingeschlichen. Versuch\'s noch einmal.`,
  ],
}

