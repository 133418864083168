import AngularTranslate from 'angular-translate'

export default angular.module('flatastic.chores.service.rotationTimes', [
  AngularTranslate,
])

.service('RotationTimes', ['$translate', function($translate) {
  var values = [{
    label: $translate.instant('TIME__AS_NEEDED'),
    value: -1,
  }, {
    label: $translate.instant('TIME__ONCE'),
    value: -2,
  }, {
    label: $translate.instant('TIME__DAILY'),
    value: 1 * 60 * 60 * 24,
  }, {
    label: $translate.instant('TIME__EVERY_2_DAYS'),
    value: 2 * 60 * 60 * 24,
  }, {
    label: $translate.instant('TIME__EVERY_3_DAYS'),
    value: 3 * 60 * 60 * 24,
  }, {
    label: $translate.instant('TIME__EVERY_4_DAYS'),
    value: 4 * 60 * 60 * 24,
  }, {
    label: $translate.instant('TIME__EVERY_5_DAYS'),
    value: 5 * 60 * 60 * 24,
  }, {
    label: $translate.instant('TIME__EVERY_6_DAYS'),
    value: 6 * 60 * 60 * 24,
  }, {
    label: $translate.instant('TIME__WEEKLY'),
    value: 1 * 7 * 24 * 60 * 60,
  }, {
    label: $translate.instant('TIME__EVERY_2_WEEKS'),
    value: 2 * 7 * 24 * 60 * 60,
  }, {
    label: $translate.instant('TIME__EVERY_3_WEEKS'),
    value: 3 * 7 * 24 * 60 * 60,
  }, {
    label: $translate.instant('TIME__EVERY_4_WEEKS'),
    value: 4 * 7 * 24 * 60 * 60,
  }, {
    label: $translate.instant('TIME__EVERY_5_WEEKS'),
    value: 5 * 7 * 24 * 60 * 60,
  }, {
    label: $translate.instant('TIME__EVERY_6_WEEKS'),
    value: 6 * 7 * 24 * 60 * 60,
  }, {
    label: $translate.instant('TIME__EVERY_2_MONTHS'),
    value: 2 * 30 * 60 * 60 * 24,
  }, {
    label: $translate.instant('TIME__EVERY_3_MONTHS'),
    value: 3 * 30 * 60 * 60 * 24,
  }, {
    label: $translate.instant('TIME__EVERY_4_MONTHS'),
    value: 4 * 30 * 60 * 60 * 24,
  }, {
    label: $translate.instant('TIME__EVERY_HALF_YEAR'),
    value: 183 * 60 * 60 * 24,
  }, {
    label: $translate.instant('TIME__EVERY_YEAR'),
    value: 365 * 60 * 60 * 24,
  }];

  this.values = values;
  this.getLabelFor = function(value) {
    for (var i = 0; i < values.length; i++) {
      var elem = values[i];
      if (value === elem.value) {
        return elem.label;
      }
    }
    return value;
  };

}])

.name;
