import HomelessController, { HomelessControllerName } from './homeless.ctrl'
import template from './template.html'

export default angular.module('ft.homeless', [
  HomelessController,
])
.config(['$stateProvider', function($stateProvider) {

  $stateProvider
    .state('homeless', {
      url: '/homeless',
      views: {
        root: {
          template,
          controller: HomelessControllerName,
          controllerAs: 'vm',
        },
      },
      onEnter,
    })
}])

.name

onEnter.$inject = ['$ionicHistory', '$timeout']
function onEnter($ionicHistory, $timeout) {
  return $ionicHistory.clearHistory()
}
