import AngularTranslate from 'angular-translate'
import { allPass, anyPass, filter, gt, ifElse, includes, isEmpty, map, not, pipe, prop, propEq, T } from 'ramda'
import { toggleItemInList } from '../../scripts/helpers'
import FirebaseModule from '../../scripts/modules/flatasticFirebase'
import ToastService from '../../scripts/modules/mcmToast'
import ActionsheetService from '../../scripts/modules/yptActionsheet'
import CordovaService from '../../scripts/modules/yptCordova'
import UserService from '../user/svc_user'
import WgService from '../wg/svc_wg'
import templatePopoverOptions from './popover-options.html'
import './popover.scss'
import ChoreProposalsService from './svc_choreproposals'
import ChoresService from './svc_chores'
import RotationTimeService from './svc_rotation-times'
import { CHORES__FILTER__PAYWALL__MSG } from './translations'

export default angular.module('flatastic.chores.controller.chores', [
  ChoresService,
  ChoreProposalsService,
  CordovaService,
  ActionsheetService,
  UserService,
  RotationTimeService,
  ToastService,
  WgService,
  FirebaseModule,
  AngularTranslate,
  'ionic',
])

.controller('ChoresCtrl',
    ['$scope', 'Chores', 'yptNotify', '$state',
    '$filter', 'yptActionsheet', 'User', 'Wg', '$ionicScrollDelegate',
    'RotationTimes', '$timeout', '$rootScope', 'ChoreProposals', 'mcmToast',
    '$ionicHistory', '$ionicPopover', 'Firebase', '$translate',
    function ($scope, Chores, yptNotify, $state,
    $filter, yptActionsheet, User, Wg, $ionicScrollDelegate,
    RotationTimes, $timeout, $rootScope, ChoreProposals, mcmToast,
    $ionicHistory, $ionicPopover, Firebase, $translate) {

  // Filter
  $scope.isFilterExperimentEnabled = false;
  Firebase.getConfigValue('chores_filter_enabled', false)
    .then(isEnabled => { $scope.isFilterExperimentEnabled = isEnabled })
  $scope.popover = $ionicPopover.fromTemplate(templatePopoverOptions, { scope: $scope })
  $scope.openPopover = ($event) => {
    $rootScope.$broadcast('track-event', {
      event: 'chores_clicked_filter',
    })
    if (!User.isPremium()) {
      const label = 'Use chores filter'
      const message = CHORES__FILTER__PAYWALL__MSG
      return $state.go('premium-paywall', { label, message });
    }
    $scope.popover.show($event)
  }
  $scope.$on('$destroy', $scope.popover.remove)
  $scope.appliedFilters = []
  $scope.toggleFilter = function(item) {
    $scope.appliedFilters = toggleItemInList($scope.appliedFilters)(item)
  }
  const getChoreTypeFilters = filter(propEq('type', 'choreType'))
  const getMemberFilters = filter(propEq('type', 'member'))
  const getUserIdsOfPickedMembers = pipe(getMemberFilters, map(prop('payload')))
  const isMemberSelected = function(userId) {
    return pipe(
      getUserIdsOfPickedMembers,
      ifElse(isEmpty, T, includes(userId))
    )($scope.appliedFilters)
  }
  $scope.isSelected = isMemberSelected
  $scope.choreFilter = function(chore) {
    if ($scope.appliedFilters.length === 0) {
      return true
    }
    const choreTypeFilters = getChoreTypeFilters($scope.appliedFilters)

    return isMemberSelected(chore.currentUser) && satisfiesChoreTypeFilters(choreTypeFilters)(chore)

    function satisfiesChoreTypeFilters(choreTypeFilters) {
      const isOverdue = allPass([pipe(prop('timeLeftNext'), gt(0)), pipe(propEq('rotationTime', -1), not)])
      const isOneTimeChore = propEq('rotationTime', -2)
      const isOnDemandChore = propEq('rotationTime', -1)
      if (choreTypeFilters.length === 0) {
        return T
      }
      return anyPass(choreTypeFilters.map(({ payload: type }) => {
        if (type === 'onDemand') {
          return isOnDemandChore
        }
        if (type === 'oneTime') {
          return isOneTimeChore
        }
        if (type === 'overdue') {
          return isOverdue
        }
      }))
    }
  }

  $scope.user = User.properties;

  $scope.hasBackView = $ionicHistory.backView;

  // Proposals
  $scope.proposals = ChoreProposals;

  $scope.switch = 'overview';
  $scope.changeSwitch = function(val) {
    $scope.switch = val;
    $ionicScrollDelegate.resize()
  };
  $scope.chores = Chores;
  $scope.Math = Math;
  $scope.flatmates = Wg.flatmates;
  $scope.getLabelForRotationTime = RotationTimes.getLabelFor;
  $scope.rotationTimes = RotationTimes.values

  $scope.statistics = Chores.statistics
  $scope.getRanking = Chores.getRanking

  $scope.myPoints = function() {
    if (Chores.statistics().chore) {
      return Chores.statistics().chore[User.properties.id];
    }
    return 0;
  };

  $scope.status = Chores.status;
  $scope.hideLoading = false;
  $scope.refresh = function(type) {
    const promises = []

    $scope.hideLoading = true;
    if (!type || type === 'statistics') {
      promises.push(Chores.getStatisticsFromServer());
    }
    if (!type || type === 'listing') {
      promises.push(Chores.getFromServer())
    }
    Promise.all(promises)
      .finally(function() {
        $scope.hideLoading = false;
        $scope.$broadcast('scroll.refreshComplete');
      });
  };

  $scope.showHelp = function() {
    yptNotify.alert({
      title: $translate.instant('CHO__HELP-TITLE'),
      message: $translate.instant('CHO__HELP-MSG'),
      trackEvent: {
        name: 'showHelp',
        parameters: {
          context: 'chores',
        },
      },
    });
    $rootScope.$broadcast('track-event', {
      category: 'Help',
      action: 'Clicked',
      label: '/chores',
    });
  };

  /*
   * Reset Statistics
   */
  var resetStatistics = function() {
    $scope.isResetButtonDisabled = true;
    Chores.resetStatistics()
    .success(function() {
      const message = $translate.instant('CHO__STATISTICS_RESET_SUCCESS-MSG')
      mcmToast.show({ message });
    })
    .error(function(data) {
      yptNotify.alert({
        title: $translate.instant('CHO__STATISTICS_RESET_ERROR-TITLE'),
        message: $translate.instant('CHO__STATISTICS_RESET_ERROR-MSG', {
          error: data.error,
        }),
      });
    })
    .finally(function() {
      delete $scope.isResetButtonDisabled;
    });
  };
  $scope.resetChoresStatistics = function() {
    yptNotify.confirm({
      title: $translate.instant('CHO__STATISTICS_RESET_CONFIRM-TITLE'),
      message: $translate.instant('CHO__STATISTICS_RESET_CONFIRM-MSG'),
      buttonLabels: [
        $translate.instant('BTN_CANCEL'),
        $translate.instant('YES'),
      ],
      callback: function(answer) {
        if (answer === true) {
          resetStatistics();
        }
      },
    });
  };


  /*
   * Interact with chore
   */
  $scope.editChore = function(chore) {
    $state.go('wg.chores-edit', { id: chore.id });
  };

  $scope.askWhoCompletedChore = function(chore) {
    const name = Wg.flatmates._get(chore.currentUser).firstName;
    const options = {
      title: $translate.instant('Another person is responsible for this task. What do you want to do?', { name }),
      buttonLabels: [
        $translate.instant(`Check task for person`, { name }),
        $translate.instant(`I take over the task`),
      ],
      addCancelButtonWithLabel: $translate.instant('BTN_CANCEL'),
    }
    const actions =[
      function() { $scope.checkForPersonInCharge(chore) },
      function() { $scope.takeOverChore(chore) },
    ]
    return yptActionsheet.show(options, actions)
  }

  $scope.takeOverChore = function(chore) {
    var options = {
      title: $translate.instant('CHO__NEXT-MSG_TAKE', {
        name: Wg.flatmates._get(chore.currentUser).firstName,
        title: chore.title,
      }),
      buttonLabels: chore.users.map(function(userId) {
        return Wg.flatmates._get(userId).firstName || '';
      }),
      androidEnableCancelButton: false,
      addCancelButtonWithLabel: $translate.instant('BTN_CANCEL'),
    };
    var actions = chore.users.map(function(userId) {
      return function() {
        chore.justDone = true;
        $timeout(function() {
          chore.justDone = false;
          delete chore.justDone;
        }, 2000);
        return completeChore(chore, userId);
      };
    });
    return yptActionsheet.show(options, actions);
  }

  $scope.completeChore = function(chore) {
    var name = Wg.flatmates._get(chore.currentUser).firstName || '';
    var isDoneBySomeoneElse = (chore.currentUser != User.properties.id);

    if (isDoneBySomeoneElse) {
      return $scope.askWhoCompletedChore(chore)
    }

    var title = $translate.instant('CHO__NEXT-TITLE_OWN');
    var message = $translate.instant('CHO__NEXT-MSG_OWN', { title: chore.title });
    yptNotify.confirm({
      title,
      message,
      buttonLabels: [
        $translate.instant('BTN_CANCEL'),
        $translate.instant('OF_COURSE'),
      ],
      callback: function(answer) {
        if (answer === false) {
          return;
        }
        chore.justDone = true;
        $timeout(function() {
          chore.justDone = false;
          delete chore.justDone;
        }, 2000);
        return completeChore(chore)
      },
    });
  };

  function completeChore(chore, userId, completedBy) {
    return chore.next(userId)
      .then(() =>  mcmToast.show({
        duration: 'long',
        message: $translate.instant(`Congrats. You completed the task.`, {
          points: chore.points,
          title: chore.title,
        }),
      }))
  }

  $scope.checkForPersonInCharge = function(chore) {
    var name = Wg.flatmates._get(chore.currentUser).firstName || '';
    var dialogTitle = $translate.instant('CHO__CHECK_FOR_PIC-TITLE', {
      title: chore.title,
      name,
    });
    var dialogMsg = $translate.instant('CHO__CHECK_FOR_PIC-MSG', {
      title: chore.title,
      name,
    });
    yptNotify.confirm({
      title: dialogTitle,
      message: dialogMsg,
      buttonLabels: [
        $translate.instant('BTN_CANCEL'),
        $translate.instant('OF_COURSE'),
      ],
      callback: function(answer) {
        if (answer === false) {
          return;
        }
        chore.justDone = true;
        $timeout(function() {
          chore.justDone = false;
          delete chore.justDone;
        }, 2000);
        chore.next(null, chore.currentUser);
      },
    });
  };

  $scope.remindPerson = function(chore) {
    var name = Wg.flatmates._get(chore.currentUser).firstName || '';
    chore.remind()
      .then(function() {
        mcmToast.show({
          message: $translate.instant('CHO__REMIND_USER-MSG', {
            name,
            title: chore.title,
          }),
        });
      });
  };

  $scope.removeChore = function (chore) {
    yptNotify.confirm({
      title: $translate.instant('CHO__REMOVE-TITLE'),
      message: $translate.instant('CHO__REMOVE-MSG', {
        title: chore.title,
      }),
      buttonLabels: [
        $translate.instant('BTN_CANCEL'),
        $translate.instant('DELETE'),
      ],
      callback: function(answer) {
        if (answer === true) {
          chore.delete()
          .success(function() {
            mcmToast.show({
              message: $translate.instant('DELETED'),
            });
          });
        }
      },
    });
  };
  $scope.interact = function(chore) {
    var name = Wg.flatmates._get(chore.currentUser).firstName || '';

    var options = {
      title: $translate.instant('CHO__OPTIONS-TITLE', {
        title: chore.title,
      }),
      addDestructiveButtonWithLabel: $translate.instant('DELETE'),
      buttonLabels: [
        (chore.currentUser != User.properties.id) ?
            $translate.instant('CHO__OPTIONS-BTN_TAKE') :
            $translate.instant('CHO__OPTIONS-BTN_DO'),
        $translate.instant('CHO__OPTIONS-BTN_EDIT'),
      ],
      androidEnableCancelButton: false,
      addCancelButtonWithLabel: $translate.instant('BTN_CANCEL'),
    };
    var actions = [
      next,
      edit,
    ];
    if (chore.currentUser != User.properties.id) {
      actions.unshift(checkForPersonInCharge);
      var label = $translate.instant('CHO__OPTIONS-BTN_CHECK_FOR_PIC', {
        name,
      });
      options.buttonLabels.unshift(label);
    }
    actions.unshift(remove);
    yptActionsheet.show(options, actions);

    // Functions used for Actionsheet
    function next() { $scope.completeChore(chore); }
    function edit() { $scope.editChore(chore); }
    function remove() { $scope.removeChore(chore); }
    function checkForPersonInCharge() { $scope.checkForPersonInCharge(chore); }
  };

  $scope.goToChoreHistory = function(userId) {
    if (!User.isPremium()) {
      const label = 'View chore history'
      return $state.go('premium-paywall', { label });
    }
    $state.go('wg.chores-history', { userId });
  }

}])

.name;
