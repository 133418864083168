import UserService from '../../../../modules/user/svc_user'
import { selectTags } from '../../store/shoppinglistSlice'
import templateDetails from './template-details.html'
import template from './template.html'
export default angular.module('ft.components.shoppinglist.tags-explanation', [
  'ionic',
  UserService,
  'ngRedux',
])
.component('shlTagsExplanation', {
  template,
  controllerAs: 'vm',
  controller,
  transclude: true,
  bindings: {
    onCreateTag: '&',
  },
})
.component('shlTagsExplanationDetails', {
  template: templateDetails,
  controllerAs: 'vm',
  controller: TagDetailsExplanation,
  transclude: true,
})
.name


controller.$inject = ['$window', 'User', '$ngRedux', '$scope']
function controller($window, User, $ngRedux, $scope) {
  const vm = this
  const storageKey = 'Flatastic.shoppinglist.tags.modal.explanation.hide'

  vm.hide = hide
  vm.hideTagsExplanation = Boolean($window.localStorage[storageKey])
  vm.language = User.getLanguage()
  vm.isUserPremium = User.isPremium

  let unsubscribe = $ngRedux.connect(mapStateToThis)(vm)
  $scope.$on('$destroy', unsubscribe)

  function hide() {
    vm.hideTagsExplanation = true
    $window.localStorage[storageKey] = true
  }


  function mapStateToThis(state) {
    return {
      hasTags: selectTags(state).length > 0,
    }
  }
}

TagDetailsExplanation.$inject = ['$window']
function TagDetailsExplanation($window) {
  const storageKey = `Flatastic.shoppinglist.tags.details.explanation.hide`
  const vm = this
  vm.hide = hide
  vm.hideTagsExplanation = Boolean($window.localStorage[storageKey])

  function hide() {
    vm.hideTagsExplanation = true
    $window.localStorage[storageKey] = true
  }

}
