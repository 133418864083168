import { head, propEq } from 'ramda'
import flatasticUserService from '../../modules/user/svc_user'
// template
import infoView from './modal_info.html'
import './purchase.scss'
import flatasticPurchaseService from './svc_purchase'

export default angular.module('flatastic.purchase.controller', [
  flatasticPurchaseService,
  flatasticUserService,
  'ionic',
])

.controller('PurchaseCtrl',
    ['$scope', '$rootScope', 'Purchase', '$ionicLoading', '$timeout', 'User', '$ionicModal', '$ionicScrollDelegate', '$location',
    function($scope, $rootScope, Purchase, $ionicLoading, $timeout, User, $ionicModal, $ionicScrollDelegate, $location) {

    $scope.products = null;
    $scope.isBrowser = function() {
      return ionic.Platform.is('browser');
    }
    $scope.isIOS = function() {
      return ionic.Platform.isIOS();
    }
    $scope.isAndroid = function() {
      return ionic.Platform.isAndroid();
    }
    init();

    $scope.onPurchase = function(productId) {
      console.log('test', productId)
    }

    $scope.purchaseItem = function(productId) {
      $ionicLoading.show({
        template: '<div><ion-spinner></ion-spinner><br/><span translate="PURCHASES__SUBSCRIBING"></span></div>',
      });
      const trackAndHideModal = label => {
        $rootScope.$broadcast('track-event', {
          category: 'InAppPurchases',
          action: 'Did subscribe to item',
          label,
        })
        return $ionicLoading.hide();
      }
      return Purchase.subscribeToItem(productId)
        .then(function() {
          return trackAndHideModal('Success')
        })
        .catch(function(error) {
          return trackAndHideModal(`Error: ${JSON.stringify(error)}`)
        })
    };

    $scope.refresh = function() {
      return Purchase.getProducts()
        .then(function (response) {
          $scope.$broadcast('scroll.refreshComplete');
          $timeout(function() {
            $scope.products = response;
          });
        })
        .catch(function(response) {
          $scope.$broadcast('scroll.refreshComplete');
        });
    };

    $scope.restorePurchases = function() {
      $ionicLoading.show({
        template: '<div><ion-spinner></ion-spinner><br/><span translate="PURCHASES_RESTORING"></span></div>',
      });
      return Purchase.restorePurchases()
        .then(function(response) {
          $rootScope.$broadcast('track-event', {
            category: 'InAppPurchases',
            action: 'Did restore purchases',
            label: `Success`,
          })
          $ionicLoading.hide();
        })
        .catch(function(error) {
          $rootScope.$broadcast('track-event', {
            category: 'InAppPurchases',
            action: 'Did restore purchases',
            label: `Error: ${JSON.stringify(error)}`,
          })
          console.log('Error while restoring purchases', error);
          $ionicLoading.hide();
        });
    };

    $scope.openInfoModal = function() {
      $scope.modal.show();
    };
    $scope.closeInfoModal = function() {
      $scope.modal.hide();
    };


    // Workaround to make $getByHandle work for modals as well
    // https://github.com/ionic-team/ionic/issues/5897
    function getScrollDelegate(handleName) {
      const instances = $ionicScrollDelegate.$getByHandle(handleName)._instances
      return head(instances.filter(propEq('$$delegateHandle', handleName)))
    }

    $scope.scrollTo = function(target) {
      const scrollDelegate = getScrollDelegate('purchase-content');
      $location.hash(target)
      // Slow down scroll speed
      scrollDelegate.getScrollView().options.animationDuration = 750;
      scrollDelegate.anchorScroll(true)
    }

    $scope.scrollTop = function() {
      getScrollDelegate('purchase-content').scrollTop(true)
    }

    function init() {
      $scope.user = User;
      $scope.availableProducts = [];
      if (!$scope.isBrowser) {
        return
      }

      $ionicLoading.show({
        template: '<div><ion-spinner></ion-spinner><br/><span translate="LOADING_PRODUCTS"></span></div>',
      });

      // Initialize info modal
      $scope.modal = $ionicModal.fromTemplate(infoView, {
        scope: $scope,
        animation: 'slide-in-up',
      });

      // Get available products
      return Purchase.getProducts()
        .then(function(response) {
          $ionicLoading.hide();
          $timeout(function() {
            $scope.availableProducts = response.map(function(product) {
              product.title = product.title && product.title.replace(/ \([0-9a-zA-Z: -]+\)$/g, '');
              product.period = (product.productId.indexOf('monthly') > -1) ? 'monthly' : 'yearly';
              return product;
            });
          });
        })
        .catch(function(error) {
          console.error('error', error);
          $ionicLoading.hide();
        });
    }
}])

.name;
