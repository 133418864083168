import LegacyChoresController from '../../modules/chores/ctrl_chores'
import LegacyChoresHistoryController from '../../modules/chores/ctrl_chores-history'
import LegacyDirectivesBgImg from '../../modules/general/directive_background-image'
import { hideAccessoryBar, showAccessoryBar } from '../../modules/keyboardController'
import ChoresHistoryTemplate from './chores-history.html'
import choresListingTemplate from './chores-listing.html'
import LegacyNewChoresTemplate from './chores-new.html'
import choresStatisticsTemplate from './chores-statistics.html'
import ChoresControllerModule from './chores.ctrl'
import template from './chores.html'
import './chores.scss'
import LegacyChoresNewController from './legacy-ctrl_chores-new'

export default angular.module('ft.chores', [
  ChoresControllerModule,
  LegacyChoresController,
  LegacyChoresNewController,
  LegacyDirectivesBgImg,
  LegacyChoresHistoryController,
])
  .config(ChoresConfig)
  .name;


ChoresConfig.$inject = ['$stateProvider']
function ChoresConfig($stateProvider) {
  $stateProvider
    .state('household.chores', {
      url: '/chores',
      views: {
        'chores-tab': {
          abstract: true,
          controller: 'ChoresCtrl',
          template,
        },
      },
    })
    .state('household.chores.statistics', {
      url: '/statistics',
      viewName: '/chores/statistics',
      views: {
        'chores-content': {
          template: choresStatisticsTemplate,
        },
      },
    })
    .state('household.chores.listing', {
      url: '/listing',
      viewName: '/chores/listing',
      views: {
        'chores-content': {
          template: choresListingTemplate,
        },
      },
    })
    .state('household.chores.history', {
      url: '/history?userId',
      viewName: '/chores/history',
      params: {
        userId: null,
      },
      views: {
        'chores-content': {
          template: ChoresHistoryTemplate,
          controller: 'ChoresHistoryCtrl',
        },
      },
    })
    .state('household.chores-new', {
      url: '/chores-new',
      views: {
        'chores-tab': {
          template: LegacyNewChoresTemplate,
          controller: 'ChoresNewCtrl',
        },
      },
      onEnter: showAccessoryBar,
      onExit: hideAccessoryBar,
    })
    .state('household.chores-edit', {
      url: '/chores-edit/:id',
      views: {
        'chores-tab': {
          template: LegacyNewChoresTemplate,
          controller: 'ChoresNewCtrl',
        },
      },
      onEnter: showAccessoryBar,
      onExit: hideAccessoryBar,
    })
}


onEnter.$inject = ['$ionicHistory']
function onEnter($ionicHistory) {
  $ionicHistory.nextViewOptions({
    disableAnimate: true,
  })
}

checkPaywall.$inject = ['User', '$state']
function checkPaywall(User, $state) {
  if (!User.isPremium()) {
    const label = 'View chore history'
    return $state.go('premium-paywall', { label });
  }
  return true
}
