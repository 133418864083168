// ['en', 'de']

export default {
  [`Purchase:Reviews:1:Title`]: [
    'Super App!',
    'Super App!',
  ],
  [`Purchase:Reviews:1:Message`]: [
    'Your app is awesome to track who has spend how much money for the household!',
    'Eure App is super beim Nachvollziehen wer wie viel Geld für die WG ausgelegt hat!',
  ],
  [`Purchase:Reviews:2:Title`]: [
    'Also for families, not just for flat shares',
    'Auch für Familien, nicht nur für WGs',
  ],
  [`Purchase:Reviews:2:Message`]: [
    `With this app it's easy to distribute the chores in a family. With the collected points we see, who has done how much. There is a bonus for the points. Suddenly the kids are hard-working!`,
    'Mit dieser App lässt sich die Hausarbeit in einer Familie gut verteilen. Mit den gesammelten Punkten sehen wir, wieviel wer was gemacht. Es gibt für die Punkte einen Taschengeld Bonus. Plötzlich sind die Kinder sehr fleißig!',
  ],
  [`Purchase:Reviews:3:Title`]: [
    'Great app - we like it',
    'Tolles Programm – gefällt uns super.',
  ],
  [`Purchase:Reviews:3:Message`]: [
    'We have ordered the premium version right away',
    'Haben gleich die Premium Version bestellt!',
  ],
  [`Purchase:Reviews:4:Title`]: [
    'Just cool',
    'Einfach cool',
  ],
  [`Purchase:Reviews:4:Message`]: [
    `We are using this app from the very beginning and we love it. Put an item on the list and everyone knows immediatey what's missing. Who doing the shopping checks it and done. We even use it for our chores.`,
    'Wir nutzen die App von Anfang an und lieben Sie. Liste erstellen und jeder weiß direkt was fehlt. Wer einkauft hakt ab und fertig. Wir machen sogar unseren Haushalt damit.',
  ],
  [`Purchase:Reviews:5:Title`]: [
    'Top! Perfect for couples, too',
    'Top! Perfekt auch für Paare',
  ],
  [`Purchase:Reviews:5:Message`]: [
    'We simply add every expense in the app and at the end of the month we do the settlement, super uncomplicated. This app is a real simplification for every day life.',
    'Wir geben jede Ausgabe einfach in die App ein und am Ende des Monats rechnen wir ab, super unkompliziert. Eine echte vereinfachung des Alltags diese App!',
  ],
}
