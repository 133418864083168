// [en, de]

export default {
  [`Finances:SettlementEntry:Title`]: [
    `{{ count }} Expense Entr{{ count > 1 ? 'ies' : 'y' }}`,
    `{{ count }} Finanzeintr{{ count > 1 ? 'äge' : 'ag' }}`,
  ],
  [`Finances:SettlementEntry:TriggeredBy`]: [
    `{{ name }} triggered this settlement`,
    `{{ name }} hat diesen Kassensturz veranlasst`,
  ],
}
