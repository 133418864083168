import StackTrace from 'stacktrace-js'
import FirebaseModule from './flatasticFirebase'

export default angular.module('ft.firebase.error-logging', [
  FirebaseModule,
])
.run(FirebaseErrorLogging)
.name

function FirebaseErrorLogging(Firebase) {
  const appRoot = window.location.href.replace('index.html', '')
  const oldOnErrorHandler = window.onerror
  window.onerror = function(errorMsg, url, line, col, error) {
    oldOnErrorHandler && oldOnErrorHandler(errorMsg, url, line, col, error)
    handleUnhandledError(errorMsg, url, line, col, error)
  }

  function handleUnhandledError(errorMsg, url, line, col, error) {
    const logMessage = `${errorMsg}: url=${(url || '').replace(appRoot, '')}; line=${line}; col=${col}`

    if (typeof error !== 'object') {
      return Firebase.logError(logMessage)
    }

    return StackTrace.fromError(error)
      .then(stackTrace => Firebase.logError(logMessage, stackTrace))
  }
}
