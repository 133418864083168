import AngularTranslate from 'angular-translate'
import { is, values } from 'ramda'
import yepptApi from '../../scripts/modules/api'
import flatasticUserService from '../user/svc_user'

export default angular.module('flatastic.settlements.service', [
  AngularTranslate,
  yepptApi,
  flatasticUserService,
])

.factory('Settlements', ['Api', '$translate', function(Api, $translate) {

  const settlements = {
    data: [],
  };
  const limitGet = 10;
  let moreCanBeLoaded = false;

  return {
    fetch: fetchFromServer,
    findLocally,
    get: function() { return settlements; },
    getStatus: function() { return settlements.status },
    loadMore,
    moreCanBeLoaded: function() {
      return moreCanBeLoaded;
    },
  }

  ////

  function findLocally(settlementId) {
    return settlements.data.find(settlement => settlement.id === Number(settlementId))
  }

  function fetchFromServer() {
    settlements.status = 'loading';
    var params = {
      limit: limitGet,
      offset: 0,
    };
    return Api.get('/cashflow/settlements', { params })
      .success(function(data) {
        // Fixes a bug where API returns an object instead of an array
        // https://www.notion.so/flatasticapp/Bugs-Small-Improvements-2c785e1fb6f941a0b1cf9034e1e99624?p=955adf5ec3c34843baa9784f61ef96ac
        if (!is(Array, data)) {
            data = values(data)
        }
        settlements.data = data.map(formatEntry);
        moreCanBeLoaded = (data.length >= limitGet);
        delete settlements.error;
        delete settlements.status;
      })
      .error(function(data) {
        data = data || {};
        settlements.error = data.error || $translate.instant('NO_INTERNET');
        delete settlements.status;
      })
  };

  function loadMore() {
    var params = {};
    params.limit = limitGet;
    params.offset = settlements.data.length;
    settlements.status = 'loading';

    return Api.get('/cashflow/settlements', { params })
      .success(function(data) {
        // Fixes a bug where API returns an object instead of an array
        // https://www.notion.so/flatasticapp/Bugs-Small-Improvements-2c785e1fb6f941a0b1cf9034e1e99624?p=955adf5ec3c34843baa9784f61ef96ac
        if (!is(Array, data)) {
          data = values(data)
        }
        var formatedData = data.map(formatEntry);
        settlements.data = settlements.data.concat(formatedData);
        // if 10 items are requested and only 7 returned, there aren't any more…
        moreCanBeLoaded = (data.length >= limitGet);
        delete settlements.status;
      });
  };

  function formatEntry(entry) {
    entry.show = false;
    entry.maxWidth = 2 * Math.max.apply(null, Object.values(entry.balance).map(Math.abs));
    return entry;
  }

}])

.name;
