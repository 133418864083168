import AngularTranslate from 'angular-translate'
import template from './template.html'

export default angular.module('ft.components.premium-list', [
  AngularTranslate,
])
.component('premiumList', {
  template,
  transclude: true,
  bindings: {
    items: '<',
    category: '@',
  },
  controller,
  controllerAs: '$ctrl',
})
.name

controller.$inject = ['$translate']
function controller($translate) {
  const $ctrl = this
  const reasons = {
    finances: [{
      title: $translate.instant('Full History of every Entry'),
      text: $translate.instant(`Get a full history of all settlements including every entry.`),
    }, {
      title: $translate.instant(`Export as CSV`),
      text: $translate.instant(`Export your current expenses and do your own data magic.`),
    }, {
      title: $translate.instant(`Personal Spendings`),
      text: $translate.instant(`See how much money you've spent each month.`),
    }, {
      title: $translate.instant(`Add Images to Entries`),
      text: $translate.instant(`Take a photo of your receipts or the item you bought as a proof.`),
      isComingSoon: true,
    }],
    chores: [{
      title: $translate.instant(`Full History`),
      text: $translate.instant(`Need to check who did or didn't do a chore? See the full history of completed chores with Flatastic Premium.`),
    }, {
      title: $translate.instant(`Sort & Filter`),
      text: $translate.instant(`Get a quick overview who needs to do what next or who's chores are due.`),
    }, {
      title: $translate.instant(`Notes`),
      text: $translate.instant(`Add details, instructions and tips to chores.`),
    }],
  }
  $ctrl.items = $ctrl.items || reasons[$ctrl.category]
}
