import AngularTranslate from 'angular-translate'
import yepptCordova from '../../scripts/modules/yptCordova'
import flatasticWgService from '../wg/svc_wg'
import flatasticExpensesService from './svc_expenses'

export default angular.module('flatastic.expenses.controller.details', [
  AngularTranslate,
  flatasticExpensesService,
  flatasticWgService,
  yepptCordova,
  'ionic',
])

.controller('ExpensesDetailsCtrl',
    ['$scope', 'yptNotify', 'Expenses', 'User', '$state', '$stateParams', 'Wg',
    '$translate', '$rootScope',
    function ($scope, yptNotify, Expenses, User, $state, $stateParams, Wg,
    $translate, $rootScope) {

  $scope.user = User.properties;
  $scope.flatmates = Wg.flatmates;

  $scope.showHelp = function() {
    yptNotify.alert({
      title: $translate.instant('EXP__DETAIL_HELP-TITLE'),
      message: $translate.instant('EXP__DETAIL_HELP-MSG'),
      trackEvent: {
        name: 'showHelp',
        parameters: {
          context: 'expenses__details',
        },
      },
    });
    $rootScope.$broadcast('track-event', {
      category: 'Help',
      action: 'Clicked',
      label: '/expenses-details',
    });
  };

  $scope.expense = Expenses.get($stateParams.id);

  $scope.deleteExpense = function() {
    yptNotify.confirm({
      message: $translate.instant('EXP__DELETE-MSG', {
        name: $scope.expense.name,
      }),
      title: $translate.instant('EXP__DELETE-TITLE'),
      buttonLabels: [
        $translate.instant('BTN_CANCEL'),
        $translate.instant('DELETE'),
      ],
      callback: function(answer) {
        if (answer === true) {
          $scope.expense.removeFromCollection().delete();
          $state.go('wg.expenses');
        }
      },
    })
  };

}])

.name;
