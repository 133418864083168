import { debounce } from 'lodash'
import KeyboardModule from '../../scripts/modules/yptKeyboard'
export default angular.module('ft.directive.dragawayable', [
  KeyboardModule,
  'ionic',
])
.directive('dragawayable', ['yptKeyboard', function(yptKeyboard) {
  return {
    restict: 'A',
    link,
  }

  function link(scope, element, attrs) {
    element[0].addEventListener('touchstart', onTouchStart, { passive: true })
    element[0].addEventListener('touchmove', onTouchMove, { passive: true } )
    element[0].addEventListener('touchend', onTouchEnd, { passive: true })
    scope.$on('$destroy', function() {
      element[0].removeEventListener('touchstart', onTouchStart)
      element[0].removeEventListener('touchmove', onTouchMove)
      element[0].removeEventListener('touchend', onTouchEnd)
      ionic.off('native.keyboardshow', onKeyboardShow, window);
      ionic.off('native.keyboardhide', onKeyboardHide, window);
      if (ionContentElement) {
        ionContentElement.off('scroll', onScrollContent)
      }
    })

    // Handle keyboard when editing inputs
    ionic.on('native.keyboardshow', onKeyboardShow, window);
    ionic.on('native.keyboardhide', onKeyboardHide, window);
    let formerContainerStyleTop
    let hasBeenApplied = false
    function onKeyboardHide() {
      container.style.bottom = ``
      container.style.top = formerContainerStyleTop
      hasBeenApplied = false
    }
    function onKeyboardShow(e) {
      if (hasBeenApplied) {
        return
      }
      hasBeenApplied = true
      const keyboardHeight = e.keyboardHeight || (e.detail && e.detail.keyboardHeight);
      if (ionic.Platform.isAndroid()) {
        // Unlike on iOS, the measurement starts above the keyboard, so no offset needed here
      } else {
        container.style.bottom = `${keyboardHeight}px`
      }
      formerContainerStyleTop = container.style.top

      let containerStyleTop = container.style.top.replace(/[^\d]/gi, '')
      container.style.top = `${Math.max(safeAreaTop + 10, containerStyleTop - keyboardHeight)}px`
      if (ionContent) {
        setTimeout(function() {
          ionContent.style.height = ``
        }, 150)
      }
    }

    const safeAreaTop = parseInt(getComputedStyle(document.documentElement)
                          .getPropertyValue('--safe-area-top')
                          .replace(/[^\d]*/, ''), 10)

    let formerTransform
    let formerTransition
    let startYPos
    let container = element[0]
    let containerHeight

    let scrollController
    let bodyContent = element[0].querySelector('.detail-modal-body')
    let ionContent = element[0].querySelector('ion-content')
    let scrollBody = ionContent ? ionContent.querySelector('.scroll') : bodyContent
    const fixedFooter = element[0].querySelector('.actions-footer[fixed]')
    if (ionContent) {
      scrollController = element.controller('$ionicScroll')
    }
    const header = element[0].querySelector('.detail-modal-header')

    let formerScrollBodyHeight
    let waiter
    function observer() {
        if (waiter) {
          clearTimeout(waiter)
        }
        waiter = setTimeout(() => {
          const scrollBodyHeight = scrollBody.childElementCount === 1
            ? scrollBody.firstElementChild.scrollHeight
            : scrollBody.scrollHeight
          const fixedFooterHeight = fixedFooter ? fixedFooter.scrollHeight : 0
          formerScrollBodyHeight = scrollBodyHeight
          const screenHeight = Math.min(window.innerHeight, screen.height)
          const modalHeight = header.scrollHeight + scrollBodyHeight + fixedFooterHeight
          container.style.top = `${Math.max(safeAreaTop + 10, screenHeight - modalHeight)}px`
          if (ionContent) {
            ionContent.style.top = `${header.scrollHeight}px`
            if (ionContent.scrollHeight >= scrollBodyHeight) {
              scrollController.freezeScroll(true)
            }
          }
          waiter = undefined
        }, 0)
    }
    new ResizeObserver(observer).observe(scrollBody)
    new ResizeObserver(observer).observe(header)

    let isContentBodyInteraction = false
    let canOverScroll = false

    let ionContentElement
    if (ionContent) {
      ionContentElement = angular.element(ionContent)
      ionContentElement.on('scroll', onScrollContent)
    }
    let contentIsScrolled = false
    function onScrollContent(e) {
      debounce(() => {
        const scrollTop = (e.detail || e.target).scrollTop
        if (!contentIsScrolled && scrollTop > 0) {
          element.addClass('is-scrolled')
          contentIsScrolled = true
        } else if (contentIsScrolled && scrollTop <= 0) {
          element.removeClass('is-scrolled')
          contentIsScrolled = false
        }
      }, 50)
    }

    function onTouchStart(e) {
      element.addClass('is-dragged')
      if (ionContent && ionContent.clientHeight >= scrollBody.clientHeight) {
        scrollController.freezeScroll(true)
      }
      isContentBodyInteraction = ionContent && ionContent.contains(e.target)
      const startScrollTop = ionContent && scrollController.getScrollPosition().top
      canOverScroll = ionContent && isContentBodyInteraction && startScrollTop === 0
      containerHeight = container.clientHeight;
      formerTransform = container.style.transform
      formerTransition = container.style.transition
      container.style.transition = ''
      startYPos = getYPos(e)
    }
    function onTouchMove(e) {
      const deltaY = getYPos(e) - startYPos
      const isOverScrolling = canOverScroll && deltaY > 0
      if (isContentBodyInteraction && !isOverScrolling) {
        return
      }
      if (isOverScrolling) {
        scrollController.freezeScroll(true)
      }
      container.style.transform = `translate3d(0, ${Math.max(deltaY, 0)}px, 0)`
    }
    function onTouchEnd(e) {
      element.removeClass('is-dragged')
      const deltaY = getYPos(e) - startYPos
      container.style.transform = formerTransform
      container.style.transition = formerTransition
      ionContent && scrollController.freezeScroll(false)
      if (isContentBodyInteraction && (deltaY < 0 || !canOverScroll && deltaY > 0)) {
        return
      }
      if (deltaY > 150) {
        yptKeyboard.close()
        if (attrs.dragawayable) {
          scope.$apply(attrs.dragawayable)
        }
      }
    }
    function getYPos(e) {
      return  e.pageY || e.changedTouches[0].pageY
    }
  }
}])
.name
