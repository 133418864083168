import AngularTranslate from 'angular-translate'
import { concat, descend, map, pipe, prop, sortBy, uniqBy } from 'ramda'
import yepptApi from '../../scripts/modules/api'
import flatasticUserService from '../user/svc_user'
import Expense from './svc_expense'

export default angular.module('flatastic.settlement.entries.service', [
  AngularTranslate,
  yepptApi,
  flatasticUserService,
  Expense,
])

.factory('SettlementEntries', ['Api', 'Expense', '$translate', function(Api, Expense, $translate) {

  var settlementEntries = {
    data: [],
  };
  var limitGet = 10;
  var moreCanBeLoaded = false;

  return {
    fetch: fetchFromServer,
    get() { return settlementEntries; },
    getStatus() { return settlementEntries.status },
    loadMore,
    moreCanBeLoaded() { return moreCanBeLoaded },
  }

  ////

  function sortEntries(entries) {
    return sortBy(descend(prop('insertDate')))(entries)
  }

  function fetchFromServer({ settlementId, ...queryParams }) {
    settlementEntries.status = 'loading';
    var params = {
      limit: limitGet,
      offset: 0,
      ...queryParams,
    };
    return Api.get(`/cashflow/settlements/${settlementId}`, { params })
      .success(function(data) {
        settlementEntries.data = pipe(
          map(formatExpense),
          sortEntries,
        )(data);
        moreCanBeLoaded = (data.length >= limitGet);
        delete settlementEntries.error;
        delete settlementEntries.status;
      })
      .error(function(data) {
        data = data || {};
        settlementEntries.error = data.error || $translate.instant('NO_INTERNET');
        delete settlementEntries.status;
      })
  };

  function loadMore({ settlementId, ...queryParams }) {
    var params = {
      limit: limitGet,
      offset: settlementEntries.data.length,
      ...queryParams,
    };

    settlementEntries.status = 'loading';
    return Api.get(`/cashflow/settlements/${settlementId}`, { params })
    .success(function(data) {
      settlementEntries.data = pipe(
        map(formatExpense),
        concat(settlementEntries.data),
        uniqBy(prop('id')),
        sortEntries,
      )(data);
      // if 10 items are requested and only 7 returned, there aren't any more…
      moreCanBeLoaded = (data.length >= limitGet);
      delete settlementEntries.error;
      delete settlementEntries.status;
    })
    .error(function(data) {
      data = data || {};
      settlementEntries.error = data.error || $translate.instant('NO_INTERNET');
      delete settlementEntries.status;
    });
  };

  function formatExpense(data) {
    return new Expense(data)
  }

}])

.name;
