import ChoreProposalsService from '../../../../modules/chores/svc_choreproposals'
import template from './template.html'

export default angular.module('ft.components.chores.proposals', [
  ChoreProposalsService,
  'ionic',
])
.component('choreProposals', {
  template,
  controllerAs: 'cp',
  controller: ChoreProposalsController,
})
.name

ChoreProposalsController.$inject = ['ChoreProposals']
function ChoreProposalsController(ChoreProposals) {
  const vm = this
  vm.proposals = ChoreProposals
}
