import ngRedux from 'ng-redux'
import { defaultTo, pipe, prop, uniqBy } from 'ramda'
import PersonalProposalsService from '../../modules/shoppinglist/svc_personalproposals'
import UserService from '../../modules/user/svc_user'
import WgService from '../../modules/wg/svc_wg'
import rawSuggestions from './data/suggestions'
import { selectCheckedItems, selectUncheckedItems } from './store/shoppinglistSlice'

export default angular.module('flatastic.shoppinglist.suggestions', [
  ngRedux,
  PersonalProposalsService,
  WgService,
  UserService,
])
.factory('ShoppinglistSuggestions', ShoppinglistSuggestions)
.name

ShoppinglistSuggestions.$inject = ['$ngRedux', 'PersonalProposals', 'User']
function ShoppinglistSuggestions($ngRedux, PersonalProposals, User) {

  let suggestions = []

  init()

  return {
    get,
    includes,
  }

  ////

  function init() {
    console.time('Shl.Suggestions')
    const userLanguage = User.getLanguage()
    const items = pipe(
      prop(userLanguage),
      defaultTo(rawSuggestions['en'])
    )(rawSuggestions)
    const generalSuggestions = items.map(({ categoryId, name }) => {
      return {
        categoryId,
        name,
        lowerCasedName: name.toLowerCase(),
      }
    })

    const personalProposals = PersonalProposals.get(3).map(proposal => ({
      name: proposal,
      lowerCasedName: proposal.toLowerCase(),
      categoryId: generalSuggestions.find(({ name }) => proposal === name)?.categoryId,
    }))
    suggestions = personalProposals.concat(generalSuggestions)
    console.timeEnd('Shl.Suggestions')
  }

  function generateUniqueId(item) {
    const processedDetails = (item.details || '').split(',').map(i => i.trim()).filter(i => /^[^#]/.test(i)).join(', ')
    return `${item.name}${processedDetails}`
  }

  function includes(searchStr = '') {
    const res = get(searchStr, 10)
    return res.map(i => generateUniqueId(i).toLowerCase())
      .includes(searchStr.toLowerCase())
  }

  function get(searchStr = '', limit = 10) {
    console.time('get')
    const lowerCasedSearchStr = searchStr.toLowerCase()
    const recentlyUsedItems = selectCheckedItems($ngRedux.getState())
      .map(i => ({ ...i, lowerCasedName: i.name.toLowerCase() }))
    const itemsToBuy = selectUncheckedItems($ngRedux.getState())
    const lowerCasedItemsOnList = itemsToBuy.map(item => generateUniqueId(item).toLowerCase())
    const allProposals = [].concat(recentlyUsedItems, suggestions)
    let result = allProposals
      .filter(suggestion => {
        return suggestion.lowerCasedName.includes(lowerCasedSearchStr)
          && !lowerCasedItemsOnList.includes(suggestion.lowerCasedName)
      })
    result = uniqBy(generateUniqueId, result).slice(0, limit)
    console.timeEnd('get')
    return result
  }
}
