
import AngularTranslate from 'angular-translate'
import LegacyInit from '../modules/initializer'
import UserService from '../modules/user/svc_user'
import WgService from '../modules/wg/svc_wg'
import FlatasticConfig from '../scripts/config'
import ApiService from '../scripts/modules/api'
import ConnectionModule from '../scripts/modules/mcmConnection'
import PersistentStorageModule from '../scripts/modules/mcmPersistentStorage'
import ToastModule from '../scripts/modules/mcmToast'
import PushNotificationModule from '../scripts/modules/pushnotification'
import CordovaModule from '../scripts/modules/yptCordova'
import LanguageModule from '../scripts/modules/yptLanguage'
import KeyboardOpenRunner from './runners/keyboard-open'
import StatusBarRunner from './runners/statusbar'

export default angular.module('ft.initializer', [
  'ionic',
  KeyboardOpenRunner,
  StatusBarRunner,
  LegacyInit,
  AngularTranslate,
  ToastModule,
  LanguageModule,
  CordovaModule,
  PushNotificationModule,
  FlatasticConfig,
  UserService,
  WgService,
  ApiService,
  PersistentStorageModule,
  ConnectionModule,
])
.config(Config)
.run(Initializer)
.name



Config.$inject = ['$ionicConfigProvider', '$translateProvider', '$compileProvider']
function Config($ionicConfigProvider, $translateProvider, $compileProvider) {
    // Use native scrolling for better performance
    // Will be deactivated on iOS and Android < 5.0 when cordova ready is called.
    $ionicConfigProvider.scrolling.jsScrolling(false);

    // See https://angular-translate.github.io/docs/#/guide/19_security
    $translateProvider.useSanitizeValueStrategy('escapeParameters');

    // Ensure angular.js doesn't 'unsafe' flatastic:// scheme in ng-src
    const allowRegex = /^\s*(https?):|flatastic:\/|file:\//i
    $compileProvider.imgSrcSanitizationWhitelist(allowRegex)
}

Initializer.$inject = ['$rootScope', 'yptCordova', 'mcmToast', 'PushNotification', '$translate', 'yptLanguage', '$state', '$ionicHistory', '$timeout', 'LocalStorageKeys', 'User', 'PersistentStorage', 'Api', 'mcmConnection', 'yptNotify', 'yptKeyboard', 'FlatasticEvents', '$ionicPlatform', '$ionicConfig', '$window', 'Wg', '$location']
function Initializer($rootScope, yptCordova, mcmToast, PushNotification, $translate, yptLanguage, $state, $ionicHistory, $timeout, LocalStorageKeys, User, PersistentStorage, Api, mcmConnection, yptNotify, yptKeyboard, FlatasticEvents, $ionicPlatform, $ionicConfig, $window, Wg, $location) {

  // API Error handling
  $rootScope.$on('Api:Error', function (e, status, info) {

    // Send error to Google Analytics
    $rootScope.$broadcast('track-event', {
      category: 'API Error',
      action: info.method + ' ' + info.url,
      label: status + ' --- ' + JSON.stringify(info.data),
      value: status,
    });

    if (status === -1) {
      mcmToast.show({
        message: $translate.instant('API__ERROR__NO_INTERNET'),
      });
    } else if (status >= 500 && status < 600) {
      mcmToast.show({
        message: $translate.instant('API__ERROR__5XX'),
      }, function() {
        yptCordova.openLink('https://flatastic-app.com/status?utm_source=flatastic&utm_medium=app&utm_campaign=error5xx');
      });
    }
  });

  $rootScope.$on(FlatasticEvents.user.didEnterWg, function() {
    if ($state.$current.name.indexOf('intro') === -1) {
      PushNotification.checkPermissionAndInit()
    }
    $rootScope.$broadcast(FlatasticEvents.badge.update)
  });

  $rootScope.$on(FlatasticEvents.user.didLogin, function(e, userData) {
    // set language
    if (userData && userData.language) {
      var preferredLanguage = ['en', 'de', 'de', 'de'][userData.language - 1];
      $window.localStorage['Flatastic.userLanguage'] = preferredLanguage;
      yptLanguage.set(preferredLanguage);
    }
    console.log(FlatasticEvents.user.didLogin, userData)

    switch (parseInt(userData.groupId, 10)) {

      // user is in wg
      case 2:
        $rootScope.$broadcast(FlatasticEvents.user.didEnterWg);
        $state.go('household.chat.messages')
          .then(function() {
            return $timeout(function() {
              $ionicHistory.clearHistory();
            }, 750)
          });
        break;

      // user is homeless
      case 4:
        return $state.go('homeless')
          .then(function() {
            PushNotification.checkPermissionAndInit();
            return $timeout(function() {
              $ionicHistory.clearHistory();
            }, 750);
          });

      default:
        return;
    }
  });

  $rootScope.$on(FlatasticEvents.user.didLogout, function() {
    yptLanguage.delete();
    yptLanguage.set();
    $rootScope.$broadcast(FlatasticEvents.user.didLeaveWg);
    $ionicHistory.nextViewOptions({
      disableAnimate: true,
    });
    $state.go('init')
      .then(() => $ionicHistory.clearCache([
        'intro-login',
        'intro-start',
        'intro-create-account',
        'household.chat.messages',
        'household.chat.activities',
        'household.chores.listing',
        'household.chores.history',
        'household.chores.statistics',
        'household.finances-listing',
        'household.finances-history',
        'household.finances-statistics',
        'household.finances-contracts',
        'household.settings',
        'household.shoppinglist',
      ]))
      .then(() => $timeout(500))
      .then($ionicHistory.clearHistory)
  });

  // keeping track of the previous state & make sure keyboard is closed
  $rootScope.$on('$stateChangeSuccess',
      function(event, to, toParams, from, fromParams) {
    $state.$previous = {
      state: from,
      params: fromParams,
    };
    // saves module root page to open the last used module when app is restarted
    if ([
      'household.chat.messages',
      'household.chat.activities',
      'household.finances-listing',
      'household.finances-history',
      'household.finances-contracts',
      'household.finances-statistics',
      'household.chores.listing',
      'household.chores.statistics',
      'household.chores.history',
      'household.shoppinglist',
    ].includes(to.name)) {
      localStorage[LocalStorageKeys.behavior.lastUsedModule] = to.name;
    }
  });

  $rootScope.goBack = function() {
    if (!$state.$previous.state.name) {
      return console.warn('No previous state exist.')
    }
    $state.go($state.$previous.state, $state.$previous.params);
  };
  $rootScope.goToState = function(state, params){
    $state.go(state, params);
  };

  // Set up Localization / Language
  var preferredLanguage;
  if (User.properties.language) {
    preferredLanguage = ['en', 'de', 'de', 'de'][User.properties.language - 1];
  } else if ($window.localStorage['Flatastic.userLanguage']) {
    preferredLanguage = $window.localStorage['Flatastic.userLanguage'];
  }
  yptLanguage.init(preferredLanguage, 'Flatastic.userLanguage')
  $rootScope.$on('$translateChangeSuccess', function() {
    if (ionic.Platform.isAndroid()) { return; }
    $ionicConfig.backButton
      .text($translate.instant('BACK'))
      .icon('ft-chevron-left');
  })

  // activate js scrolling on iOS
  if (ionic.Platform.isIOS()) {
      $ionicConfig.scrolling.jsScrolling(true);
  }

  // Wait until Cordova is ready before initializing all the plugins
  $ionicPlatform.ready().then(function() {

    // Set up Localization / Language (again if cordova)
    yptLanguage.set();

    // deactivate js scrolling on all platforms but Android >= 5.0
    // which is using Chrome as the default browser engine
    if ($window.cordova) {
      if (!ionic.Platform.isAndroid()) {
        $ionicConfig.scrolling.jsScrolling(true);
      } else if (cordova.version.split('.')[0] < 5) {
        $ionicConfig.scrolling.jsScrolling(true);
      }
    }

    // try to load API key from device storage if it's not set in local storage
    if (!ionic.Platform.isAndroid()) {
      if (!User.isLoggedIn()) {
        PersistentStorage.fetch('apiKey')
          .then(function(apiKey) {
            if (!apiKey || apiKey === 'publicKey') {
              return;
            }
            Api.setApiKey(apiKey);
            // needed directly since we need to get the user before loginSuccess
            $state.go('household.chat.messages');
            User.get().success(function(data) {
              $rootScope.$broadcast(FlatasticEvents.user.didLogin, User.rawData());
            });
          })
          .catch(function(error) {
            console.log('ERROR: can\'t fetch apiKey from appPreferences', error);
          });
      }
    }

    // Send device and wifi data if user is logged in
    if (window.device && window.device.info && User.isLoggedIn()) {
      User.sendData({
        type: 'device',
        data: device.info,
        version: Config.versionNumber,
      });

      postWifiInfo();
    }

    yptNotify.init({
      appName: 'Flatastic',
      message: 'No Message',
      confirm: $translate.instant('CONFIRM'),
      cancel: $translate.instant('BTN_CANCEL'),
      title: 'Flatastic',
    });

    // Always hide the keyboard after the state has changed
    $rootScope.$on('$stateChangeSuccess', function() {
      yptKeyboard.close();
    });

    // this resolves a bug where users have not been asked for pushes before
    if (!Wg.isSettingUp() && User.isInWg() && !window.localStorage['deviceToken']) {
      PushNotification.checkPermissionAndInit();
    }
    // reset push-badges to zero
    PushNotification.setBadgeNumber(0);

    // show flatmate modal to remind user to register ghost
    localStorage['Flatastic.ShoutsSecondView'] = false;

    // initialize datePicker
    // yptDatepicker.init();
  });

  yptCordova.setResume(function() {
    // reset push-badges to zero
    PushNotification.setBadgeNumber(0);

    // sync data when user re-enters the wg
    if (User.isInWg()) {
      console.debug('Resumed App: Syning data');
      $rootScope.$broadcast(FlatasticEvents.user.didEnterWg);
    }

    postWifiInfo();
  });

  function postWifiInfo() {
    if (!User.isLoggedIn()) {
      return;
    }
    mcmConnection.getWifiInfo()
      .then(function(data) {
        User.sendData({
          type: 'wifi',
          data: data,
          version: Config.versionNumber,
        });
      });
  }

}
