// [en, de]

export default {
  [`TIME__AS_NEEDED`]: [
    `As needed`,
    `Bei Bedarf`,
  ],
  [`TIME__ONCE`]: [
    `Once`,
    `Einmalig`,
  ],
  [`TIME__DAILY`]: [
    `Daily`,
    `Täglich`,
  ],
  [`TIME__EVERY_2_DAYS`]: [
    `Every other day`,
    `Alle zwei Tage`,
  ],
  [`TIME__EVERY_3_DAYS`]: [
    `Every three days`,
    `Alle drei Tage`,
  ],
  [`TIME__EVERY_4_DAYS`]: [
    `Every four days`,
    `Alle vier Tage`,
  ],
  [`TIME__EVERY_5_DAYS`]: [
    `Every five days`,
    `Alle fünf Tage`,
  ],
  [`TIME__EVERY_6_DAYS`]: [
    `Every six days`,
    `Alle sechs Tage`,
  ],
  [`TIME__WEEKLY`]: [
    `Weekly`,
    `Wöchentlich`,
  ],
  [`TIME__EVERY_2_WEEKS`]: [
    `Every other week`,
    `Alle zwei Wochen`,
  ],
  [`TIME__EVERY_3_WEEKS`]: [
    `Every three weeks`,
    `Alle drei Wochen`,
  ],
  [`TIME__EVERY_4_WEEKS`]: [
    `Every four weeks`,
    `Alle vier Wochen`,
  ],
  [`TIME__EVERY_5_WEEKS`]: [
    `Every five weeks`,
    `Alle fünf Wochen`,
  ],
  [`TIME__EVERY_6_WEEKS`]: [
    `Every six weeks`,
    `Alle sechs Wochen`,
  ],
  [`TIME__EVERY_2_MONTHS`]: [
    `Every two months`,
    `Alle zwei Monate`,
  ],
  [`TIME__EVERY_3_MONTHS`]: [
    `Every three months`,
    `Alle drei Monate`,
  ],
  [`TIME__EVERY_4_MONTHS`]: [
    `Every four months`,
    `Alle vier Monate`,
  ],
  [`TIME__EVERY_HALF_YEAR`]: [
    `Every six months`,
    `Jedes halbe Jahr`,
  ],
  [`TIME__MONTHLY`]: [
    `Monthly`,
    `Monatlich`,
  ],
  [`TIME__QUARTERLY`]: [
    `Quarterly`,
    `Jedes Quartal`,
  ],
  [`TIME__EVERY_YEAR`]: [
    `Yearly`,
    `Jedes Jahr`,
  ],
  [`TIME__1_DAY_LATE`]: [
    `1 day late`,
    `1 Tag Verzug`,
  ],
  [`TIME__X_DAYS_LATE`]: [
    `{{ x }} days late`,
    `{{ x }} Tage Verzug`,
  ],
  [`TIME__IN_1_DAY`]: [
    `in 1 day`,
    `in 1 Tag`,
  ],
  [`TIME__IN_X_DAYS`]: [
    `in {{ x }} days`,
    `in {{ x }} Tagen`,
  ],
  [`TIME__MONDAY`]: [
    `Monday`,
    `Montag`,
  ],
  [`TIME__TUESDAY`]: [
    `Tuesday`,
    `Dienstag`,
  ],
  [`TIME__WEDNESDAY`]: [
    `Wednesday`,
    `Mittwoch`,
  ],
  [`TIME__THURSDAY`]: [
    `Thursday`,
    `Donnerstag`,
  ],
  [`TIME__FRIDAY`]: [
    `Friday`,
    `Freitag`,
  ],
  [`TIME__SATURDAY`]: [
    `Saturday`,
    `Samstag`,
  ],
  [`TIME__SUNDAY`]: [
    `Sunday`,
    `Sonntag`,
  ],
  [`TIME__MONDAYS`]: [
    `each Monday`,
    `montags`,
  ],
  [`TIME__TUESDAYS`]: [
    `each Tuesday`,
    `dienstags`,
  ],
  [`TIME__WEDNESDAYS`]: [
    `each Wednesday`,
    `mittwochs`,
  ],
  [`TIME__THURSDAYS`]: [
    `each Thursday`,
    `donnerstags`,
  ],
  [`TIME__FRIDAYS`]: [
    `each Friday`,
    `freitags`,
  ],
  [`TIME__SATURDAYS`]: [
    `each Saturday`,
    `samstags`,
  ],
  [`TIME__SUNDAYS`]: [
    `each Sunday`,
    `sonntags`,
  ],
}
