import '../../modules/chores/popover.scss'

export const CHORES__FILTER__PAYWALL__MSG = 'CHORES__FILTER__PAYWALL__MSG'
export const CHORES__FILTER__OVERDUE = 'CHORES__FILTER__OVERDUE'
export const CHORES__FILTER__SECTION_FLATMATES = 'CHORES__FILTER__SECTION_FLATMATES'
export const CHORES__FILTER__SECTION_MORE = 'CHORES__FILTER__SECTION_MORE'

const translations = {
  [CHORES__FILTER__PAYWALL__MSG]: [
    `Get Flatastic premium to filter chores by who's responsible, the status and type.`,
    `Hol dir Flatastic Premium um Aufgaben nach Mitbewohnern, Status und Art zu filtern.`,
  ],
  [CHORES__FILTER__OVERDUE]: [
    'Overdue chores',
    'Überfällig',
  ],
  [CHORES__FILTER__SECTION_FLATMATES]: [
    'Filter for members:',
    'Nach Mitbewohnern filtern:',
  ],
  [CHORES__FILTER__SECTION_MORE]: [
    'More filters:',
    'Weitere Filter:',
  ],
}

export default translations
