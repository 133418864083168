import { mapObjIndexed } from 'ramda'

const meta = ['en', 'de']
const trans = {
  [`Step 2 of 3`]: [
    `Step 2 of 3`,
    `Schritt 2 von 3`,
  ],
  [`Add a picture of your home`]: [
    `Add a picture of "{{ householdName }}"`,
    `Wähle ein Bild für «{{ householdName }}»`,
  ],
  [`Make sure the others feel right at home when you invite them.`]: [
    `Make sure the others feel right at home when you invite them.`,
    `So fühlen sich deine Mitbewohner gleich zuhause.`,
  ],
  [`Add Photo`]: [
    `Add Photo`,
    `Bild auswählen`,
  ],
  [`Skip`]: [
    `Skip`,
    `Überspringen`,
  ],
  [`Continue`]: [
    `Continue`,
    `Weiter`,
  ],
  // Legacy
  [`WG__IMAGE_DIALOGUE-TITLE`]: [
    `Please select how you want to update the image for your home`,
    `Bitte wähle, wie du das Bild für euer Zuhause ändern möchtest`,
  ],
  [`WG__IMAGE_DIALOGUE-FROM_GALLERY`]: [
    `Choose existing photo`,
    `Bild aus Galerie`,
  ],
  [`WG__IMAGE_DIALOGUE-TAKE_PHOTO`]: [
    `Take photo`,
    `Foto machen`,
  ],
  [`WG__IMAGE_ERROR_DIALOGUE-TITLE`]: [
    `Upload failed`,
    `Upload fehlgeschlagen`,
  ],
  [`WG__IMAGE_ERROR_DIALOGUE-MSG`]: [
    `The following errors occured:\n\n {{ error }}`,
    `Folgende Fehler sind aufgetreten:\n\n{{ error }}`,
  ],
}

export default {
  en: mapObjIndexed((val, key, obj) => val[0])(trans),
  de: mapObjIndexed((val, key, obj) => val[1])(trans),
}
