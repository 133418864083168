import angular from 'angular'
import UserService from '../../modules/user/svc_user'
import WgService from '../../modules/wg/svc_wg'
import CordovaModule from '../../scripts/modules/yptCordova'

export default angular.module('ft.finances.controllers.expenses-details', [
  UserService,
  WgService,
  CordovaModule,
])
.controller('ExpensesDetailsController', ExpenseDetailsController)
.name


ExpenseDetailsController.$inject = ['Wg', '$state', '$translate', 'yptNotify', 'User']
export function ExpenseDetailsController(Wg, $state, $translate, yptNotify, User) {
  const vm = this
  vm.currency = Wg.currency
  vm.deleteExpense = deleteExpense
  vm.isCreditor = isCreditor
  vm.isQuickExpense = isQuickExpense
  vm.showPhoto = showPhoto
  vm.getCreditor = getCreditor
  vm.members = Wg.flatmates

  function getCreditor(expense) {
    return Wg.flatmates._get(expense.creatorId)
  }

  function showPhoto() {
    yptNotify.alert({
      title: $translate.instant('Show image'),
      message: $translate.instant(`Soon you'll be able to add images to your expenses. Stay tuned.`),
      buttonLabel: $translate.instant('OK'),
    })
  }

  function isQuickExpense(expense) {
    return expense.items.length() === 1 && expense.items.get()[0].name === expense.name
  }

  function isCreditor(expense) {
    return expense.creatorId === User.properties.id
  }

  function deleteExpense(expense) {
    yptNotify.confirm({
      message: $translate.instant('EXP__DELETE-MSG', {
        name: expense.name,
      }),
      title: $translate.instant('EXP__DELETE-TITLE'),
      buttonLabels: [
        $translate.instant('BTN_CANCEL'),
        $translate.instant('DELETE'),
      ],
      callback: function(answer) {
        if (answer !== true) {
          return
        }
        expense.removeFromCollection().delete()
        $state.go('household.finances-listing')
      },
    })
  }
}
