import AngularTranslate from 'angular-translate'
import UserService from '../../modules/user/svc_user'
import FlatasticConfig from '../../scripts/config'

export default angular.module('ft.legacy-functionality', [
  AngularTranslate,
  FlatasticConfig,
  UserService,
])
.run(LegacyRun)
.name

LegacyRun.$inject = ['$rootScope', '$timeout', 'FlatasticEvents', '$translate', 'User']
function LegacyRun($rootScope, $timeout, FlatasticEvents, $translate, User) {
  $rootScope.$on(FlatasticEvents.wg.didNotInviteAnyoneElse, () => {
    if (User.isPremium()) {
      return
    }
    $timeout(() => {
      $rootScope.$broadcast('toaster-show', {
        title: $translate.instant('WG__FLATMATE_REMINDER-TITLE'),
        message: $translate.instant('WG__FLATMATE_REMINDER-MSG'),
        actions: [{
          label: $translate.instant('WG__FLATMATE_REMINDER-BTN'),
          goTo: ['household.settings', 'household.settings-members'],
        }, {
          label: $translate.instant('Close'),
          close: true,
        }],
      });
    }, 2500)
  })
  $rootScope.$on(FlatasticEvents.wg.someoneHasNotMovedInYet, () => {
    $timeout(() => {
      $rootScope.$broadcast('toaster-show', {
        title: $translate.instant('WG__INCOMPLETE_REMINDER-TITLE'),
        message: $translate.instant('WG__INCOMPLETE_REMINDER-MSG'),
        actions: [{
          label: $translate.instant('WG__INCOMPLETE_REMINDER-BTN_SHARE'),
          goTo: ['household.settings', 'household.settings-members'],
        }, {
          label: $translate.instant('WG__INCOMPLETE_REMINDER-BTN_LATER'),
          close: true,
        }],
      });
    }, 2500)
  })
}
