import UserService from '../../../modules/user/svc_user'
import WgService from '../../../modules/wg/svc_wg'
import WelcomeTemplate from './template.html'

export default angular.module('ft.modals.welcome', [
  'ionic',
  WgService,
  UserService,
])
.factory('WelcomeModal', WelcomeModal)
.name

WelcomeModal.$inject = ['$rootScope', '$ionicModal', 'User', 'Wg']
function WelcomeModal($rootScope, $ionicModal, User, Wg) {
  return {
    showWelcomeModal,
  }

  ////

  function showWelcomeModal() {
    const scope = $rootScope.$new()
    scope.firstName = User.properties.firstName
    scope.isFounder = User.properties.id == Wg.founderId
    const modal = $ionicModal.fromTemplate(WelcomeTemplate, {
      scope,
      animation: 'slide-in-up',
    })
    scope.modal = modal
    scope.$on('$destroy', function() {
      scope.modal.remove()
      modal.remove();
    })
    return modal.show()
  }

}

