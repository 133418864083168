// ['en', 'de']

export default {
  [`Send Feedback`]: [
    `Send Feedback`,
    `Feedback senden`,
  ],
  [`Write here…`]: [
    `Write here…`,
    `Hier schreiben…`,
  ],
  [`Submit`]: [
    `Submit`,
    `Abschicken`,
  ],
  [`FDB__INFO_TEXT`]: [
    `Send us a message: Suggestions, criticisms, ideas, kudos or a simple "Hello". No matter what, we\'re happy to hear from you.`,
    `Wir freuen uns immer über deine Nachrichten. Ganz egal, ob Anregung, Kritik, Verbesserungsvorschlag oder ein einfaches "Hallo".`,
  ],

  //// Legacy
  [`FDB__CONFIRM_SUCCESS-TITLE`]: [
    `Feedback`,
    `Feedback`,
  ],
  [`FDB__CONFIRM_SUCCESS-MSG`]: [
    `Your feedback has been sent. Thank you!`,
    `Dein Feedback wurde übermittelt. Vielen Dank!`,
  ],
  [`FDB__CONFIRM_ERROR-TITLE`]: [
    `Feedback`,
    `Feedback`,
  ],
  [`FDB__CONFIRM_ERROR-MSG`]: [
    `Your Feedback could not be sent:\n{{ error }}`,
    `Dein Feedback konnte nicht übermittelt werden:\n{{ error }}`,
  ],

}
