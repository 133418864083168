import WgService from '../../../../modules/wg/svc_wg'
import template from './template.html'

export default angular.module('ft.components.chores.order-members', [
  WgService,
  'ionic',
])
.component('orderMembers', {
  template,
  controllerAs: 'om',
  controller: OrderMembersController,
  bindings: {
    selectedUserIds: '=',
  },
})
.name

OrderMembersController.$inject = ['$scope', 'Wg']
function OrderMembersController($scope, Wg) {
  const vm = this
  const members = Wg.flatmates.get().reduce((acc, member) => {
    acc[member.id] = {
      id: member.id,
      isSelected: false,
      firstName: member.firstName,
      profileImage: member.profileImage,
    }
    return acc
  }, {})
  vm.members = members
  vm.unselect = (userId) => {
    vm.selectedUserIds.splice(vm.selectedUserIds.indexOf(userId), 1)
  }
  vm.select = (userId)=> {
    vm.selectedUserIds.push(userId)
  }
  vm.reorderItem = (item, fromIndex, toIndex) => {
    console.log('reorder-item', item, fromIndex, toIndex, vm.selectedUserIds)
    vm.selectedUserIds.splice(fromIndex, 1);
    vm.selectedUserIds.splice(toIndex, 0, item);
  }
  vm.getSelectedMembers = () => (vm.selectedUserIds || []).map(userId => vm.members[userId])
  vm.getUnselectedMembers = () => Object.values(members).filter(({ id }) => !(vm.selectedUserIds || []).includes(id))
}
