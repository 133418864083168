export default {
  [`Help us to improve the premium version!`]: [
    `Help us to improve the premium version!`,
    `Hilf mit die Premium Version zu verbessern!`,
  ],
  [`We are happy about honest feedback from users, who want to help testing new features.`]: [
    `We are happy about honest feedback from users, who want to help testing new features.`,
    `Wir freuen uns über jedes ehrliche Feedback von Nutzern, die helfen wollen, neue Features zu testen.`,
  ],
  [`Send a message`]: [
    `Send a message`,
    `Nachricht senden`,
  ],
  [`Write feedback or just say 'hello'. We are looking forward to it.`]: [
    `Write feedback or just say 'hello'. We are looking forward to it.`,
    `Feedback oder ein nettes "Hallo" hinterlassen. Wir freuen uns.`,
  ],
}
