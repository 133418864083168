import WgService from '../../modules/wg/svc_wg'

export default angular.module('ft.bulletinboard.controller', [
  WgService,
])
.directive('withPhoto', [function () {
  return {
    restrict: 'A',
    link: function($scope, $element) {
      console.log($scope, $element)
    },
  }
}])
.controller('BulletinBoardController', BulletinBoardController)
.name

BulletinBoardController.$inject = ['Wg', '$ionicScrollDelegate']
function BulletinBoardController(Wg, $ionicScrollDelegate) {
  const vm = this
  vm.household = Wg
  vm.members = Wg.flatmates
  vm.isImageHidden = false

  vm.toggleHideImage = () => {
    console.log('toggle clicked')
    vm.isImageHidden = !vm.isImageHidden
    $ionicScrollDelegate.$getByHandle('bulletinboard-messages').resize()
  }
}
