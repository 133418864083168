import AngularTranslate from 'angular-translate'
import template from './app-reviews.comp.html'
import './app-reviews.comp.scss'

export default angular.module('flatastic.component.app-reviews', [
  AngularTranslate,
])

.component('appReviews', {
  controller: ['$translate', function($translate)  {
    this.appReviews = [
      {
        name: 'Samantha B.',
        title: $translate.instant('Purchase:Reviews:1:Title'),
        description: $translate.instant('Purchase:Reviews:1:Title'),
      }, {
        name: 'Lisapaulin',
        title: $translate.instant('Purchase:Reviews:2:Title'),
        description: $translate.instant('Purchase:Reviews:2:Title'),
      }, {
        name: 'Basil S.',
        title: $translate.instant('Purchase:Reviews:3:Title'),
        description: $translate.instant('Purchase:Reviews:3:Title'),
      }, {
        name: 'Phil83s',
        title: $translate.instant('Purchase:Reviews:4:Title'),
        description: $translate.instant('Purchase:Reviews:4:Title'),
      }, {
        name: 'Manuel P.',
        title: $translate.instant('Purchase:Reviews:5:Title'),
        description: $translate.instant('Purchase:Reviews:5:Title'),
      },
    ];
  }],
  template,
})

.name;
