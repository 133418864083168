// ['en', 'de']
export default {
  [`PAYWALL_GENERIC__TITLE`]: [
    `You've discovered a premium feature!`,
    `Du hast ein Premium Feature entdeckt!`,
  ],
  [`PAYWALL_GENERIC__TEXT`]: [
    `Unlock this and many more features by getting Flatastic Premium. Thanks to your support we'll develop even more awesome features.`,
    `Schalte dieses und viele weitere Features frei, indem du Premium Mitglied wirst. Mit deiner Unterstützung entwickeln wir tolle neue Features!`,
  ],
  [`PAYWALL_GENERIC__CTA`]: [
    `Go Premium`,
    `Premium holen`,
  ],
}
