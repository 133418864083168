export default {
  [`WelcomeModal:Title`]: [
    `Welcome to your new home, {{ name }}!`,
    `Wilkommen im neuen Zuhause, {{ name }}!`,
  ],
  [`WelcomeModal:IntroText`]: [
    `Flatastic helps you organize your Home and live more harmoniously. What do you want to do first?`,
    `Flatastic hilft den Haushalt zu organisieren und das Zusammenleben zu verbessern. Was möchtest du als Erstes tun?`,
  ],
  [`WelcomeModal:CTA:Pinboard`]: [
    `Leave a message for the others`,
    `Eine Nachicht an alle schreiben`,
  ],
  [`WelcomeModal:Founder:CTA:Chores`]: [
    `Organize chores and tasks`,
    `Putzplan anlegen`,
  ],
  [`WelcomeModal:Joiner:CTA:Chores`]: [
    `Check chores and tasks`,
    `Putzplan anschauen`,
  ],
  [`WelcomeModal:Founder:CTA:ShoppingList`]: [
    `Add items to Shopping List`,
    `Einkaufsliste befüllen`,
  ],
  [`WelcomeModal:Joiner:CTA:ShoppingList`]: [
    `Add items to Shopping List`,
    `Einkaufsliste ansehen`,
  ],
  [`WelcomeModal:CTA:Finances`]: [
    `Keep track of Expenses`,
    `Finanzen regeln`,
  ],
}
